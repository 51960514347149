import React, { useContext, useEffect, useMemo, useState } from "react";
import { useStore, useSelector } from "react-redux";
import imxtools from "imxtools";
import { AppContext } from "../../../../../services/AppContext";
import Attribute from "../Attribute";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import DropTargetBody from "../DropTargetBody";
import "./index.scss";
function Path({
  data = [],
  path = {},
  relationship = false,
  currentPath = [],
  profile = false,
}) {
  let store = useStore();
  const { setIsDragingIn, setOpenInspection } = useContext(AppContext);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const [hoverAtt, setHoverAtt] = useState(false);
  const [dataAttributes, setDataAttributes] = useState(
    imxtools?.toArray(data?.attribute)
  );
  useEffect(() => {
    setDataAttributes(imxtools?.toArray(data?.attribute));
  }, [data?.attribute]);
  return (
    <div className={`path-container ${!readOnly && !hoverAtt && "hovered"}`}>
      {data?.attribute &&
        dataAttributes?.map((att, index) => {
          const dataLength = dataAttributes?.length == 1;
          return (
            <DropTargetBody
              accept={"attribute"}
              dragParent={path}
              className="drop-attributes-zone"
              style={{ width: dataLength && "100%", alignItems: "center" }}
            >
              <React.Fragment key={index}>
                <DragItemAttribute
                  index={index}
                  component={"attribute"}
                  dragParentPath={[...path, "path"]}
                  dragData={att}
                  dataAttributes={dataAttributes}
                  setDataAttributes={setDataAttributes}
                  setIsDraging={setIsDragingIn}
                  style={{ width: dataLength && "100%" }}
                >
                  <React.Fragment key={index}>
                    <Attribute
                      profile={profile}
                      data={att}
                      readOnly={readOnly}
                      index={
                        dataAttributes?.length === 1 ? null : index.toString()
                      }
                      path={[...path, "path"]}
                      numAttributes={dataAttributes?.length || 0}
                      relationship={relationship}
                      currentPath={currentPath}
                      setHoverAtt={setHoverAtt}
                      isPathAtt={true}
                    />
                  </React.Fragment>
                </DragItemAttribute>
              </React.Fragment>
              <div style={{ color: "#aaaaaa" }}>
                {" "}
                {index < dataAttributes.length - 1 && " / "}
              </div>
            </DropTargetBody>
          );
        })}
    </div>
  );
}
export default Path;
