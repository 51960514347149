import ConditionsUI from "./ConditionsUI";
import Lookups from "./Lookups";
import Scripts from "./Scripts";

export const utilitiesTabs = [
  { title: "Conditions UI", component: <ConditionsUI /> },
  { title: "Lookups", component: <Lookups /> },
  { title: "Scripts", component: <Scripts /> },
];

export function handleSelectAll(newSelection, setSelectedCards) {
  setSelectedCards(newSelection);
}

export function handleCardClick(index, selectedCards, setSelectedCards) {
  const updatedSelectedCards = selectedCards.includes(index)
    ? selectedCards.filter((cardIndex) => cardIndex !== index)
    : [...selectedCards, index];

  setSelectedCards(updatedSelectedCards);
}

export function handleOpenModal(
  reference,
  index,
  type,
  data,
  path,
  openModal,
  createNew
) {
  const itemData = data.find((item) => item?.reference === reference);
  openModal(type, reference, createNew, itemData, [...path, index?.toString()]);
}
