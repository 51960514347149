import React, { useContext, useEffect, useState } from "react";
import Attribute from "../Attribute";
import imxtools from "imxtools";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import { AppContext } from "../../../../../services/AppContext";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import DropTargetBody from "../DropTargetBody";
import { isSelectedComponent } from "../../services/isSelectedComponent";

function Section({
  data = {},
  path = [],
  display = "column",
  numSetion = 1,
  indexSection = 0,
  currentPath = [],
}) {
  const [dataAttributes, setDataAttributes] = useState([]);
  const { setIsDragingIn } = useContext(AppContext);
  const readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  useEffect(() => {
    setDataAttributes(imxtools?.toArray(data?.attribute));
  }, [data?.attribute]);

  let store = useStore();

 
  return (
    <React.Fragment>
      {Object?.keys(data)?.length > 0 && (
        <React.Fragment>
          <div
            className="section-container"
            style={{
              backgroundColor: data?.style
                ? data?.style.split(":")[1]
                : "white",
            }}
            onClick={(e) =>
              handleComponentProp(
                e,
                numSetion > 1 ? indexSection?.toString() : null,
                data,
                path,
                store,
                "section"
              )
            }
          >
            <DropTargetBody
              accept={"attribute"}
              dragParent={
                numSetion === 1
                  ? [...path, "section"]
                  : [...path, "section", indexSection.toString()]
              }
              className={`drop-section-container ${
                isSelectedComponent(
                  readOnly,
                  numSetion === 1
                    ? [...path, "section"]
                    : [...path, "section", indexSection.toString()],
                  currentPath
                )
                  ? "selectedComponent"
                  : !readOnly && "unSelectedComponent"
              } 
              
              ${!readOnly && "hovered"}`}
            >
              {data?.attribute
                ? dataAttributes?.map((att, index) => {
                    return (
                      <React.Fragment key={index}>
                        <DragItemAttribute
                          index={index}
                          component={"attribute"}
                          dragParentPath={
                            numSetion === 1
                              ? [...path, "section"]
                              : [...path, "section", indexSection.toString()]
                          }
                          dragData={att}
                          dataAttributes={dataAttributes}
                          setDataAttributes={setDataAttributes}
                          setIsDraging={setIsDragingIn}
                        >
                          <Attribute
                            key={index}
                            data={att}
                            currentPath={currentPath}
                            display={display}
                            readOnly={readOnly}
                            fromSection={true}
                            bg={data?.style ? true : false}
                            path={
                              numSetion === 1
                                ? [...path, "section"]
                                : [...path, "section", indexSection?.toString()]
                            }
                            index={
                              dataAttributes?.length === 1
                                ? null
                                : index.toString()
                            }
                            sectionDisplay={
                              data?.display?.toLowerCase() === "row" ?? false
                            }
                          />
                        </DragItemAttribute>
                      </React.Fragment>
                    );
                  })
                : !readOnly && (
                    <DropTargetBody
                      accept={"attribute"}
                      dragParent={
                        numSetion === 1
                          ? [...path, "section"]
                          : [...path, "section", indexSection.toString()]
                      }
                      className="section-empty"
                    >
                      {readOnly ? "No Attribute here" : "Section"}
                    </DropTargetBody>
                  )}
            </DropTargetBody>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Section;
