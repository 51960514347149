import React, { useRef, useState } from "react";
import useToasts from "../../../../../../../components/CustomToast/useToasts";

import "./index.scss";
import xml2js from "minexml2js";
import { useStore, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import CustomButton from "../../../../../../../components/CustomButton";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { getButtonStyles } from "../../../../AppBar/services/getButtonStyles ";
import { useTheme } from "../../../../../../../theme/themeContext";
import HandleImportFile from "../../../services/handleImportFile";

function UploadFiles({ XmlSide }) {
  const { addToast } = useToasts();
  let store = useStore();
  const [side, setSide] = useState(XmlSide.isleft);
  const [filename, setFilename] = useState("");
  const { themeMode, themeColors } = useTheme();
  let hiddenFileInput = useRef(null);
  const { data, readOnly, showRight, showLeft, draftDisplay } = useSelector(
    (state) => ({
      data: state?.entities?.explorer?.skeleton || {},
      readOnly: state?.entities?.explorer?.readOnly || false,
      showLeft: state?.entities?.compareFiles?.leftCode?.active || false,
      showRight: state?.entities?.compareFiles?.rightCode?.active || false,
      draftDisplay: state?.entities?.explorer?.draftDisplay,
    })
  );
  const commonButtonStyles = getButtonStyles(
    readOnly,
    themeColors,
    themeMode,
    false
  );

  function handleClick() {
    hiddenFileInput.current.click();
  }
  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]; // Assuming single file upload, take the first file

    if (!file) {
      // Handle error: No file provided
      addToast("No file selected.", "warn");

      return;
    }

    // Check the file type or extension
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (
      fileType === "text/xml" ||
      fileType === "application/xml" ||
      fileExtension === "xml"
    ) {
      changeHandler(file); // It's an XML file
      // Handle the XML file processing here
    } else {
      // Not an XML file
      // Handle error here
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false, // Set to 'false' if you want to allow only one file at a time
    accept: "text/xml, application/xml",
    noClick: true,
  });

  async function changeHandler(selectedFile) {
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onloadend = async () => {
      try {
        const searchRegExp = new RegExp("&", "g");
        const xmlData = reader.result.replace(searchRegExp, "&amp;");
        const { maxOrder, result } = await new xml2js.Parser({
          explicitArray: false,
          emptyTag: { $: { id: "mockId" } },
        }).parseStringPromise(xmlData);
        HandleImportFile(
          side,
          showLeft,
          showRight,
          draftDisplay,
          xmlData,
          selectedFile,
          store
        );
      } catch (error) {
        addToast("Select a file with XML extension.", "error");
      }
    };
  }

  function handleChange(event = null) {
    if (!event) return;
    const fileUploaded = event?.target?.files[0] || null;
    fileUploaded && changeHandler(fileUploaded);
    hiddenFileInput.current.value = null;
  }
  return (
    <div {...getRootProps()} className="BrowserFileContainer">
      {/* <input {...getInputProps()} style={{ display: "none" }} /> */}
      <div className="BrowserFile-mainContainer">
        <Icon icon={"uploadFile"} size={42} color="black" />
        <div className="BrowserFile-instructions">
          {!isDragActive ? (
            <>
              <p className="BrowserFileLabel">Drop your file here or</p>
              <div className="BrowserFileButton">
                <CustomButton
                  {...commonButtonStyles}
                  label="Browse File"
                  onClick={handleClick}
                  Input={true}
                  handleInput={handleChange}
                  inputRef={hiddenFileInput}
                  backgroundColor={"#4c36a5"}
                  color="white"
                  borderRadius={2}
                  widthBtn={135}
                  paddingVertical={1}
                />
              </div>
            </>
          ) : (
            <>
              <>
                <p className="BrowserFileLabel">Drop the file here...</p>
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadFiles;
