import { getIn, setIn } from "lodash-redux-immutability";
import imxtools from "imxtools";
import removeNulls from "../../../views/Designer/services/helpers/removeNulls";

const explorer = {
  initialState: {
    skeleton: {},
    openDefault: {},
    readOnly: true,
    draftDisplay: false,
    draftSkeleton: {},

    maxOrder: -1,
  },
  reducers: {
    incrementOrder: (dataSet, action) => {
      const { order = 0 } = action.payload;
      dataSet["maxOrder"] = order;
    },
    dataAdded: (dataSet, action) => {
      const { skeleton, readOnly } = action.payload;
      dataSet["skeleton"] = skeleton;
      dataSet["readOnly"] = readOnly;
    },
    updateState: (dataSet, action) => {
      const { draftSkeleton, skeleton, draftDisplay, readOnly } =
        action?.payload;
      dataSet["draftSkeleton"] = draftSkeleton;
      dataSet["readOnly"] = readOnly;
      dataSet["skeleton"] = skeleton;
      dataSet["draftDisplay"] = draftDisplay;
    },

    newSkeleton: (dataSet, action) => {
      dataSet["draftSkeleton"] = {};
      dataSet["readOnly"] = false;
      dataSet["skeleton"] = {};
      dataSet["draftDisplay"] = true;
      dataSet["maxOrder"] = -1;
      dataSet["compare"] = false;
    },
    updateData: (dataSet, action) => {
      const { skeleton, readOnly } = action.payload;
      dataSet["skeleton"] = skeleton;
      dataSet["readOnly"] = readOnly;
    },
    displayDraft: (dataSet, action) => {
      const { draftDisplay } = action?.payload;
      dataSet["draftDisplay"] = draftDisplay;
    },
    addDraftSkeleton: (dataSet, action) => {
      const { skeleton } = action?.payload;
      dataSet["draftSkeleton"] = skeleton;
    },
    moveItem: (dataSet, action) => {
      const { parent, key, dragData, dragPath, dragParentPath } =
        action.payload;
      if (
        dragParentPath?.toString() === parent?.toString() ||
        parent?.length < 1
      ) {
        return;
      }
      const globalSkeleton = dataSet.skeleton;
      const newdata = setIn(globalSkeleton, dragPath, undefined);
      const removenulls = removeNulls(newdata);
      const currentData = getIn(removenulls, [...parent, key]);
      const newData = setIn(
        removenulls,
        [...parent, key],
        [...imxtools?.toArray(currentData), dragData]
      );
      dataSet["skeleton"] = { ...newData };
      dataSet["readOnly"] = false;
    },
    deleteItem: (dataSet, action) => {
      const { currentPath } = action.payload;
      const globalSkeleton = dataSet.skeleton;
      let newData = setIn(globalSkeleton, currentPath, undefined);
      const plusData = removeNulls(newData);
      dataSet["skeleton"] = { ...plusData };
      dataSet["readOnly"] = false;
    },
    deleteItems: (dataSet, action) => {
      const { path, newData } = action.payload;
      const globalSkeleton = dataSet.skeleton;
      const newSkeleton = setIn(globalSkeleton, path, newData);
      dataSet["skeleton"] = newSkeleton;
      dataSet["readOnly"] = false;
    },
    reset: (dataSet) => {
      // if (!dataSet["draftDisplay"]) {
      return (dataSet = {
        skeleton: {},
        openDefault: {},
        readOnly: true,
        draftDisplay: false,
        draftSkeleton: {},
        compare: false,
      });
      // }
    },
    switchItem: (dataSet, action) => {
      const { parentPath, draggedIndex, hoveredIndex } = action.payload;

      // Check if draggedIndex and hoveredIndex are not undefined
      if (
        typeof draggedIndex === "undefined" ||
        typeof hoveredIndex === "undefined"
      ) {
        console.error("draggedIndex or hoveredIndex is undefined");
        return;
      }

      // Navigate to the array using parentPath
      let itemsArray = parentPath.reduce((acc, curr) => acc[curr], dataSet);

      // Additional checks
      if (!Array.isArray(itemsArray) || draggedIndex === hoveredIndex) {
        return;
      }

      // Ensure indices are within the bounds of the array
      if (
        draggedIndex < 0 ||
        draggedIndex >= itemsArray.length ||
        hoveredIndex < 0 ||
        hoveredIndex >= itemsArray.length
      ) {
        return;
      }

      // Remove the dragged item and insert it at the hoveredIndex
      const [item] = itemsArray.splice(draggedIndex, 1);
      itemsArray.splice(hoveredIndex, 0, item);

      // Indicating modification
      dataSet["readOnly"] = false;
    },
    switch: (dataSet, action) => {
      // const { path, itemOrder, skeleton } = action.payload;
      // let list = _.get(skeleton, path);
      // const itemIndex = list.findIndex((item) => item.itemOrder === itemOrder);
      // if (itemIndex === -1) return dataSet;
      // const item = list.splice(itemIndex, 1)[0];
      // list.push(item);
      // const updatedDataSet = _.setWith(_.clone(dataSet), path, list, _.clone);
      // return updatedDataSet;
    },
  },
};

export default explorer;
