import React, { useContext } from "react";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { useStore } from "react-redux";
import { handleComponentProp } from "../../services/handleComponentProperties";
import useToasts from "../../../../../../../components/CustomToast/useToasts";
import "./index.scss";
import { AppContext } from "../../../../../services/AppContext";
import { isSelectedComponent } from "../../services/isSelectedComponent";
function Event({
  data = {},
  path = [],
  index,
  readOnly = true,
  forms = [],
  setTargetForm,
  setOpenForm,
  currentPath = [],
}) {
  let store = useStore();
  const { addToast } = useToasts();
  const { setTargetRefEvent } = useContext(AppContext);

  const openTargetForm = (e, data) => {
    const targetRef = data?.targetref ? data?.targetref?.toLowerCase() : "";
    let foundIndex = -1;
    const foundForm = forms.find((form, index) => {
      if (form?.reference === targetRef) {
        foundIndex = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
    } else {
      setTargetForm(null);
    }
    if (data && data.hasOwnProperty("targetref")) {
      setTargetRefEvent(data?.targetref);
      setOpenForm(true);
    } else {
      addToast("you should add targetref to the event !", "warn");
    }
  };

  return (
    <div
      className={`event ${!readOnly && "hovered"} ${
        isSelectedComponent(
          readOnly,
          index === null ? [...path, "event"] : [...path, "event", index],
          currentPath
        ) && "selectedComponent"
      }`}
      onClick={(e) => handleComponentProp(e, index, data, path, store, "event")}
      onDoubleClick={(e) => openTargetForm(e, data)}
    >
      <div className="event-icon">
        <Icon icon={data?.icon ? data?.icon : "missing"} size={20} />
      </div>
      <div className="event-body">
        {data?.service ? data?.service?.toLowerCase() : "event (missing label)"}
      </div>
    </div>
  );
}

export default Event;
