const dashboard = {
  initialState: {
    availableConfig: false,
    loading: false,
    loggedIn: false,
    getConfigResponse: null,
    isTransactionServer: false,
  },
  reducers: {
    updateAvailableConfig: (dataSet, action) => {
      try {
        const { isAvailableConfig } = action.payload;
        dataSet["availableConfig"] = isAvailableConfig;
      } catch (error) {
        console.error(action.paylaod);
      }
    },
    setLoading: (dataSet, action) => {
      try {
        const { isLoading } = action.payload;
        dataSet["loading"] = isLoading;
      } catch (error) {
        console.error(action.paylaod);
      }
    },
    updateLoginStatus: (dataSet, action) => {
      try {
        const { isLoggedin } = action.payload;
        dataSet["loggedIn"] = isLoggedin;
      } catch (error) {
        console.error(action.paylaod);
      }
    },

    setGetCongfigError: (dataSet, action) => {
      try {
        const { getConfigError } = action.payload;
        dataSet["getConfigResponse"] = getConfigError;
      } catch (error) {
        console.error(action.paylaod);
      }
    },
    setIsTransactionServer: (dataSet, action) => {
      try {
        const { isTransactionServer = false } = action.payload;

        dataSet["isTransactionServer"] = isTransactionServer;
      } catch (error) {
        console.error("error setIsTransactionServer==", action.payload);
      }
    },

    reset: (dataSet) => {
      return (dataSet = {
        availableConfig: false,
        loading: false,
        loggedIn: false,
        getConfigResponse: null,
        isTransactionServer: false,
      });
    },
  },
};

export default dashboard;
