import React, { useEffect, useState } from "react";
import CustomButton from "../../../../../../components/CustomButton";
import "./index.scss";
import useButtonTheme from "../../../../customHooks/useButtonTheme";
import getDebugInfo from "../../../Debugger/services/getDebugInfo";

const TableControlButtons = ({ toggleTable, setToggleTable, tabs }) => {
  const TableControlButton = useButtonTheme("TableControlButton");
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const url = new URL(window.location.href);
    const hostnameWithPort = url?.port
      ? `${url?.hostname}:${url?.port}`
      : url?.hostname;
    setIpAddress(hostnameWithPort);
  }, []);

  return (
    <div className="TableControlButtons">
      <div className="leftButtons">
        {tabs?.map(
          (button, index) =>
            button?.label === "Debug" && (
              <CustomButton
                key={index}
                isActive={index === toggleTable}
                withLabel={false}
                iconName="debug"
                tooltip={"Debug"}
                iconColor={index === toggleTable ? "#FFFFFF" : "#4C36A5"}
                borderRight={true}
                iconSize={25}
                label={button?.label}
                themeColors
                onClick={() => setToggleTable(index)}
                {...TableControlButton}
              />
            )
        )}
        <div className="ipAddress">
          <div className="ipAddressText">IP address:</div> {ipAddress}
        </div>
      </div>
      <div className="rightButtons">
        {tabs?.map(
          (button, index) =>
            button?.label !== "Debug" && (
              <CustomButton
                key={index}
                isActive={index === toggleTable}
                label={button?.label}
                onClick={() => setToggleTable(index)}
                {...TableControlButton}
              />
            )
        )}
      </div>
    </div>
  );
};

export default TableControlButtons;
