function transformJson(obj, addToast) {
  try {
    if (!obj || typeof obj !== "object") {
      return {};
    }
    if (obj?.length === 0) {
      return {};
    }

    let newObj = {};

    for (var ogKey in obj) {
      if (Array.isArray(obj[ogKey])) {
        let test = [];
        for (var i in obj[ogKey]) {
          test.push(transformJson(obj[ogKey][i]));
        }
        newObj[ogKey] = [...test];
      } else if (typeof obj[ogKey] === "object") {
        newObj[ogKey] = transformJson(obj[ogKey]);
      } else {
        // Move all key-value pairs into the "$" sub-object without excluding any keys
        newObj["$"] = newObj["$"] || {};
        newObj["$"][ogKey] = obj[ogKey];
      }
    }
    return newObj;
  } catch (error) {
    console.error("Error in transformForXml:", error);
    addToast(`Error in transformForXml2: ${error}`, "error");
    return {};
  }
}

export default transformJson;
