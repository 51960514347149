function Logout(store, navigate) {
  localStorage.removeItem("authorization");
  store.dispatch({
    type: "dashboard/updateLoginStatus",
    payload: {
      isLoggedin: false,
    },
  });
  navigate("/");
}

export default Logout;
