import { useSelector, useStore } from "react-redux";
import { useLanguage } from "../../../Translate/LanguageContext";
import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { dachboardSignUp } from "../services/index";
import Icon from "../../../components/CustomIcon/Icon";
import { useTheme } from "../../../theme/themeContext";
import CustomInput from "../../../components/CustomInput";
import backgroundLightLTR from "../../../assets/auth-light-ltr.png";
import backgroundLightRTL from "../../../assets/auth-light-rtl.png";
import backgroundDarkLTR from "../../../assets/auth-dark-ltr.png";
import backgroundDarkRTL from "../../../assets/auth-dark-rtl.png";
import Footer from "../Cardfooter/index";
import { HandleStepChange } from "../../Installer/services/stepChange/stepChange";

function Register() {
  const { themeMode } = useTheme();
  const isDarkMode = themeMode === "dark";
  const [loginMessage, setLoginMessage] = useState("");
  const store = useStore();
  const { translate } = useLanguage();
  const [showDropdown, setShowDropdown] = useState(false);

  let language = useSelector(
    (state) => state?.entities?.settings?.language || "en"
  );

  useEffect(() => {
    document.addEventListener("click", handleLanguageDropdownClick);

    return () => {
      document.removeEventListener("click", handleLanguageDropdownClick);
    };
  }, [showDropdown]);

  const updateLoginStatus = () => {
    store.dispatch({
      type: "dashboard/updateLoginStatus",
      payload: {
        isLoggedin: true,
      },
    });
  };

  function goInstaller() {
    store.dispatch({
      type: "checkMaximoConfig",
    });
  }

  const languages = [
    { LangName: "English", LangSymbole: "EN", lang: "en" },
    { LangName: "Français", LangSymbole: "FR", lang: "fr" },
    { LangName: "Deutsch", LangSymbole: "AL", lang: "al" },
    { LangName: "العربية", LangSymbole: "ع", lang: "ar" },
    { LangName: "Italiano", LangSymbole: "IT", lang: "it" },
  ];

  function toggleDropdown() {
    setShowDropdown(!showDropdown);
  }

  const handleLanguageDropdownClick = (event) => {
    if (!event.target.closest(".LanguageBtn")) {
      setShowDropdown(false);
    }
  };

  function changeLanguage(lang) {
    store.dispatch({
      type: "settings/changeLanguage",
      payload: {
        language: lang,
      },
    });
    setShowDropdown(false);
  }

  return (
    <div
      className="login-screen"
      style={{
        backgroundImage: `url(${
          themeMode?.toLowerCase() === "dark"
            ? language?.toLowerCase() === "ar"
              ? backgroundDarkRTL
              : backgroundDarkLTR
            : language?.toLowerCase() === "ar"
            ? backgroundLightRTL
            : backgroundLightLTR
        })`,
      }}
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
          ConfirmPassword: "",
        }}
        onSubmit={(values) => {
          setLoginMessage("");
          dachboardSignUp(values.email, values.password, values.ConfirmPassword)
            .then((response) => {
              if (response?.success) {
                updateLoginStatus();
                goInstaller();
                HandleStepChange (store, "login") 
              } else {
                setLoginMessage(
                  response?.message?.message ?? response?.message
                );
              }
            })
            .catch((error) => {
              setLoginMessage(error?.message);
            });
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <div
              className={`form-card ${
                language?.toLowerCase() === "ar" && "rtl-card"
              }`}
            >
              <div
                className="card-header"
                style={{
                  flexDirection:
                    language?.toLowerCase() === "ar" && "row-reverse",
                }}
              >
                <div className="void"></div>
                <div className="login-logo">
                  <Icon className="logo" icon="BlackLogo" />
                </div>
                <div
                  className="LanguageBtn"
                  style={{
                    flexDirection:
                      language?.toLowerCase() === "ar" && "row-reverse",
                  }}
                >
                  <div className="LanguageLabel" onClick={toggleDropdown}>
                    {language === "en" ? "EN" : "ع"}
                  </div>
                  <div className="LanguageIcon" onClick={toggleDropdown}>
                    <Icon className="languageGlobe" icon="language" size={30} />
                  </div>
                  {showDropdown && (
                    <div className="language-dropdown">
                      {languages?.map((lang, index) => {
                        return (
                          <div
                            key={index}
                            className={`lang-item ${
                              language === lang?.lang && "active-lang"
                            }`}
                            onClick={() =>
                              changeLanguage(lang?.lang?.toLowerCase())
                            }
                          >
                            <div className="lang-name">{lang?.LangName}</div>
                            <div className="lang-symbole">
                              {lang?.LangSymbole}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="card-content">
                <CustomInput
                  label={translate("Email")}
                  required
                  value={values.email}
                  language={language}
                  onChange={handleChange("email")}
                >
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="errorMessage"
                  />
                </CustomInput>
                <CustomInput
                  required
                  label={translate("password")}
                  value={values.password}
                  type="password"
                  onChange={handleChange("password")}
                  language={language}
                >
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="errorMessage"
                  />
                </CustomInput>
                <CustomInput
                  name="ConfirmPassword"
                  required
                  label={translate("ConfirmPassword")}
                  value={values.ConfirmPassword}
                  type="password"
                  onChange={handleChange("ConfirmPassword")}
                  language={language}
                >
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="errorMessage"
                  />
                </CustomInput>
                <div className="response-error">
                  <span className="login-error">{loginMessage}</span>
                </div>
              </div>
              <button
                className={Formik.isValid ? "submitButton" : "submitButton"}
                disabled={Formik.isValid}
                data-testid="ConnectButton"
                type="submit"
              >
                <span className="ConnectButton-text">
                  {translate("Sign up")}
                </span>
              </button>
              <Footer />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Register;
