import React from "react";

const CustomCursorDiv = ({ svgCursor, children, style }) => {
  const cursorStyle = {
    cursor: `url(${svgCursor}), auto`,
    ...style,
  };

  return <div style={cursorStyle}>{children}</div>;
};

export default CustomCursorDiv;
