export const slicesArray = [
  'installer',
  'explorer',
  'editor',
  'revision',
  'dashboard',
  'settings',
  'deviceview',
  'codeeditor',
  'panelDisplay',
  'layouts',
  'compareFiles',
  'interceptor',
];
export const listToBeReseted = ['explorer', 'revision', 'deviceview'];
