const exitInstaller = (store, navigate) => {
  store.dispatch({
    type: "installer/reset",
  });
  store.dispatch({
    type: "dashboard/reset",
  });
  store.dispatch({
    type: "checkMaximoConfig",
  });
  navigate("/");
};
export default exitInstaller;
