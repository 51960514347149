export function filterPageData(obj = {}) {
  try {
    const keysToFilter = ["app", "kpi", "module", "row"];
    return Object.keys(obj).reduce((result, key) => {
      if (keysToFilter.includes(key)) {
        result[key] = obj[key];
      }
      return result;
    }, []);
  } catch (error) {
    console.error("Error in filterPageData:", error);
    return {};
  }
}
