import React, { useContext, useRef } from "react";
import { useDrop } from "react-dnd";

import "./index.scss";
import { AppContext } from "../../../../../services/AppContext";

const DropTargetApps = ({
  children,
  style = {},
  pages = [],
  currentPage,
  className = "",
}) => {
  const { setInsideInner } = useContext(AppContext);

  const [, drop] = useDrop({
    accept: [],

    drop: (item = { dragParentPath: [] }) => {},
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      if (pages?.length === 2 && (pages?.[0]?.gis || pages?.[1]?.gis)) {
        if (pages?.length > 1) {
          setInsideInner(["maxapps", "page"]);
        } else {
          setInsideInner(["maxapps", "page"]);
        }
      } else {
        if (pages.length > 1) {
          setInsideInner(["maxapps", "page", String(currentPage)]);
        } else {
          setInsideInner(["maxapps", "page"]);
        }
      }
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const ref = useRef();
  drop(ref);
  const finalStyle = {
    ...style,
  };
  return (
    <div style={finalStyle} ref={ref} className={className}>
      {children}
    </div>
  );
};

export default DropTargetApps;
