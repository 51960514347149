import React, { useRef } from "react";
// import Chart from "chart.js/auto"; // don't remove this !!
import { Line } from "react-chartjs-2";
import { dataCurve } from "../../../../../../../../../utils/chartFakeData";
import "./index.scss";

function Curve({
  size = "small",
  label = "Add label",
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  const chartRef = useRef(null);

  const updateGradient = () => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, "#6A549E");
      gradient.addColorStop(1, "#FFFFFF"); 

      chart.data.datasets[0].backgroundColor = gradient;
      chart.update();
    }
  };

  const optionsCurve = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      onComplete: () => updateGradient(), 
    },
    elements: {
      point: {
        radius: 0, 
      },
      line: {
        tension: 0.4, 
      },
    },
    plugins: {
      legend: {
        display: false, 
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    layout: {
      padding: 4,
    },
  };

  return (
    <div
      className={`curve-graph ${
        size?.toLowerCase() !== "small" && "curve-large"
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className="curve-graph-label">{label}</div>
      <div className="curve-graph-chart">
        <Line ref={chartRef} data={dataCurve} options={optionsCurve} />
      </div>
    </div>
  );
}

export default Curve;
