import React from 'react';

const ExecutionTime = ({ selectedRequest }) => {
  const executionTime =
    selectedRequest?.response?.responseMetadata?.execTimeSeconds;

  return (
    <div
      className="response-options-container"
      style={{ backgroundColor: '#f4f2f9', border: 'none' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h4 style={{ color: '#4C36A5', fontSize: '20px', fontWeight: '500' }}>
          <span>Execution Time</span>
        </h4>
        <p
          style={{
            color: '#39354A',
            fontWeight: '400',
            fontSize: '16px ',
          }}
        >
          <span>Time:</span> {executionTime}s
        </p>
      </div>
    </div>
  );
};

export default ExecutionTime;
