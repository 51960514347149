import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as DeviceComponents from "./components/DeviceComponents";
import Home from "./components/screens/HomeScreen";
import "./index.scss";
import PhoneFrame from "./components/PhoneFrame";
import Drawer from "./components/Drawer";
import DeleteModal from "../../Properties/components/DeleteModal";
import { useCopyPaste } from "../../../services/CopyPasteContext";

const DeviceView = () => {
  const [openModal, setOpenModal] = useState(false);
  const {pasteComponent, copyComponent, copiedComponent } =
    useCopyPaste();

  const { edit, currentComponent, readOnly } = useSelector((state) => ({
    edit: state?.entities?.editor || {},
    currentComponent:
      state?.entities?.deviceview?.currentComponent || "maxapps",
    readOnly: state?.entities?.explorer?.readOnly || false,
  }));

  const {
    dataToSet: data,
    parentToEdit: parent,
    component: componentEdited,
  } = edit;

  useEffect(() => {
    const onKeyDown = (e) => {
      const activeElement = document.activeElement;
      const isInputFocused =
        activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA";
  
      try {
        if (!readOnly) {
          if ((e.ctrlKey || e.metaKey) && e.key === "c") {
            copyComponent(data, componentEdited);
          } else if (
            (e.ctrlKey || e.metaKey) &&
            e.key === "v" &&
            !isInputFocused &&
            Object?.keys(copiedComponent)?.length > 0
          ) {
            pasteComponent(parent);
          } else if (
            (e.key === "Delete" || e.key === "Backspace") &&
            !isInputFocused &&
            componentEdited
          ) {
            setOpenModal(true);
          }
        }
      } catch (error) {
        console.error(`Error handling keydown event: ${error}`);
      }
    };
  
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [
    readOnly,
    data,
    componentEdited,
    copiedComponent,
    copyComponent,
    pasteComponent,
    parent,
    setOpenModal,
  ]);
  

  const RenderScreenComponent = () => {
    if (
      Object.prototype.hasOwnProperty.call(DeviceComponents, currentComponent)
    ) {
      const TargetComponet = DeviceComponents[currentComponent];
      return (
        <React.Fragment>
          <TargetComponet />
          {currentComponent !== "LoginScreen" &&
            currentComponent !== "SplashScreen" && <Drawer />}
        </React.Fragment>
      );
    } else {
      return <Home />;
    }
  };

  return (
    <React.Fragment>
      <div className="DeviceView">
        <PhoneFrame>
          <div className="mobileDevice">{RenderScreenComponent()}</div>
        </PhoneFrame>
      </div>
      <DeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={data}
        component={componentEdited}
        path={parent}
      />
    </React.Fragment>
  );
};

export default DeviceView;
