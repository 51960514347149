import React from 'react';
import CustomButton from '../../../../../components/CustomButton';
const KeyCopy = ({ text, value, tooltip = '' }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span className="response-area-keys">{text}:</span>
      {/* copy button */}

      <CustomButton
        iconName="copy"
        iconSize={20}
        iconColor="rgba(57, 53, 74, 1)"
        tooltip={tooltip ? tooltip : 'Copy'}
        style={{
          marginLeft: '10px',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '0',
        }}
        onClick={() => {
          navigator.clipboard.writeText(
            typeof value === 'object' ? JSON.stringify(value) : value
          );
        }}
      />
    </div>
  );
};

export default KeyCopy;
