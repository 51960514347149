import React from "react";
import "./index.scss";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { isSelectedComponent } from "../../services/isSelectedComponent";

function SearchBar({
  handleInputSearch = () => {},
  search = false,
  setSearch = () => {},
  currentPath = [],
  path = [],
  adSearchIndex = null,
  numEvent = 0,
  readOnly = true,
}) {
  return (
    <div className="searchbar">
      <div
        className={`icon-container ${
          isSelectedComponent(
            readOnly,
            numEvent > 1
              ? [...path, "event", adSearchIndex]
              : [...path, "event"],
            currentPath
          ) && "selectedComponent"
        }`}
        onClick={(event) => handleInputSearch(event)}
        onDoubleClick={() => setSearch(!search)}
      >
        <Icon icon="times2" size={15} className="timesIcon2" />
      </div>
      <div className="searchInput">
        <div className="icon-filter">
          <Icon icon="filter" size={20} />
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
