import xml2js from "minexml2js";
import transformForXmlwithId from "./transformForXmlwithId";

/**
 * Parses an XML string and returns the first found value of an 'id' attribute.
 * @param {string} xmlString The XML content as a string.
 * @returns {string|null} The value of the first 'id' attribute found, or null if an error occurs.
 */
export function getIdValue(xmlString) {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser?.parseFromString(xmlString, "text/xml");
    const firstIdElement = xmlDoc?.querySelector("[id]");
    return firstIdElement ? firstIdElement?.getAttribute("id") : null;
  } catch (error) {
    console.error("Error in getIdValue:", error);
    return null;
  }
}

/**
 * Finds the line number of a given id within an XML content.
 * @param {string} xmlContent The XML content as a string.
 * @param {string} id The 'id' value to search for.
 * @returns {number} The line number where the 'id' is found, or -1 if not found or an error occurs.
 */
export function findLineOfId(xmlContent, id) {
  try {
    const lines = xmlContent?.split("\n");
    return lines?.findIndex((line) => line?.includes(`id="${id}"`)) + 1;
  } catch (error) {
    console.error("Error in findLineOfId:", error);
    return -1;
  }
}

/**
 * Converts JSON data to XML format.
 * @param {Object} jsonData JSON object to be converted to XML.
 * @returns {string} The converted XML string, or an empty string if an error occurs.
 */
export function json2xml(componentEdited = "", jsonData = {}, addToast) {
  try {
    const data = { [componentEdited]: jsonData };
    const builder = new xml2js.Builder({ headless: true });
    let xml = builder?.buildObject(transformForXmlwithId(data, addToast));
    xml = xml?.replace(new RegExp("&amp;", "g"), "&"); // Replace all occurrences of '&amp;' with '&'
    return xml;
  } catch (error) {
    console.error("Error converting JSON to XML:", error);
    return "";
  }
}

export function JsonToXML(jsonData = {}, addToast = () => {}) {
  const builder = new xml2js.Builder();
  const xml = builder?.buildObject(transformForXmlwithId(jsonData, addToast));
  const finalXML = xml?.replace(new RegExp("&amp;", "g"), "&");
  return finalXML;
}
