import React from "react";
import "./index.scss";
import MediaButton from "./MediaButton";

function MediaForm({ data = {}, path = [] }) {
  const supportedMediaTypes = [
    "gallery",
    "video",
    "camera",
    "audio",
    "attachment",
    "image",
    "file",
  ];

  return (
    <>
      {Object.entries(data).map(([type, dataMedia], index) => {
        const lowerCaseType = type.toLowerCase();
        if (
          supportedMediaTypes.includes(lowerCaseType) &&
          dataMedia !== undefined
        ) {
          return (
            <React.Fragment key={index}>
              <MediaButton
                key={index}
                data={dataMedia}
                type={type}
                path={path}
              />
            </React.Fragment>
          );
        } else {
          return (
            <div
              style={{
                minHeight: 25,
                width: "auto",
                maxWidth : "70%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            ></div>
          );
        }
      })}
    </>
  );
}

export default MediaForm;
