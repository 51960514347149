import fileDownload from "js-file-download";
import JSZip from "jszip";

async function handleDownload(iconsListing, iconsSelected, addToast) {
  if (iconsSelected.length === 0) {
    addToast("No icons selected for download", "warn");
    return;
  }

  if (iconsSelected.length > 1) {
    const zip = new JSZip();
    for (const icon of iconsSelected) {
      const toDownloadIcon = iconsListing.find(
        (iconListed) => iconListed === icon
      );
      if (toDownloadIcon) {
        try {
          const response = await fetch(
            `/assets/getSingleIcon/${toDownloadIcon.iconName}`
          );
          const blob = await response.blob();
          zip.file(toDownloadIcon.iconName, blob);
        } catch (error) {
          addToast("Error downloading file: " + toDownloadIcon.iconName);
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      fileDownload(content, "icons.zip");
    });
  } else {
    const toDownloadIcon = iconsListing.find(
      (iconListed) => iconListed === iconsSelected[0]
    );
    if (toDownloadIcon) {
      try {
        const response = await fetch(
          `/assets/getSingleIcon/${toDownloadIcon.iconName}`
        );
        const blob = await response.blob();
        fileDownload(blob, toDownloadIcon.iconName);
      } catch (error) {
        addToast("Error downloading file: " + toDownloadIcon.iconName);
      }
    }
  }
}

export default handleDownload;
