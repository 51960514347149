import SamlLogin from "./SamlLogin";
import SecureLogin from "./SecureLogin";
import StandardLogin from "./StandardLogin";
import * as Yup from "yup";

import validateUrl from "./services/validateUrl";

export const tabs = [
  { title: "Standard Login", component: <StandardLogin />, label: "Test URLs" },
  { title: "Secure Login", component: <SecureLogin />, label: "Test URLs" },
  { title: "SAML Login", component: <SamlLogin />, label: "Connect" },
];

export const SamlFields = [
  { label: "SAML URL", name: "url", type: "text" },
  { label: "APIKey", name: "apikey", type: "text" },
  { label: "OSLC URL", name: "oslcUrl", type: "text" },
  { label: "MAXRest URL", name: "maxRestUrl", type: "text" },
];
export const ApiKeyFields = [
  { label: "Maximo URL", name: "url", type: "text" },
  { label: "Username", name: "username", type: "text" },
  { label: "APIKey", name: "userApiKey", type: "text" },
  { label: "OSLC URL", name: "oslcUrl", type: "text" },
];
export const SFields = [
  { label: "Maximo URL", name: "url", type: "text" },
  { label: "Username", name: "username", type: "text" },
  { label: "Password", name: "password", type: "password" },
  { label: "Oslc URL", name: "oslcUrl", type: "text" },
  { label: "MaxRest URL", name: "maxRestUrl", type: "text" },
];

export const SInitialValues = {
  username: "",
  password: "",
  url: "",
  oslcUrl: "",
  maxRestUrl: "",
};
export const ApiKeyInitialValues = {
  url: "",
  username: "",
  userApiKey: "",
  oslcUrl: "",
};
export const SamlInitialValues = {
  url: "",
  apikey: "",
  oslcUrl: "",
  maxRestUrl: "",
  apikey: "",
};

export const SamlLoginSchema = Yup.object().shape({
  url: Yup.string().required("*Required Field"),
  apikey: Yup.string().required("*Required Field"),
  oslcUrl: Yup.string().required("*Required Field"),
  maxRestUrl: Yup.string().required("*Required Field"),
});
export const ApiKeyLoginSchema = Yup.object().shape({
  url: Yup.string().required("*Required Field"),
  userApiKey: Yup.string().required("*Required Field"),
  oslcUrl: Yup.string().required("*Required Field"),
  username: Yup.string().required("*Required Field"),
});
export const SLoginSchema = Yup.object().shape({
  username: Yup.string().required("*Required Field"),
  password: Yup.string().required("*Required Field"),
  url: Yup.string()
    .required("*Required Field")
    .test("is-valid-url", "Please enter a valid URL !", (value) =>
      validateUrl(value)
    ),
});
