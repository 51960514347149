import React, { useState } from "react";
import "./index.scss";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import * as Tabs from "./Views";
import { Settings } from "./Views";
import { useSelector, useStore } from "react-redux";
import { useDeviceTheme } from "../../../../../../../../DeviceTheme/DeviceThemeContext";
import { useLanguageDevice } from "../../../services/DeviceTranslate/LanguageContext";

function SettingsScreen() {
  const [currentTab, setCurrentTab] = useState("Settings");
  const { theme, themes } = useDeviceTheme();
  let store = useStore();
  const { translate } = useLanguageDevice();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const listSettingsTabs = [
    translate("Settings"),
    translate("Profile"),
    translate("About"),
    translate("Contact Us"),
    translate("PrivacyPolicy"),
  ];

  const RenderViewComponent = () => {
    const TargetComponent = Tabs[currentTab] || Settings;
    return <TargetComponent />;
  };

  const handleNavigateBack = (e) => {
    e.stopPropagation();
    store.dispatch({
      type: "deviceview/navigateBack",
    });
  };
  return (
    <div className="SettingsScreen">
      <div
        className="headerSettings"
        style={{
          flexDirection: language?.toLowerCase() === "ar" && "row-reverse",
        }}
      >
        <div className="goBack" onClick={(e) => handleNavigateBack(e)}>
          <Icon
            icon={
              language?.toLowerCase() === "ar"
                ? "chevron-right"
                : "chevron-left"
            }
            size={20}
          />
        </div>
        <div className="tabsList">
          {listSettingsTabs?.map((tab, index) => {
            return (
              <div
                key={index}
                className="tabButtom"
                style={{
                  borderBottom:
                    currentTab === tab
                      ? `2px solid ${themes?.[theme]?.primary}`
                      : null,
                  color: currentTab === tab ? themes?.[theme]?.primary : null,
                  fontWeight: currentTab === tab ? 700 : 400,
                }}
                onClick={() => setCurrentTab(tab)}
              >
                {tab}
              </div>
            );
          })}
        </div>
      </div>
      <div className="contentSettings">{RenderViewComponent()}</div>
    </div>
  );
}

export default SettingsScreen;
