import { Override } from "./Override";

import { Maxapps } from "./Maxapps";
import { Ruby } from "./Ruby";
import { Emerald } from "./Emerald";
import { MaxappsDark } from "./MaxappsDark";
import { Mood } from "./Mood";
import { Mechanical } from "./Mechanical";
import { Ocean } from "./Ocean";
import { Gray } from "./Gray";
import { DarkSea } from "./DarkSea";
import { GoldSilver } from "./GoldSilver";
import { GrayTwo } from "./GrayTwo";
import { Bluenorange } from "./Bluenorange";
import { Orange } from "./Orange";
import { Imoka } from "./Imoka";
import { Adariona } from "./Adariona";
import { Higanta } from "./Higanta";
import { Eshar } from "./Eshar";
import { Kori } from "./Kori";
import { Ason } from "./Ason";
import { Omnis } from "./Omnis";
import { Haaj } from "./Haaj";
import { Caio } from "./Caio";
import { Lyfe } from "./Lyfe";
import { Jouf } from "./Jouf";
import { Ola } from "./Ola";
import { Sydec } from "./Sydec";
import { Avocado } from "./Avocado";
import { Qiddiya } from "./Qiddiya";
import { Jeddah } from "./Jeddah";
import { Historic } from "./Historic";
import { Resine } from "./Resine";
import { Figrus } from "./Figrus";
import { Luaris } from "./Luaris";
import { Gamboge } from "./Gamboge";
import { King } from "./King";
import { Thermic } from "./Thermic";
import { Caelus } from "./Caelus";
import { Mira } from "./Mira";

export const colors = {
  Maxapps,
  Ruby,
  Emerald,
  MaxappsDark,
  Mood,
  Mechanical,
  Ocean,
  Gray,
  DarkSea,
  GoldSilver,
  GrayTwo,
  Bluenorange,
  Orange,
  Imoka,
  Adariona,
  Higanta,
  Eshar,
  Kori,
  Ason,
  Omnis,
  Haaj,
  Caio,
  Lyfe,
  Jouf,
  Ola,
  Sydec,
  Avocado,
  Qiddiya,
  Jeddah,
  Historic,
  Resine,
  Figrus,
  Luaris,
  Gamboge,
  King,
  Thermic,
  Caelus,
  Mira,
  Override,
};
