import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Lottie from "react-lottie";
import Loading from "../../assets/lottie-files/loader.json";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import "./Login/index.scss";

function DashboardAuth() {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const store = useStore();

  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };
  const handleRetry = () => {
    store.dispatch({
      type: "checkMaximoConfig",
    });
  };

  const checkAvailableConfig = useSelector(
    (state) => state?.entities?.dashboard?.availableConfig
  );

  const getConfigStatus = useSelector(
    (state) => state?.entities?.dashboard?.getConfigResponse
  );

  const isLoading = useSelector((state) => state?.entities?.dashboard?.loading);

  const isLoggedin = localStorage.getItem("authorization") ? true : false;

  let navigate = useNavigate();

  useEffect(() => {
    if (checkAvailableConfig && isLoggedin) navigate("/installer");
    if (getConfigStatus) {
      openDialog();
    }
  }, [checkAvailableConfig, isLoggedin, navigate, getConfigStatus, isLoading]);

  if (checkAvailableConfig === null || isLoading) {
    return (
      <div className="loading-page" data-testid="DashboardAuth">
        <Lottie
          options={LoadingOptions}
          height={100}
          width={100}
          isClickToPauseDisabled={true}
        />
        ;
      </div>
    );
  }
  if (getConfigStatus) {
    return (
      <div className="loading-page" data-testid="DashboardAuth">
        <AlertDialog
          dialogHeader={getConfigStatus ?? ""}
          show={showDialog}
          CloseDialog={closeDialog}
          type="alert"
        >
          <div className="close-alert-error">
            <button
              className="close-btn"
              onClick={(e) => {
                e.stopPropagation();
                handleRetry();
              }}
              data-testid="No"
            >
              <span className="dialog-btn-text">OK</span>
            </button>
          </div>
        </AlertDialog>
      </div>
    );
  }
  return (
    <div data-testid="DashboardAuth">
      {checkAvailableConfig ? <Login /> : <Register />}
    </div>
  );
}

export default DashboardAuth;
