export const handlePage = (
  e,
  activePage,
  setPath,
  store,
  pages,
  numPages,
  skeleton
) => {
  try {
    e.stopPropagation();
    const data = pages[activePage];
    const path = pages[activePage]?.gis
      ? ["maxapps", "gis"]
      : (numPages === 1 && !skeleton?.gis) || (numPages === 2 && skeleton?.gis)
      ? ["maxapps", "page"]
      : ["maxapps", "page", activePage?.toString()];
    setPath(path);
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: pages[activePage]?.gis ? data?.gis : data,
        parent: path,
        component: pages[activePage]?.gis ? "gis" : "page",
      },
    });
  } catch (error) {
    console.error("Error in handlePage:", error);
  }
};
