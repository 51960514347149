export const Haaj = {
  themeName: "Haaj",
  isDark: false,
  CardViewBgColor: "#D3B97610",
  secondaryLight: "#0B452280",
  primary: "#D3B976",
  iconAppItem: "#D3B976",
  AppText: "#343231",
  connectBtn: "#D3B976",
  loader: "#D3B976",
  headerColor: "#D3B976",
  primaryLight: "#D3B97650",
  primaryColor: "#D3B976B2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#343231",
  focusedBorderLoginInput: "#D3B976",
  focusedLabelLoginInput: "#D3B976",
  resetInputLogin: "#D3B976",

  languageIcon: "#343231",

  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#AE8C4C",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#343231",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#D3B976",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#D3B976",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#D3B97650",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#D3B976",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#EEEEEE1A",

  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#fff",
  shadowBtnConnect: "#343231",
  bnConnect: "#D3B976",
  notfocusedBorderLoginInput: "#ffffff",
  loginFooter: "#343231",
  headerPrimary: "#D3B97600",
  headerDark: "#AE8C4C00",
  headerText: "#D3B976",
  notificationUpColor: "#FDFBF7",
  notificationDownColor: "#F9F5EC",

  listItemNotificationUpColor: "#FBF9F3",
  listItemNotificationDownColor: "#F6F1E4",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F3F1",

  notFocusedSocialMedia: "#D3B976",
  focusedSocialMedia: "#D3B976cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#D3B976",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
