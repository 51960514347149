export const dataChartMultiBar = {
  labels: ["PENDING...", "NEW", "INPRO...", "CLOSED..."],
  datasets: [
    {
      label: "Dataset 1",
      data: [28, 34, 0, 0],
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
    },
    {
      label: "Dataset 2",
      data: [0, 0, 2, 2],
      backgroundColor: "rgba(153, 102, 255, 0.2)",
      borderColor: "rgba(153, 102, 255, 1)",
      borderWidth: 1,
    },
  ],
};

export const dataCurve = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First dataset",
      fill: true,
      lineTension: 0.1, // Adjust for sharpness; closer to 0 for sharper curves
      backgroundColor: "rgba(106, 84, 158, 0.5)", // This will be overridden by the gradient
      borderColor: "rgba(106, 84, 158, 1)", // Line color
      borderWidth: 2,
      pointRadius: 0, // Hide the data points
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

export const dataProgress = {
  datasets: [
    {
      data: [10, 20, 30, 40, 50],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ],
      borderWidth: 1,
      cutout: "90%",
    },
    {
      data: [50, 40, 30, 20, 10],
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(255, 206, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ],
      borderWidth: 1,
      cutout: "75%",
    },
  ],
};

export const dataPie = [
  ["Pac Man", "Percentage"],
  ["", 75],
  ["", 10],
  ["", 5],
  ["", 5],
  ["", 5],
];

export const dataStacked = {
  labels: ["BEDFORD", "--"],
  datasets: [
    {
      label: "NEW",
      data: [25, 0],
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
    },
    {
      label: "CLOSED",
      data: [463, 0],
      backgroundColor: "rgba(153, 102, 255, 0.2)",
      borderColor: "rgba(153, 102, 255, 1)",
      borderWidth: 1,
    },
    {
      label: "INPROG",
      data: [0, 89],
      backgroundColor: "rgba(255, 159, 64, 0.2)",
      borderColor: "rgba(255, 159, 64, 1)",
      borderWidth: 1,
    },
    {
      label: "PENDING",
      data: [0, 25],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
    },
  ],
};
