const codeeditor = {
  initialState: {
    fullScreen: false,
    theme: "vs-dark",
    fontSize: "16",
  },
  reducers: {
    fullScreen: (dataSet, action) => {
      const { fullScreen } = action.payload;
      dataSet["fullScreen"] = fullScreen;
    },
    updateTheme: (dataSet, action) => {
      const { theme } = action.payload;
      dataSet["theme"] = theme;
    },
    updateFontSize: (dataSet, action) => {
      const { fontSize } = action.payload;
      dataSet["fontSize"] = fontSize;
    },
  },
};

export default codeeditor;
