export const optionsMultiBar = {
  responsive: true,
  // indexAxis: "y",
  plugins: {
    legend: {
      display: false, // This will hide the legend
    },
    title: {
      display: false, // This will hide the title
    },
    tooltip: {
      enabled: false, // This will hide the tooltip
    },
    datalabels: {
      display: true,
      align: "end",
      anchor: "end",
    },
  },
  scales: {
    x: {
      display: true, // Only show the x-axis
      grid: {
        display: false, // Hide x-axis grid lines
      },
      beginAtZero: true, // Ensures the scale starts at 0
      ticks: {
        font: {
          size: 6, // Change this value to adjust the font size of the labels
        },
      },
    },
    y: {
      display: false, // Hide the y-axis
    },
  },
};

export const optionsProgress = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

export const optionsPie = {
  legend: "none",
  pieSliceText: "none",
  pieStartAngle: 135,
  tooltip: { trigger: "none" },
  slices: {
    0: { color: "yellow" },
    1: { color: "green" },
    2: { color: "pink" },
    3: { color: "violet" },
    4: { color: "transparent" },
  },
};

export const optionsStacked = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",

      labels: {
        usePointStyle: true,
        color: "#222222", // à revenir
        font: {
          size: 6,
        },
      },
    },
    tooltip: {
      enabled: true,
    },
    datalabels: {
      color: "#36A2EB",
      anchor: "end",
      align: "top",
      formatter: function (value, context) {
        return value > 0 ? value : null;
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
  },
};
