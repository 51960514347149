import React from "react";
import Icon from "../../../../../../../../../components/CustomIcon/Icon";
import { handleComponentProp } from "../../../../services/handleComponentProperties";
import { useStore } from "react-redux";
import "./index.scss";

function MediaButton({ data = {}, path = [], type = "" }) {
  let store = useStore();
  return (
    <div
      className="mediaBtn"
      onClick={(event) =>
        handleComponentProp(event, null, data, path, store, type)
      }
    >
      <Icon
        icon={type.toLowerCase() === "attachment" ? "file" : type?.toString()}
        size={22}
      />
    </div>
  );
}

export default MediaButton;
