import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import DropTarget from "../../services/DropTarget";
import { ComponentsLibraries } from "../../../../../services/common";
import PageBtn from "./PageBtn";
import DragPage from "../../../../ComponentsLibrary/DragPage";
import { useSelector, useStore } from "react-redux";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { isHoveredComponent } from "../../services/isHoveredComponent";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { AppContext } from "../../../../../services/AppContext";

function PageNav({
  path,
  pages = [],
  setPages = () => {},
  numPages = 0,
  readOnly = true,
  handlePage = () => {},
  indexGis,
  isGIS = false,
  setPath,
  skeleton = {},
  onlyPages = [],
}) {
  const [hoverStates, setHoverStates] = useState([]);
  let store = useStore();
  const scrollRef = useRef(null);
  const { setKpiDetail } = useContext(AppContext);
  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );
  let currentPage = useSelector(
    (state) => state?.entities?.deviceview?.currentPage
  );

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const handleMouseEnter = (index) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = true;
    setHoverStates(updatedHoverStates);
  };

  function setCurrentPage(index) {
    if (currentPage === index && path?.includes("page")) {
      return;
    }
    store.dispatch({
      type: "deviceview/setCurrentPage",
      payload: {
        currentPage: index,
      },
    });
  }

  function handleMouseLeave(index) {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = false;
    setHoverStates(updatedHoverStates);
  }

  function handlePageClick(e, currentPage) {
    e.stopPropagation();
    setCurrentPage(currentPage);
    handlePage(e, currentPage, setPath, store, pages, numPages, skeleton);
    setKpiDetail(false);
  }

  useEffect(() => {
    try {
      const scrollableElement = scrollRef.current;
      if (!scrollableElement) return;

      // Set scroll position to the beginning on mount and when pages change
      scrollableElement.scrollLeft = 0;

      const handleWheel = (event) => {
        event.preventDefault();
        const scrollSpeedFactor = 5;
        const newScrollPosition = event.deltaY / scrollSpeedFactor;
        scrollableElement.scrollLeft += newScrollPosition;
      };

      scrollableElement.addEventListener("wheel", handleWheel, {
        passive: false,
      });

      scrollableElement.scrollLeft =
        scrollableElement.scrollWidth - scrollableElement.clientWidth;
      return () => {
        scrollableElement.removeEventListener("wheel", handleWheel);
      };
    } catch (error) {
      console.error("WheelScroll PageNav", error);
    }
  }, [pages.length]);

  return (
    <DropTarget
      className={`nav-drop-zone`}
      homeScreen={true}
      accept={Object.keys(ComponentsLibraries?.maxapps)}
      dropPath={["maxapps"]}
      onClick={() => setKpiDetail(false)}
    >
      <div
        className={`Nav-container ${!readOnly && "hoverComp"} ${
          currentPath?.length === 1 && !readOnly && "selectedComponent"
        }`}
        style={{
          flexDirection:
            language?.toLowerCase() === "ar" ? "row-reverse" : "row",

          justifyContent: pages?.length > 3 ? "flex-start" : "center",
        }}
        ref={scrollRef}
        onClick={(e) => {
          handleComponentProp(e, null, skeleton, ["maxapps"], store, "maxapps");
          setKpiDetail(false);
        }}
      >
        {onlyPages?.length > 0 &&
          onlyPages?.map((page, index) => {
            return (
              <React.Fragment key={index}>
                <DragPage
                  index={index}
                  component={"page"}
                  className={`pageNav-drop-zone ${
                    isSelectedComponent(
                      readOnly,
                      null,
                      currentPath,
                      "page",
                      numPages > 1 ? index : null
                    ) && "selectedComponent"
                  } ${numPages > 1 && currentPage === index && "selectedPage"}`}
                  dragParentPath={["maxapps", "page"]}
                  dragData={page}
                  dataAttributes={onlyPages}
                  setDataAttributes={setPages}
                  onClick={() => setKpiDetail(false)}
                >
                  <PageBtn
                    page={page}
                    index={index}
                    handlePageClick={handlePageClick}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    isGIS={false}
                  />
                </DragPage>
              </React.Fragment>
            );
          })}
        {isGIS && (
          <div
            className={`gis-btn ${
              numPages > 1 &&
              currentPath?.slice(-1)[0] === "gis" &&
              "selectedPage"
            } ${
              isSelectedComponent(readOnly, ["maxapps", "gis"], currentPath) &&
              "selectedComponent"
            } ${
              isHoveredComponent(currentPath, "gis", readOnly) && "hoverComp"
            }`}
          >
            <div className="page-nav" onClick={() => setKpiDetail(false)}>
              <PageBtn
                page={pages[indexGis]?.gis}
                index={indexGis}
                handlePageClick={handlePageClick}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                isGIS={isGIS}
              />
            </div>
          </div>
        )}
      </div>
    </DropTarget>
  );
}

export default PageNav;
