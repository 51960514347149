import React, { useEffect, useState } from "react"
import "./index.scss"
import imxtools from "imxtools"
import Icon from "../../../../../../../components/CustomIcon/Icon"
import Attribute from "../Attribute"
import { handleComponentProp } from "../../services/handleComponentProperties"
import { useStore } from "react-redux"

function Lookup({ data = {}, setIsOpen, readOnly = true, path = [] }) {
  const [lookupsNum, setLookupsNum] = useState(0)
  let store = useStore()

  useEffect(() => {
    const min = 10
    const max = 20
    const randomLookupsCount = Math.floor(Math.random() * (max - min + 1)) + min
    setLookupsNum(randomLookupsCount)
  }, [])

  const renderLookups = () => {
    const Lookups = []
    for (let i = 0; i < lookupsNum; i++) {
      Lookups.push(
        <div className='lookup'>
          {data?.body && (
            <div
              className='lookupBody'
              onClick={(e) => {
                handleComponentProp(e, null, data?.bady, path, store, "body")
              }}
            >
               {data?.body?.attribute &&
                imxtools
                  ?.toArray(data?.body?.attribute ? data?.body?.attribute : [])
                  ?.map((att, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Attribute
                          data={att}
                          index={index}
                          AttHeight={"18px"}
                          path={[...path, "body"]}
                          readOnly={readOnly}
                        />
                      </React.Fragment>
                    )
                  })}
            </div>
          )}
        </div>
      )
    }
    return Lookups
  }

  return (
    <div className='lookup-container'>
      <div className='header-lookup'>
        <div className='searchbar'>
          <div className='input-lookup' placeholder='Search'>
            <div>Search</div>
            <div className='inputIcon' onClick={() => setIsOpen(false)}>
              <Icon icon='times' size={13} color='#6f53a3' />
            </div>
          </div>
        </div>
        <div className='lookupNumber'>{lookupsNum}</div>
      </div>
      <div className='list-lookup'>{renderLookups()}</div>
    </div>
  )
}

export default Lookup
