import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import Attribute from "../../../../../Attribute";
import { useSelector } from "react-redux";
import DropTargetBody from "../../../../../DropTargetBody";
import DragItemAttribute from "../../../../../../../../ComponentsLibrary/DragItemAttribute";
import imxtools from "imxtools";
import { AppContext } from "../../../../../../../../../services/AppContext";
function Pullup({ setPosition, position, data, path }) {
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const [dataAttributes, setDataAttributes] = useState([]);
  const { setIsDragingIn } = useContext(AppContext);
  useEffect(() => {
    setDataAttributes(imxtools.toArray(data?.attribute));
  }, [data?.attribute]);

  return (
    <div className="pullup-content">
      <div
        className="horizontale-line"
        onClick={() => setPosition(!position)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
          height: "100%",
          padding: 10,
        }}
      >
        {data?.hasOwnProperty("attribute") && data?.attribute !== undefined ? (
          <div style={{ width: "100%", minHeight: 30 }}>
            <DropTargetBody
              accept={"attribute"}
              dragParent={path}
              style={
                ({
                  width: "100%",
                  overflowY: "auto",
                  height: "100%",
                  padding: 10,
                },
                !readOnly
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "#fff" })
              }
            >
              {data?.attribute ? (
                dataAttributes?.map((att, index) => {
                  return (
                    <React.Fragment key={index}>
                      <DragItemAttribute
                        index={index}
                        component={"attribute"}
                        style={{
                          display: "flex",
                          width: "100%",
                          padding: 15,
                          justifyContent: "flex-end",
                          borderBottomLeftRadius: dataAttributes
                            ? "9px"
                            : "0px",
                          borderBottomRightRadius: dataAttributes
                            ? "9px"
                            : "0px",
                        }}
                        dragParentPath={path}
                        dragData={att}
                        dataAttributes={dataAttributes}
                        setDataAttributes={setDataAttributes}
                        setIsDraging={setIsDragingIn}
                      >
                        <React.Fragment key={index}>
                          <Attribute
                            profile={{}}
                            data={att}
                            readOnly={readOnly}
                            index={
                              dataAttributes?.length === 1
                                ? null
                                : index.toString()
                            }
                            path={path}
                          />
                        </React.Fragment>
                      </DragItemAttribute>
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
            </DropTargetBody>
          </div>
        ) : (
          <DropTargetBody
            accept={"attribute"}
            dragParent={path}
            style={
              ({
                width: "100%",
                overflowY: "auto",
                height: "100%",
                padding: 10,
              },
              !readOnly
                ? { backgroundColor: "#fff" }
                : { backgroundColor: "#fff" })
            }
          ></DropTargetBody>
        )}
      </div>
    </div>
  );
}

export default Pullup;
