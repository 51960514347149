import { arrayToObject } from "./";

async function handleSave(
  newData = {},
  store,
  addToast,
  readOnly = false,
  finalData,
  props = []
) {
  try {
    if (readOnly) {
      return;
    }
    const simpleProp = arrayToObject(
      props.filter((item) => Object.keys(item)?.length > 0),
      addToast
    );

    if (simpleProp === null) {
      return;
    }

    store.dispatch({
      type: "explorer/updateData",
      payload: {
        skeleton: { ...newData },
        readOnly: false,
      },
    });

    store.dispatch({
      type: "editor/updateData",
      payload: {
        data: { ...finalData },
      },
    });

    

    addToast("Saved Successfully", "success");
  } catch (error) {
    console.error("error--------------", error);
  }
}

export default handleSave;
