import React, { useEffect, useState } from "react";
import "./index.scss";
import { handleComponentProp } from "../Preview/services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import Body from "../Preview/components/Body";
import DropTargetBody from "../Preview/components/DropTargetBody";
import { navigateTo } from "../Preview/services/navigationMiddleware";
import Toolbar from "../Preview/components/Toolbar";
import Modal from "../Preview/components/Modal";
import Swipe from "../Preview/components/Swipe";
import imxtools from "imxtools";
function Pullup({
  openPullup = false,
  setOpenPullup = () => {},
  data = {}, 
  path = [],
}) {
  const [openModalSwipe, setOpenModalSwipe] = useState(false);
  const [forms, setForms] = useState([]);
  const [hforms, setHforms] = useState([]);
  function handleNavigateTo(event, data, path) {
    event.stopPropagation();
    const newPath = [...path, "pullup", "detail"];
    handleComponentProp(event, null, data, newPath, store, "detail");
    navigateTo(store, "Detail", { data, path: newPath });
  }
  useEffect(() => {
    const forms = imxtools?.toArray(data?.form ? data?.form : []);
    setForms(forms);
    const hforms = imxtools?.toArray(data?.hform ? data?.hform : []);
    setHforms(hforms);
  }, [data]);
  let store = useStore();
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const handleOpenActions = (event, open) => {
    event.stopPropagation();
    setOpenModalSwipe(open);
  };
  return (
    <>
      <div
        className={!openPullup ? "pull-up-container" : "pull-up-container-up"}
        onClick={(e) =>
          handleComponentProp(
            e,
            null,
            data?.pullup,
            [...path, "pullup"],
            store,
            "pullup"
          )
        }
      >
        <div
          className="horizontale-bar"
          onDoubleClick={() => setOpenPullup(!openPullup)}
        />
        <DropTargetBody
          accept={["body", "toolbar", "swipe", "detail"]}
          dragParent={path}
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="pull-up-content"
            style={{
              height: "auto",
            }}
            onDoubleClick={
              data?.pullup?.detail
                ? (e) => handleNavigateTo(e, data?.pullup, path)
                : null
            }
          >
            {data?.pullup?.hasOwnProperty("toolbar") &&
            data?.pullup?.toolbar !== undefined ? (
              <Toolbar
                data={data?.pullup?.toolbar}
                path={[...path, "pullup"]}
                searchStatus={data?.pullup?.search?.toLowerCase() === "true"}
                readOnly={readOnly}
                relationship={true}
              />
            ) : (
              <></>
            )}
            {data?.pullup?.hasOwnProperty("body") &&
            data?.pullup?.body !== undefined ? (
              <Body
                parentData={data?.pullup}
                data={data?.pullup?.body}
                listData={data?.pullup?.body}
                previewImage={false}
                path={[...path, "pullup"]}
                readOnly={readOnly}
                handleOpenActions={handleOpenActions}
              />
            ) : (
              <>{readOnly ? "No Body here" : "Drop Body here"}</>
            )}
          </div>
        </DropTargetBody>
      </div>
      <Modal openModal={openModalSwipe} tab={true}>
        <Swipe
          data={data?.pullup?.swipe}
          path={[...path, "pullup"]}
          handleOpenActions={handleOpenActions}
          readOnly={readOnly}
          forms={[...forms, ...hforms]}
          numForms={forms?.length}
        />
      </Modal>
    </>
  );
}

export default Pullup;
