import React from "react";
import "./index.scss";
import PinMap from "./../../../../../../../assets/PinMap.png";
function HeaderGis({
  setDisplayPullup = () => {},
  setOpenModalMarker = () => {},
  setOpenPullup = () => {},
}) {
  return (
    <div className="header-with-pullup">
      <div className="header-part-1"></div>
      <div className="header-part-2">
        <img src={PinMap} alt="pinmap" />
        <span style={{ marginLeft: 10 }}>3099</span>
      </div>
      <div className="header-part-3">
        <i
          className="bx bx-x"
          style={{ fontSize: 26 }}
          onDoubleClick={() => {
            setDisplayPullup(false);
            setOpenPullup(false);
          }}
        ></i>
      </div>
    </div>
  );
}

export default HeaderGis;
