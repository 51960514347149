import "./index.scss";

const ModalLanguageSettings = ({ closeModal }) => {
  return (
    <div className="modal-lang-container">
      <div className="close-modal-lang">
        <i
          className="bx bx-x"
          style={{
            fontSize: 28,
            backgroundColor: "#fff",
            borderRadius: 500,
            marginBottom: 15,
          }}
          onClick={() => closeModal()}
        ></i>
      </div>
      <div className="modal-lang-content">
        <div
          className="lang-txt-container-selected"
          style={{ borderTopRightRadius: 9, borderTopLeftRadius: 9 }}
        >
          <span className="lang-txt">English</span>
          <span className="abrev-txt">EN</span>
        </div>
        <div className="lang-txt-container">
          <span className="lang-txt">Frensh</span>
          <span className="abrev-txt">FR</span>
        </div>
        <div className="lang-txt-container">
          <span className="lang-txt">Arabic</span>
          <span className="abrev-txt">AR</span>
        </div>
      </div>
    </div>
  );
};
export default ModalLanguageSettings;
