import "./index.scss";

const LeftSideInspection = ({
  isOpen,
  setViewContent,
  viewContent,
  listQuestions,
  setQuestionToDisplay,
  data,
  path,
}) => {
  return (
    <div
      className={
        isOpen
          ? "inspection-content-left-open"
          : "inspection-content-left-close"
      }
      style={{ borderBottomLeftRadius: 35 }}
    >
      <div
        className={
          viewContent === "home"
            ? "home-tab-inspection-clicked"
            : "home-tab-inspection"
        }
        onClick={() => {
          setViewContent("home");
          setQuestionToDisplay(undefined);
        }}
      >
        <i
          className={"bx bx-home"}
          style={{
            fontSize: 24,
            color: viewContent === "home" ? "#fff" : "#000",
          }}
        ></i>
        {isOpen ? (
          <span style={{ color: viewContent === "home" ? "#fff" : "#000" }}>
            Home
          </span>
        ) : (
          <></>
        )}
      </div>
      {listQuestions?.length > 0 ? (
        listQuestions?.map((question, index) => (
          <div
            key={index}
            className={
              !isOpen
                ? "question-tab-inspection-open"
                : "question-tab-inspection"
            }
            style={{
              backgroundColor:
                question?.num === viewContent && question?.completed
                  ? "#569D59"
                  : question?.num === viewContent && !question?.completed
                  ? "#6A549E"
                  : "#fff",
            }}
            onClick={() => {
              setViewContent(question?.num);
              setQuestionToDisplay(question);
            }}
          >
            <div
              className={"bull-num-q"}
              style={{
                backgroundColor:
                  question?.num === viewContent && question?.completed
                    ? "#569D59"
                    : question?.num === viewContent && !question?.completed
                    ? "#6A549E"
                    : question?.num !== viewContent && !question?.completed
                    ? "#e2e2e2"
                    : question?.num !== viewContent && !question?.completed
                    ? "#E8E8E8"
                    : "#569D59",
              }}
            >
              <>{question?.num}</>
            </div>
            {isOpen ? (
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  marginLeft: 8,
                  textAlign: "start",
                  width: "70%",
                }}
              >
                {question?.title}
              </span>
            ) : (
              <></>
            )}
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};
export default LeftSideInspection;
