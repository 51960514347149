import React from "react";
import ConditionsModal from "./ConditionsModal";
import LookupsModal from "./LookupsModal";
import ScriptsModal from "./ScriptsModal";
import { useModal } from "../../../../services/ModalContext";

function ModalWrapper() {
  const { isOpen, type, closeModal } = useModal();

  if (!isOpen) return null;

  let ModalComponent;
  switch (type) {
    case "condition":
      ModalComponent = ConditionsModal;
      break;
    case "lookup":
      ModalComponent = LookupsModal;
      break;
    case "script":
      ModalComponent = ScriptsModal;
      break;
    default:
      return null;
  }

  return <ModalComponent onClose={closeModal} />;
}

export default ModalWrapper;
