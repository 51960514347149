import React, { useEffect, useState } from "react";
import { useModal } from "../../../../../services/ModalContext";
import { useSelector, useStore } from "react-redux";
import { Close as CloseIcon } from "@mui/icons-material";
import imxtools from "imxtools";
import Dropdown from "react-multilevel-dropdown";
import CustomButton from "../../../../../../../components/CustomButton";
import DeleteButton from "../../../components/DeleteButton";
import Property from "../../../../../../../components/Property";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { useTheme } from "../../../../../../../theme/themeContext";
import LookupsModalElement from "./LookupsModalElement/index";
import DeleteModal from "../../../components/DeleteModal";
import { onSave, transformArray } from "../../services/UtilityServices";
import "./index.scss";

function LookupsModal() {
  const { themeMode, themeColors } = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [properties, setProperties] = useState({
    data: {},
    path: [],
    component: "",
  });
  const [activeLookup, setActiveLookup] = useState({
    lookup: true,
    whereclause: false,
    attributes: false,
    tags: false,
  });
  const [filtredData, setFiltredData] = useState({});

  const handleClickButton = () => {
    setOpenModal(true);
  };

  const {
    closeModal,
    resetModal,
    // type,
    reference,
    createNew,
    data: dataLookup,
    path,
  } = useModal();
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let store = useStore();
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  useEffect(() => {
    if (Object?.keys(dataLookup)?.length === 0) {
      return;
    }
    setProperties({
      data: dataLookup,
      path: path,
      component: "lookup",
    });
  }, [dataLookup, path]);

  useEffect(() => {
    const _filteredData = Object.keys(properties?.data ?? {})
      ?.filter(
        (att) =>
          att?.toString() !== "id" &&
          att?.toLowerCase() !== "itemorder" &&
          typeof properties?.data?.[att] !== "object"
      )
      ?.reduce((filteredObject, key) => {
        filteredObject[key] = properties?.data?.[key];
        return filteredObject;
      }, {});

    setFiltredData(_filteredData);
  }, [properties]);

  const handleCloseModal = () => {
    closeModal();
    resetModal();
  };

  const handleProp = (e, index = null, data, path = [], component = "") => {
    try {
      e.stopPropagation();
      let newPath = [];
      if (index === null) {
        newPath = [...path, component];
      } else {
        newPath = [...path, component, index];
      }
      setProperties({
        data: data,
        path: newPath,
        component: component,
      });
    } catch (error) {
      console.error("An error occurred in handleProp:", error);
    }
  };
  let editor = useSelector((state) =>
    state?.entities?.editor ? state?.entities?.editor : {}
  );
  const { dataToSet, parentToEdit, component: editComponent } = editor;
  return (
    <div className="lookupModal-container">
      <div className="lookupContainer">
        <div className="lcontent-body">
          <div className="lookupBody">
            <div
              className={`lookupTitleContainer ${
                activeLookup.lookup ? "active" : ""
              }`}
              onClick={(event) => {
                handleProp(event, null, dataLookup, path, "lookup");
                setActiveLookup((prev) => ({
                  ...prev,
                  lookup: true,
                  whereclause: false,
                  attributes: false,
                  tags: false,
                }));
              }}
            >
              <p
                className={`lookupTitle ${
                  activeLookup?.lookup ? "active" : ""
                }`}
              >
                lookup
              </p>
              <p
                className={`lookupReference ${
                  activeLookup?.lookup ? "active" : ""
                }`}
              >
                {reference !== "" && !createNew
                  ? `reference="${reference}"`
                  : "__"}
              </p>
            </div>
            <div className="lookupAddElm">
              <Dropdown
                title={
                  <>
                    <div className="addElmLabel">Add Element</div>
                    <Icon
                      icon="add-circle"
                      size={20}
                      color={"black"}
                      // color={
                      //   readOnly
                      //     ? themeColors[themeMode].textDisabled
                      //     : themeColors[themeMode].text
                      // }
                    />
                  </>
                }
                className="dropdownTitle"
              >
                <Dropdown.Item className="dropdownItem">
                  whereclause
                </Dropdown.Item>
                <Dropdown.Item className="dropdownItem">
                  attribute
                </Dropdown.Item>
                <Dropdown.Item className="dropdownItem">tag</Dropdown.Item>
              </Dropdown>
              {/* <div className="addElmLabel">Add Element</div>
              <Icon
                icon="add-circle"
                size={20}
                color={"black"}
                // color={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
              /> */}
            </div>

            <div className="whereclauses-container">
              <LookupsModalElement
                lookupData={dataLookup?.whereclause}
                iconName={"Whereclause"}
                newCard={createNew}
                whereclause={true}
              />
              <div className="whereclause-element">
                {imxtools?.toArray(dataLookup?.whereclause || []) &&
                  imxtools
                    ?.toArray(dataLookup?.whereclause || [])
                    ?.map((w, index) => {
                      const numWhereclause = imxtools?.toArray(
                        dataLookup?.whereclause || []
                      )?.length;
                      return (
                        <div
                          key={index}
                          className={`whereclause ${
                            activeLookup?.whereclause ? "active" : ""
                          }`}
                          onClick={(event) => {
                            handleProp(
                              event,
                              numWhereclause <= 1 ? null : index?.toString(),
                              w,
                              path,
                              "whereclause"
                            );
                            setActiveLookup((prev) => ({
                              ...prev,
                              lookup: false,
                              whereclause: true,
                              attributes: false,
                              tags: false,
                            }));
                          }}
                        >
                          <div className="whereclause-name-label">
                            <p className="whereclause-name ">whereclause</p>
                            <p
                              className={`whereclause-label ${
                                activeLookup ? "active" : ""
                              }`}
                            >
                              {`label=${dataLookup?.whereclause?.label}`}
                            </p>
                          </div>
                          <button
                            className="whereclause-closeBtn"
                            onClick={
                              readOnly || !editComponent
                                ? null
                                : (e) => handleClickButton(e)
                            }
                          >
                            <Icon icon="Close" size={18} color={"#AAAAAA"} />
                          </button>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="attribute-container">
              <LookupsModalElement
                lookupData={dataLookup?.body}
                iconName={"Attribute"}
                newCard={createNew}
              />
              <div className="attribute-element">
                {imxtools?.toArray(dataLookup?.body?.attribute || []) &&
                  imxtools
                    ?.toArray(dataLookup?.body?.attribute || [])
                    ?.map((att, index) => {
                      const numAtt = imxtools?.toArray(
                        dataLookup?.body?.attribute || []
                      )?.length;
                      return (
                        <div
                          key={index}
                          className={`attribute ${
                            activeLookup.attributes ? "active" : ""
                          }`}
                          onClick={(event) => {
                            handleProp(
                              event,
                              numAtt <= 1 ? null : index?.toString(),
                              att,
                              [...path, "body"],
                              "attribute"
                            );

                            setActiveLookup((prev) => ({
                              ...prev,
                              lookup: false,
                              whereclause: false,
                              attributes: true,
                              tags: false,
                            }));
                          }}
                        >
                          <div className="attribute-title-detail">
                            <div className="attTitle">attribute</div>
                            <div className="attDetail">{`name="${att?.name}"`}</div>
                          </div>
                          <button
                            className="attribute-closeBtn"
                            onClick={
                              readOnly || !editComponent
                                ? null
                                : (e) => handleClickButton(e)
                            }
                          >
                            <Icon icon="Close" size={18} color={"#AAAAAA"} />
                          </button>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="tags-container">
              <LookupsModalElement
                lookupData={dataLookup?.tag}
                iconName={"tag"}
                newCard={createNew}
              />
              <div className="tag-element">
                {imxtools?.toArray(dataLookup?.tag || []) &&
                  imxtools?.toArray(dataLookup?.tag || [])?.map((t, index) => {
                    const numTags = imxtools?.toArray(
                      dataLookup?.tag || []
                    )?.length;
                    return (
                      <div
                        key={index}
                        className={`tag ${activeLookup.tags ? "active" : ""}`}
                        onClick={(event) => {
                          handleProp(
                            event,
                            numTags <= 1 ? null : index?.toString(),
                            t,
                            path,
                            "tag"
                          );

                          setActiveLookup((prev) => ({
                            ...prev,
                            lookup: false,
                            whereclause: false,
                            attributes: false,
                            tags: true,
                          }));
                        }}
                      >
                        <div className="tag-title-detail">
                          <div className="tagTitle">tag</div>
                          <div className="tagDetail">{t?.value}</div>
                        </div>
                        <button
                          className="tag-closeBtn"
                          onClick={
                            readOnly || !editComponent
                              ? null
                              : (e) => handleClickButton(e)
                          }
                        >
                          <Icon icon="Close" size={18} color={"#AAAAAA"} />
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
            {openModal && (
              <DeleteModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                data={dataToSet}
                component={editComponent}
                path={parentToEdit}
              />
            )}
          </div>
        </div>
      </div>
      <div className="LookupProperties">
        <div className="lprop-header">
          {!readOnly ? (
            <>
              <div className="lookup-component">
                <Icon icon={"tag-prop"} size={24} color={"#F2AD27"} />
                <p className="lookup-component-title">
                  Component:
                  <span>{properties?.component}</span>
                </p>
              </div>
              <CustomButton
                iconName="add-circle"
                iconSize={20}
                // iconColor={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                label="Add Property"
                // color={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                gap={0.5}
                backgroundColor={"white"}
                heightBtn="2.5rem"
                fontSize={14}
                reverseBtn={true}
                paddingVertical={0.5}
                paddingHorizontal={1}
                onClick={() => setFiltredData({ ...filtredData, "": "" })}
              />
              <CustomButton
                iconName="Save"
                iconSize={22}
                iconColor="blue"
                // iconColor={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                label="Save Properties"
                // color={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                gap={0.5}
                backgroundColor={"white"}
                heightBtn="2.5rem"
                fontSize={14}
                reverseBtn={true}
                paddingVertical={0.5}
              />
            </>
          ) : (
            <div className="closeButton">
              <CloseIcon
                onClick={handleCloseModal}
                sx={{
                  background: "#FF505A",
                  color: "white",
                  width: "22px",
                  height: "22px",
                  borderRadius: "2px",
                }}
              />
            </div>
          )}
        </div>
        <div className={`lprop-body ${readOnly && "lprop-readOnly"}`}>
          <div className="itemTag">
            <DeleteButton />
          </div>
          {/* {properties?.length === 0 ? (
            <NoItem icon='Maintenance' text='No property to display' />
          ) : (
            <div className='properties-content'>
              <div className='HeaderProperties'>
                <div>Name</div>
                <div>Value</div>
              </div>
              <div
                className='conProperties'
                style={{
                  gridTemplateColumns: readOnly ? "20% 78%" : "20% 73% 3%",
                }}
              >
                {properties?.map((prop, index) => {
                
                  return (
                    <React.Fragment key={index}>
                      <UtilityProperty
                        Key={prop?.name}
                        objectKey='name'
                        Value={prop?.value}
                        index={index}
                        disabled={readOnly ?? true}
                        data={properties}
                        setData={setProperties}
                        readOnly={readOnly}
                      />
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          )} */}
          <div className="properties">
            {Object.keys(filtredData)
              ?.filter(
                (att) =>
                  att?.toLocaleLowerCase() !== "condition" &&
                  !filtredData[att]?.includes("#FCT")
              )
              ?.map((attribute, index) => {
                return (
                  <div
                    key={index}
                    className="propertie"
                    style={{
                      gridTemplateColumns: readOnly ? "20% 78%" : "20% 73% 3%",
                    }}
                  >
                    <Property
                      Key={attribute?.toString()}
                      Value={
                        filtredData[attribute]
                          ? filtredData[attribute]
                          : readOnly
                          ? "--"
                          : ""
                      }
                      disabled={readOnly ?? true}
                      maxRows={4}
                      multiline={true}
                      index={index}
                      data={filtredData}
                      setData={setFiltredData}
                      readOnly={readOnly}
                    />
                  </div>
                );
              })}

            {/* Scripts */}
            {/* {Object.keys(data2set)
              ?.filter((att) => data2set[att]?.includes("#FCT"))
              ?.map((script, index) => {
                return (
                  <div
                    key={index}
                    className="propertie"
                    style={{
                      gridTemplateColumns: readOnly ? "100%" : "95% 5%",
                      gap: "0%",
                    }}
                    onDoubleClick={() =>
                      handleOpenModal(
                        "script",
                        functionRefrence(data2set[script])
                      )
                    }
                  >
                    <Property
                      KeyValue={script}
                      AttValue={data2set[script]}
                      disabled={readOnly ?? true}
                      index={index}
                      data={data2set}
                      setData2set={setData2set}
                      readOnly={readOnly}
                      scripts={true}
                    />
                  </div>
                );
              })} */}
            {/* ConditionsUI */}
            {/* <div className="ConditionsProperties">
              {conditionValues?.length > 0 &&
                conditionValues?.map((condition, index) => {
                  return (
                    <div
                      key={index}
                      className="ConditionsPropertie"
                      // style={{
                      //   gridTemplateColumns: "40% 40%",
                      // }}
                      onDoubleClick={() =>
                        handleOpenModal("condition", condition)
                      }
                    >
                      <Property
                        KeyValue="Condition"
                        AttValue={condition}
                        disabled={readOnly ?? true}
                        index={index}
                        data={data2set}
                        setData2set={setData2set}
                        readOnly={readOnly}
                        condition={true}
                      />
                    </div>
                  );
                })}
            </div> */}
          </div>
        </div>
        {/* Footer */}
        {!readOnly && (
          <div className="lprop-footer">
            <div className="secondaryAction">
              <CustomButton
                label={"Cancel"}
                backgroundColor="white"
                widthBtn="100%"
                heightBtn="2.75rem"
                color="#39354A"
                borderColor="#EEEEEE"
                borderWidth="1px"
                fontSize={16}
                onClick={handleCloseModal}
                disableClickReadOnly={false}
                withIcon={false}
                verticalBorder={true}
              />
            </div>
            <div className="primaryAction">
              <CustomButton
                label={createNew ? "Create" : "Save"}
                backgroundColor={themeColors[themeMode].SupportButtonBg}
                borderColor={themeColors[themeMode].SupportButtonBg}
                borderWidth="1px"
                widthBtn="100%"
                heightBtn="2.75rem"
                color="white"
                fontSize={16}
                onClick={() => {
                  // Assuming transformArray or another function might be incorrectly formatting the data.
                  const finalData = createNew
                    ? { ...properties }
                    : {
                        ...dataLookup,
                        ...transformArray(filtredData),
                        ...properties,
                      };

                  // Check if finalData is coming as an array and if it's using the first item if it's not meant to be an array.
                  const dataToSave = finalData[0];

                  onSave(
                    "lookup",
                    createNew,
                    path,
                    dataToSave,
                    skeleton,
                    store
                  );

                  handleCloseModal();
                }}
                disableClickReadOnly={false}
                withIcon={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LookupsModal;
