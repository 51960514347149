function transformForXml(obj, addToast) {
  try {
    if (!obj || typeof obj !== "object") {
      return {};
    }
    if (obj?.length === 0) {
      return {};
    }

    let newObj = {};

    for (var ogKey in obj) {
      if (Array.isArray(obj[ogKey])) {
        let test = [];
        for (var i in obj[ogKey]) {
          test.push(transformForXml(obj[ogKey][i]));
        }
        newObj[ogKey] = [...test];
      } else if (typeof obj[ogKey] === "object") {
        newObj[ogKey] = transformForXml(obj[ogKey]);
      } else {
        if (
          ogKey?.toLowerCase() !== "id" &&
          ogKey?.toLowerCase() !== "itemorder"
        ) {
          newObj["$"] = newObj["$"] || {};
          newObj["$"][ogKey] = obj[ogKey];
        }
      }
    }
    return newObj;
  } catch (error) {
    console.error("Error in transformForXml:", error);
    addToast(`Error in transformForXml2: ${error}`, "error");
    return {};
  }
}

export default transformForXml;
