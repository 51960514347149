function HandleCloseForViewMode(isleft, store) {
  if (isleft) {
    //localStorage.setItem("leftXml", JSON.parse(true));
    store.dispatch({
      type: "compareFiles/setLeftSideShow",
      payload: {
        showLeft: false,
      },
    });
    store.dispatch({
      type: "compareFiles/setLeftMode",
      payload: {
        leftMode: "",
      },
    });
    store.dispatch({
      type: "compareFiles/updateLeftXmlValue",
      payload: {
        leftXml: "",
      },
    });
    store.dispatch({
      type: "compareFiles/setSelectedItemLeft",
      payload: {
        itemLeftSelected: {},
      },
    });
    store.dispatch({
      type: "compareFiles/updateCompareState",
      payload: {
        compare: false,
      },
    });
    store.dispatch({
      type: "compareFiles/setLeftFileName",
      payload: {
        leftFile: "",
      },
    });
  } else {
    store.dispatch({
      type: "compareFiles/setRightSideShow",
      payload: {
        showRight: false,
      },
    });
    store.dispatch({
      type: "compareFiles/updateRightXmlValue",
      payload: {
        rightXml: "",
      },
    });
    store.dispatch({
      type: "compareFiles/setRightMode",
      payload: {
        rightMode: "",
      },
    });
    store.dispatch({
      type: "compareFiles/setSelectedItemRight",
      payload: {
        itemRightSelected: {},
      },
    });
    store.dispatch({
      type: "compareFiles/updateCompareState",
      payload: {
        compare: false,
      },
    });
    store.dispatch({
      type: "compareFiles/setRightFileName",
      payload: {
        rightFile: "",
      },
    });
  }
}
export default HandleCloseForViewMode;
