import "./index.scss";

import React from "react";

function ConfirmDialog({
  confirmQuestion,
  itemName,
  show,
  CloseDialog,
  ConfirmAction,
}) {
  return (
    <>
      {show && (
        <div
          id="ConfirmDialog"
          className="ConfirmDialog"
          onClick={(e) => {
            e.stopPropagation();
            CloseDialog();
          }}
        >
          <div className="confirm-dialog">
            <div className="confirm-dialog-header">
              <p className="confirm-message">{confirmQuestion}</p>
              <p className="item-name">{itemName}</p>
            </div>
            <div className="confirm-dialog-footer">
              <button className="cancel-btn" onClick={() => CloseDialog()}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={() => ConfirmAction()}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ConfirmDialog;
