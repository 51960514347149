import EncryptTxtToBase64 from "../../../Designer/services/helpers/encrypttxtToBase64";
import { axiosProviderNode } from "../../../../services/http/httpService";
import { services } from "../configVariables";

async function maximoLogin(
  username = "",
  password = "",
  maximoUrl = "",
  oslcUrl = "",
  maxRestUrl = "",
  maxappsHost = "",
  method = "standard",
  apikey = "",
  userApiKey = ""
) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_MAXIMO_MAXIMO_AUTH;
    if (method.toUpperCase() === "SAML") {
      const body = {
        maxappsHost,
        maximoLoginMethod: method,
        maximoUrl,
        oslcUrl,
        maxRestUrl,
        apikey,
      };
      const params = {};

      const res = await axiosProviderNode.post(
        transactionNodeService,
        body,
        params
      );
      return res?.data;
    }
    const body = {
      username,
      password: EncryptTxtToBase64(password),
      maximoUrl,
      oslcUrl,
      maxRestUrl,
      maxappsHost,
      maximoLoginMethod: method,
      userApiKey,
    };
    const params = {};

    const res = await axiosProviderNode.post(
      transactionNodeService,
      body,
      params
    );
    return res?.data;
  } catch (error) {
    console.error("maximoLogin error", error);
    throw error;
  }
}

export default maximoLogin;
