import { axiosProviderNode } from "../../../../../services/http/httpService"
import { services } from "./dashboardEndPoints"

async function DataCollectionFn(email, password, endPoint) {
  try {
    const transactionNodeService = services[endPoint]
    const body = {
      u: email,
      p: password,
    }

    const response = await axiosProviderNode.get(transactionNodeService, body)
    return response?.data
  } catch (error) {
    console.error("Failed to fetch data collection:", error)
    throw error
  }
}

export default DataCollectionFn
