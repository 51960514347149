import React, { useState, useEffect } from "react"
import imxtools from "imxtools"
import { useSelector } from "react-redux"
import InputBase from "@mui/material/InputBase"
import Icon from "../../../../../../components/CustomIcon/Icon"
import CheckboxWithThreeStates from "../../../../../../components/Checkbox"
import DeleteItems from "../components/DeleteItems"
import { useModal } from "../../../../services/ModalContext"
import { handleCardClick, handleOpenModal, handleSelectAll } from "../utilities"
import "./index.scss"
import NoItem from "../../../../../../components/NoItem"
import DragUtility from "../services/DragUtility"
import SearchBar from "../SearchBar"

function ConditionsUI() {
  const [selectedCards, setSelectedCards] = useState([])
  const [searchText, setSearchText] = useState("")
  const [hover, setHover] = useState(false)
  const [filterData, setFilterData] = useState([])
  const { openModal } = useModal()
  const skeleton = useSelector((state) => state?.entities?.explorer?.skeleton)
  const readOnly = useSelector((state) => state?.entities?.explorer?.readOnly)
  const [filteredConditions, setFilteredConditions] = useState([])

  const path = ["maxapps", "conditions", "condition"]
  const conditions = imxtools?.toArray(
    skeleton?.maxapps?.conditions?.condition ?? []
  )

  useEffect(() => {
    const filterData = conditions?.filter(
      (c, index) => !selectedCards?.includes(index)
    )
    setFilterData(
      filterData?.length === 0
        ? []
        : filterData?.length === 1
        ? filterData[0]
        : filterData
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCards])

  const renderCard = (condition, index) => {
    const isSelected = selectedCards.includes(index)
    const stylePropValue = imxtools
      ?.toArray(condition?.property || [])
      ?.find((prop) => prop?.value?.includes("backgroundColor:"))
      ?.value?.split("backgroundColor:")[1]

    return (
      <React.Fragment key={index}>
        <DragUtility component='condition' reference={condition?.reference}>
          <div
            className={`card ${isSelected ? "selected" : ""}`}
            key={index}
            onClick={() =>
              !readOnly &&
              handleCardClick(index, selectedCards, setSelectedCards)
            }
            onDoubleClick={() =>
              handleOpenModal(
                condition?.reference,
                index,
                "condition",
                conditions,
                path,
                openModal,
                false
              )
            }
          >
            <div
              className={`cardColor ${stylePropValue ? "" : "gridBg"}`}
              style={{ backgroundColor: stylePropValue }}
            ></div>
            <div className='cardRef'>
              <div className='refCard'>{condition?.reference}</div>
              <div className='valuesProp'>
                {imxtools
                  ?.toArray(condition?.property || [])
                  ?.map(
                    (prop) =>
                      prop.name && (
                        <div key={prop.id}>{prop.name.toUpperCase()}</div>
                      )
                  )}
              </div>
            </div>
          </div>
        </DragUtility>
      </React.Fragment>
    )
  }
  useEffect(() => {
    const filteredData = conditions.filter((condition) => {
      const lowerCaseSearch = searchText.toLowerCase()
      return (
        condition?.reference?.toLowerCase()?.includes(lowerCaseSearch) ||
        imxtools
          ?.toArray(condition?.property ?? [])
          ?.some((prop) => prop.name.toLowerCase().includes(lowerCaseSearch))
      )
    })

    setFilteredConditions(filteredData)
  }, [conditions, searchText])
  return (
    <div className='conditionsUI-container'>
      {/* <div className='actionHeader'>
        <div className='actionHeader-search'>
          <div className='uSearch-icon' placeholder='Search'>
            <Icon icon='search' size={22} />
          </div>
          <InputBase
            sx={{
              ml: 1,
              mr: 1,
              flex: 1,
            }}
            placeholder='Search'
            className='SearchPlaceHolder'
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className='actionHeader-right'>
          <CheckboxWithThreeStates
            label='Select All'
            selectedCards={selectedCards}
            totalCount={filteredConditions.length}
            readOnly={readOnly}
            data={filteredConditions}
            onSelectionChange={
              readOnly
                ? () => {}
                : (newSelection) =>
                    handleSelectAll(newSelection, setSelectedCards)
            }
          />
          <DeleteItems
            readOnly={readOnly}
            selectedCards={selectedCards}
            component='condition'
            setSelectedCards={setSelectedCards}
            filterData={filterData}
            path={path}
          />
        </div>
      </div> */}
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        selectedCards={selectedCards}
        functions={filteredConditions}
        readOnly={readOnly}
        setSelectedCards={setSelectedCards}
        filterData={filterData}
        path={path}
        component={"function"}
      />
      <div className={`conBody ${!conditions?.length && "noItem"}`}>
        {!readOnly && (
          <div
            className='card New'
            onClick={() => openModal("condition", "", true, conditions, path)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Icon
              icon='add-circle'
              size={40}
              color={hover ? "#4C36A5" : "#aaa"}
            />
          </div>
        )}
        {!conditions?.length && readOnly ? (
          <NoItem icon='Conditions' text='No condition to display' />
        ) : (
          conditions
            ?.filter((condition) => {
              const lowerCaseSearch = searchText.toLowerCase()
              return (
                condition?.reference
                  ?.toLowerCase()
                  ?.includes(lowerCaseSearch) ||
                imxtools
                  ?.toArray(condition?.property ?? [])
                  ?.some((prop) =>
                    prop.name.toLowerCase().includes(lowerCaseSearch)
                  )
              )
            })
            ?.map(renderCard)
        )}
      </div>
    </div>
  )
}

export default ConditionsUI
