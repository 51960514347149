import * as TabComponents from "./TabComponents";
import DeviceView from "./Preview";
import LayersView from "./Layers";

export const tabs = [
  { title: "Device", component: <DeviceView /> },
  //{ title: "Layers", component: <LayersView /> },
];

export function RenderTab(activeTab = 0) {
  if (Object.prototype.hasOwnProperty.call(TabComponents, activeTab)) {
    const ActiveTab = TabComponents[activeTab];
    return <ActiveTab />;
  } else {
    return <DeviceView />;
  }
}
