import { jwtDecode } from "jwt-decode"

function getMonitorCredentials() {
  const authorization = localStorage.getItem("authorization")

  if (!authorization) {
    throw new Error("Authorization token not found in localStorage")
  }

  const token = authorization.split("Bearer: ")[1]?.trim()

  if (!token) {
    throw new Error("Token is not in the expected format")
  }

  try {
    const decoded = jwtDecode(token)
    return decoded
  } catch (error) {
    throw new Error("Failed to decode token")
  }
}

export default getMonitorCredentials
