import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import Editor from "../../../XmlSide/AceEditor";
import Icon from "../../../../../../components/CustomIcon/Icon";
import { transformXml } from "../../../../services/transformXml";
import fetchListVersion from "../../../../services/revision";
import { useLocation } from "react-router-dom";
import exportFile from "../../services/exportFile";
import xml2js from "minexml2js";
import transformForStore from "../../../../services/helpers/transformForStore";
import useToasts from "../../../../../../components/CustomToast/useToasts";
import handleSaveRevision from "../../../../services/helpers/handleSaveRevison";
import HandleCloseForViewMode from "../../services/handleCloseViewMode";

function CustomEditor(isleft = false) {
  let store = useStore();
  const [side, setSide] = useState(isleft.isleft);
  let location = useLocation();
  const isOn = JSON.parse(localStorage.getItem("isUp"));
  const iconClicked = JSON.parse(localStorage.getItem("iconClicked"));
  const {
    itemRightSelected,
    itemLeftSelected,
    leftFileName,
    rightFileName,
    leftCode,
    rightCode,
    leftMode,
    rightMode,
    draftDisplay,
    readOnly,
    skeletonState,
    versionsList,
    revisionSelected,
    theme,
    fontSize,
    selected,
  } = useSelector((state) => ({
    itemRightSelected:
      state?.entities?.compareFiles?.rightCode?.fileSelected
        ?.itemRightSelected || {},
    itemLeftSelected:
      state?.entities?.compareFiles?.leftCode?.fileSelected?.itemLeftSelected ||
      {},
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    rightFileName: state?.entities?.compareFiles?.rightCode?.filename || "",
    leftCode: state?.entities?.compareFiles?.leftCode?.code || "",
    rightCode: state?.entities?.compareFiles?.rightCode?.code || "",
    leftMode: state?.entities?.compareFiles?.leftCode?.mode || "",
    rightMode: state?.entities?.compareFiles?.rightCode?.mode || "",
    draftDisplay: state?.entities?.explorer?.draftDisplay,
    readOnly: state?.entities?.explorer?.readOnly,
    skeletonState: state?.entities?.explorer?.skeleton || {},
    versionsList: state?.entities?.revision?.list || [],
    revisionSelected: state?.entities?.revision?.revisionSelected || {},
    theme: state?.entities?.codeeditor?.theme,
    fontSize: state?.entities?.codeeditor?.fontSize,
    selected: state?.entities?.revision?.itemSelected || {},
  }));
  const [selectedVersion, setSelectedVersion] = useState(versionsList[0]);
  const { dataVersions = [] } = fetchListVersion(versionsList) || {};
  const lastVersion = dataVersions?.[0];
  const { addToast } = useToasts();
  const [xmlValue, setXmlValue] = useState("");
  useEffect(() => {
    const xmlValue = transformXml(skeletonState);
    setXmlValue(xmlValue);
  }, [skeletonState]);
  const onChange = (newXmlValue) => {
    setXmlValue(newXmlValue);
  };
  useEffect(() => {
    // Extracting the first key as the filename to search
    const filenameToSearch = Object.keys(selected)[0]; // Assuming selected has only one key, and it's the filename.

    // Search for a selected filename in the listVersion array
    const match = versionsList.find(
      (version) => version.filename === filenameToSearch
    );
    setSelectedVersion(match);
  }, [selectedVersion]);
  return (
    <React.Fragment>
      {side ? (
        <React.Fragment>
          <div
            className={`editorContainer ${draftDisplay ? "Draft" : "newLeft"}`}
          >
            <div className="editorHeader">
              <>
                <div className="editorName">
                  {!draftDisplay ? (
                    <>
                      <p className="statutNew">
                        <span className="originalContext">{leftMode}</span>
                        <span className="hoveredContext">
                          <span>Close</span>
                          <Icon
                            className="iconClose"
                            icon={"close1"}
                            size={22}
                            color="white"
                            onClick={() => HandleCloseForViewMode(true, store)}
                          />
                        </span>
                      </p>
                      <p className="headerFile">
                        {itemLeftSelected &&
                        Object.keys(itemLeftSelected).length > 0
                          ? `${itemLeftSelected.version}  ${itemLeftSelected.xmlAlias}`
                          : `${leftFileName}`}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="statutDraft">Draft</p>
                      {itemLeftSelected &&
                      Object.keys(itemLeftSelected).length > 0 ? (
                        <p className="headerFile">
                          {itemLeftSelected.version}
                          {itemLeftSelected.xmlAlias}
                        </p>
                      ) : selectedVersion &&
                        Object.keys(selectedVersion).length > 0 ? (
                        <p className="headerFile">
                          {/* V{selectedVersion.version} -{" "} */}
                          {selectedVersion.xmlAlias}
                        </p>
                      ) : (
                        <p className="headerFile">Untitled</p>
                      )}
                    </>
                  )}
                </div>
                <div className="editorProprety">
                  {draftDisplay ? (
                    <>
                      <div className="iconEditor">
                        <Icon
                          className="iconClose"
                          icon="Export"
                          size={22}
                          color="#39354A"
                          onClick={() => exportFile(xmlValue, location)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            </div>
          </div>
          <div className="editorValue">
            <Editor
              onChange={onChange}
              value={!draftDisplay ? leftCode : xmlValue}
              readOnly={readOnly}
              theme={theme}
              fontSize={fontSize}
              height="calc(-12rem + 100vh)"
              className={`editor${
                !draftDisplay ? "CompareLeft" : "CompareDraft"
              }`}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="editorContainernewRight">
            <div className="editorHeader">
              {" "}
              <>
                <div className="headerName">
                  <p className="rightStatut">
                    <span className="originalContext">{rightMode}</span>
                    <span className="hoveredContext">
                      <span>Close</span>
                      <Icon
                        className="iconClose"
                        icon={"close1"}
                        size={22}
                        color="white"
                        onClick={() => HandleCloseForViewMode(false, store)}
                      />
                    </span>
                  </p>
                  <p className="headerFile">
                    {itemRightSelected &&
                    Object.keys(itemRightSelected).length > 0
                      ? `${itemRightSelected.version}  ${itemRightSelected.xmlAlias}`
                      : `${rightFileName}`}
                  </p>
                </div>
              </>
            </div>
          </div>
          <div className="editorValue">
            <Editor
              onChange={onChange}
              value={rightCode}
              readOnly={readOnly}
              theme={theme}
              fontSize={fontSize}
              height="calc(-12rem + 100vh)"
              className="editorCompareRight"
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default CustomEditor;
