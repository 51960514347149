import { getMaximoConfig } from "../../../../services";

async function __getScripts() {
  try {
    const response = await getMaximoConfig();

    return {
      ObjectStructures: response.content?.dashboardUser?.objectStructures,
      syncBackendStatus: response.content?.dashboardUser?.syncBackendStatus,
      maximoUrl: response.content?.dashboardUser?.maximoUrl,
      scripts: response.content?.dashboardUser?.scriptsStatus
        ?.sort((a, b) => Number(a.status) - Number(b.status))
        .sort((a, b) => Number(a.loggerStatus) - Number(b.loggerStatus)),
      maxappsHost: response.content?.dashboardUser?.maxappsHost,
      completedInstallation:
        response.content?.dashboardUser?.completedInstallation,
    };
  } catch (error) {
    console.error("Error=====>", error);
  }
}

export default __getScripts;
