import React from "react";
import * as SampleKPI from "./KpiComponents";
import UnknownSampleKPI from "./UnknownSampleKPI";

function SampleKpi({
  data = {},
  hadleGraphClick: hadleKPIClick = () => {},
  handleNavigateTo = () => {},
  path,
  inPage = false,
}) {
  const KPISampleComponentAtt = {
    size: data?.size,
    label: data?.label,
    data,
    path,
    onClick: (event) => {
      hadleKPIClick("detail", event);
    },
    onDoubleClick: (event) => {
      handleNavigateTo(event, "detail", data?.detail);
    },
    inPage,
  };

  const RenderKPIGraphComponent = (prop) => {
    const SampleKpiType = data?.type?.toLowerCase();
    if (Object.prototype.hasOwnProperty.call(SampleKPI, SampleKpiType)) {
      const TargetComponent = SampleKPI[SampleKpiType];
      return <TargetComponent {...prop} />;
    } else {
      return (
        <UnknownSampleKPI
          UnknownType={SampleKpiType}
          size={data?.size}
          data={data}
          path={path}
          inPage={inPage}
        />
      );
    }
  };
  return <>{RenderKPIGraphComponent(KPISampleComponentAtt)}</>;
}

export default SampleKpi;
