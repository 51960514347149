import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import Icon from "../CustomIcon/Icon";
import { useTheme } from "../../theme/themeContext";

function Dropdown({
  children,
  showSelectedOption = false,
  options = [],
  onSelect = () => {},
  withIcon = true,
  withLabel = true,
  activeDropdown = true,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverStates, setHoverStates] = useState(() => new Map()); // Initialize as a Map
  const dropdownRef = useRef(null);
  const { themeMode, themeColors } = useTheme();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    // Reinitialize hoverStates when options change
    setHoverStates(new Map(options.map((_, index) => [index, false])));
  }, [options.length]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (
      !option?.active ||
      !option?.action ||
      typeof option.action !== "function"
    ) {
      setIsOpen(false);
      return;
    }
    option.action();
    setIsOpen(false);
  };

  const handleMouseEnter = (index) => {
    setHoverStates((prev) => new Map(prev).set(index, true));
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prev) => new Map(prev).set(index, false));
  };

  return (
    <div className={`dropdown ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        {showSelectedOption
          ? ""
          : typeof children === "function"
          ? children({ isOpen })
          : children}
      </div>
      {isOpen && activeDropdown && (
        <ul className="dropdown-options">
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              {withIcon && (
                <div className="opIcon">
                  <Icon
                    className={option?.active ? "iconHoverColor" : ""}
                    icon={option?.icon}
                    size={20}
                    color={
                      !option?.active
                        ? hoverStates.get(index)
                          ? themeColors[themeMode].textHovered
                          : themeColors[themeMode].textDisabled
                        : hoverStates.get(index)
                        ? themeColors[themeMode].iconLabelHovered
                        : themeColors[themeMode].text
                    }
                  />
                </div>
              )}
              {withLabel && <div className="opLabel">{option.label}</div>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
