import React, { useEffect, useState } from "react"
import imxtools from "imxtools"
import { useSelector } from "react-redux"
import InputBase from "@mui/material/InputBase"
import { useModal } from "../../../../services/ModalContext"
import Icon from "../../../../../../components/CustomIcon/Icon"
import CheckboxWithThreeStates from "../../../../../../components/Checkbox"
import { handleCardClick, handleOpenModal, handleSelectAll } from "../utilities"
import DeleteItems from "../components/DeleteItems"
import NoItem from "../../../../../../components/NoItem"
import DragUtility from "../services/DragUtility"
import "./index.scss"
import SearchBar from "../SearchBar/index"

function Scripts() {
  const [selectedCards, setSelectedCards] = useState([])
  const [searchText, setSearchText] = useState("")
  const [hover, setHover] = useState(false)
  const [filterData, setFilterData] = useState([])
  const { openModal } = useModal()
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton)
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly)

  const path = ["maxapps", "script", "function"]
  const scripts = imxtools?.toArray(skeleton?.maxapps?.script?.function ?? [])

  useEffect(
    () => {
      const filterData = scripts?.filter(
        (c, index) => !selectedCards?.includes(index)
      )
      setFilterData(
        filterData?.length === 0
          ? []
          : filterData?.length === 1
          ? filterData[0]
          : filterData
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCards]
  )

  return (
    <div className='scripts-container'>
      {/* <div className='actionHeader'>
        <div className='actionHeader-search'>
          <div className='uSearch-icon' placeholder='Search'>
            <Icon icon='search' size={22} color='#989898' />
          </div>
          <input
            placeholder='Search'
            className='SearchPlaceHolder'
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className='actionHeader-right'>
          <CheckboxWithThreeStates
            label='Select All'
            selectedCards={selectedCards}
            totalCount={scripts.length}
            readOnly={readOnly}
            data={scripts}
            onSelectionChange={
              readOnly
                ? () => {}
                : (newSelection) =>
                    handleSelectAll(newSelection, setSelectedCards)
            }
          />
          <DeleteItems
            readOnly={readOnly}
            selectedCards={selectedCards}
            component='function'
            setSelectedCards={setSelectedCards}
            filterData={filterData}
            path={path}
          />
        </div>
      </div> */}
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        selectedCards={selectedCards}
        functions={scripts}
        readOnly={readOnly}
        setSelectedCards={setSelectedCards}
        filterData={filterData}
        path={path}
        component={"function"}
      />
      <div className={`scriptBody ${!scripts?.length && "noItem"}`}>
        {!readOnly && (
          <div
            className='card New'
            onClick={() => !readOnly && openModal("script", "", true, {}, [])}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Icon
              icon='add-circle'
              size={40}
              color={hover ? "#4C36A5" : "#aaa"}
            />
          </div>
        )}
        {!scripts?.length && readOnly ? (
          <NoItem icon='Script' text='No script to display' />
        ) : (
          scripts
            ?.filter((fct) => {
              return fct?.reference
                .toLowerCase()
                .includes(searchText.toLowerCase())
            })
            ?.map((fct, index) => {
              return (
                <React.Fragment key={index}>
                  <DragUtility component='function' reference={fct?.reference}>
                    <div
                      key={index}
                      className={`card ${
                        selectedCards.includes(index) ? "selected" : ""
                      }`}
                      onClick={() =>
                        !readOnly &&
                        handleCardClick(index, selectedCards, setSelectedCards)
                      }
                      onDoubleClick={() =>
                        handleOpenModal(
                          fct?.reference,
                          index,
                          "script",
                          scripts,
                          path,
                          openModal,
                          false
                        )
                      }
                    >
                      {fct?.reference}
                    </div>
                  </DragUtility>
                </React.Fragment>
              )
            })
        )}
      </div>
    </div>
  )
}

export default Scripts
