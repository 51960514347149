import "./index.scss"
import Icon from "../../../../../../../../components/CustomIcon/Icon"

const index = ({ iconName, lookupData, newCard, whereclause = false }) => {
  return (
    <div
      className={`new-Lookup-element-container ${
        !lookupData && !newCard && "disable"
      }`}
    >
      <div className='new-Lookup-element'>
        <Icon
          icon={iconName}
          size={24}
          color={lookupData && !newCard ? "#4C36A5" : "#9C9AA5"}
        />
        <p className='new-Lookup-element-title'>{iconName}</p>
      </div>
      <button className='add-lookup-element'>
        <Icon
          icon='add-circle'
          size={24}
          color={whereclause === true && !newCard ? "#EBEBED" : "#9C9AA5"}
        />
      </button>
    </div>
  )
}
export default index
