import Icon from "../../../../../../components/CustomIcon/Icon"
import "./index.scss"
const NoData = ({ data1 = "", data2 = "" }) => {
  return (
    <div className='no-data-container'>
      <Icon icon='noData' size={80} style={{ color: "#989898" }} />
      <div className='text-container'>
        <p>{data1}</p>
        <p>{data2}</p>
      </div>
    </div>
  )
}
export default NoData
