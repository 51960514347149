import CryptoJS from "react-native-crypto-js";
function EncryptTxtToBase64(str) {
  try {
    const words = CryptoJS.enc.Utf8.parse(str); 
     return CryptoJS.enc.Base64.stringify(words);
  } catch (error) {
    console.error("error EncryptTxtToBase64====",error);
    return str;
  }
}

export default EncryptTxtToBase64;
