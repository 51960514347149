const editor = {
  initialState: {
    dataToSet: {},
    parentToEdit: [],
    component: "",
    XMLChanged: false,
  },
  reducers: {
    dataAdded: (dataSet, action) => {
      const { data, parent, component } = action.payload || {};
      Array.isArray(data)
        ? (dataSet["dataToSet"] = {})
        : (dataSet["dataToSet"] = { ...data });
      dataSet["parentToEdit"] = parent;
      dataSet["component"] = component;
    },
    updateData: (dataSet, action) => {
      const { data } = action.payload;
      dataSet["dataToSet"] = { ...data };
    },
    updateXMLState: (dataSet, action) => {
      const { XMLChanged } = action.payload;
      dataSet["XMLChanged"] = XMLChanged;
    },
    reset: (dataSet) => {
      return (dataSet = { dataToSet: {}, parentToEdit: [], component: "" });
    },
  },
};

export default editor;
