import React from "react";
import "./index.scss";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import { useStore, useSelector } from "react-redux";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";
import useRandomData from "../../Attribute/useRandomData";

function Tag({
  data = {},
  path = [],
  numTags = 0,
  index = null,
  readOnly = true,
}) {
  let store = useStore();
  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  const randomData = useRandomData(data.type);
  return (
    <div
      className={`tag-content`}
      style={{
        backgroundColor:
          data?.style && data?.style?.includes("backgroundColor:")
            ? data?.style?.split("backgroundColor:")[1]
            : "#E6D6F9",
      }}
      onClick={(e) => {
        handleComponentProp(
          e,
          numTags <= 1 ? null : index?.toString(),
          data,
          path,
          store,
          "tag"
        );
      }}
    >
      {/* {`${data?.label ? getLabelByLanguage(language, data) : "Add label"}: ${
        data?.name ? "--" : "N/A"
      }`} */}
      {data?.label
        ? language === "ar"
          ? data?.type && data?.name
            ? randomData + " : " + getLabelByLanguage(language, data)
            : " -- : " + getLabelByLanguage(language, data)
          : data?.type && data?.name
          ? getLabelByLanguage(language, data) + " : " + randomData
          : getLabelByLanguage(language, data) + ": --"
        : "Add Name"}
    </div>
  );
}

export default Tag;
