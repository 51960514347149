import { services } from "../../../../services/configVariable";
import { axiosProviderNode as serviceRequest } from "../../../../../../services/http/httpService";
import saveFile from "../../../AppBar/services/saveFile";

async function ExportRevisionItem(filename, addToast, location, xmlAlias) {
  try {
    if (filename && filename !== "") {
      const transactionNodeService = services.NODE_ENDPOINT_GET_FILE_CONTENT;

      const res = await serviceRequest.get(transactionNodeService, {
        filename,
      });

      if (res?.data?.success) {
        try {
          saveFile(res?.data?.content?.skeleton, location, xmlAlias);
        } catch (error) {
          addToast(error?.message || error, "error");
        }
      } else {
        addToast(res.data.message, "error");
      }
    }
  } catch (error) {
    addToast("Server is unreachable!", "error");
  }
}

export default ExportRevisionItem;
