import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react"

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "condition",
    reference: "",
    createNew: false,
    data: {},
    path: [],
  })

  const openModal = useCallback(
    (type = "", reference = "", createNew = false, data = {}, path = []) => {
      setModalState({ isOpen: true, type, reference, createNew, data, path })
    },
    []
  )

  const closeModal = useCallback(() => {
    setModalState({ ...modalState, isOpen: false })
  }, [modalState])

  const resetModal = useCallback(() => {
    setModalState({
      isOpen: false,
      type: "condition",
      reference: "",
      createNew: false,
      data: {},
      path: [],
    })
  }, [])

  const contextValue = useMemo(
    () => ({
      ...modalState,
      openModal,
      closeModal,
      resetModal,
    }),
    [modalState, closeModal, resetModal, openModal]
  )

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider")
  }
  return context
}
