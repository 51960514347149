import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import RevisionItem from "./RevisionItem";
import DraftRevision from "./DraftRevision";
import Icon from "../../../../../components/CustomIcon/Icon";
import LastPublishedRevision from "./LastPublishedRevision";
import "./index.scss";

function Revisions({
  draftDisplay,
  data = [],
  lastVersion,
  versionsList,
  revision,
  setRevision = () => {},
  isTransformed,
  setIsTransformed = () => {},
  compareXML,
  setCompareXML = () => {},
  enableBackground,
  setEnableBackground = () => {},
  setShowDraftsList = () => {},
  Bordered,
  setBorder = () => {},
}) {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { revisionSelected } = useSelector((state) => ({
    revisionSelected: state?.entities?.revision?.revisionSelected,
  }));

  const readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter((revision) =>
    searchTerm
      ? Object.values(revision).some(
          (value) =>
            typeof value === "string" &&
            value?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        )
      : true
  );
  // selectedRevision={revisionSelected?.xmlAlias}

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="revisions-container">
      {versionsList?.length > 0 && (
        <div
          className="revision-search"
          style={{
            border: isFocused ? "1px solid var(--primary)" : "none",
          }}
        >
          <div className="search-icon">
            <Icon
              icon="search"
              size={24}
              color={isFocused ? "var(--primary)" : "#989898"}
            />
          </div>
          <input
            className="search-input"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchTerm}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
          />
          {searchTerm && (
            <Icon
              icon="close1"
              size={24}
              color="var(--searchCloseIcon)"
              onClick={() => setSearchTerm("")}
            />
          )}
        </div>
      )}
      <div className="revision-list">
        {!draftDisplay && Object.keys(lastVersion)?.length !== 0 ? (
          <LastPublishedRevision
            data={lastVersion}
            versionsData={versionsList}
            disable={draftDisplay}
            selectedData={lastVersion}
            enableBackground={enableBackground}
            setEnableBackground={setEnableBackground}
            setCompareXML={setCompareXML}
            compareXML={compareXML}
            revision={revision}
            setRevision={setRevision}
            setIsTransformed={setIsTransformed}
            Bordered={Bordered}
            setBorder={setBorder}
            setShowDraftsList={setShowDraftsList}
          />
        ) : null}

        {/*  {draftDisplay && !readOnly && (
          <DraftRevision
            lastVersion={lastVersion}
            selectedRevision={revisionSelected?.xmlAlias}
          />
        )} */}
        {filteredData?.map((revisionItem, index) => {
          if (revisionItem?.filename === lastVersion?.filename) {
            return null;
          }
          return (
            <React.Fragment key={index}>
              <RevisionItem
                data={revisionItem}
                index={index}
                versionsData={versionsList}
                disable={draftDisplay}
                selectedData={revisionItem}
                revision={revision}
                setRevision={setRevision}
                handleTransform={isTransformed}
                setIsTransformed={setIsTransformed}
                setCompareXML={setCompareXML}
                compareXML={compareXML}
                enableBackground={enableBackground}
                setEnableBackground={setEnableBackground}
                Bordered={Bordered}
                setBorder={setBorder}
                
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Revisions;
