import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../../services/http/httpService";

async function addNewVersion(
  store,
  skeleton,
  maxOrder,
  xmlAlias = "My Alias",
  typeFile = "",
  addToast
) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_ADD_FILE;

    const transactionPayload = {
      content: JSON.stringify(skeleton),
      maxOrder,
      xmlAlias,
      typeFile,
    };

    const res = await serviceRequest.post(
      transactionNodeService,
      transactionPayload
    );

    if (res?.data?.success) {
      store.dispatch({
        type: "revision/updateData",
        payload: {
          objToAdd: res?.data?.content,
        },
      });
    }

    addToast("Draft added!","success");

    return res?.data?.content;
  } catch (error) {
    addToast("Server is unreachable!", "error");

    return null;
  }
}

export default addNewVersion;
