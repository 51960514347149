import React from "react";
import "./index.scss";
import { handleComponentProp } from "../../../../services/handleComponentProperties";
import { useStore } from "react-redux";
import useToasts from "../../../../../../../../../components/CustomToast/useToasts";
import { navigateTo } from "../../../../services/navigationMiddleware";

function UnknownGraphKPI({ data = {}, path = [], size = "small" }) {
  const store = useStore();
  const { addToast } = useToasts();

  function handleNavigateTo(e) {
    e.stopPropagation();
    if (!data?.detail && !data?.hasOwnProperty("detail")) {
      addToast("No detail tab in the list", "error");
      return;
    }
    handleComponentProp(
      e,
      null,
      data?.detail,
      [...path, "detail"],
      store,
      "detail"
    );
    navigateTo(store, "Detail", {
      data: data?.detail,
      path: [...path, "detail"],
    });
  }

  return (
    <div
      className="unknown-graph-kpi"
      onClick={(e) =>
        handleComponentProp(
          e,
          null,
          data?.detail,
          [...path, "graph"],
          store,
          "graph"
        )
      }
      onDoubleClick={(e) => handleNavigateTo(e)}
    >
      Unknown Graph KPI type
    </div>
  );
}

export default UnknownGraphKPI;
