import React from "react";
import imxtools from "imxtools";
import Module from "../../Module";
import DragItemAttribute from "../../../../../ComponentsLibrary/DragItemAttribute";

function RenderModules(
  path = [],
  readOnly = true,
  currentPage = 0,
  pages = [],
  PageContent = {},
  moduleContent,
  setModuleContent,
  setIsDragingIn,
  view = "row",
  currentPath = [],
  setOpenModuleMatrix = () => {},
  setModuleData = () => {}
) {
  const modules = imxtools?.toArray(PageContent?.module ?? []);
  return (
    <React.Fragment>
      {moduleContent.length > 0 &&
        moduleContent.map(function (module, index) {
          return (
            <DragItemAttribute
              index={index}
              className={
                view?.toLowerCase() === "row"
                  ? "drag-module-zone"
                  : "drag-module-zone-matrix"
              }
              component={"module"}
              dragParentPath={path}
              dragData={module}
              dataAttributes={moduleContent}
              setDataAttributes={setModuleContent}
              setIsDraging={setIsDragingIn}
            >
              <Module
                currentPage={currentPage}
                pages={pages}
                data={module}
                path={path}
                numModule={modules?.length}
                indexModule={modules?.length === 1 ? null : index}
                key={index}
                readOnly={readOnly}
                setModuleData={setModuleData}
                setOpenModuleMatrix={setOpenModuleMatrix}
              />
            </DragItemAttribute>
          );
        })}
    </React.Fragment>
  );
}

export default RenderModules;
