import React from "react";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import { renderStep } from "./rendringSteps";

const Installer = () => {
  const currentStep = useSelector(
    (state) => state?.entities?.installer?.currentStep
  );

  const completedInstallation = useSelector(
    (state) => state?.entities?.installer?.completedInstallation
  );

  const connected = useSelector(
    (state) => state?.entities?.installer?.maximoLoginIn
  );

  return (
    <div className="container">
      {completedInstallation
        ? renderStep["Disconnect"]
        : connected
        ? renderStep[currentStep]
        : renderStep["login"]}
    </div>
  );
};

export default Installer;
