import { axiosProviderNode } from "../../../services/http/httpService";

async function rebootServer(addToast) {
  try {
    const transactionNodeService = "sys/reboot";

    const res = await axiosProviderNode.get(transactionNodeService);
    addToast(res?.data?.message, {
      appearance: "success",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });
  } catch (error) {
    addToast("Server is unreachable!", {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });
  }
}

export default rebootServer;
