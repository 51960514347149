import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import ImgStreet from "./../../../../../../../../assets/img-street.png";
import ImgHybride from "./../../../../../../../../assets/img-hybride.png";
import Autocomplete from "react-google-autocomplete";

import {
  RightbtnsInMap,
  RightbtnsInMapH,
} from "../../../../../../../../utils/util";
import { useSelector } from "react-redux";
import imxtools from "imxtools";
import { AppContext } from "../../../../../../services/AppContext";
import useToasts from "../../../../../../../../components/CustomToast/useToasts";
import { getIn } from "lodash-redux-immutability";
import LineButtonsGis from "./LineButtonsGis";
import Pins from "../../../../../../../../components/Pins";

function GisLayersNew({
  data = {},
  path = [],
  handleMapTypeChange = () => {},
  setOpenForm = () => {},
  targetForm = "",
  setTargetForm = () => {},
  pathOfList = [],
  setPathOfList = () => {},
  setTargetFormIndex = () => {},
  setFormHform = () => {},
  setOpenFilterForm = () => {},
  openPullup = false,
  setCurrentMarkerPath = () => {},
  currentMarkerData = {},
  displayPullup = false,
}) {
  const [verticalBtns, setVerticalBtns] = useState(RightbtnsInMap);
  const [onTopBulles, setOnTopBulles] = useState(RightbtnsInMapH);

  const [change, setChange] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [locationInput, setLocationInput] = useState("");

  const { addToast } = useToasts();

  const { targetRefEvent, setTargetRefEvent } = useContext(AppContext);

  const handleDeleteLocationSearchInput = (e) => {
    e.preventDefault();
    setLocationInput("");
  };

  const handleSearchBtn = () => {
    setActiveSearch(true);
  };

  const handleCloseSearch = () => {
    setActiveSearch(false);
  };

  const handleToggleBtn = (index, btn) => {
    setChange(!change);

    setVerticalBtns((prevBtns) => {
      return prevBtns.map((prevBtn) => {
        if (prevBtn.name === btn.name) {
          return { ...prevBtn, isOpen: !prevBtn.isOpen };
        }
        return prevBtn;
      });
    });
  };
  const handleSearchLocationInput = (e) => {
    e.preventDefault();
    setLocationInput(e.target.value);
  };
  const handleToggleBtnTop = (index, btn) => {
    setChange(!change);

    setOnTopBulles((prevBtns) => {
      return prevBtns.map((prevBtn) => {
        if (prevBtn.name === btn.name) {
          return { ...prevBtn, isOpen: !prevBtn.isOpen };
        }
        return prevBtn;
      });
    });
  };

  const layersButton = verticalBtns.find(
    (btn) => btn.name === "layers" && btn?.isOpen === true
  );

  const handleForm = (e, indexEvent, event, pathList) => {
    if (indexEvent === null) {
      if (event && event.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
        setPathOfList(pathList);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    } else {
      if (event && event?.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    }
  };
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );
  let formsData = imxtools.toArray(
    getIn(skeletonState, [...pathOfList, "form"]) || []
  );
  let hformsData = imxtools.toArray(
    getIn(skeletonState, [...pathOfList, "hform"]) || []
  );

  useEffect(() => {
    let foundIndex = -1;
    let foundIndexHform = -1;
    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndex = index;
        return true;
      }
      return false;
    });
    const foundHform = hformsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndexHform = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
      setFormHform("form");
    } else if (foundHform) {
      setTargetForm(foundHform);
      setTargetFormIndex(foundIndexHform);
      setFormHform("hform");
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hformsData, formsData, targetRefEvent, targetForm, data?.media]);

  function hasEventListWithToolbar(array) {
    for (let item of array) {
      if (item.toolbar && item.toolbar.event) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <div className="layer-content">
        {!activeSearch ? (
          <div
            className={
              currentMarkerData?.pullup && displayPullup
                ? "right-top-btns-map-container-pullup"
                : "right-top-btns-map-container"
            }
          >
            <button
              className="btn-back-wrapper"
              onDoubleClick={() => handleSearchBtn()}
            >
              <Icon
                icon={"search"}
                color={"#AAAAAA"}
                size={24}
                style={{
                  height: "auto",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </button>
          </div>
        ) : (
          <></>
        )}
        {!activeSearch ? (
          <Pins
            displayPullup={displayPullup}
            currentMarkerData={currentMarkerData}
            pathOfList={pathOfList}
          />
        ) : (
          <></>
        )}
        <>
          {layersButton ? (
            <div
              className={
                !openPullup
                  ? "right-btns-map-container"
                  : "right-btns-map-container-up"
              }
            >
              {verticalBtns?.length > 0 ? (
                verticalBtns?.map((btn, index) => (
                  <LineButtonsGis
                    key={index}
                    btn={btn}
                    data={data}
                    path={path}
                    handleForm={handleForm}
                    handleMapTypeChange={handleMapTypeChange}
                    handleToggleBtn={handleToggleBtn}
                    index={index}
                    setCurrentMarkerPath={setCurrentMarkerPath}
                    setPathOfList={setPathOfList}
                    displayPullup={displayPullup}
                    currentMarkerData={currentMarkerData}
                    openPullup={openPullup}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div
              className={
                !openPullup
                  ? "right-btns-map-container-without-retry"
                  : "right-btns-map-container-without-retry-up"
              }
            >
              {verticalBtns?.length > 0 ? (
                verticalBtns
                  ?.filter((item) => item.name !== "retry")
                  ?.map((btn, index) => (
                    <LineButtonsGis
                      key={index}
                      btn={btn}
                      data={data}
                      path={path}
                      handleForm={handleForm}
                      handleMapTypeChange={handleMapTypeChange}
                      handleToggleBtn={handleToggleBtn}
                      index={index}
                      setCurrentMarkerPath={setCurrentMarkerPath}
                      setPathOfList={setPathOfList}
                      displayPullup={displayPullup}
                      currentMarkerData={currentMarkerData}
                      openPullup={openPullup}
                    />
                  ))
              ) : (
                <></>
              )}
            </div>
          )}
        </>
        <>
          {activeSearch ? (
            <div
              className={
                currentMarkerData?.pullup && displayPullup
                  ? "active-serach-container-pullup"
                  : "active-serach-container"
              }
            >
              <div className="active-serach-record-container">
                <button
                  className="close-serach-btn"
                  onDoubleClick={() => handleCloseSearch()}
                >
                  <Icon
                    icon={"close"}
                    color={"#6A549E"}
                    fontSize={45}
                    style={{ fontSize: 45 }}
                    size={22}
                  />
                </button>
                <input
                  type="text"
                  placeholder="Search records"
                  className="serach-bar-input"
                />
                {hasEventListWithToolbar(imxtools.toArray(data?.list)) ? (
                  <button
                    className="open-filter-btn"
                    onDoubleClick={() => setOpenFilterForm(true)}
                  >
                    <Icon
                      icon={"filter"}
                      color={"#6A549E"}
                      fontSize={45}
                      style={{ fontSize: 45 }}
                      size={22}
                    />
                  </button>
                ) : (
                  <button className="open-filter-btn"></button>
                )}
              </div>
              <div className="active-serach-location-container">
                <button
                  className="btn-location"
                  onDoubleClick={() => handleCloseSearch()}
                >
                  <Icon
                    icon={"location"}
                    color={"#6A549E"}
                    fontSize={45}
                    style={{ fontSize: 45 }}
                    size={22}
                  />
                </button>
                <Autocomplete
                  // onPlaceSelected={(place) => console.log(place)}
                  value={locationInput}
                  placeholder="Search Location"
                  className="serach-location-input"
                  onChange={(e) => handleSearchLocationInput(e)}
                />
                {Boolean(locationInput) ? (
                  <button
                    className="close-location-btn"
                    onDoubleClick={(e) => handleDeleteLocationSearchInput(e)}
                  >
                    <Icon
                      icon={"close"}
                      color={"#fff"}
                      fontSize={45}
                      style={{ fontSize: 45, backgroundColor: "#AAAAAA" }}
                      size={22}
                    />
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <>
              <div
                className={
                  currentMarkerData?.pullup && displayPullup
                    ? "left-bottom-btns-map-container-pullup"
                    : "left-bottom-btns-map-container"
                }
              >
                {onTopBulles?.map((btn, index) => (
                  <div className="btns-line-wrapper" key={index}>
                    <button
                      className={
                        btn?.name === "target"
                          ? "btn-style-target"
                          : "btn-style"
                      }
                      onDoubleClick={() => handleToggleBtnTop(index, btn)}
                    >
                      <Icon
                        icon={btn?.icon}
                        color={"#AAAAAA"}
                        size={24}
                        style={{
                          height: "auto",
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </button>
                    {btn?.name === "map-style" ? (
                      <>
                        {btn?.isOpen === true ? (
                          <div className="btns-line-content-map-style">
                            <img
                              src={ImgStreet}
                              alt="streetmap"
                              onClick={() => {
                                handleMapTypeChange("terrain");
                              }}
                            />

                            <img
                              src={ImgHybride}
                              alt="ImgHybride"
                              onClick={() => {
                                handleMapTypeChange("street");
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
              {currentMarkerData?.pullup && displayPullup && openPullup && (
                <div className={"right-top-plus-btns-map-container"}>
                  {onTopBulles?.map((btn, index) => (
                    <div className="btns-line-wrapper" key={index}>
                      <button
                        className={
                          btn?.name === "target"
                            ? "btn-style-target"
                            : "btn-style"
                        }
                        onDoubleClick={() => handleToggleBtnTop(index, btn)}
                      >
                        <Icon
                          icon={btn?.icon}
                          color={"#AAAAAA"}
                          size={24}
                          style={{
                            height: "auto",
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      </button>
                      {btn?.name === "map-style" ? (
                        <>
                          {btn?.isOpen === true ? (
                            <div className="btns-line-content-map-style">
                              <img
                                src={ImgStreet}
                                alt="streetmap"
                                onClick={() => {
                                  handleMapTypeChange("terrain");
                                }}
                              />

                              <img
                                src={ImgHybride}
                                alt="ImgHybride"
                                onClick={() => {
                                  handleMapTypeChange("street");
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
}

export default GisLayersNew;
