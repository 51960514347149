import React from "react";
import "./index.scss";
import Icon from "../../../../../../../../../../../../components/CustomIcon/Icon";
import { useSelector } from "react-redux";

function SettingModule({
  children = <></>,
  title = "",
  status = false,
  setStatus,
  headerIcon,
  iconColor = "black",
  dispbleOpen = false,
  iconSize = 12,
}) {
  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  return (
    <div className="settingModule">
      <div
        className="settingModuleTitle"
        onClick={() => setStatus(!status)}
        style={{
          flexDirection: language?.toLowerCase() === "ar" && "row-reverse",
        }}
      >
        <div className="settTitle">{title}</div>
        <div className="settIcon">
          <Icon
            icon={
              headerIcon ? headerIcon : status ? "chevron-up" : "chevron-down"
            }
            color={iconColor}
            size={iconSize}
          />
        </div>
      </div>
      {!dispbleOpen && status && (
        <div className="settingModulkeBody">{children}</div>
      )}
    </div>
  );
}

export default SettingModule;
