import { useState, useEffect } from "react";
import loadData from "../components/Dashboard/services/loadDataCollection";

function useDataCollection(endPoint, cleaningFunction) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    if (endPoint) {
      setLoading(true);

      const timeoutId = setTimeout(() => {
        if (!isCancelled) {
          setLoading(false);
        }
      }, 15000);

      loadData(
        endPoint,
        (fetchedData) => {
          if (!isCancelled) {
            setData(fetchedData);
            setLoading(false);
            clearTimeout(timeoutId);
          }
        },
        cleaningFunction
      );

      return () => {
        isCancelled = true;
        clearTimeout(timeoutId);
      };
    }
  }, [endPoint, cleaningFunction]);

  return { data, loading };
}

export default useDataCollection;
