const deviceview = {
  initialState: {
    navigationPath: [],
    currentComponent: "SplashScreen",
    currentPage: 0,
    loginStatus: false,
    lookups: [],
    settings: {
      openedScrollGuide: true,
      showScrollGuide: true,
      theme: "Maxapps",
      view: "row",
      font: "montserrat",
      appearance: "current",
      language: "en",
    },
  },
  reducers: {
    loginStatus: (dataSet, action) => {
      const { loginStatus } = action.payload;
      dataSet["loginStatus"] = loginStatus;
    },
    navigateTo: (state, action) => {
      const { component, props } = action.payload;
      const newPath = [...state.navigationPath, { component, props }];
      return {
        ...state,
        navigationPath: newPath,
        currentComponent: component,
      };
    },
    navigateToWithDrawer: (state, action) => {
      const { component, props } = action.payload;
      let firstPath = [];

      if (state.navigationPath[0]?.component?.toLowerCase() === "maxapps") {
        firstPath.push(state.navigationPath[0]);
      } else {
        firstPath = state.navigationPath?.slice(0, 2);
      }

      const newPath = [...firstPath, { component, props }];
      return {
        ...state,
        navigationPath: newPath,
        currentComponent: component,
      };
    },
    navigateToHome: (state, action) => {
      let firstPath = [];

      if (state.navigationPath[0]?.component?.toLowerCase() === "maxapps") {
        firstPath.push(state.navigationPath[0]);
      } else {
        firstPath = state.navigationPath?.slice(0, 2);
      }

      const newPath = [...firstPath];
      return {
        ...state,
        navigationPath: newPath,
        currentComponent: "maxapps",
      };
    },
    navigateBack: (state) => {
      if (state.navigationPath.length > 1) {
        const newPath = state.navigationPath.slice(0, -1);
        const previousComponent = newPath[newPath.length - 1].component;

        return {
          ...state,
          navigationPath: newPath,
          currentComponent: previousComponent,
        };
      }
      return state;
    },
    loginAct: (state, action) => {
      const newPath = [
        ...state.navigationPath,
        { component: "maxapps", props: {} },
      ];
      return {
        ...state,
        loginStatus: true,
        navigationPath: newPath,
        currentComponent: "maxapps",
      };
    },
    logoutAct: (state) => {
      const newPath = [{ component: "LoginScreen", props: {} }];
      return {
        ...state,
        loginStatus: false,
        navigationPath: newPath,
        currentComponent: "LoginScreen",
      };
    },
    setCurrentPage: (state, action) => {
      const { currentPage } = action.payload;
      state["currentPage"] = currentPage;
    },
    setTheme: (state, action) => {
      const { theme } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          theme,
        },
      };
    },
    setView: (state) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          view: state?.settings?.view === "row" ? "matrix" : "row",
        },
      };
    },

    setFont: (state, action) => {
      const { font } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          font,
        },
      };
    },

    setAppearance: (state) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          appearance:
            state?.settings?.appearance === "current" ? "legacy" : "row",
        },
      };
    },
    setLanguage: (state, action) => {
      const { language } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          language,
        },
      };
    },
    setOpenedScrollGuide: (state, action) => {
      const { openedScrollGuide } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          openedScrollGuide,
        },
      };
    },
    setShowScrollGuide: (state, action) => {
      const { showScrollGuide } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          showScrollGuide,
        },
      };
    },
    reset: (dataSet) => {
      return (dataSet = {
        ...dataSet,
        settings: {
          openedScrollGuide: dataSet?.settings?.openedScrollGuide,
          showScrollGuide: dataSet?.settings?.showScrollGuide,
          theme: dataSet?.settings?.theme,
          view: "row",
          font: "montserrat",
          appearance: "current",
          language: dataSet?.settings?.language,
        },
        navigationPath: [],
        currentComponent: "SplashScreen",
      });
    },
  },
};

export default deviceview;
