import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { dataChartMultiBar } from "../../../../../../../../../utils/chartFakeData";
import { optionsMultiBar } from "../../../../../../../../../utils/chartOptions";
import "./index.scss";

function Multibar({
  size = "small",
  label = "Add label",
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  return (
    <div
      className={`multibar-graph ${
        size?.toLowerCase() !== "small" && "multibar-large"
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className="multibar-graph-label">{label}</div>
      <div className="multibar-graph-chart">
        <Bar data={dataChartMultiBar} options={optionsMultiBar} />
      </div>
    </div>
  );
}

export default Multibar;
