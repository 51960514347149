import React from "react";
import "./index.scss";

function Modal({
  children,
  openModal = false,
  bg = "",
  tab = false,
  field = false,
  app = false,
  right,
  header = false,
  scrollGuide = false,
  lang = false,
}) {
  return (
    <React.Fragment>
      {openModal ? (
        <>
          {tab && !app ? (
            <div
              className="Model-mainContainer"
              style={{
                background: bg,
                right: header && "0",
              }}
            >
              <div
                className="ModalContainer"
                style={{
                  background: bg,
                  top: tab ? "5%" : field ? "0%" : null,
                  right: app ? "1.5%" : null,
                }}
              >
                {children}
              </div>
            </div>
          ) : (
            <div
              className="ModalContainer"
              style={{
                background: bg,
                top: tab ? "5.2%" : field ? "0%" : null,
                right: app ? "1.45%" : null,
                height: scrollGuide
                  ? "calc((100vh - 11.5rem)* 0.94)"
                  : lang && "calc((100vh - 12.4rem)* 0.94)",
                maxHeight: scrollGuide
                  ? "calc((100vh - 11.5rem)* 0.94)"
                  : lang && "calc((100vh - 12.4rem)* 0.94)",
                minHeight: scrollGuide
                  ? "calc((100vh - 11.5rem)* 0.94)"
                  : lang && "calc((100vh - 12.4rem)* 0.94)",
              }}
            >
              {children}
            </div>
          )}{" "}
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default Modal;
