function handleUtilityState(state, utilityState = "", setUtilityState) {
  try {
    if (state === "expend") {
      if (utilityState === "") {
        setUtilityState(state);
      } else {
        setUtilityState("");
      }
    }
    if (state === "maximize") {
      if (utilityState === "" || utilityState === "expend") {
        setUtilityState(state);
      } else {
        setUtilityState("expend");
      }
    }
  } catch (error) {
    console.error(`Error in handleUtilityState: ${error.message}`);
  }
}

export default handleUtilityState;
