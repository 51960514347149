import getFileContent from "../getFileContent";

async function handleClickedItem(
  addToast,
  store,
  skeleton,
  filename,
  draftDisplay,
  readOnly
) {
  if (!store) {
    return;
  }

  const payload = {
    draftSkeleton: {},
    draftDisplay,
    skeleton,
    readOnly,
  };

  store.dispatch({
    type: "explorer/updateState",
    payload,
  });

  const selectedItemPayload = filename
    ? {
        obj: { [filename]: true },
      }
    : { obj: {} };
  // store.dispatch({
  //   type: "revision/setSelectedItem",
  //   payload: selectedItemPayload,
  // });
  if (filename) {
    await getFileContent(store, filename, readOnly, addToast);
  }
}

export default handleClickedItem;
