import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss";
import { useTheme } from "../../../../../../theme/themeContext";
import DeleteModal from "../DeleteModal";
import CustomButton from "../../../../../../components/CustomButton";
import NotAllowed from "../../../ComponentsLibrary/Assets/NotAllowed.svg";
import Pointer from "../../../ComponentsLibrary/Assets/Pointer.svg";

function DeleteButton({
  withLabel = true,
  tooltip = "Delete",
  label = "Delete item",
  XMLChanged = false,
}) {
  const [openModal, setOpenModal] = useState(false);
  const { themeMode, themeColors } = useTheme();

  let editor = useSelector((state) =>
    state?.entities?.editor ? state?.entities?.editor : {}
  );

  const { dataToSet: data, parentToEdit: path, component } = editor;

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  const handleClickButton = () => {
    if (XMLChanged) {
      setOpenModal(true);
    }
  };
  const cursorStyle = XMLChanged ? { cursor: "not-allowed" } : {};
  return (
    <React.Fragment>
      {!openModal ? (
        <div style={cursorStyle}>
          <CustomButton
            iconName="del"
            tooltip={tooltip}
            label={label}
            isActive={!readOnly && component}
            iconColor={
              readOnly || !component || XMLChanged
                ? themeColors[themeMode].textDisabled
                : themeColors[themeMode].text
            }
            iconSize={24}
            withLabel={withLabel}
            onClick={() => {
              if (readOnly || !component || XMLChanged) {
                return;
              }
              setOpenModal(true);
            }}
            cursor={`url(${
              readOnly || !component || XMLChanged ? NotAllowed : Pointer
            }), auto`}
          />
          <div
            style={cursorStyle}
            className="DeleteBtn"
            onClick={
              readOnly || !component ? null : (e) => handleClickButton(e)
            }
          ></div>
        </div>
      ) : (
        <DeleteModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={data}
          component={component}
          path={path}
        />
      )}
    </React.Fragment>
  );
}

export default DeleteButton;
