export function calculateTotalSizeInMB(iconsListing) {
  const totalSizeInBytes = iconsListing.reduce((acc, icon) => {
    const sizeValue = icon?.size || 0;
    return acc + sizeValue;
  }, 0);

  const totalSizeInMB = totalSizeInBytes / (1024 * 1024);

  return `${totalSizeInMB.toFixed(2)} MB`;
}
