import getFileContent from "../getFileContent";

async function handleReadContentVersion(addToast, store, filename, data = {}, readOnly) {
  if (!store) {
    return;
  }

  const draftSkeleton = store?.getState()?.entities?.explorer?.skeleton;

  store.dispatch({
    type: "explorer/addDraftSkeleton",
    payload: { skeleton: draftSkeleton },
  });
  store.dispatch({
    type: "editor/dataAdded",
    payload: { data: {}, parent: [] },
  });

  const payload = filename ? { obj: { [filename]: true } } : {};
  store.dispatch({ type: "revision/setSelectedItem", payload });

  store.dispatch({ type: "revision/setSelectedRevision", payload: { data } });

  await getFileContent(store, filename, readOnly, addToast);
  // await getFileContent(store, filename, false, addToast);
}

export default handleReadContentVersion;
