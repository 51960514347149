import { useState, useEffect } from "react"
import "./index.scss"

const LoggerInput = ({ label, data, type = "text", id, reload = false }) => {
  const [value, setValue] = useState(data)

  useEffect(() => {
    if (reload) {
      setValue(data)
    }
  }, [data, reload])

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className='input-group-logger'>
      <label htmlFor={id} className='input-label'>
        {label}
      </label>
      <input
        type={type}
        id={id}
        className='input-field'
        value={reload ? data : value}
        onChange={handleChange}
        autoComplete={type === "password" ? "off" : "on"}
      />
    </div>
  )
}

export default LoggerInput
