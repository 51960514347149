import React, { useState } from "react";
import "./index.scss";
import Sidebar from "./components/SideBar/index";
import Dashboard from "./components/Dashboard/index";

function Monitor() {
  const [showModal, setShowModal] = useState(false);
  const [activeDropdownBtn, SetDropdownActiveBtn] = useState("Dashboard");

  return (
    <div className="monitor-container">
      <Sidebar showModal={showModal} setShowModal={setShowModal} />

      {activeDropdownBtn === "Dashboard" && (
        <Dashboard showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
}

export default Monitor;
