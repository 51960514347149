function deleteItemByIndex(data, setData, index) {
  try {
    if (index >= 0 && index < data.length) {
      const newData = [...data.slice(0, index), ...data.slice(index + 1)];
      setData(newData);
    } else {
      throw new Error("Index out of bounds");
    }
  } catch (error) {
    console.error(`An error occurred in deleteItemByIndex: ${error.message}`);
  }
}

export default deleteItemByIndex;
