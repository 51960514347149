import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as DeviceLanguage from "./index";

const i18nInstanceDevice = i18n.createInstance();
i18nInstanceDevice.use(initReactI18next).init({
  resources: {
    ar: {
      translation: DeviceLanguage.ar,
    },
    en: {
      translation: DeviceLanguage.en,
    },
    al: {
      translation: DeviceLanguage.al,
    },
    fr: {
      translation: DeviceLanguage.fr,
    },
    it: {
      translation: DeviceLanguage.it,
    },
    nb: {
      translation: DeviceLanguage.nb,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18nInstanceDevice;
