import validateUrl from "./validateUrl";
import verifyUrlStatus from "./verifyUrlStatus";

async function verifyConditions(
  values,
  setOslcStatus = () => {},
  // setMaxRestStatus = () => {},
  setIsLoading = () => {},
  setCondition = () => {},
  // setErrorMaxRestUrl = () => {},
  setErrorOslcUrl = () => {}
) {
  if (validateUrl(values?.url)) {
    const oslcResponse = await verifyUrlStatus(values?.oslcUrl, values);
    const errorOslcMessage = oslcResponse?.content?.message;
    setOslcStatus(oslcResponse?.content?.status);
    errorOslcMessage && setErrorOslcUrl(errorOslcMessage);

    // const maxRestResponse = await verifyUrlStatus(
    //   `${values?.maxRestUrl}/rest/os`,
    //   values
    // );
    // const errorRestMessage = maxRestResponse?.content?.message;

    // setMaxRestStatus(maxRestResponse?.content?.status);
    // errorRestMessage && setErrorMaxRestUrl(errorRestMessage);

    setIsLoading(false);

    setCondition(
      oslcResponse?.content?.status
      // && maxRestResponse?.content?.status
    );
  }
}
export default verifyConditions;
