import React, { useContext } from "react";
import "./index.scss";
import { handleComponentProp } from "../../../../services/handleComponentProperties";
import { useStore } from "react-redux";
import useToasts from "../../../../../../../../../components/CustomToast/useToasts";

import { navigateTo } from "../../../../services/navigationMiddleware";
import { AppContext } from "../../../../../../../services/AppContext";

function UnknownSampleKPI({ data = {}, path = [], inPage = false }) {
  const store = useStore();
  const { addToast } = useToasts();
  const { setKpiDetail } = useContext(AppContext);

  function handleNavigateTo(e) {
    e.stopPropagation();
    if (!data?.detail && !data?.hasOwnProperty("detail")) {
      addToast("No detail tab in the list", "error");
      return;
    }
    handleComponentProp(
      e,
      null,
      data?.detail,
      [...path, "detail"],
      store,
      "detail"
    );
    navigateTo(store, "List", {
      data: data?.detail,
      path: [...path, "detail"],
    });
  }

  return (
    <div
      className={`unknown-sample-kpi ${inPage && "unknown-sample-kpi-page"}`}
      onClick={(e) => {
        handleComponentProp(
          e,
          null,
          data?.detail,
          [...path, "detail"],
          store,
          "detail"
        );
        setKpiDetail(true);
      }}
      onDoubleClick={(e) => handleNavigateTo(e)}
    >
      Type Unspecified
    </div>
  );
}

export default UnknownSampleKPI;
