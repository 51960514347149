import uploadIconToServer from "./uploadIconToServer";
import mergeArray from "./helpers/mergeArrays";

async function handleUpload(
  files,
  iconMaxSize,
  addToast,
  setIconsListing,
  iconsListing
) {
  files.forEach(async (file) => {
    const formdata = new FormData();

    if (file.type !== "image/png" && !file.name.endsWith(".png")) {
      addToast("Only PNG files are supported  " + file.name, "warn");
      return;
    }

    if (file.size > iconMaxSize) {
      addToast("Icon size is too large for " + file.name, "warn");
      return;
    }

    if (iconsListing.some((icon) => icon.iconName === file.name)) {
      addToast("Icon already uploaded: " + file.name, "warn");
      return;
    }

    formdata.append("files", file);

    try {
      const uploadedFiles = await uploadIconToServer(formdata, addToast);
      setIconsListing((oldIconsList) =>
        mergeArray(oldIconsList, uploadedFiles, "iconName")
      );
    } catch (error) {
      addToast(error, "error");
    }
  });
}

export default handleUpload;
