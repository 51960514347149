import { axiosProviderNode } from "../../../services/http/httpService";
import { services } from "./configVariables";

async function dachboardSignUp(email, password, passwordConfirmation) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_DASHBOARD_SIGNUP;

    const body = {
      dashboardUserName: email || "",
      dashboardUserPassword: password || "",
      dashboardUserPasswordConfirmation: passwordConfirmation || "",
    };

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    console.error("dashboard signUp error", error);
    if (error?.response?.status === 500) {
      return {
        status: false,
        message: "Cannot connect to Maxapps sync Server",
      };
    }
    throw error;
  }
}

export default dachboardSignUp;
