import imxtools from "imxtools";

export const fetchDataSkelotonDrawe = (obj, currentPath = ["maxapps"]) => {
  let arrayDrawer = [];

  // const processItems = (items = [], path, key = "list") => {
  //   items?.forEach((item, index) => {
  //     const newPath = [...path, index.toString(), key];
  //     if (Boolean(item?.label)) {
  //       arrayDrawer.push({
  //         label: item.label,
  //         icon: item.icon,
  //         index: "",
  //         path: newPath,
  //       });
  //     }
  //   });
  // };

  // const processModuleOrRow = (item, path, key) => {
  //   if (item && item.hasOwnProperty(key)) {
  //     const itemsArray = imxtools?.toArray(item[key]);
  //     const newPath = [...path, key];

  //     // Check if there are rows, apps, or kpis
  //     if (itemsArray?.length > 1) {
  //       processItems(itemsArray, newPath, key);
  //     } else if (itemsArray?.length === 1) {
  //       // Check if the single item is of type "row", "app", or "kpi"
  //       const singleItem = itemsArray[0];
  //       const subKey =
  //         singleItem.hasOwnProperty("row")
  //           ? "row"
  //           : singleItem.hasOwnProperty("app")
  //           ? "app"
  //           : singleItem.hasOwnProperty("kpi")
  //           ? "kpi"
  //           : null;

  //       if (subKey) {
  //         const subItemsArray = imxtools?.toArray(singleItem[subKey]);
  //         const subPath =
  //           subKey === "app" && subItemsArray?.length > 1
  //             ? [...newPath, subKey]
  //             : subKey === "kpi" && subItemsArray?.length > 1
  //             ? [...newPath, subKey ]
  //             : [...newPath, subKey];

  //         processItems(subItemsArray, subPath, subKey);
  //       }
  //     }
  //   }
  // };

  // if (typeof obj === "object" && obj !== null && obj?.maxapps) {
  //   const { maxapps } = obj;

  //   if (maxapps.hasOwnProperty("page")) {
  //     imxtools?.toArray(maxapps.page).forEach((page, indexPage) => {
  //       const newPath = [...currentPath, "page", indexPage.toString()];

  //       // Process rows, modules, and kpis
  //       processModuleOrRow(page, newPath, "row");
  //       processModuleOrRow(page, newPath, "module");
  //       processModuleOrRow(page, newPath, "kpi");

  //       // Process individual app
  //       processModuleOrRow(page, newPath, "app");
  //     });
  //   }

  //   // Process GIS
  //   if (maxapps.hasOwnProperty("gis") && Boolean(maxapps.gis?.label)) {
  //     const gisPath = [...currentPath, "gis"];
  //     arrayDrawer.push({
  //       label: maxapps.gis.label,
  //       icon: maxapps.gis.icon,
  //       index: "",
  //       path: gisPath,
  //     });
  //   }
  // }

  return arrayDrawer;
};

export function arraysAreEqual(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (arr1?.[i] !== arr2?.[i]) {
      return false;
    }
  }

  return true;
}

export function compareArraysOfObjects(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1.sort();
  arr2.sort();

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();
    if (keys1.join(",") !== keys2.join(",")) {
      return false;
    }

    for (let j = 0; j < keys1.length; j++) {
      const key = keys1[j];
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
}

export const restrictedList = [
  "footer",
  "header",
  "list",
  "body",
  "thumb",
  "graph",
  "detail",
  "media",
  "takegeoposition",
  "toolbar",
  "signature",
  "carroussel"
];

export function arraysHaveSameElementsInOrder(array1, array2) {
  if (array1.length > array2.length) {
    return false; // If array1 is longer, it can't be entirely contained in array2
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false; // If elements don't match in the same position, return false
    }
  }

  return true; // All elements matched in the same order
}

export const RightbtnsInMap = [
  { name: "layers", icon: "layers", isOpen: false },
  { name: "add", icon: "new_solid", isOpen: false },
  { name: "label-on", icon: "label-on", isOpen: false },
  { name: "localisation-history", icon: "localisation-history", isOpen: false },
  { name: "iot-icon", icon: "iot-icon", isOpen: false },
  { name: "all-pins", icon: "all-pins", isOpen: false },
];
export const RightbtnsInMapH = [
  { name: "retry", icon: "retry", isOpen: false },
  { name: "display-label", icon: "display-label", isOpen: false },
  { name: "gps_solid", icon: "gps_solid", isOpen: false },
  { name: "map-style", icon: "map-style", isOpen: false },
];
export const RightbtnsInMapV = [
  { name: "display-label", icon: "display-label", isOpen: false },
  { name: "gps_solid", icon: "gps_solid", isOpen: false },
  { name: "map-style", icon: "map-style", isOpen: false },
];

export const RightbtnsInMapHorizontale = [
  { name: "wo-pin", icon: "wo-pin" },
  { name: "wo-pin", icon: "wo-pin" },
  { name: "assets", icon: "assets" },
  { name: "wo-pin", icon: "wo-pin" },
  { name: "assets", icon: "assets" },
];

// export const RightbtnsInMapH = [
//   { name: "iot-icon", icon: "iot-icon" },
//   { name: "all-pins", icon: "all-pins" },
//   { name: "localisation-history", icon: "localisation-history" },
//   { name: "target", icon: "target" },
// ];

// export const RightbtnsInMapHorizontale = [
//   { name: "wo-pin", icon: "wo-pin" },
//   { name: "wo-pin", icon: "wo-pin" },
//   { name: "assets", icon: "assets" },
//   { name: "wo-pin", icon: "wo-pin" },
//   { name: "assets", icon: "assets" },
// ];

// export const RightbtnsInMap = [
//   { name: "layers", icon: "layers", isOpen: false },
//   { name: "add", icon: "add", isOpen: false },
//   { name: "label-on", icon: "label-on", isOpen: false },
//   { name: "google-pins", icon: "google-pins", isOpen: false },
//   { name: "display-label", icon: "display-label", isOpen: false },
//   { name: "map-style", icon: "map-style", isOpen: false },
//   { name: "retry", icon: "retry", isOpen: false },
// ];
