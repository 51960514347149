import React, { useEffect, useState } from "react";
import Icon from "../../../../../../components/CustomIcon/Icon";
import UploadFiles from "./DragAndDrop/index";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import fetchListVersion from "../../../../services/revision";
import xml2js from "minexml2js";
import { useDropzone } from "react-dropzone";
import HandleImportFile from "../../services/handleImportFile";
import useToasts from "../../../../../../components/CustomToast/useToasts";
import getFileXmlContent from "../../services/getFileXmlContent";

function CustomDropzone(isleft = true) {
  let store = useStore();
  const { addToast } = useToasts();
  const [side, setSide] = useState(isleft.isleft);
  const [searchTerm, setSearchTerm] = useState("");
  const [xmlVal, setXmlVal] = useState("");
  const { draftDisplay, showRight, showLeft, versionsList } = useSelector(
    (state) => ({
      draftDisplay: state?.entities?.explorer?.draftDisplay,
      showLeft: state?.entities?.compareFiles?.leftCode?.active || false,
      showRight: state?.entities?.compareFiles?.rightCode?.active || false,
      versionsList: state?.entities?.revision?.list || [],
    })
  );

  const { dataVersions = [] } = fetchListVersion(versionsList) || {};
  const isOn = JSON.parse(localStorage.getItem("isUp"));
  const [isUp, setIsUp] = useState(isOn);
  const iconClicked = JSON.parse(localStorage.getItem("iconClicked"));
  const [isIconClicked, setisIconClicked] = useState(iconClicked);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setIsUp(event.target.value !== "");
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) {
      console.error("No file selected.");
      return;
    }

    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (
      fileType === "text/xml" ||
      fileType === "application/xml" ||
      fileExtension === "xml"
    ) {
      changeHandler(file);
    } else {
      addToast("Select a file with XML extension.", "error");
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: "text/xml, application/xml",
    noClick: true,
  });

  async function changeHandler(selectedFile) {
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onloadend = async () => {
      try {
        const searchRegExp = new RegExp("&", "g");
        const xmlData = reader.result.replace(searchRegExp, "&amp;");
        const { maxOrder, result } = await new xml2js.Parser({
          explicitArray: false,
          emptyTag: { $: { id: "mockId" } },
        }).parseStringPromise(xmlData);
        HandleImportFile(
          side,
          showLeft,
          showRight,
          draftDisplay,
          xmlData,
          selectedFile,
          store
        );
      } catch (error) {
        addToast("Select a file with XML extension.", "error");
      }
    };
  }
  const filteredData = searchTerm
    ? dataVersions.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : dataVersions;

  const revisionClick = async (item) => {
    const file = item.filename;
    const finalXML = await getFileXmlContent(file);

    if (side) {
      store.dispatch({
        type: "compareFiles/setLeftMode",
        payload: {
          leftMode: "Revision",
        },
      });
      store.dispatch({
        type: "compareFiles/setSelectedItemLeft",
        payload: {
          itemLeftSelected: item,
        },
      });
    } else {
      store.dispatch({
        type: "compareFiles/setRightMode",
        payload: {
          rightMode: "Revision",
        },
      });
      store.dispatch({
        type: "compareFiles/setSelectedItemRight",
        payload: {
          itemRightSelected: item,
        },
      });
    }
    setXmlVal(finalXML);
  };

  useEffect(() => {
    if (side && xmlVal != "") {
      store.dispatch({
        type: "compareFiles/setLeftXmlValue",
        payload: {
          leftXml: xmlVal,
        },
      });
      store.dispatch({
        type: "compareFiles/setLeftSideShow",
        payload: {
          showLeft: true,
        },
      });
      if (showRight === true) {
        store.dispatch({
          type: "compareFiles/updateCompareState",
          payload: {
            compare: true,
          },
        });
      }
    } else if (!side && xmlVal != "") {
      store.dispatch({
        type: "compareFiles/setRightXmlValue",
        payload: {
          rightXml: xmlVal,
        },
      });
      store.dispatch({
        type: "compareFiles/setRightSideShow",
        payload: {
          showRight: true,
        },
      });
      if (showLeft === true || draftDisplay) {
        store.dispatch({
          type: "compareFiles/updateCompareState",
          payload: {
            compare: true,
          },
        });
      }
    }
  }, [xmlVal]);
  const handleIconClick = () => {
    setIsUp(!isUp);

    setisIconClicked(!isIconClicked);
  };
  return (
    <React.Fragment>
      <div
        className="DropDownCompare-container"
        onClick={() => {
          if (isUp) {
            setIsUp(!isUp);
          }
        }}
      >
        <div className="Compare-container" {...getRootProps()}>
          <label className="label-search">Select from revision</label>
          <div
            className={`Compare-search ${
              searchTerm || isIconClicked ? "text-entered" : ""
            }`}
          >
            <input
              className="search-input"
              placeholder="Select File"
              onChange={handleSearchChange}
              value={searchTerm}
              onClick={() => setIsUp(!isUp)}
            />
            <div className="search-icon" onClick={handleIconClick}>
              <Icon
                icon={isUp ? "chevronUp" : "chevronDown"}
                size={24}
                color="#4C36A5"
              />
            </div>
            {filteredData.length > 0 && (
              <div
                className={`versionList ${isUp ? "versionList-active" : ""}`}
              >
                {filteredData.map((item, index) => (
                  <div
                    className="revisionContent"
                    key={index}
                    onClick={() => revisionClick(item)}
                  >
                    <div>
                      <div className="xmlAlias">
                        {item?.version} {item?.xmlAlias}
                      </div>
                      <div className="releaseDatetime">{item?.release}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <label className="label-search">Or upload your XML file</label>
          <div className="dnd-container">
            <UploadFiles XmlSide={isleft} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CustomDropzone;
