import deleteIcons from "./deleteIcons";

const confirmDelete = async (
  iconsToDelete,
  setIconsListing,
  iconsListing,
  addToast,
  setIconsToDelete,
  setIconsSelected,
  setShowDeleteModal
) => {
  try {
    await deleteIcons(iconsToDelete, addToast);

    const filteredData = iconsListing.filter(
      (icon) => !iconsToDelete.includes(icon.iconName)
    );

    setIconsListing(filteredData);
    setIconsSelected([]);
    setIconsToDelete([]);
    setShowDeleteModal(false);
  } catch (error) {
    console.error("Error deleting icons:", error);

    addToast("Error deleting icons.", "error");
  }
};

export default confirmDelete;
