export const Figrus = {
  themeName: "Figrus",
  isDark: false,
  CardViewBgColor: "#189ED910",
  secondaryLight: "#12182380",
  primary: "#189ED9",
  iconAppItem: "#FEBE10",
  AppText: "#414141",
  connectBtn: "#189ED9",
  loader: "#189ED9",
  headerColor: "#189ED9",
  primaryLight: "#189ED950",
  primaryColor: "#189ED9B2",

  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#FFF",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#FEBE10",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#FEBE10",
  focusedBorderLoginInput: "#FEBE10",
  focusedLabelLoginInput: "#FEBE10",
  resetInputLogin: "#FEBE10",
  focusedBorderLoginInput: "#FEBE10",

  languageIcon: "#565757",
  settingCollapseColor: "#eee",
  welcomeBg: "#FBF9F9",
  success: "#3ecd7e",
  primaryDark: "#0E5A7C",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#189ED9",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#189ED9",
  yellowToast: "#edc300",
  greyToast: "#FEBE10",
  grayToast: "#aaa",
  lightToast: "#D9C9CC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#189ED9",
  groupIcon: "#ffffff",
  loginBtnLabel: "#189ED9",

  borderRecord: "#189ED950",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D9C9CC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D9C9CC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#189ED9",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#ffe1ff1a",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#FFF",
  loginOptions: "#fff",
  loginFooter: "#565757",
  shadowBtnConnect: "#FEBE10",
  bnConnect: "#fff",
  notfocusedBorderLoginInput: "#FFF",
  headerPrimary: "#189ED900",
  headerText: "#414141",
  headerDark: "#6E0E1F00",
  notificationUpColor: "#F6FBFE",
  notificationDownColor: "#E8F6FC",

  listItemNotificationUpColor: "#F1FAFD",
  listItemNotificationDownColor: "#DFF3FB",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F3F4",

  notFocusedSocialMedia: "#189ED9",
  focusedSocialMedia: "#189ED9cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#189ED9",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",
};
