import React, { useState } from "react";

export const DNDContext = React.createContext();
const CustomDndProvider = ({ children }) => {
  // const [insideInner, setInsideInner] = useState(false);
  const [index, setIndex] = useState();
  const [path, setPath] = useState([]);
  const [data, setData] = useState({});
  return (
    <DNDContext.Provider
      value={{
        index,
        setIndex,
        path,
        setPath,
        data,
        setData,
      }}
    >
      {children}
    </DNDContext.Provider>
  );
};

export default CustomDndProvider;
