import React, { useEffect, useMemo, useState } from "react";
import { useStore, useSelector } from "react-redux";
import { useTheme } from "../../../../theme/themeContext";
import "./index.scss";
import xml2js from "minexml2js";
import Dropdown from "react-multilevel-dropdown";
import Icon from "../../../../components/CustomIcon/Icon";
import Modal from "../../../../components/CustomModal";
import handleClickedItem from "../../services/helpers/handleClickedItem";
import useToasts from "../../../../components/CustomToast/useToasts";
import handleEditContentVersion from "../../services/helpers/handleEditContentVersion";
import transformForStore from "../../services/helpers/transformForStore";
import handleSaveRevision from "../../services/helpers/handleSaveRevison";
import {
  aceEditorFontSize,
  aceEditorThemes,
} from "./AceEditor/services/EditorSettings";
import Editor from "./AceEditor";
import NoItem from "../../../../components/NoItem";
import CustomButton from "../../../../components/CustomButton";
import {
  JsonToXML,
  findLineOfId,
  getIdValue,
  json2xml,
} from "./services/xmlUtils";
import { useSaveProps } from "../../services/savePropsContext";
import Tooltip from "@mui/material/Tooltip";
import Switcher from "../../../../components/Switcher";

const XmlSide = ({
  lastVersion = {},
  xmlValue = "",
  onChange = () => {},
  versionsList = [],
  setHandleEditorClick = () => {},
  XMLChanged = false,
}) => {
  const { themeMode, themeColors } = useTheme();
  const [lineNumber, setLineNumber] = useState(-1);
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [showSwitchPopup, setShowSwitchPopup] = useState(false);
  const [editorDisabled, setEditorDisabled] = useState(false);
  const store = useStore();
  const { addToast } = useToasts();
  const [wordWrap, setWordWrap] = useState(true);

  const {
    currentThemeMode,
    draftDisplay,
    revisionSelected,
    codeEditor,
    draftSkeleton,
    skeleton,
    edit,
    existSelectedVersion,
  } = useSelector((state) => ({
    currentThemeMode: state?.entities?.theme?.mode || "light",
    draftDisplay: state?.entities?.explorer?.draftDisplay || false,
    revisionSelected: state?.entities?.revision?.revisionSelected || {},
    codeEditor: state?.entities?.codeeditor || {},
    draftSkeleton: state?.entities?.explorer?.draftSkeleton || {},
    skeleton: state?.entities?.explorer?.skeleton || {},
    edit: state?.entities?.editor || {},
    existSelectedVersion: state?.entities?.revision?.itemSelected ?? {},
  }));

  const {
    dataToSet: data,
    parentToEdit: parent,
    component: componentEdited,
  } = edit;

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  const { fontSize, theme } = codeEditor;
  const { res_equal } = useSaveProps();

  useEffect(() => {
    setEditorDisabled(!res_equal);
  }, [res_equal]);

  useEffect(() => {
    if (currentThemeMode === "dark") {
      store.dispatch({
        type: "codeeditor/updateTheme",
        payload: {
          theme: "twilight",
        },
      });
    }
  }, [currentThemeMode]);

  useEffect(() => {
    const processXmlContent = () => {
      const XmlFullCode = JsonToXML(skeleton, addToast);
      const xmlContent = json2xml(componentEdited, data, addToast);
      const firstId = getIdValue(xmlContent);
      const currentLineNumber = firstId
        ? findLineOfId(XmlFullCode, firstId)
        : -1;

      setLineNumber(currentLineNumber);
    };
    if (
      versionsList?.length === 0 &&
      !draftSkeleton?.maxapps &&
      !skeleton?.maxapps
    ) {
      return;
    }
    processXmlContent();
  }, [parent, componentEdited]);

  const handleDiscard = async () => {
    await handleClickedItem(
      addToast,
      store,
      {},
      lastVersion?.filename,
      false,
      true
    );
  };

  const handleEditVersion = async () => {
    await handleEditContentVersion(
      addToast,
      store,
      revisionSelected?.filename,
      revisionSelected?.maxOrder
    );
  };

  const handleFontSize = (e, newSize) => {
    e.stopPropagation();
    store.dispatch({
      type: "codeeditor/updateFontSize",
      payload: {
        fontSize: newSize,
      },
    });
  };

  const handleTheme = (e, newTheme) => {
    e.stopPropagation();
    store.dispatch({
      type: "codeeditor/updateTheme",
      payload: {
        theme: newTheme,
      },
    });
  };

  const handleClick = () => {
    setHandleEditorClick(true);

    if (editorDisabled && !readOnly) {
      setShowSwitchPopup(true);
    }
  };

  const toggleWordWrap = () => {
    setWordWrap((prevWordWrap) => !prevWordWrap);
  };



  return (
    <React.Fragment>
      <div className="rightBar">
        <div className="tabsHeader">
          <div
            className="headerTitle"
            style={{
              color:
                editorDisabled && !readOnly
                  ? "#b9b8bd"
                  : "var(--TitleContainer)",
              gap: "5px",
            }}
          >
            Code Editor
            {editorDisabled && !readOnly && (
              <Icon icon={"lock"} color={"#b9b8bd"} size={18} />
            )}
          </div>
          {versionsList?.length > 0 && (
            <div className="header-CE">
              <Tooltip title="Theme and size">
                <div className="options">
                  <Dropdown
                    title={
                      <Icon
                        icon="editor-otions"
                        size={24}
                        color={themeColors[themeMode].text}
                      />
                    }
                    menuClassName="Dropdown-Menu"
                    openOnHover={false}
                    className="dropdownTitle"
                    wrapperClassName="wrapperDropdown"
                  >
                    <Dropdown.Item className="Dropdown-Item">
                      Font Size
                      <Dropdown.Submenu className="Dropdown-Submenu">
                        {aceEditorFontSize?.map((size, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Dropdown.Item
                                className={`Dropdown-Item ${
                                  size?.toString() === fontSize?.toString() &&
                                  "Selected-Dropdown-Item"
                                }`}
                                key={index}
                                onClick={(e) => {
                                  handleFontSize(e, size);
                                }}
                              >
                                {size}
                              </Dropdown.Item>
                            </React.Fragment>
                          );
                        })}
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                    <Dropdown.Item className="Dropdown-Item">
                      Themes
                      <Dropdown.Submenu className="Dropdown-Submenu">
                        {aceEditorThemes?.map((Theme, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Dropdown.Item
                                className={`Dropdown-Item ${
                                  Theme === theme && "Selected-Dropdown-Item"
                                }`}
                                key={index}
                                onClick={(e) => {
                                  handleTheme(e, Theme);
                                }}
                              >
                                {Theme.charAt(0).toUpperCase() + Theme.slice(1)}
                              </Dropdown.Item>
                            </React.Fragment>
                          );
                        })}
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                    <Dropdown.Item className="Dropdown-Item">
                      {" "}
                      Word Wrap
                      <Switcher
                        toggleSwitch={() => toggleWordWrap()}
                        isActive={wordWrap}
                      />
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className={`tabContent ${editorDisabled ? "editor-disabled" : ""}`}
          onClick={handleClick}
        >
          {versionsList?.length === 0 &&
          !draftSkeleton?.maxapps &&
          !skeleton?.maxapps ? (
            <NoItem
              icon="code"
              text="No XML uploaded"
              iconColor={themeColors[themeMode].iconLabelDisable}
              textColor={themeColors[themeMode].iconLabelDisable}
              marginVertical={10}
            />
          ) : (
            <div
              className={`tabContent ${
                editorDisabled ? "editor-disabled" : ""
              }`}
              onClick={handleClick}
            >
              <Editor
                theme={theme}
                onChange={onChange}
                fontSize={fontSize}
                value={xmlValue}
                readOnly={editorDisabled || readOnly}
                lineNumber={lineNumber}
                dataToSet={data}
                store={store}
                wordWrap={wordWrap}
                revisionSelected={revisionSelected}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        open={showDiscardPopup}
        onClose={() => {
          setShowDiscardPopup(false);
        }}
        onClickPrimaryAction={() => {
          setShowDiscardPopup(false);
          handleDiscard();
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowDiscardPopup(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Discard"
      >
        <div className="ModalContent">
          <div className="message">Are you sure to discard this draft?</div>
          {/* <div className="draftVersion">{`Draft of: ${version} - ${xmlAlias}`}</div> */}
        </div>
      </Modal>
      <Modal
        open={showSwitchPopup}
        onClose={() => {
          setShowSwitchPopup(false);
        }}
        onClickPrimaryAction={() => {
          setShowSwitchPopup(false);
          handleClickedItem(
            addToast,
            store,
            skeleton,
            revisionSelected?.filename,
            true,
            false
          );
          setEditorDisabled(false);
          store.dispatch({
            type: "editor/updateXMLState",
            payload: { XMLChanged: false },
          });
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowSwitchPopup(false);
        }}
        primaryLabel="Discard"
        secondaryLabe="Cancel"
        modalTitle="Attention!"
      >
        <div className="ModalContent">
          <div className="message">
            You are currently editing on Properties Panel!
          </div>
          <div className="message">Would you like to discard your edits?</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default XmlSide;
