import { batch } from "react-redux";
import { setIn } from "lodash-redux-immutability";
import imxtools from "imxtools";
import handleAddItem from "../../components/ComponentsLayers/Preview/services/handleAddItem";
import handlePublish from "../../components/AppBar/services/handlePublish";
import transformForXml from "./transformForXml";

async function handleNewSkeleton(store, filename, addToast, skeleton) {
  if (!store) {
    return;
  }

  const resetActions = [
    { type: "explorer/newSkeleton" },
    { type: "editor/reset" },
    { type: "revision/newSkeleton" },
  ];

  batch(() => {
    resetActions.forEach((action) => store.dispatch(action));
  });

  try {
    handleAddItem("maxapps", store, skeleton, []);

    const parent = ["maxapps"];
    const key = imxtools.generateUniqueKey(parent[parent.length - 1]);

    const finalData = {
      version: "V2",
      id: `imxComponent${key}`,
    };

    const newData = setIn(skeleton, parent, finalData);
    handlePublish(
      transformForXml(newData),
      filename,
      store,
      "draft",
      addToast,
      false
    );
    store.dispatch({
      type: "explorer/updateData",
      payload: {
        skeleton: { ...newData },
        readOnly: false,
      },
    });
    store.dispatch({
      type: "deviceview/navigateToHome",
    });
    store.dispatch({
      type: "deviceview/setCurrentPage",
      payload: {
        currentPage: 0,
      },
    });
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: {},
        parent: ["maxapps"],
        component: "maxapps",
      },
    });
  } catch (error) {}
}

export default handleNewSkeleton;
