import React, { Suspense } from "react"
import { Outlet, useLocation } from "react-router-dom"
import NavBar from "../../components/NavBar"
import Loading from "../../components/Loading"

const Layout = () => {
  const location = useLocation()

  const shouldShowNavBar = location.pathname !== "/"

  return (
    <div width='100vw' height='100vh'>
      {shouldShowNavBar && <NavBar />}
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </div>
  )
}

export default Layout
