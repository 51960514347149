import React from "react";

import Icon from "../../../../../icon";
import HoveredIcon from "../../../../../hovered-icon";

import { colors } from "../../../../../../../../services/common";

function ObjectSubItem(props) {
  const {
    objectAttr = {},
    indexElement = {},
    openedFromParent = () => {},
    readOnly = {},
    attrValue = {},
    open = {},
    hasObject = {},
    parent = {},
    label = "",
    handleCollapse = () => {},
    deleteItem = () => {},
    // handleAdd = () => {},
    currentOpen = {},
  } = props;
  return (
    <div
      className={
        currentOpen[indexElement] ? "sub-selected-drop-down" : "sub-drop-down"
      }
    >
      <button
        className={`sub-collapse-btn`}
        onClick={() =>
          handleCollapse(
            parent,
            indexElement,
            attrValue,
            objectAttr,
            openedFromParent
          )
        }
      >
        <div className="object-button d-flex flex-row">
          {hasObject &&
            (openedFromParent || open[indexElement] ? (
              <Icon icon="chevron-down" size={15} color="#6f53a3" />
            ) : (
              <Icon icon="chevron-right" size={15} color="#6f53a3" />
            ))}

          <div
            className="sub-item-label"
            style={{
              background: `${colors[objectAttr]}`,
            }}
          >{`${objectAttr}`}</div>
          <div
            className={`object-name ${
              currentOpen[indexElement] ? "selected" : ""
            }`}
          >
            {label}
          </div>
        </div>
      </button>
      {!readOnly && (
        <React.Fragment>
          <button
            className="delete-item"
            onClick={() => deleteItem(indexElement, label)}
          >
            <HoveredIcon
              indexElement={indexElement}
              iconName={"rubbish-bin_solid"}
              defaultColor={"#6f53a3"}
              hoverColor={"#d10e0e"}
              size={20}
            />
          </button>
          <span className="add-left-border"></span>
        </React.Fragment>
      )}
    </div>
  );
}

export default ObjectSubItem;
