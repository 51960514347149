import React, { useState, useContext, useEffect } from "react";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import DragItemApp from "../../../../../ComponentsLibrary/DragItemApp";
import imxtools from "imxtools";
import { handleMouseEnter, handleMouseLeave, handleNavigateTo } from "./";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import { useSelector } from "react-redux";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";
import Swipe from "../../Swipe";
import DropTargetBody from "../../DropTargetBody";
import { AppContext } from "../../../../../../services/AppContext";
import Event from "../../Event";
import DragItemAttribute from "../../../../../ComponentsLibrary/DragItemAttribute";
import Form from "../../Form";
import Modal from "../../Modal";
import useToasts from "../../../../../../../../components/CustomToast/useToasts";
import { getIn } from "lodash-redux-immutability";

function RenderApps(
  store,
  readOnly = true,
  pages = {},
  path = [],
  currentPage = 0,
  PageContent = {},
  hoverIn = "",
  setHoverIn,
  theItem = false,
  setTheItem,
  Apps,
  setApps,
  hoverStates = [],
  setHoverStates,
  view = "row",
  currentPath = []
) {
  const { addToast } = useToasts();
  const [forms, setForms] = useState([]);
  const [hforms, setHforms] = useState([]);
  const apps = imxtools?.toArray(PageContent?.app ?? []);
  const [dataAttributes, setDataAttributes] = useState([]);
  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  const [appAccept, setAppAccept] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [formHform, setFormHform] = useState(null);
  const [targetForm, setTargetForm] = useState("");
  const [targetFormIndex, setTargetFormIndex] = useState(null);
  const [appPath, setAppPath] = useState([]);
  const { targetRefEvent, setTargetRefEvent, setKpiDetail, setEventApp } =
    useContext(AppContext);
  const [formsData, setformsData] = useState([]);
  const [hformsData, setHformsData] = useState([]);
  const numApp = apps?.length;
  const [appEvent, setAppEvent] = useState();
  const [appIndex, setAppIndex] = useState("");
  const [appDetail, setAppDetail] = useState({});
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );
  useEffect(() => {
    setformsData(
      imxtools.toArray(getIn(skeletonState, [...appPath, "form"]) || [])
    );

    setHformsData(
      imxtools.toArray(getIn(skeletonState, [...appPath, "hform"]) || [])
    );
  }, [appPath, formsData, hformsData]);

  const handleForm = (e, indexEvent, event) => {
    if (indexEvent === null) {
      if (event && event.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    } else {
      if (event && event?.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    }
  };
  useEffect(() => {
    const forms = imxtools?.toArray(Apps?.form ? Apps?.form : []);
    setForms(forms);
    const hforms = imxtools?.toArray(Apps?.hform ? Apps?.hform : []);
    setHforms(hforms);
  }, [Apps]);
  useEffect(() => {
    let foundIndex = -1;
    let foundIndexHform = -1;

    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndex = index;
        return true;
      }
      return false;
    });
    const foundHform = hformsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndexHform = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
      setFormHform("form");
    } else if (foundHform) {
      setTargetForm(foundHform);
      setTargetFormIndex(foundIndexHform);
      setFormHform("hform");
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
  }, [Apps, hformsData, formsData, targetRefEvent, targetForm, Apps?.media]);

  useEffect(() => {
    setDataAttributes(imxtools.toArray(Apps?.event));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Apps?.event]);

  const handleSubmit = () => {
    // This removes the last three elements

    handleNavigateTo(
      appEvent,
      numApp === 1 ? null : appIndex?.toString(),
      "app",
      null,
      appDetail,
      store,
      path
    );
  };
  return (
    <React.Fragment>
      <div className="app-conatiner" onClick={() => setKpiDetail(false)}>
        {Apps.length > 0 &&
          Apps.map(function (app, index) {
            const dragPath =
              numApp === 1
                ? [...path, "app"]
                : [...path, "app", index?.toString()];

            return (
              <DragItemApp
                className={`
                ${
                  view?.toLowerCase() === "row"
                    ? "drag-app-zone"
                    : "drag-app-zone-matrix"
                } ${
                  isSelectedComponent(readOnly, dragPath, currentPath) &&
                  "selectedComponent"
                }`}
                accept={["app"]}
                key={index}
                hoverIn={hoverIn}
                setHoverIn={setHoverIn}
                hoveredComponent={"page"}
                sort={true}
                fromComponentLib={false}
                theItem={theItem}
                setTheItem={setTheItem}
                listApps={Apps}
                setListApps={setApps}
                index={index}
                component={"app"}
                dragData={app}
                dragPath={dragPath}
                dragParentPath={path}
                dataSorted={Apps}
                style={{ marginBottom: "10px" }}
              >
                <DropTargetBody
                  className={`${
                    view?.toLowerCase() === "row"
                      ? "Application-container"
                      : "Application-container-matrix"
                  } ${
                    isSelectedComponent(readOnly, dragPath, currentPath) &&
                    "selectedApp"
                  }`}
                  accept={["event", "list"]}
                  dragPath={dragPath}
                  style={{
                    flexDirection:
                      language?.toLowerCase() === "ar" &&
                      view?.toLowerCase() === "row" &&
                      "row-reverse",
                    textAlign:
                      language?.toLowerCase() === "ar" &&
                      view?.toLowerCase() === "row" &&
                      "right",
                  }}
                  onClick={(event) => {
                    handleComponentProp(
                      event,
                      numApp === 1 ? null : index?.toString(),
                      app,
                      path,
                      store,
                      "app"
                    );
                    setAppIndex(index.toString());
                    setKpiDetail(false);
                  }}
                  onDoubleClick={(e) => {
                    setAppEvent(e);
                    if (readOnly) {
                      if (app?.event && app?.event?.targetref) {
                        imxtools
                          ?.toArray(app?.event ? app?.event : [])
                          ?.map((event, indexEvent) => {
                            const numEvent = imxtools?.toArray(
                              app?.event
                            )?.length;
                            handleForm(
                              e,
                              numEvent > 1 ? indexEvent?.toString() : null,
                              event
                            );
                          });
                        setAppDetail(app);
                        setAppPath(dragPath);
                      } else if (!app?.event) {
                        handleNavigateTo(
                          e,
                          numApp === 1 ? null : index?.toString(),
                          "app",
                          null,
                          app,
                          store,
                          path
                        );
                      }
                    }
                  }}
                  onMouseEnter={() =>
                    handleMouseEnter(index, hoverStates, setHoverStates)
                  }
                  onMouseLeave={() =>
                    handleMouseLeave(index, hoverStates, setHoverStates)
                  }
                  key={index}
                >
                  <div
                    className="app-label"
                    onDoubleClick={(event) => {
                      if (!readOnly) {
                        handleNavigateTo(
                          event,
                          numApp === 1 ? null : index?.toString(),
                          "app",
                          null,
                          app,
                          store,
                          path
                        );
                      }
                    }}
                    onClick={() => setKpiDetail(false)}
                  >
                    <div className="app-icon">
                      <Icon
                        icon={app?.icon ? app?.icon : "notoper"}
                        size={view?.toLowerCase() === "row" ? 20 : 45}
                      />
                    </div>
                    <div className="app-title">
                      <div className="title">
                        {app?.label
                          ? getLabelByLanguage(language, app)
                          : "Add Label"}
                      </div>
                    </div>
                  </div>
                  {app?.event &&
                    !readOnly &&
                    imxtools
                      ?.toArray(app?.event ? app?.event : [])
                      ?.map((event, indexEvent) => {
                        const numEvent = imxtools?.toArray(app?.event)?.length;

                        return (
                          <div
                            key={indexEvent}
                            className={`event-content-app ${
                              isSelectedComponent(
                                readOnly,
                                [...dragPath, "event"],
                                currentPath,
                                "event",
                                numEvent > 1 ? indexEvent?.toString() : null
                              ) && "selectedComponent"
                            }`}
                            onClick={(e) => {
                              handleComponentProp(
                                e,
                                numEvent > 1 ? indexEvent?.toString() : null,
                                event,
                                [...dragPath, "event"],
                                store,
                                "event"
                              );
                              setAppPath(dragPath);
                              setKpiDetail(false);
                            }}
                            style={{
                              backgroundColor: "white",
                              borderRadius: "30%",
                              marginRight: "10px",
                              padding: "5px",
                            }}
                            onDoubleClick={(e) => {
                              handleForm(
                                e,
                                numEvent > 1 ? indexEvent?.toString() : null,
                                event
                              );

                              setAppDetail(app);
                              setEventApp(true);
                            }}
                          >
                            <div
                              className="event-container"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Icon icon={"Event"} size={20} />
                            </div>
                          </div>
                        );
                      })}
                </DropTargetBody>
              </DragItemApp>
            );
          })}
      </div>
      <Modal openModal={openForm} isForm={true} tab={true} app={true}>
        <Form
          data={targetForm}
          path={
            formHform === "form"
              ? formsData?.length === 1
                ? [...appPath, formHform]
                : [...appPath, formHform, targetFormIndex]
              : hformsData?.length === 1
              ? [...appPath, formHform]
              : [...appPath, formHform, targetFormIndex]
          }
          index={forms?.length === 1 ? null : targetFormIndex?.toString()}
          parentPath={appPath}
          component={formHform}
          setOpenForm={setOpenForm}
          readOnly={readOnly}
          inGis={false}
          isApp={true}
          setAppAccept={setAppAccept}
          onSubmit={handleSubmit}
        />
      </Modal>
    </React.Fragment>
  );
}

export default RenderApps;
