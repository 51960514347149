import React, { useEffect, useState } from "react";
import "./index.scss";
import { useStore } from "react-redux";

import { promises, LoadingOptions, succesMessage, errorMessage } from "../data";

import { Box, CircularProgress } from "@mui/material";
import Icon from "../../../../../components/CustomIcon/Icon";
import Lottie from "react-lottie";
import DynamicModal from "../../../../../components/CustomModal";

import {
  getFailedListScripts,
  installScriptPart,
  retryInstallAll,
  retryInstallOne,
} from "./services/index";

import { HandleStepChange } from "../../../services/stepChange/stepChange";

function SetupComponent({ label = "", renderFailedScripts = true }) {
  const [isVerifying, setIsVerifying] = useState(false);
  const [enableRetryButton, setEnableRetryButton] = useState(false);
  const [failedSciptsList, setFailedSciptsList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");

  const store = useStore();

  useEffect(() => {
    (async () => {
      setIsVerifying(true);
      let responses = [];
      for (let promise of promises) {
        const result = await installScriptPart(promise);
        responses.push(result);
      }
      const failedList = getFailedListScripts(responses);
      if (failedList.length > 0) {
        setEnableRetryButton(true);
      }

      setIsVerifying(false);
      setFailedSciptsList(responses);
    })();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const openModal = (message) => {
    setCurrentMessage(message);
    setShowPopUp(true);
  };
  const closePopUp = () => {
    setShowPopUp(false);
  };
  return (
    <div className="setupComponentContainer">
      {isVerifying ? (
        renderFailedScripts ? (
          <div className="VerificationBody">
            <div className="VerificationTitle">
              Object Structures, Maxmessages, and MAXapps Application
              Installation
            </div>
            <Box>
              <CircularProgress
                variant="determinate"
                value={100}
                style={{
                  color: "#e0e0e0",
                  position: "absolute",
                  zIndex: 1,
                }}
              />
              <CircularProgress
                variant="determinate"
                value={progress}
                style={{
                  color: "var(--TitleContainer)",
                  position: "absolute",
                  zIndex: 2,
                }}
              />
            </Box>
          </div>
        ) : (
          <Lottie
            options={LoadingOptions}
            height={50}
            width={60}
            isClickToPauseDisabled={true}
            style={{
              cursor: "auto",
            }}
          />
        )
      ) : (
        <div className="setupBody">
          <div className="setupTitles">
            {failedSciptsList?.map((script, index) => (
              <div className="setup-result">
                <div key={index} className="setup-messages">
                  <span className="setup-title">{script?.scriptName} :</span>
                  <div className="setupContent">
                    {script?.content?.status === "success" ? (
                      <>
                        <span className="successMessage">{succesMessage}</span>
                        <div className="setup-icon">
                          <Icon
                            icon="check"
                            size={20}
                            style={{ color: "var(--Green)" }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="errorMessage">{errorMessage}</span>
                        <div className="setup-icon">
                          <Icon
                            icon="info1"
                            size={20}
                            className="info1"
                            color="#AAAAAA"
                            onClick={() => openModal(script?.content?.message)}
                          />
                          <Icon
                            icon="Reboot"
                            size={20}
                            style={{ color: "var(--Red)" }}
                            onClick={() => {
                              retryInstallOne(
                                script,
                                failedSciptsList,
                                setIsVerifying,
                                setEnableRetryButton,
                                setFailedSciptsList
                              );
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="setupFooter">
            {renderFailedScripts ? (
              <Icon
                icon="Reboot"
                size={28}
                className="login-cached-icon"
                style={{ color: "var(--SupportButtonBg)" }}
                onClick={() =>
                  retryInstallAll(
                    failedSciptsList,
                    setIsVerifying,
                    setEnableRetryButton,
                    setFailedSciptsList
                  )
                }
              ></Icon>
            ) : null}
            <button
              className="setup-next-button"
              onClick={() => {
                HandleStepChange(store, "SyncBackend");
              }}
            >
              {label}
              <Icon
                icon="chevron-right"
                size={14}
                style={{ color: "#FFFFFF", marginLeft: "10px" }}
              />
            </button>
          </div>
        </div>
      )}
      <DynamicModal
        open={showPopUp}
        onClose={closePopUp}
        modalTitle={"Error"}
        iconTitle={
          <Icon icon="close1" size={27} style={{ color: "#DC2626" }} />
        }
        showFooter={false}
      >
        <div className="popupContainer-script">
          <div className="title-popup-script">{currentMessage}</div>
        </div>
      </DynamicModal>
    </div>
  );
}

export default SetupComponent;
