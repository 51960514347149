import "./index.scss";

import React, { useState } from "react";

import { useStore } from "react-redux";

import ObjectSubItem from "./object-sub-item";
import ConfirmDialog from "../../../../confirmation-dialog";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { v4 as uuidv4 } from "uuid";

function SubItem({
  setCurentParent,
  data = {},
  parent = [],
  setEdited,
  edited,
  displayAddNew = () => {},
  currentParent = [],
  readOnly = true,
  setFirstOpen,
  currentOpen,
  setCurrentOpen,
}) {
  const [open, setOpen] = useState({});

  const [showDialog, setShowDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  // const [currentPath, setCurrentPath] = useState("");

  const CloseDialog = () => setShowDialog(false);

  const OpenDialog = () => setShowDialog(true);

  let store = useStore();

  const handleDelete = () => {
    try {
      // const globalSkeleton = store.getState()?.entities?.explorer?.skeleton;
      // let newData = setIn(globalSkeleton, currentPath, undefined);
      // const plusData = removeNulls(newData);
      // store.dispatch({
      //   type: "explorer/updateData",
      //   payload: {
      //     skeleton: { ...plusData },
      //     //readOnly: false,
      //   },
      // });
      setForEdited({}, [], "");
    } catch (error) {
      console.error("error-", error);
    }
  };

  const handleCollapse = (
    parent,
    indexElement,
    attrValue,
    objectAttr,
    openedFromParent
  ) => {
    setFirstOpen(parent);

    setCurentParent({
      parent: [...parent],
      item: "",
    });
    setEdited({
      [indexElement]: !edited[indexElement],
    });

    setOpen({
      [indexElement]: openedFromParent
        ? !openedFromParent
        : !open[indexElement],
    });
    setCurrentOpen({
      [indexElement]: openedFromParent
        ? !openedFromParent
        : !open[indexElement],
    });

    !open[indexElement]
      ? setForEdited(attrValue, indexElement, objectAttr)
      : setForEdited({}, []);
  };

  const handleAdd = (indexElement, objectAttr) => {
    displayAddNew(true, {
      parent: indexElement,
      item: objectAttr,
    });
  };

  const deleteItem = (indexElement, label) => {
    OpenDialog();
    // setCurrentPath(indexElement);
    setCurrentItem(label);
  };

  const setForEdited = (val, parent, component) => {
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: val,
        parent,
        component,
      },
    });
  };

  const droppableId = uuidv4();
  const nestedDroppableId = uuidv4();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      return;
    }

    const objectAttrs = Object.keys(data); // Get the keys (objectAttrs) from the data object
    const draggedObjectAttr = objectAttrs[source.index]; // Get the dragged objectAttr
    const updatedObjectAttrs = [...objectAttrs]; // Create a copy of the objectAttrs array

    // Reorder the objectAttrs array based on the drag-and-drop result
    updatedObjectAttrs.splice(source.index, 1);
    updatedObjectAttrs.splice(destination.index, 0, draggedObjectAttr);

    // Create a new data object with the updated object order
    // const updatedData = updatedObjectAttrs.reduce((acc, objectAttr) => {
    //   acc[objectAttr] = data[objectAttr];
    //   return acc;
    // }, {});

    // Dispatch an action to update the Redux store with the new data
    // store.dispatch({
    //   type: "explorer/updateData",
    //   payload: {
    //     skeleton: updatedData,
    //     //readOnly: false,
    //   },
    // });

    // Update the state or dispatch an action to update the data
    // Assuming you have a function to update the data in the parent component
    // UpdateData(updatedData);

    // For Redux store update, you might dispatch an action like this
    // store.dispatch({
    //   type: "explorer/updateData",
    //   payload: updatedData,
    // });

    // This is just a sample implementation, adjust it based on your data structure and how it's managed.

    // After updating the data, you can set the state to the new order if necessary
    // setData(updatedData);
  };

  const onNestedDragEnd = (result, parentIndexElement) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    const parentAttrValue =
      data[parentIndexElement[parentIndexElement.length - 1]];
    const nestedAttrValue = [
      ...parentAttrValue[parentIndexElement[parentIndexElement.length - 2]],
    ];

    const draggedObjectAttr = nestedAttrValue[source.index];
    nestedAttrValue.splice(source.index, 1);
    nestedAttrValue.splice(destination.index, 0, draggedObjectAttr);

    const newData = {
      ...data,
      [parentIndexElement[parentIndexElement.length - 1]]: {
        ...parentAttrValue,
        [parentIndexElement[parentIndexElement.length - 2]]: nestedAttrValue,
      },
    };

    store.dispatch({
      type: "explorer/updateData",
      payload: {
        skeleton: newData,
        //readOnly: false,
      },
    });
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <div id="SubItem" className="SubItem">
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div id="subItem">
                  {data && (
                    <div>
                      {Object.entries(data).map(
                        ([objectAttr, attrValue], attrIndex) => {
                          let hasObject = false;
                          if (attrValue instanceof Object) {
                            Object.keys(attrValue).forEach((item) => {
                              if (attrValue[item] instanceof Object) {
                                hasObject = true;
                              }
                            });
                          }
                          let indexElement = [...parent, objectAttr];
                          let openedFromParent = false;
                          if (
                            !Array.isArray(attrValue) &&
                            attrValue instanceof Object &&
                            Array.isArray(currentParent) &&
                            indexElement?.toString()?.length <=
                              currentParent?.toString()?.length &&
                            currentParent
                              ?.toString()
                              ?.includes(indexElement?.toString())
                          ) {
                            openedFromParent = true;
                          }
                          return (
                            <Draggable
                              key={objectAttr}
                              draggableId={objectAttr}
                              index={attrIndex}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div key={attrIndex}>
                                    {Array.isArray(attrValue) &&
                                      attrValue.map((item, index) => {
                                        hasObject = false;
                                        if (item instanceof Object) {
                                          Object.keys(item).forEach((elem) => {
                                            if (
                                              attrValue?.[index]?.[
                                                elem
                                              ] instanceof Object
                                            ) {
                                              hasObject = true;
                                            }
                                          });
                                        }
                                        let indexElementArray = [
                                          ...parent,
                                          objectAttr,
                                          index.toString(),
                                        ];
                                        if (
                                          Array.isArray(currentParent) &&
                                          indexElementArray?.toString()
                                            ?.length <=
                                            currentParent?.toString()?.length &&
                                          currentParent
                                            ?.toString()
                                            ?.includes(
                                              indexElementArray?.toString()
                                            )
                                        ) {
                                          openedFromParent = true;
                                        }
                                        return item instanceof Object ? (
                                          <div
                                            key={index}
                                            className={`object-item `}
                                          >
                                            <ObjectSubItem
                                              objectAttr={objectAttr}
                                              indexElement={indexElementArray}
                                              openedFromParent={
                                                openedFromParent
                                              }
                                              readOnly={readOnly}
                                              attrValue={
                                                data[objectAttr]?.[index]
                                              }
                                              open={open}
                                              hasObject={hasObject}
                                              parent={parent}
                                              label={
                                                data[objectAttr]?.[index]?.label
                                                  ? data[objectAttr]?.[index]
                                                      ?.label
                                                  : `${objectAttr}  ${
                                                      index + 1
                                                    }`
                                              }
                                              handleCollapse={handleCollapse}
                                              deleteItem={deleteItem}
                                              handleAdd={handleAdd}
                                              currentOpen={currentOpen}
                                            />
                                            {hasObject &&
                                              (openedFromParent ||
                                                open[indexElementArray]) && (
                                                <DragDropContext
                                                  onDragEnd={(result) =>
                                                    onNestedDragEnd(
                                                      result,
                                                      indexElementArray
                                                    )
                                                  }
                                                >
                                                  <Droppable
                                                    droppableId={
                                                      nestedDroppableId
                                                    }
                                                  >
                                                    {(provided) => (
                                                      <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                      >
                                                        {attrValue[
                                                          index
                                                        ] instanceof Object &&
                                                          Object.keys(
                                                            attrValue[index]
                                                          ).map(
                                                            (
                                                              elem,
                                                              innerIndex
                                                            ) => (
                                                              <Draggable
                                                                key={innerIndex}
                                                                draggableId={`${objectAttr}-${index}-${elem}`}
                                                                index={
                                                                  innerIndex
                                                                }
                                                              >
                                                                {(provided) => (
                                                                  <div
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                  >
                                                                    {/* Render nested subitems here */}
                                                                    {/* You can use the same approach as above */}
                                                                  </div>
                                                                )}
                                                              </Draggable>
                                                            )
                                                          )}
                                                        {provided.placeholder}
                                                      </div>
                                                    )}
                                                  </Droppable>
                                                </DragDropContext>
                                              )}
                                          </div>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    <div
                                      className={`object-item ${
                                        openedFromParent || open[indexElement]
                                          ? "level-selected"
                                          : ""
                                      }`}
                                    >
                                      {!Array.isArray(attrValue) &&
                                      attrValue instanceof Object ? (
                                        <React.Fragment>
                                          <ObjectSubItem
                                            objectAttr={objectAttr}
                                            indexElement={indexElement}
                                            openedFromParent={openedFromParent}
                                            readOnly={readOnly}
                                            attrValue={attrValue}
                                            open={open}
                                            hasObject={hasObject}
                                            parent={parent}
                                            label={
                                              data[objectAttr]?.label
                                                ? data[objectAttr]?.label
                                                : objectAttr
                                            }
                                            handleCollapse={handleCollapse}
                                            deleteItem={deleteItem}
                                            handleAdd={handleAdd}
                                            currentOpen={currentOpen}
                                          />
                                          {(openedFromParent ||
                                            open[indexElement]) && (
                                            <SubItem
                                              data={attrValue}
                                              parent={indexElement}
                                              edited={edited}
                                              setEdited={setEdited}
                                              displayAddNew={displayAddNew}
                                              currentParent={currentParent}
                                              setCurentParent={setCurentParent}
                                              readOnly={readOnly}
                                              setFirstOpen={setFirstOpen}
                                              currentOpen={currentOpen}
                                              setCurrentOpen={setCurrentOpen}
                                            />
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <ConfirmDialog
        confirmQuestion="Are you sure to delete this item?"
        itemName={currentItem}
        show={showDialog}
        CloseDialog={CloseDialog}
        ConfirmAction={handleDelete}
      />
    </React.Fragment>
  );
}
export default SubItem;
