import React from "react";
import "./ildex.scss";

function Message({ data = {} }) {
  return (
    <>
      {data?.message ? (
        <div className="message-contact">
          {data?.message?.label ? data?.message?.label : ""}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Message;
