import React, { useCallback, useEffect, useRef, useState } from "react";
import "./index.scss";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function IconCard({
  icon,
  onToggle,
  onClick,
  isOpen,
  isSelected,
  onDeleteIcon,
  DownloadIcon,
  setIconsSelected,
  iconsSelected,
  onRenameClick,
  isMultiSelect,
  OnCopy,
}) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onToggle();
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onToggle]);

  const isDisabled = iconsSelected.length !== 1;

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      switch (event.type) {
        case "click":
          onClick();
          break;
        case "contextmenu":
          onToggle();
          break;
        default:
          break;
      }
    },
    [onClick, onToggle]
  );

  function handleRenameButtonClick() {
    if (isMultiSelect) return;
    onRenameClick(icon);
  }

  function handleCopyIconName(e) {
    e.stopPropagation();
    setIconsSelected([]);
    setIconsSelected([icon]);
    OnCopy(e, icon.iconName);
    setOpen(true);
    setTimeout(() => setOpen(false), 1000);
  }

  function handleKeyDown(event) {
    event.preventDefault();
    if (event.ctrlKey && event.key === "c") {
      handleCopyIconName(event);
    }
  }

  return (
    <div
      className={`iconCard ${isSelected ? "active" : ""}`}
      onClick={handleClick}
      onContextMenu={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0} // Add this line to make the component focusable
    >
      {isOpen && (
        <ul ref={dropdownRef} className="icon-dropdownMenu">
          <li
            onClick={(e) => {
              e.stopPropagation();

              if (!isDisabled) handleCopyIconName(e, icon.iconName);
              onToggle();
            }}
            className={isDisabled ? "disabled" : ""}
          >
            Copy name
          </li>
          <li
            onClick={(e) => {
              e.stopPropagation();
              if (!isDisabled) handleRenameButtonClick();
              onToggle();
            }}
            className={isDisabled ? "disabled" : ""}
          >
            Rename
          </li>
          <li
            onClick={(e) => {
              e.stopPropagation();
              DownloadIcon();
              onToggle();
            }}
          >
            Download
          </li>
          <li
            onClick={(e) => {
              e.stopPropagation();
              onDeleteIcon();
              onToggle();
            }}
          >
            Delete
          </li>
        </ul>
      )}
      <div className="iconDisplay">
        <img src={icon?.uri} alt={icon?.iconName} className="iconImage" />
      </div>
      <div className="iconFooter">
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
              className: "custom-tooltip",
            }}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied!"
          >
            <div className="iconName" onClick={handleCopyIconName}>
              {icon?.iconName}
            </div>
          </Tooltip>
        </ClickAwayListener>
      </div>
    </div>
  );
}

export default IconCard;
