import React from "react";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import { navigateToWithDrawer } from "../../../services/navigationMiddleware";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import { useDeviceTheme } from "../../../../../../../../DeviceTheme/DeviceThemeContext";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";

function DrawerItem({
  index = null,
  data = {},
  path = [],
  setOpenDrawer,
  isModule = false,
  isModuleChild = false,
  GIS = false,
  indexGIS = 0,
  currentPage = null,
}) {
  const store = useStore();
  const { theme, themes } = useDeviceTheme();

  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const array1 = currentPath?.slice(0, [...path, "list"].length);
  const array2 = [...path, "list"];

  function isCurrentItem() {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  }

  return (
    <div
      key={index}
      className={`drawer-item ${
        ((GIS && currentPage === indexGIS) || isCurrentItem()) &&
        "selected-item"
      } ${isModuleChild && "isModuleChild"}`}
      style={{
        flexDirection: language === "ar" && "row-reverse",
      }}
      onClick={(event) => {
        setOpenDrawer(false);
        if (isModule) {
          return;
        }
        if (GIS) {
          store.dispatch({
            type: "deviceview/setCurrentPage",
            payload: {
              currentPage: indexGIS,
            },
          });

          store.dispatch({
            type: "deviceview/navigateToHome",
          });
        } else {
          navigateToWithDrawer(
            store,
            "List",
            {
              data: {},
              path: path,
            },
            event,
            data,
            path
          );
        }
      }}
    >
      <div className="drawer-item-icon">
        <Icon
          icon={data?.icon ? data?.icon : "appgen"}
          size={18}
          color={themes?.[theme]?.primary}
        />
      </div>

      <div className="drawer-item-label">
        {data?.label ? getLabelByLanguage(language, data) : "Add label"}
      </div>
    </div>
  );
}

export default DrawerItem;
