import { verifyAutoscripts } from "../../../../services";

async function verifyingInstall(
  setIsVerifying = () => {},
  setErrorMessage = () => {},
  setAllInstalled = () => {},
  setCompletedInsatllation = () => {}
) {
  setIsVerifying(true);
  try {
    const response = await verifyAutoscripts();
    setCompletedInsatllation(response.content?.result?.exist);

    if (response?.content?.status === "failure") {
      setIsVerifying(false);
      setErrorMessage(response?.content?.message);
    } else {
      setAllInstalled(response.content?.result?.exist);
      setIsVerifying(true);
    }
  } catch (error) {
    setIsVerifying(false);
    setErrorMessage(error.message);
  }
}
export default verifyingInstall;
