import fileDownload from "js-file-download";
import xml2js from "minexml2js";
import parse from "url-parse";
import transformForXml from "../../../services/helpers/transformForXml";

async function exportFile(data, location) {
  try {
    const fullDomainName = parse(location.href).hostname;
    const indexOfSubdomain = fullDomainName.indexOf(".maxapps-maximo.com");
    let domainName;

    if (indexOfSubdomain !== -1) {
      domainName = fullDomainName.slice(0, indexOfSubdomain);
    } else {
      domainName = fullDomainName;
    }

    fileDownload(data, `${domainName} ${new Date().toLocaleString()}.xml`);
  } catch (error) {
    console.error("Error saving file:", error);
    throw new Error("Failed to save file");
  }
}

export default exportFile;
