import React from "react";
import OneSideBar from "./components/OneSideBar";
import ManySideBar from "./components/ManySideBar";
import DefaultSideBar from "./components/DefaultSideBar";
import "./index.scss";

function SideBar({
  searchText = "",
  setSearchText,
  iconsListing = [],
  setIconsListing,
  iconsSelected = [],
  setIconsSelected,
  onDeleteIcon,
  currentIcon,
  setIsRenameActive,
  isRenameActive,
  OnCopy,
  setCurrentIcon,
  addToast,
}) {
  if (iconsSelected?.length === 0) {
    return (
      <DefaultSideBar
        searchText={searchText}
        setSearchText={setSearchText}
        iconsListing={iconsListing}
      />
    );
  } else if (iconsSelected?.length === 1) {
    return (
      <OneSideBar
        iconsListing={iconsListing}
        iconsSelected={iconsSelected}
        setIconsListing={setIconsListing}
        setIconsSelected={setIconsSelected}
        onDeleteIcon={onDeleteIcon}
        isRenameActive={isRenameActive}
        setIsRenameActive={setIsRenameActive}
        currentIcon={currentIcon}
        OnCopy={OnCopy}
        setCurrentIcon={setCurrentIcon}
        addToast={addToast}
      />
    );
  } else {
    return (
      <ManySideBar
        iconsListing={iconsListing}
        iconsSelected={iconsSelected}
        setIconsListing={setIconsListing}
        setIconsSelected={setIconsSelected}
        onDeleteIcon={onDeleteIcon}
      />
    );
  }
}

export default SideBar;
