import React, { useState } from "react";
import "./index.scss";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { useStore } from "react-redux";

function Maximomedia({
  data = {},
  path = [],
  index = "",
  handleClick = () => {},
  readOnly = true,
  currentPath = [],
}) {
  const [openMaximomedia, setOpenMaximomedia] = useState(false);
  let store = useStore();

  const handleOpenModule = (event) => {
    handleClick(index, "module", data, event);
    setOpenMaximomedia(!openMaximomedia);
  };
  const handleComp = (e, data, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };

  return (
    <div
      className={`maximomedia-content ${
        isSelectedComponent(readOnly, path, currentPath) && "selectedComponent"
      }`}
      onClick={(e) => handleComp(e, data, path, "maximomedia")}
    >
      <div
        className="maximomedia-header"
        onClick={(event) => handleOpenModule(event)}
      >
        <div className="maximomedia-label">
          {data?.label ? data?.label : "Maximomedia"}
        </div>
        <div className="maximomedia-icon">
          <Icon
            icon={openMaximomedia ? "chevron-up" : "chevron-down"}
            size={15}
          />
        </div>
      </div>
      {openMaximomedia ? (
        <div className="maximomedia-desc">
          {data?.where ? data?.where : "missing desc"}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Maximomedia;
