import React from "react";
import Modal from "../../../../../../components/CustomModal";
import deleteItem from "../../../ComponentsLayers/Preview/services/deleteItem";
import { getLabelByLanguage } from "../../../../../../utils/getLabelByLanguage";
import { useSelector, useStore } from "react-redux";

function DeleteModal({
  openModal = false,
  setOpenModal,
  data = {},
  component = "",
  path = [],
}) {
  let store = useStore();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      onClickPrimaryAction={() => {
        setOpenModal(false);
        deleteItem(store, path);
      }}
      secondaryAction={true}
      onClickSecondaryAction={() => {
        setOpenModal(false);
      }}
      primaryLabel="Confirm"
      secondaryLabe="Cancel"
      modalTitle="Discard"
    >
      <div className="ModalContent">
        <div className="message">
          {`Are you sure to delete this ${component} ?`}
        </div>
        {data && data?.label ? (
          <div className="lineTwo">{getLabelByLanguage(language, data)}</div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
}

export default DeleteModal;
