import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import i18nInstanceDevice from "./Languages/i18n";

const DeviceLanguageContext = createContext();

export const LanguageProviderDevice = ({ children }) => {
  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  const [languageReady, setLanguageReady] = useState(false);

  useEffect(() => {
    const changeLanguage = async () => {
      await i18nInstanceDevice.changeLanguage(language);
      setLanguageReady(true);
    };
    changeLanguage();

    return () => setLanguageReady(false);
  }, [language]);

  useEffect(() => {
    if (languageReady) {
      setLanguageReady(false);
    }
  }, [languageReady]);

  const DeviceLanguageContextValue = {
    translate: i18nInstanceDevice.t.bind(i18nInstanceDevice),
    languageReady,
  };

  return (
    <DeviceLanguageContext.Provider value={DeviceLanguageContextValue}>
      {children}
    </DeviceLanguageContext.Provider>
  );
};

export const useLanguageDevice = () => useContext(DeviceLanguageContext);
