import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import { useTheme } from "../../../../theme/themeContext";
import Icon from "../../../../components/CustomIcon/Icon";
import "react-dropdown/style.css";
import { useSelector, useStore } from "react-redux";
import { RenderTab, tabs } from "./tabData";
import { useDeviceTheme } from "../../../../DeviceTheme/DeviceThemeContext";
import Tooltip from "@mui/material/Tooltip";

const ComponentsLayers = ({ codeEditor = false, properties = false }) => {
  const deviceTheme = process.env.REACT_APP_DEVICE_THEME;

  const [activeTab, setActiveTab] = useState("Device");
  const { themeMode, themeColors } = useTheme();
  const [showLanguageDropDown, setshowLanguageDropDown] = useState(false);
  const [isThemeDropdownVisible, setThemeDropdownVisible] = useState(false);
  const { themes } = useDeviceTheme();
  const store = useStore();
  const MaxappsThemes = ["Maxapps", "MaxappsDark"];
  const themeDropdownRef = useRef(null);
  const languageDropdownRef = useRef(null);

  const Themes = deviceTheme ? [deviceTheme, ...MaxappsThemes] : MaxappsThemes;

  const updateTheme = (newTheme) => {
    store.dispatch({
      type: "deviceview/setTheme",
      payload: {
        theme: newTheme,
      },
    });
  };

  const currentTheme = useSelector(
    (state) => state?.entities?.deviceview?.settings?.theme
  );

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  function toggleDropdown() {
    setshowLanguageDropDown(!showLanguageDropDown);
  }

  const toggleThemeDropDown = () => {
    setThemeDropdownVisible(!isThemeDropdownVisible);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        themeDropdownRef.current &&
        !themeDropdownRef.current.contains(event.target)
      ) {
        setThemeDropdownVisible(false);
      }
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setshowLanguageDropDown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [themeDropdownRef, languageDropdownRef]);
  const LanguagesSetup = [
    { name: "English", lang: "en" },
    { name: "French", lang: "fr" },
    { name: "العربية", lang: "ar" },
    { name: "Italian", lang: "it" },
    { name: "Allemand", lang: "al" },
    { name: "Norvegian", lang: "nb" },
  ];

  const changeLanguage = (lang) => {
    store.dispatch({
      type: "deviceview/setLanguage",
      payload: {
        language: lang,
      },
    });
  };

  return (
    <div className="ViewContainer">
      <div
        className="headerContainer"
        style={{
          borderBottom: `1px solid ${themeColors[themeMode]?.BorderStroke}`,
        }}
      >
        <div className="tabContainer">
          {tabs?.map((tab, index) => {
            const tabStyle = {
              color:
                activeTab === tab.title
                  ? themeColors[themeMode]?.TitleContainer
                  : themeColors[themeMode]?.textDisabled,
              /* borderRight:
                tab.title === "Device"
                  ? `2px solid ${themeColors[themeMode]?.BorderStroke}`
                  : "none", */
            };
            return (
              <div
                key={index}
                className={`tabTitle ${
                  activeTab === tab?.title ? "selectedTab" : ""
                }`}
                onClick={() => setActiveTab(tab?.title)}
                style={tabStyle}
              >
                {tab?.title}
              </div>
            );
          })}
        </div>
        <div className="OptionsContainer">
          <Tooltip title="Change Language">
            <div
              className="languageBtn"
              style={{
                justifyContent: !codeEditor && !properties && "flex-end",
              }}
            >
              <Icon
                icon="language1"
                className="LanguageIcon"
                size={20}
                onClick={() => {
                  toggleDropdown(showLanguageDropDown, setshowLanguageDropDown);
                }}
              />

              {showLanguageDropDown && (
                <div
                  className={
                    !properties && !codeEditor
                      ? "LanguageDropDown-left"
                      : "LanguageDropDown"
                  }
                  ref={languageDropdownRef}
                >
                  <div className="LanguageDropdownHeader">
                    Language
                    <Icon
                      size={15}
                      className="closeDropDown"
                      icon="Close"
                      onClick={() => {
                        setshowLanguageDropDown(false);
                      }}
                    />
                  </div>

                  <div className="langItems">
                    {LanguagesSetup?.map((lg, index) => (
                      <div
                        key={index}
                        className={`lang-item ${
                          language === lg?.lang && "active-lang"
                        }`}
                        onClick={() => changeLanguage(lg?.lang?.toLowerCase())}
                      >
                        {lg?.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Tooltip>

          <div className="separator1" />
          <Tooltip title="Change Theme">
            <div
              className="theme-btn"
              style={{
                justifyContent: !codeEditor && !properties && "flex-end",
              }}
            >
              <Icon
                icon="Vector"
                className="ThemeButton"
                size={20}
                onClick={toggleThemeDropDown}
              />

              {isThemeDropdownVisible && (
                <div
                  className={
                    !properties && !codeEditor
                      ? "ThemeDropdown-left"
                      : "ThemeDropdown"
                  }
                  ref={themeDropdownRef}
                  onMouseLeave={() => setThemeDropdownVisible(false)}
                >
                  <div className="DropdownHeader">
                    Theme
                    <Icon
                      size={15}
                      className="closeDropDown"
                      icon="Close"
                      onClick={() => {
                        setThemeDropdownVisible(false);
                      }}
                    />
                  </div>
                  <div className="DropDownItems">
                    {Themes?.map((th, index) => {
                      const isActiveTheme = th === currentTheme;

                      return (
                        <div
                          key={index}
                          className="themeColors"
                          onClick={() => updateTheme(th)}
                          style={{
                            border: isActiveTheme
                              ? `2px solid ${themeColors?.[themeMode]?.primary}`
                              : `1px solid ${themeColors?.[themeMode]?.borderTheme}`,
                            boxShadow: isActiveTheme
                              ? "0px 1px 6px 0px rgba(65, 65, 71, 0.25)"
                              : "none",
                          }}
                        >
                          <div
                            className="Color1"
                            style={{
                              backgroundColor: themes?.[th]?.primary,
                              borderRadius: isActiveTheme
                                ? "0px"
                                : "1px 0px 0px 2px",
                            }}
                          ></div>
                          <div
                            className="Color2"
                            style={{
                              backgroundColor: themes?.[th]?.primaryLight,
                            }}
                          ></div>
                          <div
                            className="Color3"
                            style={{
                              backgroundColor: themes?.[th]?.primaryDark,
                            }}
                          ></div>
                          <div
                            className="Color4"
                            style={{ backgroundColor: themes?.[th]?.secondary }}
                          ></div>
                          <div
                            className="Color5"
                            style={{
                              backgroundColor: themes?.[th]?.secondaryLight,
                            }}
                          ></div>
                          <div className="ColorName">{th} </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="bodyContainer">{RenderTab(activeTab)}</div>
    </div>
  );
};

export default ComponentsLayers;
