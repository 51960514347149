import React, { useEffect, useState } from "react";
import imxtools from "imxtools";
import Property from "../../../../../../components/Property";
import { handleOpenModal } from "../../service/handleModa";
import { handleConditionBackgroundColor } from "../../service/ConditionBackgroundColor";
import { useModal } from "../../../../services/ModalContext";
import Modal from "../../../../../../components/CustomModal";
import { deleteConditionByRef } from "../../../../../../components/Property/services";
import { getAllReferences } from "../../service/getAllReferences";
import "./index.scss";

function ConditionProp({
  readOnly = true,
  data = {},
  skeleton = {},
  conditions = [],
  setConditions,
  XMLChanged = false,
}) {
  const [opemModal, setOpenModal] = useState(false);
  const [refToDelete, setRefToDelete] = useState("");
  const { openModal } = useModal();

  const Conditions = imxtools?.toArray(
    skeleton?.maxapps?.conditions?.condition ?? []
  );

  useEffect(() => {
    const condition = Object.keys(data ?? {})
      ?.filter((att) => att?.toString() === "condition")
      ?.reduce((accumulator, key) => {
        accumulator = { key, value: data[key] };
        return accumulator;
      }, {});
    setConditions(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const path = ["maxapps", "conditions", "condition"];

  return (
    <>
      <div className="ConditionsProperties">
        {conditions?.value &&
          conditions?.value?.split(",")?.map((condition, index) => {
            return (
              <div
                key={index}
                className="ConditionPropertie"
                onDoubleClick={
                  getAllReferences(Conditions)?.includes(condition)
                    ? () =>
                        handleOpenModal(
                          "condition",
                          condition,
                          Conditions,
                          openModal,
                          path
                        )
                    : (e) => {
                        e.stopPropagation();
                        setRefToDelete(condition);
                        setOpenModal(true);
                      }
                }
                style={{ backgroundColor: "transparent" }}
              >
                <Property
                  Key={condition?.key}
                  Value={condition}
                  disabled={readOnly ?? true}
                  index={Object.keys(data)?.indexOf("condition")}
                  data={conditions}
                  setData={setConditions}
                  readOnly={readOnly}
                  condition={true}
                  backgroundColor={handleConditionBackgroundColor(
                    condition,
                    Conditions
                  )}
                  existRef={getAllReferences(Conditions)?.includes(condition)}
                />
              </div>
            );
          })}
      </div>
      <Modal
        open={opemModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onClickPrimaryAction={() => {
          setOpenModal(false);
          deleteConditionByRef(conditions, setConditions, refToDelete);
        }}
        primaryLabel="Delete"
        modalTitle="Unexistent reference"
      >
        <div className="ModalContent">
          <div className="message">
            This reference has been edited or does not exist.
          </div>
          <div className="message">You can delete it or create a new one.</div>
        </div>
      </Modal>
    </>
  );
}

export default ConditionProp;
