import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../../services/http/httpService";
async function getFileContent(store, filename, readOnly, addToast) {
  try {
    if (filename && filename !== "") {
      const transactionNodeService = services.NODE_ENDPOINT_GET_FILE_CONTENT;

      const res = await serviceRequest.get(transactionNodeService, {
        filename,
      });

      if (res?.data?.success) {
        try {
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: res?.data?.content?.skeleton,
              readOnly: readOnly,
            },
          });
        } catch (error) {
          addToast(error?.message || error, "error");
        }
      } else {
        addToast(res.data.message, "error");
      }

      return res;
    }

    return {
      data: { success: false },
    };
  } catch (error) {
    addToast("Server is unreachable!", "error");

    return {
      data: { success: false },
    };
  }
}

export default getFileContent;
