// InputComponent.jsx
import React from "react"
import "./index.scss"

function InputComponent({
  label,
  showLabel,
  type = "",
  name = "",
  value,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  required = false,
}) {
  return (
    <div className='input-container'>
      {showLabel && label && (
        <label htmlFor={name} className='label-visible'>
          {label}
        </label>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder || label}
        required={required}
      />
    </div>
  )
}

export default InputComponent
