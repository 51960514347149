import fileDownload from "js-file-download";
import xml2js from "minexml2js";
import parse from "url-parse";
import transformForXml from "../../../services/helpers/transformForXml";

async function saveFile(data, location, xmlAlias) {
  try {
    const builder = new xml2js.Builder();
    const xml = builder.buildObject(transformForXml(data));
    const searchRegExp = new RegExp("&amp;", "g");
    const finalXML = xml.replace(searchRegExp, "&");
    const fullDomainName = parse(location.href).hostname;
    const indexOfSubdomain = fullDomainName.indexOf(".maxapps-maximo.com");
    let domainName;

    if (indexOfSubdomain !== -1) {
      domainName = fullDomainName.slice(0, indexOfSubdomain);
    } else {
      domainName = fullDomainName;
    }

    const fileName = `${xmlAlias}.xml`;
    fileDownload(finalXML, fileName);
  } catch (error) {
    console.error("Error saving file:", error);
    throw new Error("Failed to save file");
  }
}

export default saveFile;
