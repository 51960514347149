import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { arraysAreEqual } from "../../../../../../../utils/util";
import { useSelector, useStore } from "react-redux";
import DropTargetBody from "../DropTargetBody";
import imxtools from "imxtools";
import Attribute from "../Attribute";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../services/AppContext";
import ImageIcon from "./../../../../../../../assets/IconBody.png";
function MarkerDrop({
  openModalMarker = false,
  currentMarkerData = {},
  currentMarkerPath = [],
  path = [],
  data = {},
  setDisplayPullup = () => {},
  setOpenModalMarker = () => {},
  setPathOfList = () => {},
}) {
  let edit = useSelector((state) => state?.entities?.editor);
  const [dataAttributes, setDataAttributes] = useState([]);
  const { isDragingIn, setIsDragingIn } = useContext(AppContext);

  let store = useStore();
  const handleComp = (e, data, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  useEffect(() => {
    setDataAttributes(imxtools.toArray(currentMarkerData?.body?.attribute));
  }, [currentMarkerData?.body?.attribute, isDragingIn, data, dataAttributes]);
  return (
    <DropTargetBody
      accept={["body"]}
      dragParent={currentMarkerPath}
      style={{
        width: "100%",
        overflowY: "auto",
        height: "100%",
      }}
    >
      <div
        className={
          arraysAreEqual(currentMarkerPath, edit?.parentToEdit)
            ? `high-content-mark ${"selectedComponent"}`
            : `high-content-mark`
        }
      >
        {currentMarkerData?.hasOwnProperty("body") &&
        currentMarkerData?.body !== undefined ? (
          <div
            className="marker-drop-container"
            onClick={(e) =>
              handleComp(e, currentMarkerData, currentMarkerPath, "marker")
            }
          >
            <div className="img-content">
              <img src={ImageIcon} alt="img" />
            </div>
            <div className="data-content">
              {currentMarkerData?.body?.hasOwnProperty("attribute") &&
              imxtools.toArray(currentMarkerData?.body?.attribute).length >
                0 ? (
                <div
                  className="body-drop-container"
                  onClick={(e) =>
                    handleComp(
                      e,
                      currentMarkerData?.body,
                      [...currentMarkerPath, "body"],
                      "body"
                    )
                  }
                  onDoubleClick={() => {
                    setDisplayPullup(true);
                    setOpenModalMarker(false);
                    setPathOfList(path);
                  }}
                >
                  <DropTargetBody
                    accept={["attribute"]}
                    dragParent={[currentMarkerPath, "body"]}
                    className="drop-target-body-marker"
                  >
                    {imxtools
                      .toArray(currentMarkerData?.body?.attribute)
                      ?.map((att, index) => {
                        let numAtt = imxtools.toArray(
                          currentMarkerData?.body?.attribute
                        )?.length;
                        return (
                          <DragItemAttribute
                            index={index}
                            component={"attribute"}
                            classname="dragAttributeContainer"
                            dragParentPath={[...currentMarkerPath, "body"]}
                            dragData={att}
                            dataAttributes={dataAttributes}
                            setDataAttributes={setDataAttributes}
                            setIsDraging={setIsDragingIn}
                          >
                            <Attribute
                              data={att}
                              numAttributes={numAtt}
                              readOnly={readOnly}
                              index={index}
                              path={[...currentMarkerPath, "body"]}
                              clicked={arraysAreEqual(
                                numAtt === 1
                                  ? [...currentMarkerPath, "body", "attribute"]
                                  : [
                                      ...currentMarkerPath,
                                      "body",
                                      "attribute",
                                      index?.toString(),
                                    ],
                                edit?.parentToEdit
                              )}
                            />
                          </DragItemAttribute>
                        );
                      })}
                  </DropTargetBody>
                </div>
              ) : (
                <div
                  className="body-drop-container"
                  onClick={(e) =>
                    handleComp(
                      e,
                      currentMarkerData?.body,
                      [...currentMarkerPath, "body"],
                      "body"
                    )
                  }
                >
                  <DropTargetBody
                    accept={["attribute"]}
                    dragParent={[currentMarkerPath, "body"]}
                    style={{
                      width: "100%",
                      overflowY: "auto",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                  {readOnly ? "No attributes here" : "Drop attributes here"}

                  </DropTargetBody>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className="marker-drop-container"
            onClick={(e) =>
              handleComp(e, currentMarkerData, currentMarkerPath, "marker")
            }
          >
            <DropTargetBody
              accept={["body"]}
              dragParent={currentMarkerPath}
              style={{
                width: "100%",
                overflowY: "auto",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Drop body here
            </DropTargetBody>
          </div>
        )}
      </div>
    </DropTargetBody>
  );
}

export default MarkerDrop;
