function functionReference(inputString) {
  try {
    if (typeof inputString !== "string") {
      throw new Error("Invalid input: inputString must be a string");
    }

    const regex = /\(([^)]+)\)/;
    const match = inputString.match(regex);

    return match ? match[1] : null;
  } catch (error) {
    console.error(
      `Error in functionReference: ${error.message}. Input: ${inputString}`
    );
    return null;
  }
}

export default functionReference;
