import React, { useMemo } from "react";
import "./index.scss";
import { getLabelByLanguage } from "../../../../../../../../../utils/getLabelByLanguage";
import { useSelector } from "react-redux";

function Label({
  size = "SMALL",
  label = "Add label",
  onClick = () => {},
  onDoubleClick = () => {},
  data = {},
}) {
  const randomNumber = useMemo(() => {
    return Math.floor(Math.random() * 25);
  }, []);

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  return (
    <div
      className={`label-kpi 
        ${size?.toLowerCase() !== "small" && "label-large"}
      `}
      style={{
        flexDirection: language?.toLowerCase() === "ar" ? "row-reverse" : "row",
      }}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className="label-kpi-label">
        {getLabelByLanguage(language, data)}
      </div>
      <div className="number">{randomNumber}</div>
    </div>
  );
}

export default Label;
