import React from "react";
import "./index.scss";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";
import { useLanguageDevice } from "../../services/DeviceTranslate/LanguageContext";

function DisconnectModal({ navigateToLogin, closeModal }) {
  const { theme, themes } = useDeviceTheme();
  const { translate } = useLanguageDevice();

  return (
    <div className="disconnect-modal-container">
      <div className="disconnect-modal-header">
        <span className="title-disconnect-txt">{translate("Disconnect")}</span>
        <div className="horizontal-line" />
      </div>
      <div className="disconnect-modal-body">
        <div className="icon-disconnect-wrapper">
          <i
            className="bx bx-log-out"
            style={{
              fontSize: 68,
              fontWeight: 400,
              transform: "rotate(180deg)",
              color: themes?.[theme]?.primary,
            }}
          ></i>
        </div>
        <div className="text-disconnect-wrapper">
          <span className="text-disconnect-style">
            {translate("DisconnectFromTheApplication")}
          </span>
        </div>
      </div>
      <div className="disconnect-modal-footer">
        <button className="btn-cancel-disconnect" onClick={() => closeModal()}>
          {translate("Cancel")}
        </button>
        <button
          className="btn-confirm-disconnect"
          onClick={() => navigateToLogin()}
        >
          {translate("OK")}
        </button>
      </div>
    </div>
  );
}

export default DisconnectModal;
