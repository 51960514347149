import { axiosProviderNode as serviceRequest } from "../../../services/http/httpService";

import { services } from "./configVariable";

import getFileContent from "./getFileContent";

async function getAllFiles(store, addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_ALL_FILES;

    const res = await serviceRequest.get(transactionNodeService, {});
    if (res?.data?.success) {
      if (res?.data?.content?.length > 0) {
        await getFileContent(
          store,
          res?.data?.content[0]?.filename,
          true,
          // false,
          addToast
        );
        store.dispatch({
          type: "revision/dataAdded",
          payload: {
            list: res?.data?.content,
          },
        });
      }
    } else {
      addToast(res?.data?.message, "error");
    }
  } catch (error) {
    addToast("Server is unreachable!", "error");
  }
}

export default getAllFiles;
