function editByIndex(data, setData, index, key, value) {
  const newData = [...data];

  try {
    if (index >= 0 && index < newData.length) {
      newData[index] = { ...newData[index], [key]: value };
      setData(newData);
    } else {
      throw new Error("Index out of bounds");
    }
  } catch (error) {
    console.error(`An error occurred in editByIndex: ${newData}`);
  }
}

export default editByIndex;
