export const Eshar = {
  themeName: "Eshar",
  isDark: false,
  CardViewBgColor: "#00665E10",
  secondaryLight: "#85AF9A80",
  primary: "#00665E",
  iconAppItem: "#00665E",
  AppText: "#00665E",
  loginFooter: "#00665E",
  connectBtn: "#00665E",
  loader: "#00665E",
  headerColor: "#00665E",
  primaryLight: "#00665E50",
  primaryColor: "#00665EB2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#85AF9A",
  focusedBorderLoginInput: "#85AF9A",
  focusedLabelLoginInput: "#85AF9A",
  resetInputLogin: "#85AF9A",

  languageIcon: "#85AF9A",

  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#023E39",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#85AF9A",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#00665E",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#00665E",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#00665E50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#00665E",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#85AF9A",
  bnConnect: "#00665E",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#00665E00",
  headerDark: "#023E3900",
  headerText: "#00665E",
  notificationUpColor: "#F5FFFE",
  notificationDownColor: "#E5FFFD",

  listItemNotificationUpColor: "#F0FFFE",
  listItemNotificationDownColor: "#DBFFFC",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F4F3",

  notFocusedSocialMedia: "#00665E",
  focusedSocialMedia: "#00665Ecc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#00665E",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
