import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import { setIn } from "lodash-redux-immutability";

function DragPage({
  index,
  children,
  style = {},
  component,
  dragData = {},
  dataAttributes,
  setDataAttributes,
  fromComponentLib = false,
  dragParentPath,
  className = "",
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  let store = useStore();

  const moveItem = (hoveredItem, draggedItem) => {
    setDataAttributes((prevState) => {
      const newItems = [...prevState];
      const itemToMove = newItems[draggedItem];

      newItems.splice(draggedItem, 1);
      newItems.splice(hoveredItem, 0, itemToMove);

      return newItems;
    });
    const newItems = [...dataAttributes];
    const itemToMove = newItems[draggedItem];

    newItems.splice(draggedItem, 1);
    newItems.splice(hoveredItem, 0, itemToMove);
    let dataSorted = newItems;
    let newData = setIn(skeletonState, [...dragParentPath], dataSorted);

    store.dispatch({
      type: "explorer/dataAdded",
      payload: {
        skeleton: newData,
        readOnly: false,
      },
    });
  };

  const ref = useRef(null);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  const [{ isDragging }, drag, preview] = useDrag({
    type: component,
    item: {
      type: component,
      index,
      dragData,
      fromComponentLib,
    },
    canDrag: !readOnly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    onDrag: () => {},
    end: () => {},
  });
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  const [, drop] = useDrop({
    accept: component,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref.current.getBoundingClientRect();
      const hoverMiddleX = (hoveredRect.right - hoveredRect.left) / 2;
      const mousePosition = monitor.getClientOffset();
      const hoverClientX = mousePosition.x - hoveredRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  preview(<div>{children}</div>);
  const finalStyle = {
    ...style,
  };

  return (
    <div
      style={finalStyle}
      ref={ref}
      className={`${className} ${isDragging ? "dragging" : ""}`}
      draggable
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </div>
  );
}

export default DragPage;
