import React, { useState, useEffect, useRef } from 'react';
import useInterceptorSocket from '../../customHooks/useInterceptorSocket';
import getResponseOfrequest from './services/getResponseOfRequest';
import SplitPane from 'react-split-pane';

import './index.scss';

import Requests from './requests-area/';
import NoRequests from './NoRequests';
import Lottie from 'react-lottie';
import { LoadingOptions } from '../Dashboard/data';
import { useDispatch } from 'react-redux';
import ResponseArea from './response-area';
const ADD_REQ_RES_PAIR = 'interceptor/addRequestResponsePair';
const REQUESTS_TO_PERSIST = process.env.REACT_APP_NB_REQUESTS_TO_SAVE;

const Interceptor = () => {
  const dispatch = useDispatch();
  const addRequestResponsePair = (pair) => {
    dispatch({
      type: ADD_REQ_RES_PAIR,
      payload: pair,
    });
  };

  const {
    setRequestResponsePairs,
    startSocket,
    stopSocket,
    socket,
    requestResponsePairs,
    isLoadingSocket,
    responses,
    mode,
    setMode,
  } = useInterceptorSocket();

  const pairsRef = useRef(requestResponsePairs);
  pairsRef.current = requestResponsePairs;

  useEffect(() => {
    function savePairsReqRes() {
      const lastPairs = pairsRef?.current?.slice(-REQUESTS_TO_PERSIST);
      addRequestResponsePair(lastPairs);
    }

    const handleBeforeUnload = (event) => {
      savePairsReqRes();
    };

    // Handle page refresh
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Handle component unmount
    return () => {
      savePairsReqRes();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const [interceptorIsPaused, setInterceptorIsPaused] = useState(false);
  //a state for displaying things like request response performnce
  const [display, setDisplay] = useState({
    request: true,
    responseBody: true,
    response: true,
    performance: true,
  });

  const [selectedRequest, setSelectedRequest] = useState(null);

  if (isLoadingSocket) {
    return (
      <div>
        <Lottie options={LoadingOptions} height={140} width={140} />
      </div>
    );
  }

  return (
    <div className="interceptorConatiner">
      {requestResponsePairs?.length === 0 ? (
        <NoRequests
          mode={mode}
          setMode={setMode}
          stopSocket={stopSocket}
          startSocket={startSocket}
          requestsLength={requestResponsePairs?.length}
          interceptorIsPaused={interceptorIsPaused}
          setInterceptorIsPaused={setInterceptorIsPaused}
        />
      ) : (
        <SplitPane
          split="vertical"
          minSize={300}
          defaultSize="60%" // This will make the split 60/40 by default
          className="split-pane-container"
        >
          {/* requests-area */}
          <Requests
            mode={mode}
            setMode={setMode}
            responses={responses}
            setRequests={setRequestResponsePairs}
            socket={socket}
            stopSocket={stopSocket}
            startSocket={startSocket}
            interceptorIsPaused={interceptorIsPaused}
            setInterceptorIsPaused={setInterceptorIsPaused}
            requestsData={requestResponsePairs}
            setSelectedRequest={setSelectedRequest}
            selectedRequest={selectedRequest}
            getResponseOfrequest={getResponseOfrequest}
          />

          {/* response area pane */}
          <ResponseArea
            selectedRequest={selectedRequest}
            display={display}
            setDisplay={setDisplay}
          />
        </SplitPane>
      )}
    </div>
  );
};

export default Interceptor;
