import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../../services/http/httpService";

async function editXmlAlias(
  store,
  filename,
  xmlAlias,
  addToast,
  selectedData,
  selected
) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_EDIT_XML_ALIAS;

    const transactionPayload = {
      filename,
      xmlAlias,
    };

    const res = await serviceRequest.post(
      transactionNodeService,
      transactionPayload
    );

    if (res?.data?.success) {
      store.dispatch({
        type: "revision/updateXmlAlias",
        payload: {
          objToAdd: res?.data?.result,
        },
      });
      if (Object.keys(selected)[0] === selectedData?.filename) {
        store.dispatch({
          type: "revision/setSelectedRevision",
          payload: { data: { ...selectedData, ...transactionPayload } },
        });
      }
    }

    addToast(res?.data?.message, res?.data?.success ? "success" : "error");

    return res?.data?.content;
  } catch (error) {
    addToast("Server is unreachable!", "error");

    return null;
  }
}

export default editXmlAlias;
