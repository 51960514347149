import { axiosProviderNode } from "../../../services/http/httpService";
import { services } from "./configVariables";

async function getAllIcons(toast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_ALL_ICONS;

    const res = await axiosProviderNode.get(transactionNodeService);

    return res?.data?.data;
  } catch (error) {
    toast.error(error?.message?.toString(), {
      hideProgressBar: true,
    });
    return error;
  }
}

export default getAllIcons;
