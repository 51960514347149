import React from "react";
// import { useTheme } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import Explorer from "./explorer-nav";

const SideBar = ({ layers, data = {} }) => {
  // const theme = useTheme();
  // const [activeLayer, setActiveLayer] = React.useState(null);
  // const [open, setOpen] = React.useState(false);

  // const [displayAdd, setDisplayAdd] = useState(false);
  // const [parentDetail, setParentDetail] = useState({ parent: [], item: "" });
  // const [currentOpen, setCurrentOpen] = useState({});
  // const [edited, setEdited] = useState({});
  // const [firstOpen, setFirstOpen] = useState(parentDetail?.parent);

  // let store = useStore();

  // let defaultOpen = store?.getState()?.entities?.explorer?.openDefault;

  // let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  // useEffect(() => {
  //   // setFirstOpen(defaultOpen?.parent);
  //   // setCurrentOpen({
  //   //   [defaultOpen?.parent?.toString()]: true,
  //   // });
  //   // setEdited({ [defaultOpen?.parent?.toString()]: true });
  //   store.dispatch({
  //     type: "editor/dataAdded",
  //     payload: {
  //       data: { ...defaultOpen?.data },
  //       parent: defaultOpen?.parent,
  //       component: defaultOpen?.component,
  //     },
  //   });
  // }, [store, defaultOpen]);

  // const displayAddNew = (isOpen, parent) => {
  //   setDisplayAdd(isOpen);
  //   parent && setParentDetail(parent);
  // };

  // const handleCurrentParent = (parent) => {
  //   setParentDetail(parent);
  // };

  // const handleLayerClick = (layerName) => {
  //   setActiveLayer(layerName === activeLayer ? null : layerName);
  // };

  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  return (
    <div className="sideBar">
      <div className="treeContainer">
        <Explorer data={skeletonState} />
      </div>
    </div>
  );
};

export default SideBar;
