export const Thermic = {
  themeName: "Thermic",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#5A5B5D80",
  primary: "#F6AF4B",
  iconAppItem: "#FFD947",
  AppText: "#414141",
  loginFooter: "#F6AF4B",
  connectBtn: "#FFD947",
  loader: "#F6AF4B",
  headerColor: "#F6AF4B",
  primaryLight: "#F6AF4B50",
  primaryColor: "#F6AF4BB2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#FFD947",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#4483B3",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#F6AF4B",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#F6AF4B",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#FDE7C9",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#F6AF4B",
  groupIcon: "#ffffff",
  borderRecord: "#B9915E50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#FDE7C9",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#FDE7C9",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#F6AF4B",
  listBgCard: "#eee",
  colorTextInput: "#565757",
  LogincolorTextInput: "#565757",
  LogincolorLabelInput: "#B3B3B3",
  loginOptions: "#fff",
  shadowBtnConnect: "#FFD947",
  bnConnect: "#FFD947",
  loginBtnLabel: "#414141",
  focusedBorderLoginInput: "#F6AF4B",
  focusedLabelLoginInput: "#F6AF4B",
  resetInputLogin: "#F6AF4B",
  languageIcon: "#77777A",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#FFF",
  loginFooter: "#77777A",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#AF975100",
  headerDark: "#8D7C4900",
  headerText: "#414141",
  notificationUpColor: "#FFFBF5",
  notificationDownColor: "#FEF4E7",

  listItemNotificationUpColor: "#FEF9F0",
  listItemNotificationDownColor: "#FDF0DD",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#FEF4E7",

  notFocusedSocialMedia: "#F6AF4B",
  focusedSocialMedia: "#F6AF4Bcc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#F6AF4B",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
