import React, { useEffect, useRef } from "react";
import "./index.scss";
import imxtools from "imxtools";
import Notification from "../Notification";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import DropTarget from "../../services/DropTarget";
import { ComponentsLibraries } from "../../../../../services/common";
import { isSelectedComponent } from "../../services/isSelectedComponent";

function Notifications({ data = {}, path = [] }) {
  let store = useStore();
  const scrollRef = useRef(null);

  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  useEffect(() => {
    const handleWheel = (event) => {
      if (!scrollRef.current) return;
      event.preventDefault();
      const scrollSpeedFactor = 5;
      const newScrollPosition = event.deltaY / scrollSpeedFactor;
      scrollRef.current.scrollLeft += newScrollPosition;
    };
    const scrollableElement = scrollRef.current;
    scrollableElement.addEventListener("wheel", handleWheel, {
      passive: false,
    });
    return () => {
      scrollableElement.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <DropTarget
      className={`drop-notifications ${
        isSelectedComponent(
          readOnly,
          [...path, "notifications"],
          currentPath
        ) && "selectedComponent"
      }`}
      dropPath={[...path, "notifications"]}
      accept={Object.keys(ComponentsLibraries?.notifications)}
    >
      <div
        className="notifications-container"
        onClick={(event) =>
          handleComponentProp(event, null, data, path, store, "notifications")
        }
        ref={scrollRef}
      >
        {data?.notification ? (
          imxtools?.toArray(data?.notification)?.map((notification, index) => {
            const numNotif = imxtools?.toArray(data?.notification)?.length;
            return (
              <React.Fragment key={index}>
                <Notification
                  key={index}
                  indexNotif={index}
                  data={notification}
                  path={[...path, "notifications"]}
                  numNotif={numNotif}
                  currentPath={currentPath}
                  readOnly={readOnly}
                />
              </React.Fragment>
            );
          })
        ) : (
          <div className="NoNotif">Add Notification</div>
        )}
      </div>
    </DropTarget>
  );
}

export default Notifications;
