import { axiosProviderNode } from "../../../../../services/http/httpService";
import { services } from "../../configVariables";

async function getScripts() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_AUTOSCRIPTS_SCRIPTS;

    const res = await axiosProviderNode.get(transactionNodeService);

    return res?.data;
  } catch (error) {
    console.error("allScriptsList error", error);
    return error;
  }
}
export default getScripts;
