import React, { useEffect, useState } from "react";
import imxtools from "imxtools";
import Tag from "../Tag";
import "./index.scss";
import DropTargetBody from "../../DropTargetBody";
import DragPage from "../../../../../ComponentsLibrary/DragPage";
import { useSelector, useStore } from "react-redux";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import { isSelectedComponent } from "../../../services/isSelectedComponent";

function FooterBody({
  data = {},
  path = [],
  parentPath = [],
  readOnly = true,
  currentPath = [],
}) {
  const [dataTags, setDataTags] = useState([]);
  let store = useStore();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  useEffect(() => {
    setDataTags(imxtools?.toArray(data?.tag ? data?.tag : []));
  }, [data]);

  return (
    <DropTargetBody
      className={`body-footer-container ${
        isSelectedComponent(readOnly, [...parentPath, "footer"], currentPath) &&
        "selectedComponent"
      } ${!readOnly && "hovered-footer"}`}
      style={{
        flexDirection: language?.toLowerCase() === "ar" ? "row-reverse" : "row",
      }}
      accept={["tag"]}
      dragParent={[...path, "tag"]}
      onClick={(e) => {
        handleComponentProp(e, null, data?.header, parentPath, store, "footer");
      }}
    >
      {dataTags?.length > 0 ? (
        dataTags?.map((tag, index) => {
          const numTags = imxtools?.toArray(data?.tag)?.length;
          return (
            <React.Fragment key={index}>
              <DragPage
                index={index}
                className={`drag-tag-content ${
                  isSelectedComponent(
                    readOnly,
                    numTags > 1
                      ? [...path, "tag", index?.toString()]
                      : [...path, "tag"],
                    currentPath
                  ) && `selectedComponent`
                }`}
                component={"tag"}
                dragParentPath={[...path, "tag"]}
                dragData={tag}
                dataAttributes={dataTags}
                setDataAttributes={setDataTags}
              >
                <Tag
                  key={index}
                  data={tag}
                  path={path}
                  index={index}
                  numTags={numTags}
                  readOnly={readOnly}
                />
              </DragPage>
            </React.Fragment>
          );
        })
      ) : (
        <div className="body-footer-empty">Add tag</div>
      )}
    </DropTargetBody>
  );
}

export default FooterBody;
