// useLoggerActionHandler.js
import React from "react"
import makeLoggerRequest from "../../services/makeLoggerRequest.js"
import useToasts from "../../../../../../components/CustomToast/useToasts.js"

const useLoggerActionHandler = (endpointKey, getenvconfValues) => {
  const { addToast } = useToasts()

  return React.useCallback(async () => {
    try {
      const response = await makeLoggerRequest(endpointKey)
      getenvconfValues()
      addToast(response?.content?.result, "success")
    } catch (error) {
      console.error("Error in handling logger action:", error)
      addToast("Operation failed!", "error")
    }
  }, [addToast, getenvconfValues, endpointKey])
}

export default useLoggerActionHandler
