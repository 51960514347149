import React, { useState } from "react";
import "./index.scss";
import InstallationStepper from "../../components/stepper";
import SetupComponent from "../components/SetupComponent";

function MaximoSetup() {
  const [showLoader, setShowLoader] = useState(true);

  return (
    <div className="setupContainer">
      <div className="setup-step-card">
        <InstallationStepper activeStep={1} />
      </div>

      <div className="setup-action-card">
        {/* {showLoader ? ( */}
        <SetupComponent handleClick={() => setShowLoader(false)} label="Next" />
        {/* ) : (
          <VerifComponent />
        )} */}
      </div>
    </div>
  );
}

export default MaximoSetup;
