import { axiosProviderNode } from "../../../services/http/httpService";
import { services } from "./configVariables";

async function deleteIcons(iconNames = [], addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_DELETE_ICON;

    const body = {
      filenames: iconNames,
    };
    const res = await axiosProviderNode.post(transactionNodeService, body);

    if (res?.data?.success) {
      addToast(res.data.message.toString(), "success");
    } else {
      addToast(res.data.message.toString(), "error");
    }

    return res.data.success;
  } catch (error) {
    addToast(error?.message?.toString(), "error");
  }
}

export default deleteIcons;
