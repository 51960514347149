import { maximoLogin } from "../../../services";
import { UpdateMaximoLoginStatus } from "../../../services/stepChange/maximoLoginStatus";
import { HandleStepChange } from "../../../services/stepChange/stepChange";
import { FineUrl, verifyConditions } from "./index";

async function submitForm(
  values = [],
  condition = "",
  method = "",
  protocol = "",
  address = "",
  store = "",
  setSubmitting = () => {},
  setLoginMessage = () => {},
  setOslcStatus = () => {},
  // setMaxRestStatus = () => {},
  setIsLoading = () => {},
  setCondition = () => {},
  // setErrorMaxRestUrl = () => {},
  setErrorOslcUrl = () => {}
) {
  setSubmitting(true);
  setIsLoading(true);

  if (!condition) {
    verifyConditions(
      values,
      setOslcStatus,
      // setMaxRestStatus,
      setIsLoading,
      setCondition,
      // setErrorMaxRestUrl,
      setErrorOslcUrl
    );
  } else {
    const maximoUrl = FineUrl(values?.url);
    const oslcUrl = values?.oslcUrl;
    const maxRestUrl = values?.maxRestUrl;
    const maxappsHost = `${protocol}://${address}`;
    store.dispatch({
      type: "dashboard/updateMaxappsHostValue",
      payload: {
        maxappsHost: maxappsHost,
      },
    });
    store.dispatch({
      type: "installer/updateMaximoLoginStatus",
      payload: {
        status: true,
      },
    });

    maximoLogin(
      values?.username,
      values?.password,
      maximoUrl,
      oslcUrl,
      maxRestUrl,
      maxappsHost,
      method,
      values?.apikey,
      values?.userApiKey
    )
      .then((response) => {
        if (response?.content?.status?.toUpperCase() === "SUCCESS") {
          UpdateMaximoLoginStatus(store, true);
          HandleStepChange(store, "install");
        } else {
          const errorMessage =
            "Invalid credentials. Please ensure your credentials are correct.";
          setLoginMessage(errorMessage);
        }
        setSubmitting(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setIsLoading(false);
        setLoginMessage(error?.response?.data?.message);
      });
  }
}
export default submitForm;
