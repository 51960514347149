export const Gamboge = {
  themeName: "Gamboge",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#72172080",
  primary: "#F7921E",
  iconAppItem: "#721720",
  AppText: "#414141",
  loginFooter: "#F7921E",
  connectBtn: "#F7921E",
  loader: "#F7921E",
  headerColor: "#F7921E",
  primaryLight: "#F7921E50",
  primaryColor: "#F7921EB2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#721720",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#6D4F2C",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#F7921E",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#F7921E",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#FDDDB9",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#F7921E",
  groupIcon: "#ffffff",
  borderRecord: "#F7921E50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#FDDDB9",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#FDDDB9",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#F7921E",
  listBgCard: "#eee",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#B3B3B3",
  loginOptions: "#aaa",
  shadowBtnConnect: "#721720",
  bnConnect: "#721720",
  loginBtnLabel: "#FFF",
  focusedBorderLoginInput: "#F7921E",
  focusedLabelLoginInput: "#F7921E",
  resetInputLogin: "#F7921E",
  languageIcon: "#565757",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#FFF",
  loginFooter: "#77777A",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#F7921E00",
  headerDark: "#8D7C4900",
  headerText: "#414141",
  notificationUpColor: "#FCFBF8",
  notificationDownColor: "#F7F5EE",

  listItemNotificationUpColor: "#FAF9F5",
  listItemNotificationDownColor: "#F4F0E7",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#EFEEEB",

  notFocusedSocialMedia: "#F7921E",
  focusedSocialMedia: "#F7921Ecc",
  loginBgFooter: "#fff",

  //Costum4
  loginFirstTabTitle: "#222",
  loginTabTitle: "#222",
  loginTabSelected: "#fff",
  loginTabNotSelected: "#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft: "#fff",
  languageContainerRight: "#F7921E",
  submitLeftConnect: "#222",
  submitRightConnect: "#fff",
};
