function getRandomAlphanumeric(length) {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset.charAt(randomIndex);
  }
  return result;
}

// Generate a random decimal number within a specified range
function getRandomAmount(min, max) {
  return Math.random() * (max - min) + min;
}

// Generate a random binary blob of a given length
function getRandomBlob(length) {
  const blob = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    blob[i] = Math.floor(Math.random() * 256);
  }
  return blob;
}

// Generate a random date (YYYY-MM-DD)
function getRandomDate() {
  const year = getRandomInteger(1900, 2100);
  const month = getRandomInteger(1, 12);
  const day = getRandomInteger(1, 28); // Adjust for different months and leap years
  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
}

// Generate a random date and time (YYYY-MM-DD HH:MM:SS)
function getRandomDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedDateTime = `${month}/${day}/${year} ${
    hours % 12
  }:${minutes} ${ampm}`;
  return formattedDateTime;
}

// Generate a random decimal number with a specified number of decimal places and range
function getRandomDecimal(min, max, decimalPlaces = 2) {
  return parseFloat((Math.random() * (max - min) + min).toFixed(decimalPlaces));
}

// Generate a random duration string (e.g., "1:30" for one and a half hours)
function getRandomDuration() {
  const hours = getRandomInteger(0, 23);
  const minutes = getRandomInteger(0, 59);
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
}

// Generate a random floating-point number within a specified range
function getRandomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

// Generate a random integer within a specified range
function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Generate a random time (HH:MM:SS)
function getRandomTime() {
  const hours = getRandomInteger(0, 23);
  const minutes = getRandomInteger(0, 59);
  const seconds = getRandomInteger(0, 59);
  return `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

function generateCheckboxForYORN() {
  const isChecked = Math.random() < 0.5;
  return <input type="radio" defaultChecked={isChecked} />;
}

function generateHTMLData() {
  return <div>{`HTML content.`}</div>;
}

function generateRandomData(dataType = "ALN") {
  switch (dataType) {
    case "ALN":
      return getRandomAlphanumeric(4);
    case "AMOUNT":
      return getRandomAmount(100, 1000);
    case "BLOB":
      return getRandomBlob(64);
    case "CLOB":
      return getRandomAlphanumeric(8);
    case "CRYPTO":
      return getRandomBlob(32);
    case "CRYPTOX":
      return getRandomBlob(32);
    case "DATE":
      return getRandomDate();
    case "DATETIME":
      return getRandomDateTime();
    case "DECIMAL":
      return getRandomDecimal(2, 10);
    case "DURATION":
      return getRandomDuration();
    case "FLOAT":
      return getRandomFloat(0, 10);
    case "GL":
      return getRandomAlphanumeric(6);
    case "INTEGER":
      return getRandomInteger(1, 100);
    case "BIGINT":
      return getRandomInteger(1, 100);
    case "LONGALN":
      return getRandomAlphanumeric(20);
    case "LOWER":
      return getRandomAlphanumeric(8).toLowerCase();
    case "SMALLINT":
      return getRandomInteger(1, 10);
    case "TIME":
      return getRandomTime();
    case "UPPER":
      return getRandomAlphanumeric(8).toUpperCase();
    case "YORN":
      return generateCheckboxForYORN();
    case "HTML":
      return generateHTMLData();
    default:
      return null;
  }
}

export default generateRandomData;
