import React, { useEffect, useState } from "react";
import imxtools from "imxtools";
import { Close as CloseIcon } from "@mui/icons-material";
import { useModal } from "../../../../../services/ModalContext";
import CustomButton from "../../../../../../../components/CustomButton";
import { useSelector, useStore } from "react-redux";
import { getIn } from "lodash-redux-immutability";
import UtilityProperty from "../Components/UtilityProperties";
import { useTheme } from "../../../../../../../theme/themeContext";
import {
  AddConditionProperty,
  onSave,
  transformArray,
} from "../../services/UtilityServices";
import NoItem from "../../../../../../../components/NoItem";
import "./index.scss";

function ConditionsModal() {
  const { themeMode, themeColors } = useTheme();

  const [properties, setProperties] = useState([{ name: "", value: "" }]);
  const [conditions, setConditions] = useState([
    { key: "reference", value: "", inputType: "text" },
    { key: "expression", value: "", inputType: "expression" },
  ]);

  const {
    closeModal,
    resetModal,
    // type,
    reference,
    createNew,
    data: dataCondition,
    path,
  } = useModal();
  let store = useStore();
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  const handleCloseModal = () => {
    closeModal();
    resetModal();
  };

  useEffect(() => {
    const Properties = imxtools?.toArray(
      getIn(skeleton, [...path, "property"]) ?? []
    );
    setProperties(Properties);
  }, [path, skeleton]);

  const ConditionData = Object.entries(dataCondition).reduce(
    (acc, [key, value]) => {
      if (key === "reference" || key === "expression") {
        acc.push({
          key,
          value,
          inputType: key === "expression" ? "expression" : "text",
        });
      }
      return acc;
    },
    []
  );

  useEffect(() => {
    if (createNew) {
      return;
    }
    setConditions(ConditionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`conModal-container ${readOnly && "conModal-readOnly"}`}>
      <div className="conModal-content">
        <div className="conModal-header">
          <div className="modalTitle">
            {reference !== "" && !createNew
              ? `Condition: ${reference}`
              : "New Condition"}
          </div>
          <div className="headerActions">
            {!readOnly ? (
              <CustomButton
                iconName="add-circle"
                iconSize={20}
                // iconColor={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                label="Add Property"
                // color={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                gap={0.5}
                backgroundColor={"white"}
                heightBtn="2.5rem"
                fontSize={16}
                reverseBtn={true}
                paddingVertical={0.5}
                onClick={() =>
                  AddConditionProperty(
                    properties,
                    setProperties,
                    store,
                    path,
                    "property"
                  )
                }
              />
            ) : (
              <div className="closeButton">
                <CloseIcon
                  onClick={handleCloseModal}
                  sx={{
                    background: "#FF505A",
                    color: "white",
                    width: "22px",
                    height: "22px",
                    borderRadius: "2px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="condition-content"
          style={{
            gridTemplateColumns: "20% 78%",
          }}
        >
          {conditions &&
            conditions?.map((prop, index) => {
              return (
                <React.Fragment key={index}>
                  <UtilityProperty
                    Key={prop?.key}
                    Value={prop?.value}
                    objectValue="value"
                    index={index}
                    disabled={readOnly ?? true}
                    data={conditions}
                    setData={setConditions}
                    readOnly={readOnly}
                    // script={true}
                    inputType={prop?.inputType}
                    actions={false}
                    disableKey={true}
                  />
                </React.Fragment>
              );
            })}
        </div>
        <div
          className="break"
          style={{ marginBottom: properties?.length === 0 && "1rem" }}
        ></div>
        {properties?.length === 0 ? (
          <NoItem icon="Maintenance" text="No property to display" />
        ) : (
          <div className="properties-content">
            <div className="HeaderProperties">
              <div>Name</div>
              <div>Value</div>
            </div>
            <div
              className="conProperties"
              style={{
                gridTemplateColumns: readOnly ? "20% 78%" : "20% 73% 3%",
              }}
            >
              {properties?.map((prop, index) => {
                return (
                  <React.Fragment key={index}>
                    <UtilityProperty
                      Key={prop?.name}
                      objectKey="name"
                      Value={prop?.value}
                      index={index}
                      disabled={readOnly ?? true}
                      data={properties}
                      setData={setProperties}
                      readOnly={readOnly}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {!readOnly && (
        <div className="modalFooter">
          <div className="secondaryAction">
            <CustomButton
              label={"Cancel"}
              backgroundColor="white"
              widthBtn="100%"
              heightBtn="2.75rem"
              color="#39354A"
              borderColor="#EEEEEE"
              borderWidth="1px"
              fontSize={16}
              onClick={handleCloseModal}
              disableClickReadOnly={false}
              withIcon={false}
              verticalBorder={true}
            />
          </div>
          <div className="primaryAction">
            <CustomButton
              label={createNew ? "Create" : "Save"}
              backgroundColor={themeColors[themeMode].SupportButtonBg}
              borderColor="#4C36A5"
              borderWidth="1px"
              widthBtn="100%"
              heightBtn="2.75rem"
              color="white"
              fontSize={16}
              onClick={() => {
                onSave(
                  "condition",
                  createNew,
                  path,
                  createNew
                    ? {
                        ...transformArray(conditions),
                        property:
                          properties?.length > 1 ? properties : properties[0],
                      }
                    : {
                        ...dataCondition,
                        ...transformArray(conditions),
                        property:
                          properties?.length > 1 ? properties : properties[0],
                      },
                  skeleton,
                  store
                );
                handleCloseModal();
              }}
              withIcon={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ConditionsModal;
