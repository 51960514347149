import React, { useEffect, useState } from "react";
import "./index.scss";
import getCurrentDate from "./services/getCurrentDate";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import logo from "../../../../../../../assets/maxapps-assets/home-logo.png";
import {
  getWeatherByGeolocationAndUnits,
  toggleUnits,
} from "./services/WeatherService";
import Notifications from "../Notifications";

const Header = ({ pages = [], currentPage = 0, skeleton = {} }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [geolocationError, setGeolocationError] = useState(false);
  const [units, setUnits] = useState("metric");


  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const data = await getWeatherByGeolocationAndUnits(units);
        if (data) {
          setWeatherData(data);
        } else {
          setGeolocationError(true);
        }
      } catch (error) {
        console.error("Error fetching weather data:", error);
        setGeolocationError(true);
      }
    };

    fetchWeather();
  }, [units]);

  return (
    <React.Fragment>
      {!pages[currentPage]?.gis && (
        <React.Fragment>
          <div className="header-home">
            <div className="header-container">
              <div className="Logo">
                <img src={logo} alt="Logo" className="logo-img" />
              </div>
              <div className="Date-weather">
                <div
                  className="weather"
                  onClick={(e) => toggleUnits(e, units, setUnits)}
                >
                  {geolocationError ? (
                    "--"
                  ) : weatherData ? (
                    <>
                      {weatherData?.weather.toFixed(1)}
                      {units === "imperial" ? "°F" : "°C"}
                      <img
                        src={weatherData?.icon}
                        alt="Weather Icon"
                        className="weather-icon"
                      />
                    </>
                  ) : (
                    "--"
                  )}
                </div>
                <div className="date">{getCurrentDate()}</div>
              </div>
              <div className="offlineTrans">
                <Icon icon="online_solid" size={20} />
              </div>
            </div>
          </div>
          {
            /* !readOnly   && skeleton?.header || */
            skeleton?.header?.displaygreeting?.toLowerCase() === "true" && (
              <div className="greetingDisplay">
                <div className="Hi">Hi</div>
                <div className="userName">John</div>
              </div>
            )
          }
          {skeleton?.notifications && (
            <div className="notifications-content">
              <Notifications
                data={skeleton?.notifications}
                path={["maxapps"]}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Header;
