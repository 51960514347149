import { axiosProviderNode } from "../../../../../services/http/httpService";
import { services } from "../../configVariables";

async function installApplication() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_CONFIG_MAXMESSAGES;

    const body = {};

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default installApplication;
