import React from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./views/Layout";
import Monitor from "./views/Monitor";
import Installer from "./views/Installer";
import Designer from "./views/Designer";
import IconManager from "./views/IconManager";
import DashboardAuth from "./views/Auth";
import NotFound from "./views/NotFound";
import Support from "./views/Support";
import ContextDnD from "./views/Designer/services/AppContext";
import { useSelector, useStore } from "react-redux";
import { ToastContainer } from "react-toastify";

function App() {
  const store = useStore();

  store.dispatch({
    type: "checkMaximoConfig",
  });
  const configStatus = useSelector(
    (state) => state?.entities?.dashboard?.availableConfig
  );

  const isLoggedin = useSelector(
    (state) => state?.entities?.dashboard?.loggedIn
  );

  store.dispatch({
    type: "explorer/reset",
  });
  store.dispatch({
    type: "deviceview/setOpenedScrollGuide",
    payload: {
      openedScrollGuide: true,
    },
  });
  return (
    <div className="app">
      <ContextDnD>
        <BrowserRouter>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<DashboardAuth />} />
              <Route
                path="/monitor"
                element={
                  isLoggedin && configStatus ? <Monitor /> : <Navigate to="/" />
                }
              ></Route>
              <Route
                path="/installer"
                element={
                  isLoggedin && configStatus ? (
                    <Installer />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/appbuilder"
                element={
                  isLoggedin && configStatus ? (
                    <Designer />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/assets"
                element={
                  isLoggedin && configStatus ? (
                    <IconManager />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="/*" element={<NotFound />} />
              <Route path="/support" element={<Support />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ContextDnD>
      <ToastContainer />
    </div>
  );
}

export default App;
