import { installDoclinkOS } from "../../services/installDoclinkOS/installDoclinkOS";
import { installImglibOS } from "../../services/installImgLib/installImgLib";
import { installMaxMessages } from "../../services/installMaxMessages/installMaxMessages";
import { installMaxappsApplication } from "../../services/installMaxappsApplication/installMaxappsApplication";
import Loading from "../../../Assets/lottie-files/loader-dots.json";
import Loading_white from "../../../Assets/lottie-files/loader-dots-white.json";

export const promises = [
  { scriptName: "Docklink OS", scriptInstall: installDoclinkOS },
  { scriptName: "Imglib OS", scriptInstall: installImglibOS },
  { scriptName: "Maxmessages", scriptInstall: installMaxMessages },
  {
    scriptName: "MAXAPPSMOBILE Application",
    scriptInstall: installMaxappsApplication,
  },
];
export const succesMessage = "Installed successfully ";
export const errorMessage = "Failed to Install ";

export const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const LoadingWhiteOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading_white,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
