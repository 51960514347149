import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { dataProgress } from "../../../../../../../../../utils/chartFakeData";
import { optionsProgress } from "../../../../../../../../../utils/chartOptions";
import "./index.scss";

function ProgressRing({
  size = "small",
  label = "Add label",
  data = {},
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  return (
    <div
      className={`progressring-graph ${
        size?.toLowerCase() !== "small" && "progressring-large"
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className="progressring-graph-label">{label}</div>
      <div className="progressring-graph-chart">
        <Doughnut data={dataProgress} options={optionsProgress} />
      </div>
    </div>
  );
}

export default ProgressRing;
