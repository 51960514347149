function exportToFile(content, fileName, contentType = 'text/plain') {
  // Create a Blob with the content
  const blob = new Blob([content], { type: contentType });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);

  // Revoke the temporary URL
  URL.revokeObjectURL(url);
}

export default exportToFile;
