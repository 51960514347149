import { axiosProviderNode } from "../../../../../services/http/httpService"
import { services } from "./loggerEndPoints"

async function getenvConfData(params = {}) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_ENVCONF

    const res = await axiosProviderNode.get(transactionNodeService, params)
    return res?.data
  } catch (error) {
    throw error
  }
}
export default getenvConfData
