import React, { useState } from "react";
import "./index.scss";
import DisconnectComponent from "../components/DisconnectComponent";
import InstallationStepper from "../../components/stepper";
import VerifComponent from "../components/VerifComponent";

function Disconnect() {
  const [showSyncComponent, setShowSyncComponent] = useState(false);

  return (
    <>
      {!showSyncComponent ? (
        <div className="DisconnectContainer">
          <div className="Disconnect-step-card">
            <InstallationStepper activeStep={3} />
          </div>

          <div className="Disconnect-action-card">
            <VerifComponent handleClick={() => setShowSyncComponent(true)} />
          </div>
        </div>
      ) : (
        <div className="SyncContainer">
          <DisconnectComponent />
        </div>
      )}
    </>
  );
}

export default Disconnect;
