export function isHoveredComponent(
  currentPath = [],
  component = "",
  readOnly = true
) {
  try {
    if (!currentPath || currentPath?.length < 1 || readOnly) {
      return false;
    }
    const lastIndex = currentPath?.length - 1;
    const secondLastIndex = currentPath?.length - 2;

    let result = false;

    if (isNaN(lastIndex)) {
      result = currentPath[lastIndex] === component;
    } else {
      result = currentPath[secondLastIndex] === component;
    }

    return result;
  } catch (error) {
    console.error("Error in isHoveredComponent:", error);
    return false;
  }
}
