import React from "react";
import "./index.scss";
import { handleComponentProp } from "../../views/Designer/components/ComponentsLayers/Preview/services/handleComponentProperties";
import { useStore } from "react-redux";
function Pins({
  displayPullup = false,
  currentMarkerData = {},
  pathOfList = [],
}) {
  let store = useStore();
  return (
    <div
      className={!displayPullup ? "pins-container" : "pins-container-pullup"}
    >
      {currentMarkerData?.pin ? (
        <div
          className="pin-wrapper"
          onClick={(e) =>
            handleComponentProp(
              e,
              null,
              currentMarkerData?.pin,
              [...pathOfList, "marker", "pin"],
              store,
              "pin"
            )
          }
        >
          <div
            className="pin-attribute-wrapper"
            onClick={(e) =>
              handleComponentProp(
                e,
                null,
                currentMarkerData?.pin?.attribute,
                [...pathOfList, "marker", "pin", "attribute"],
                store,
                "attribute"
              )
            }
          >
            {currentMarkerData?.pin?.attribute?.label}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Pins;
