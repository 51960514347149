import "./index.scss";
import React from "react";
import ServerControlPanel from "./controlPanel/index";
import IMXLoggerLeftSide from "./IMXLoggerLeftSide/index";
import IMXLoggerRightSide from "./IMXLoggerRightSide/index";
import geteImxLoggEnvConfData from "./services/getenvConfData";

const IMXLogger = () => {
  const [envConfData, setEnvConfData] = React.useState({});
  const envConfDataArray = Object.keys(envConfData).length;

  const getenvconfValues = React.useCallback(async () => {
    try {
      const envConfDataResponse = await geteImxLoggEnvConfData();
      setEnvConfData(envConfDataResponse?.content);
    } catch (error) {
      console.error("error ===>", error);
    }
  }, []);

  React.useEffect(() => {
    getenvconfValues();
  }, []);

  return (
    <div className="IMXLogger-container">
      <ServerControlPanel
        envConfData={envConfData}
        setEnvConfData={setEnvConfData}
      />
      <div className="IMXLogger-main">
        <IMXLoggerLeftSide
          getenvconfValues={getenvconfValues}
          envConfData={envConfData}
        />
        <div className="vertical-seperator"></div>
        {envConfDataArray > 0 ? (
          <IMXLoggerRightSide
            envConfData={envConfData}
            setEnvConfData={setEnvConfData}
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};
export default IMXLogger;
