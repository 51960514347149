import React from 'react';
import ReactJson from 'react-json-view';
import CustomButton from '../../../../../components/CustomButton';
import themeColors from '../../../../../theme/themeColors';
import ShowMoreText from 'react-show-more-text';
import KeyCopy from './KeyCopy';
import getStatusOrMethodCodeColor from '../services/getStatusOrMethodCodeColor';
const Request = ({ selectedRequest, setDisplay, display }) => {
  const requestUrl = selectedRequest?.request?.requestUrl;
  const requestMethod = selectedRequest?.request?.requestData?.method;

  const requestHeaders = selectedRequest?.request?.requestData?.headers;

  return (
    <div className="response-options-container">
      <div
        className="response-options-header"
        onClick={() => {
          setDisplay({
            ...display,
            request: !display?.request,
          });
        }}
      >
        <div>
          <div className="response-key">
            <h4>Request</h4>

            <span
              className="request-method"
              style={{
                color: getStatusOrMethodCodeColor(
                  requestMethod?.toUpperCase(),
                  'method'
                ),
                backgroundColor: `${getStatusOrMethodCodeColor(
                  requestMethod?.toUpperCase(),
                  'method'
                )}33`,
                border: `1px solid ${getStatusOrMethodCodeColor(
                  requestMethod?.toUpperCase(),
                  'method'
                )}`,
                fontSize: '14px',
              }}
            >
              {requestMethod}
            </span>
          </div>
        </div>
        <CustomButton
          iconName={display?.request ? 'arrow-down' : 'arrow-right'}
          iconColor={themeColors.light.responseDataButtonArrow}
          iconSize={20}
          tooltip={display?.request ? 'Hide Request' : 'Show Request'}
        />
      </div>

      {/* METHOD */}

      <div
        style={{
          display: display?.request ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <p
          style={{
            textTransform: 'uppercase',
            color: 'rgba(57, 53, 74, 1)',
            fontWeight: '400',
            fontSize: '16px',
          }}
        >
          <span className="response-area-keys">METHOD:</span> {requestMethod}
        </p>
        {/* url  */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <KeyCopy text="URL" value={requestUrl} tooltip="Copy request url" />
          <p
            style={{
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            {/* {requestUrl} */}
            <ShowMoreText
              lines={5}
              more="Show more"
              less="Show less"
              anchorClass="show-more"
              expanded={false}
              width={2000} // Adjust this value based on your layout
            >
              <span>{requestUrl}</span>
            </ShowMoreText>
          </p>
        </div>
        {/* Headers */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <KeyCopy
            text="HEADERS"
            value={requestHeaders}
            tooltip="Copy request headers"
          />
          <pre className="json-container">
            <ReactJson src={requestHeaders} />
          </pre>
        </div>
      </div>
    </div>
  );
};

export default Request;
