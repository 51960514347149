import React, { useContext, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { useSelector, useStore } from "react-redux";
import { getIn, setIn } from "lodash-redux-immutability";
import "./index.scss";
import { AppContext } from "../../../../../services/AppContext";
import _ from "lodash";
import handleAddItem from "../../services/handleAddItem";
import { arraysAreEqual } from "../../../../../../../utils/util";
import imxtools from "imxtools";
function takeElements(arr) {
  if (arr.length >= 3 && !isNaN(Number(arr[2]))) {
    return arr.slice(0, 3);
  } else {
    return arr.slice(0, 2);
  }
}
const moveItemToEndOfList = (oldData, itemOrder) => {
  const clonedData = _.cloneDeep(oldData);

  if (!Array.isArray(clonedData.app)) {
    console.error("Invalid oldData structure");
    return oldData;
  }

  const item = clonedData.app.find((item) => item.itemOrder === itemOrder);

  if (!item) {
    console.error("Item not found");
    return oldData;
  }

  clonedData.app = clonedData.app.filter(
    (item) => item.itemOrder !== itemOrder
  );

  clonedData.app.push(item);

  return clonedData;
};

const DropTargetBody = ({
  children,
  accept = [],
  style = {},
  dropPath = [],
  pages = [],
  currentPage,
  homeScreen,
  className = "",
  path = [],
  onClick = () => {},
  onDoubleClick = () => {},
  key = null,
}) => {
  const [insideParent, setInsideParent] = useState(null);
  const {
    insideInner,
    setInsideInner,
    targetRefEvent,
    setDragableType,
    dragParentDraged,
  } = useContext(AppContext);

  let store = useStore();
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );
  let parentToEdit = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  useEffect(() => {
    let insidePar = getIn(skeletonState, parentToEdit, null);
    setInsideParent(insidePar);
  }, [skeletonState, parentToEdit]);

  const [, drop] = useDrop({
    accept: accept,

    drop: (item = { dragParentPath: [] }) => {
      if (arraysAreEqual(insideInner, item?.dragParentPath)) {
        if (isNaN(item?.dragParentPath[item?.dragParentPath?.length - 1])) {
          let OldData = _.get(skeleton, item?.dragParentPath);
          let SortedData = moveItemToEndOfList(
            OldData,
            item?.dragData?.itemOrder
          );
          let newData = setIn(
            skeleton,
            [...item.dragParentPath, "app"],
            SortedData?.app
          );
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: newData,
              readOnly: false,
            },
          });
        } else {
          let OldData = _.get(skeleton, item.dragParentPath);
          let SortedData = moveItemToEndOfList(
            OldData,
            item?.dragData?.itemOrder
          );
          let newData = setIn(
            skeleton,
            [...item.dragParentPath, "app"],
            SortedData?.app
          );
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: newData,
              readOnly: false,
            },
          });
        }
        return;
      } else {
        if (insideInner.includes("module") || insideInner.includes("row")) {
          return;
        }
        if (item?.fromComponentLib) {
          if (item.type === "form" || item.type === "hform") {
            handleAddItem(
              item.type,
              store,
              skeletonState,
              parentToEdit,
              null,
              targetRefEvent
            );
          } else {
            handleAddItem(
              item?.type,
              store,
              skeletonState,
              parentToEdit,
              insideParent,
              null
            );
          }
        } else {
          if (homeScreen) {
            if (
              dragParentDraged == null ||
              dragParentDraged?.length === 0 ||
              arraysAreEqual(dragParentDraged, path)
            ) {
              return;
            }
            let newDatatoAdd = getIn(skeletonState, [
              ...dragParentDraged,
              item?.type,
              item?.index,
            ]);

            let newDataGettedOutSide = imxtools.toArray(
              getIn(skeletonState, [...path, item?.type])
            );
            let InitNewDataGetted = imxtools.toArray(
              getIn(skeletonState, [...dragParentDraged, item?.type])
            );
            let newDataGetted = InitNewDataGetted.filter(
              (item) => item?.itemOrder !== newDatatoAdd?.itemOrder
            );

            let newDataGettedModified = [
              ...imxtools?.toArray(newDataGettedOutSide),
              newDatatoAdd,
            ];

            let newData = setIn(
              skeletonState,
              [...dragParentDraged, item?.type],
              newDataGetted
            );
            let newDataFinale = setIn(
              newData,
              [...path, item?.type],
              newDataGettedModified
            );

            store.dispatch({
              type: "explorer/dataAdded",
              payload: {
                skeleton: newDataFinale,
                readOnly: false,
              },
            });
            return;
          }
          store.dispatch({
            type: "explorer/moveItem",
            payload: {
              globalSkeloton: skeleton,
              parent: dropPath,
              key: item.type,
              dragData: item.dragData,
              dragPath: item.dragPath,
              dragParentPath: item.dragParentPath,
            },
          });
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      setDragableType(item.type);

      if (pages?.length === 2 && (pages?.[0]?.gis || pages?.[1]?.gis)) {
        if (pages?.length === 1) {
          setInsideInner(["maxapps", "page"]);
        } else {
          setInsideInner(["maxapps", "page", String(currentPage)]);
        }
      } else {
        if (pages.length > 1) {
          setInsideInner(["maxapps", "page", String(currentPage)]);
        } else {
          setInsideInner(["maxapps", "page"]);
        }
      }
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const ref = useRef();
  drop(ref);
  const finalStyle = {
    ...style,
  };
  return (
    <div
      key={key}
      style={finalStyle}
      ref={ref}
      className={className}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </div>
  );
};

export default DropTargetBody;
