import clearAllFromMaximo from "./clearAllFromMaximo";

async function clearAll(
  store,
  _isMounted,
  clearDb,
  toclearMaximo,
  checkVersions,
  setShowPopUpOptions = () => {},
  setFailedToClear = () => {},
  setToClear = () => {},
  setDisconnectMessage = () => {},
  setFinish = () => {},
  setErrorMessage = () => {},
  setResponse = () => {}
) {
  setShowPopUpOptions(true);
  setFailedToClear(false);
  setToClear(true);
  toclearMaximo
    ? setDisconnectMessage("MAXapps has been uninstalled succesfully")
    : clearDb
    ? setDisconnectMessage("Database has been cleared succesfully")
    : setDisconnectMessage(
        "Maximo Config  has been cleared succesfully from DB"
      );
  clearAllFromMaximo(clearDb, toclearMaximo, checkVersions)
    .then((res) => {
      if (_isMounted.current) {
        if (
          res.success &&
          res?.content?.status?.toString()?.toLowerCase() !== "failure"
        ) {
          localStorage.removeItem("authorization");
          setFinish(true);
          setToClear(false);
        } else {
          setResponse(res);
          setToClear(false);
          const errorMessage = res?.message
            ? res?.message
            : "Failed to uninstall";
          setErrorMessage(errorMessage);
          setFailedToClear(true);
        }
      }
    })
    .catch((error) => {
      console.error("Disconnect error", error);
      setFailedToClear(true);
    });
}

export default clearAll;
