import { axiosProviderNode } from "../../../../../../services/http/httpService.js"
import { services } from "../../services/loggerEndPoints.js"

async function downloadLogFileByFileName(params = {}) {
  try {
    const transactionNodeService =
      services.NODE_ENDPOINT_DOWNLOAD_LOG_FILE_BY_FILENAME_IMXLOGGER +
      params?.fileName

    const res = await axiosProviderNode.get(transactionNodeService)
    return res?.data
  } catch (error) {
    throw error
  }
}
export default downloadLogFileByFileName
