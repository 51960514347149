export const Qiddiya = {
  themeName: "Qiddiya",
  isDark: false,
  CardViewBgColor: "#002E7310",
  secondaryLight: "#00B5E380",
  primary: "#002E73",
  iconAppItem: "#F5B321",
  AppText: "#002E73",
  loginFooter: "#002E73",
  connectBtn: "#002E73",
  loader: "#002E73",
  headerColor: "#002E73",
  primaryLight: "#002E7350",
  primaryColor: "#002E73B2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#00B5E3",

  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#061933",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#00B5E3",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#002E73",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#002E73",
  groupIcon: "#ffffff",

  borderRecord: "#002E7350",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#002E73",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFFFFF8A",

  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#888",
  loginOptions: "#000",
  shadowBtnConnect: "#00B5E3",
  bnConnect: "#002E73",
  notFocusedLabelInput: "#002E73",
  focusedLabelLoginInput: "#002E73",
  resetInputLogin: "#F5B321",
  focusedBorderLoginInput: "#F5B321",

  languageIcon: "#002E73",
  loginText: "#333",
  loginBtnLabel: "#fff",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#002E7300",
  headerDark: "#06193300",
  headerText: "#002E73",
  notificationUpColor: "#F5F9FF",
  notificationDownColor: "#E5F0FF",

  listItemNotificationUpColor: "#F0F6FF",
  listItemNotificationDownColor: "#DBEAFF",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F2F4",

  notFocusedSocialMedia: "#002E73",
  focusedSocialMedia: "#002E73cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#002E73",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
