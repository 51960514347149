import React from "react";
import "./index.scss";
import Icon from "../../components/CustomIcon/Icon";
import { useNavigate } from "react-router-dom";
import PenguinErrorLight from "../../assets/PenguinErrorLight.svg";
import PenguinErrorDark from "../../assets/PenguinErrorDark.svg";
import { useTheme } from "../../theme/themeContext";
import lightBackground from "../../assets/support-light.png";
import darkBackground from "../../assets/support-dark.png";

function NotFound() {
  const navigate = useNavigate();
  const { themeMode } = useTheme();

  const penguinImages = {
    light: PenguinErrorLight,
    dark: PenguinErrorDark,
  };

  return (
    <div
      className="NotFoundContainer"
      style={{
        backgroundImage: `url(${
          themeMode?.toLowerCase() === "dark" ? darkBackground : lightBackground
        })`,
      }}
    >
      <div className="TopLeftContent">
        <div className="Oops">
          Oops, <br /> Page Not Found
        </div>
        <div className="Message">
          The request page either doesn't exist or you don't have access to it
        </div>
        <div>
          <img
            alt="error"
            src={penguinImages[themeMode]}
            className="Error"
          ></img>
        </div>
      </div>
      <div className="Buttons">
        <button className="Support" onClick={() => navigate("/support")}>
          Contact Support
        </button>
        <button className="HomeButton" onClick={() => navigate("/monitor")}>
          Go Back to Home Page <Icon className="Fleche" icon="Fleche" />
        </button>
      </div>
    </div>
  );
}

export default NotFound;
