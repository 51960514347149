import { getIn, setIn } from "lodash-redux-immutability";
import React, { createContext, useContext, useState } from "react";
import imxtools from "imxtools";
import { useSelector, useStore } from "react-redux";

const CopyPasteContext = createContext();

export function useCopyPaste() {
  return useContext(CopyPasteContext);
}

export function CopyPasteProvider({ children }) {
  const [copiedProp, setCopiedProp] = useState({});
  const [copiedComponent, setCopiedComponent] = useState({});
  const [copiedCompType, setCopiedCompType] = useState("");
  const store = useStore();

  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  //Property(s) (copy/paste)
  const copyProp = (data, index) => {
    const item = data[index];
    const filteredData = Object.keys(item ?? {})
      .filter(
        (att) =>
          att?.toString() !== "id" &&
          att?.toLowerCase() !== "itemorder" &&
          typeof item?.[att] !== "object"
      )
      .reduce((filteredObject, key) => {
        filteredObject[key] = item?.[key];
        return filteredObject;
      }, {});

    setCopiedProp(filteredData); // Assuming setCopiedProp is from your context
  };
  // const copyProp = (data, index) => {
  //   const item = data[index];
  //   const filteredData = { [item.key]: item.value };

  //   setCopiedProp(filteredData); // Assuming setCopiedProp is from your context
  // };
  const pasteProp = (data, setData) => {
    const restructuredData = {};
    if (copiedProp.key && copiedProp.value) {
      restructuredData[copiedProp.key] = copiedProp.value;
    }

    // Merge the restructured data into the existing data
    setData((prevData) => ({ ...prevData, ...restructuredData }));
  };

  // Component (copy/paste)
  const copyComponent = (data = {}, compType = "") => {
    setCopiedCompType(compType?.toString());
    setCopiedComponent(data);
  };

  const pasteComponent = (parent = []) => {
    const key = imxtools?.generateUniqueKey(parent?.[parent?.length - 1]);
    let finalData = {};

    const getData = getIn(skeleton, parent);

    if (copiedCompType && getData?.[copiedCompType]) {
      const oldobj = imxtools?.toArray(getData?.[copiedCompType]);
      finalData = {
        ...getData,
        [copiedCompType]: [...oldobj, copiedComponent],
        id: `imxComponent${key}`,
      };
    } else {
      finalData = {
        ...getData,
        [copiedCompType]: copiedComponent,
        id: `imxComponent${key}`,
      };
    }

    let newData = setIn(skeleton, parent, finalData);

    store.dispatch({
      type: "explorer/updateData",
      payload: {
        skeleton: { ...skeleton, ...newData },
        readOnly: false,
      },
    });
  };

  return (
    <CopyPasteContext.Provider
      value={{
        copiedProp,
        copyProp,
        pasteProp,
        copiedCompType,
        copiedComponent,
        copyComponent,
        pasteComponent,
      }}
    >
      {children}
    </CopyPasteContext.Provider>
  );
}
