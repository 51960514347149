export const getLabelByLanguage = (language = "en", attribute) => {
  if (!attribute) {
    return "--";
  }
  const atrrLabel = attribute["label_" + language.toLowerCase()];
  let resultLabel = attribute.label;

  if (atrrLabel && atrrLabel !== "") {
    resultLabel = attribute["label_" + language.toLowerCase()];
  }

  return resultLabel;
};
