export const hardwareArray = [
  {
    name: "CPU",
    current: "N/A",
    max: "N/A",
  },
  {
    name: "RAM",
    current: "N/A",
    max: "N/A",
  },
  {
    name: "HDD",
    current: "N/A",
    max: "N/A",
  },
]

export const userArray = [
  { number: "N/A", type: "Connected Users" },
  {
    number: "N/A",
    type: "Active Users",
  },
  { number: "N/A", type: "Connected GIS Users" },
]
