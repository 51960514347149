import React from "react";
import { useStore } from "react-redux";
import DropTargetBody from "../../DropTargetBody";
import "./index.scss";

function UnknownKPI({ data = {}, path = [] }) {
  const store = useStore();

  const handleCompe = (e, data, path, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: path,
        component: component,
      },
    });
  };

  return (
    <div
      className="drop-kpi-content"
      onClick={(e) => handleCompe(e, data, path, "kpi")}
    >
      <DropTargetBody
        accept={["detail", "graph"]}
        dragParent={path}
        className="drop-kpi-known"
      >
        Detail/Graph
      </DropTargetBody>
    </div>
  );
}

export default UnknownKPI;
