import { verifyAutoscripts } from "../../../../services";

function __getData(setScriptsNumber = () => {}, handleError = () => {}) {
  verifyAutoscripts()
    .then((response) => {
      if (response?.content?.status === "failure") {
        handleError(response?.content?.message);
      } else {
        setScriptsNumber(response.content?.result?.files?.length);
      }
    })
    .catch((error) => {
      handleError(error.message);
    });
}

export default __getData;
