import axios from 'axios';

export async function getMode() {
  try {
    const response = await axios.get('interceptor/getInterceptorConfig');
    return response?.data?.content?.whosEnabled;
  } catch (error) {
    console.error('getMode error:', error);
  }
}

export async function changeMode(mode) {
  try {
    const response = await axios.post(
      'interceptor/updateInterceptorConfig',
      mode
    );
    return response?.data?.content?.whosEnabled;
  } catch (error) {
    console.error('changeMode error:', error);
  }
}
