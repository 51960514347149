import { useState } from "react";
import Icon from "../../../../components/CustomIcon/Icon";
import DynamicModal from "../../../../components/CustomModal";

const ScriptListItem = ({ script }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  const statusText =
    script?.loggerStatus?.toString()?.toUpperCase() === "TRUE"
      ? "Installed"
      : "Failed to install";

  const openModal = (message, status) => {
    setCurrentMessage(message);
    setCurrentStatus(statusText.toUpperCase());
    setShowPopUp(true);
  };
  const closePopUp = () => {
    setShowPopUp(false);
  };

  return (
    <div className="Sync-step">
      <span className="Sync-step-name">
        {script.name}
        {statusText === "Failed to install" ? (
          <Icon
            icon="info1"
            size={20}
            style={{
              color: "#aaaaaa",
              marginLeft: "5px",
            }}
            onClick={() => openModal(script.message, script.status)}
          />
        ) : null}
      </span>

      <span className={`check-${statusText.toLowerCase()}`}>{statusText}</span>
      <DynamicModal
        open={showPopUp}
        onClose={closePopUp}
        modalTitle={currentStatus}
        showFooter={false}
        iconTitle={
          currentStatus === "ERROR" ? (
            <Icon icon="close1" size={27} style={{ color: "var(--Red)" }} />
          ) : (
            <Icon icon="warning" size={20} style={{ color: "var(--Orange)" }} />
          )
        }
      >
        <div className="popupContainer-script">
          <div className="title-popup-script">{currentMessage}</div>
        </div>
      </DynamicModal>
    </div>
  );
};

export default ScriptListItem;
