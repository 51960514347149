import xml2js from "minexml2js";
import handleClickedItem from "../../../services/helpers/handleClickedItem";
import transformForStore from "../../../services/helpers/transformForStore";
import addNewVersion from "../../../services/addNewVersion";

async function JsonToXML(jsonData = {}) {
  const builder = new xml2js.Builder();
  const xml = builder.buildObject(jsonData);
  const finalXML = xml.replace(/&amp;/g, "&");
  return finalXML;
}

async function XMLtoJson(XML = "") {
  try {
    const searchRegExp = new RegExp("&", "g");
    const xmlData = XML?.replace(searchRegExp, "&amp;");
    const { maxOrder, result } = await new xml2js.Parser({
      explicitArray: false,
      emptyTag: { $: { id: "mockId" } },
    }).parseStringPromise(xmlData);
    const skeleton = await transformForStore(result);

    return { skeleton, maxOrder };
  } catch (error) {
    console.error("Error parsing XML to JSON:", error);
    throw error;
  }
}

async function handlePublish(
  jsonData,
  newFileName,
  store,
  typeFile,
  addToast,
  readOnly,
  draftDisplay = true
) {
  try {
    const finalXML = await JsonToXML(jsonData);
    const { skeleton, maxOrder } = await XMLtoJson(finalXML);

    const lastVersion = await addNewVersion(
      store,
      skeleton,
      maxOrder,
      newFileName,
      typeFile,
      addToast
    );

    await handleClickedItem(
      addToast,
      store,
      {},
      lastVersion?.filename,
      // false,
      draftDisplay,
      readOnly
    );
  } catch (error) {
    addToast(error.message, "error");
    throw error;
  }
}

export default handlePublish;
