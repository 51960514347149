import { axiosProviderNode } from '../../../services/http/httpService';
import { services } from '../../Installer/services/configVariables';

async function getMaximoConfig() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_SOCKET_CONFIG;

    const res = await axiosProviderNode.get(transactionNodeService);
    return res?.data;
  } catch (error) {
    throw error;
  }
}

export default getMaximoConfig;
