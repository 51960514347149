export function getColor(label) {
  switch (label) {
    case "CPU":
      return "#9c37da"
    case "RAM":
      return "#EF7E2C"
    case "HDD":
      return "#20DEBC"
    default:
      return ""
  }
}
