function getResponseOfrequest(requestId, responsesArray) {
  let response = responsesArray.find((response) => {
    if (
      !response?.responseMetadata ||
      response?.responseMetadata?.requestId === undefined
    ) {
      return undefined;
    }
    return response?.responseMetadata?.requestId === requestId;
  });

  return response;
}

export default getResponseOfrequest;
