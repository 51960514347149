function getObjectValues(data = {}) {
  try {
    let resObjectValues = {};

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === "object") {
        resObjectValues[key] = data[key];
      }
    });

    return resObjectValues;
  } catch (error) {
    console.error(`Error in getObjectValues: ${error.message}`);
    return {};
  }
}

export default getObjectValues;
