import React from "react";
import "./index.scss";

function PrivacyPlicy() {
  const handleButtonClick = () => {
    window.open("https://maxapps-maximo.com/privacy-policy", "_blank");
  };
  return (
    <div className="privacyPolicy-container">
      <div className="title-pp">Privacy Policy</div>
      <div className="body-pp">
        This Privacy Policy is based off of the Automattic privacy policy which
        they released under Creative Commons.
      </div>
      <button className="btn-pp" onClick={handleButtonClick}>
        Read Privacy Policy
      </button>
    </div>
  );
}

export default PrivacyPlicy;
