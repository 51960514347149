import React, { useMemo } from "react";
import "./index.scss";
import Icon from "../../../../../../../../../components/CustomIcon/Icon";

function IconCard({
  size = "small",
  label = "Add label",
  data = {},
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  const randomNumber = useMemo(() => {
    return Math.floor(Math.random() * (100 - 10 + 1)) + 10;
  }, []);

  return (
    <div
      className={`iconcard ${
        size?.toLowerCase() !== "small" && "iconcard-large"
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className="iconcard-container">
        <div className="iconcard-icon">
          <Icon
            icon={data?.icon ?? "notoper"}
            size={size?.toLowerCase() === "small" ? 18 : 40}
            color={data?.iconColor ?? "black"}
          />
        </div>
        <div className="iconcard-value">{randomNumber}</div>
        <div className="iconcard-label">{label}</div>
      </div>
    </div>
  );
}

export default IconCard;
