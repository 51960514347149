import React, { useContext, useEffect, useState } from "react";
import { useStore } from "react-redux";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import Modal from "../Modal";
import Form from "../Form";
import Event from "../Event";
import imxtools from "imxtools";
import DropTargetBody from "../DropTargetBody";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../services/AppContext";
import { isSelectedComponent } from "../../services/isSelectedComponent";

function Swipe({
  data = {},
  path = [],
  handleOpenActions = () => {},
  readOnly = true,
  forms = [],
  numForms = 0,
  hformsData = [],
  formsData = [],
  currentPath = [],
}) {
  const { targetRefEvent } = useContext(AppContext);

  const [openForm, setOpenForm] = useState(false);
  const [targetForm, setTargetForm] = useState("");
  const [targetFormIndex, setTargetFormIndex] = useState(null);
  let store = useStore();
  const [formHform, setFormHform] = useState(null);

  const { setIsDragingIn } = useContext(AppContext);
  const [dataAttributes, setDataAttributes] = useState([]);

  const getTargetFormIndex = (targetref) => {
    try {
      return forms?.findIndex(
        (form) => form?.reference?.toLowerCase() === targetref?.toLowerCase()
      );
    } catch (error) {
      console.error("Error in getTargetFormIndex:", error);
    }
  };
  useEffect(() => {
    let foundIndex = -1;
    let foundIndexHform = -1;
    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndex = index;
        return true;
      }
      return false;
    });
    const foundHform = hformsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndexHform = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
      setFormHform("form");
    } else if (foundHform) {
      setTargetForm(foundHform);
      setTargetFormIndex(foundIndexHform);
      setFormHform("hform");
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
  }, [data, forms, targetForm, formHform, formsData, hformsData]);

  useEffect(() => {
    setDataAttributes(imxtools.toArray(data?.event));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.event]);

  return (
    <>
      <div
        className="swipe-container"
        onClick={(e) =>
          handleComponentProp(e, null, data, path, store, "swipe")
        }
      >
        <div
          className="close-button"
          onClick={(event) => {
            handleOpenActions(event, false);
          }}
        >
          <Icon icon="times2" size={15} className="timesIcon" />
        </div>
        {data?.event && imxtools.toArray(data?.event)?.length > 0 ? (
          <div className="swipe">
            {data?.label && (
              <div className="swipe-title">
                <div className="swipe-label">{data?.label}</div>
                {data?.name && <div className="swipe-name">N/A</div>}
              </div>
            )}
            <DropTargetBody
              className={`drop-swipe-zone ${
                isSelectedComponent(
                  readOnly,
                  [...path, "swipe"],
                  currentPath
                ) && "selectedComponent"
              }`}
              accept={"event"}
              dragParent={[...path, "swipe"]}
            >
              {imxtools.toArray(data?.event)?.map((event, index) => {
                const numev = imxtools.toArray(data?.event)?.length;
                return (
                  <React.Fragment key={index}>
                    <DragItemAttribute
                      index={index}
                      component={"event"}
                      className={"drag-att-zone"}
                      dragParentPath={[...path, "swipe"]}
                      dragData={event}
                      dataAttributes={dataAttributes}
                      setDataAttributes={setDataAttributes}
                      setIsDraging={setIsDragingIn}
                    >
                      <Event
                        key={index}
                        data={event}
                        path={[...path, "swipe"]}
                        index={numev === 1 ? null : index.toString()}
                        readOnly={readOnly}
                        setTargetForm={setTargetForm}
                        setOpenForm={setOpenForm}
                        forms={forms}
                        indexForm={getTargetFormIndex(event?.targetref)}
                        currentPath={currentPath}
                      />
                    </DragItemAttribute>
                  </React.Fragment>
                );
              })}
            </DropTargetBody>
          </div>
        ) : (
          <DropTargetBody
            accept={"event"}
            className="swipe"
            dragParent={[...path, "swipe"]}
          >
            no event
          </DropTargetBody>
        )}
      </div>
      <Modal openModal={openForm} isForm={true}>
        <Form
          data={targetForm}
          path={
            formHform === "form"
              ? formsData.length === 1
                ? [...path, formHform]
                : [...path, formHform, targetFormIndex]
              : hformsData.length === 1
              ? [...path, formHform]
              : [...path, formHform, targetFormIndex]
          }
          index={
            forms <= 1
              ? null
              : targetForm > numForms - 1
              ? (targetForm - numForms)?.toString()
              : targetForm?.toString()
          }
          parentPath={path}
          setOpenForm={setOpenForm}
          component={formHform}
          readOnly={readOnly}
          hformsData={hformsData}
          formsData={formsData}
        />
      </Modal>
    </>
  );
}

export default Swipe;
