import { scriptInstallation } from "../../../../services";
import clearAll from "./clearAll";

async function checkInstallerTools(
  store,
  response,
  _isMounted,
  clearDb,
  toclearMaximo,
  checkVersions,
  setFailedToClear = () => {},
  setToClear = () => {},
  setResponse = () => {},
  setErrorMessage = () => {},
  setShowPopUpOptions = () => {},
  setFinish = () => {},
  setDisconnectMessage = () => {}
) {
  try {
    setFailedToClear(false);
    setToClear(true);

    const res = await scriptInstallation(response?.content?.script, true);

    if (
      res?.success &&
      res?.content?.status?.toString()?.toLowerCase() !== "failure"
    ) {
      clearAll(
        store,
        _isMounted,
        clearDb,
        toclearMaximo,
        checkVersions,
        setShowPopUpOptions,
        setFailedToClear,
        setToClear,
        setDisconnectMessage,
        setFinish,
        setErrorMessage,
        setResponse
      );
    } else {
      const errorMessage =
        res?.content?.message === "" ? res?.message : res?.content?.message;
      setToClear(false);
      setResponse(res);
      setErrorMessage(errorMessage);
      setFailedToClear(true);
    }
  } catch (error) {
    setToClear(false);
    setErrorMessage(error.message);
    setFailedToClear(true);
  }
}

export default checkInstallerTools;
