export const Maxapps = {
  themeName: "Maxapps",
  isDark: false,
  CardViewBgColor: "#f1eef6bb",
  secondaryLight: "#EFBF0080",
  primary: "#6f53a3",
  iconAppItem: "#6f53a3",
  AppText: "#333",
  loginFooter: "#000",
  connectBtn: "#6f53a3",
  loader: "#6f53a3",
  headerColor: "#6f53a3",
  primaryLight: "#AA73E150",
  primaryColor: "#aa73e1b3",
  textColor: "#000",
  listBg: "#ffffff",
  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",
  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#EFBF00",
  focusedBorderLoginInput: "#6f53a3",
  focusedLabelLoginInput: "#6f53a3",
  resetInputLogin: "#6f53a3",
  languageIcon: "#6f53a3",
  containerLanguageIcon: "#ffffff",
  settingCollapseColor: "#eee",
  welcomeBg: "#e5e2ec",
  success: "#3ecd7e",
  primaryDark: "#341761",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#998ab6",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#000",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#6F53A3",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#e5e2ec",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#6f53a3",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",
  borderRecord: "#AA73E150",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#e5e2ec",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#e5e2ec",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#6f53a3",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#EFBF0033",
  bnConnect: "#6f53a3",
  notfocusedBorderLoginInput: "#f5f5f5f5",
  headerPrimary: "#6f53a300",
  headerDark: "#34176100",
  headerText: "#333",
  notificationUpColor: "#F9F8FC",
  notificationDownColor: "#F1EEF6",
  listItemNotificationUpColor: "#F7F5FA",
  listItemNotificationDownColor: "#EBE7F3",
  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F2F1F4",

  notFocusedSocialMedia: "#6f53a3",
  focusedSocialMedia: "#6f53a3cc",
  loginBgFooter: "#fff",

  // //Costum4
  loginFirstTabTitle: "#222",
  loginTabTitle: "#222",
  loginTabSelected: "#fff",
  loginTabNotSelected: "#ffffff4d",

  //Custom Language Modal
  languageContainerLeft: "#fff",
  languageContainerRight: "#6f53a3",
  submitLeftConnect: "#222",
  submitRightConnect: "#fff",
};
