import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import themeColors from "./themeColors";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [localThemeMode, setLocalThemeMode] = useState(null);
  const store = useStore();
  let themeMode = useSelector((state) => state?.entities?.settings?.mode);

  const handleToggleTheme = () => {
    try {
      store.dispatch({
        type: "settings/toggleTheme",
      });
    } catch (error) {
      console.error("Error toggling theme:", error);
    }
  };

  useEffect(() => {
    setLocalThemeMode(themeMode);
  }, [themeMode]);

  useEffect(() => {
    const root = document.documentElement;
    const currentThemeColors = themeColors[localThemeMode ?? "light"];
    Object.keys(currentThemeColors).forEach((key) => {
      root.style.setProperty(`--${key}`, currentThemeColors[key]);
    });
  }, [localThemeMode]);

  const themeContextValue = {
    themeMode: localThemeMode ?? "light",
    toggleTheme: handleToggleTheme,
    themeColors,
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
