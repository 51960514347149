import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import "../index.scss";

import { ApiKeyFields, ApiKeyInitialValues, ApiKeyLoginSchema } from "../data";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ConnectButton } from "../../../components/button";

import {
  handleInput,
  handleReset,
  validateUrl,
  serverConfig,
  submitForm,
} from "../services/index";

import Icon from "../../../../../components/CustomIcon/Icon";
import Lottie from "react-lottie";
import { LoadingWhiteOptions } from "../../components/data";

function ApiKeyLogin() {
  const store = useStore();
  const method = "standard";
  const [loginMessage, setLoginMessage] = useState("");
  const [errorOslcUrl, setErrorOslcUrl] = useState(false);
  const [errorMaxRestUrl, setErrorMaxRestUrl] = useState(false);
  const [oslcStatus, setOslcStatus] = useState(undefined);
  const [condition, setCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [protocol, setProtocol] = useState("");
  useEffect(() => {
    serverConfig({ setAddress, setProtocol });
  }, []);

  return (
    <div className="form-group">
      <Formik
        initialValues={ApiKeyInitialValues}
        validationSchema={ApiKeyLoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          setErrorOslcUrl(!validateUrl(values?.oslcUrl));
          submitForm(
            values,
            condition,
            method,
            protocol,
            address,
            store,
            setSubmitting,
            setLoginMessage,
            setOslcStatus,
            setIsLoading,
            setCondition,
            setErrorOslcUrl
          );
        }}
      >
        {({
          values,
          touched,
          errors,
          resetForm,
          setFieldValue,
          submitForm,
        }) => (
          <Form className="installer-form">
            {ApiKeyFields.map((field, index) => (
              <React.Fragment key={index}>
                <div className="inputGroup">
                  <Field
                    name={field?.name}
                    type={"text"}
                    value={values[field?.name]}
                    onInput={(e) => {
                      setCondition(false);
                      handleInput(e, setFieldValue, field);
                    }}
                    placeholder=" "
                    className={`Simpleinput ${
                      touched[field?.name] && errors[field?.name]
                        ? "error-input"
                        : ""
                    }`}
                  />

                  <label>{field?.label}</label>
                </div>
                <ErrorMessage
                  component="div"
                  name={field?.name}
                  className="invalid-feedback"
                />
              </React.Fragment>
            ))}
            {/* {condition && ( */}
            <div className="messagesContainer">
              {loginMessage && (
                <div className="verifiedUrlContainer">
                  <span className="show-login-message">
                    <Icon
                      icon="info1"
                      size={14}
                      style={{ color: "var(--Red)", marginRight: "5px" }}
                    />
                    {loginMessage}
                  </span>
                </div>
              )}

              {(errorOslcUrl || condition) && (
                <>
                  <div className="verifiedUrlContainer">
                    <span
                      className={
                        errorOslcUrl ? "show-login-message" : "show-url"
                      }
                    >
                      {values?.oslcUrl}
                    </span>

                    <div className="icon-url">
                      <Icon
                        icon={errorOslcUrl ? "nomark" : "check"}
                        size={14}
                        style={{
                          color: errorOslcUrl ? "var(--Red)" : "var(--Green)",
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* )} */}
            <div className="login-footer">
              {/* {(errorOslcUrl || errorMaxRestUrl) && ( */}
              {errorOslcUrl && (
                <Icon
                  icon="Reboot"
                  size={28}
                  className="login-cached-icon"
                  onClick={() =>
                    handleReset(resetForm, setCondition, setLoginMessage)
                  }
                ></Icon>
              )}
              <ConnectButton
                type={"submit"}
                style={{
                  height: "44px",
                }}
                label={
                  isLoading ? (
                    <Lottie
                      options={LoadingWhiteOptions}
                      height={28}
                      width={60}
                      isClickToPauseDisabled={true}
                      style={{
                        cursor: "auto",
                        paddingBottom: "2px",
                      }}
                    />
                  ) : !condition ? (
                    "Test URLs"
                  ) : (
                    "Connect"
                  )
                }
                isActive={{
                  toLoad: isLoading,
                  disable: isLoading || errorOslcUrl || errorMaxRestUrl,
                }}
                handleEvent={submitForm}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default ApiKeyLogin;
