// File: fileSizeFormatter.js
export function formatFileSize(sizeInBytes) {
  const KB = 1024;
  const MB = KB * 1024;

  if (sizeInBytes >= MB) {
    const sizeInMB = sizeInBytes / MB;
    return sizeInMB >= 100
      ? `${Math.round(sizeInMB)} MB`
      : `${sizeInMB.toFixed(2)} MB`;
  } else if (sizeInBytes >= KB) {
    const sizeInKB = sizeInBytes / KB;
    return sizeInKB >= 100
      ? `${Math.round(sizeInKB)} KB`
      : `${sizeInKB.toFixed(2)} KB`;
  } else {
    return `${sizeInBytes} bytes`;
  }
}
