import { ComponentsLibraries } from "../../../services/common";

function handlePasteComponent(
  componentEdited = "",
  parent = [],
  copiedCompType,
  pasteComponent
) {
  try {
    if (!componentEdited) {
      console.error("No component specified for editing.");
      return;
    }

    const componentLibrary = ComponentsLibraries[componentEdited];
    if (!componentLibrary) {
      console.error(`No component library found for: ${componentEdited}`);
      return;
    }

    if (copiedCompType in componentLibrary) {
      pasteComponent(parent);
    } else {
      console.error(`Component type ${copiedCompType} not found in library.`);
    }
  } catch (error) {
    console.error(`Error in handlePasteComponent: ${error.message}`);
  }
}

export default handlePasteComponent;
