import { getMaximoConfig } from "../../../../services";

function __getMaxappsHost(setMaxappsHost = () => {}) {
  getMaximoConfig()
    .then((response) => {
      setMaxappsHost(response?.content?.dashboardUser?.maxappsHost);
    })
    .catch((error) => {
      console.error("Error in __getMaxappsHost:", error);
    });
}

export default __getMaxappsHost;
