import React from "react";
import { Chart } from "react-google-charts"; // don't remove this !!
import { dataPie } from "../../../../../../../../../utils/chartFakeData";
import { optionsPie } from "../../../../../../../../../utils/chartOptions";
import "./index.scss";

function SimplePie({
  size = "small",
  label = "Add label",
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  return (
    <div
      className={`simplePie-graph ${
        size?.toLowerCase() !== "small" && "simplePie-large"
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className="simplePie-graph-label">{label}</div>
      <div className="simplePie-graph-chart">
        <Chart
          chartType="PieChart"
          data={dataPie}
          options={optionsPie}
          width={"99%"}
          height={"99%"}
        />
      </div>
    </div>
  );
}

export default SimplePie;
