export const ComponentsLibraries = {
  maxapps: {
    page: { title: "Page", icon: "Page" },
    gis: { title: "GIS", icon: "GIS" },
    notifications: { title: "Notification", icon: "Notification" },
    header: { title: "Header", icon: "headerComp" },
    profile: { title: "Profile", icon: "Profile" },
    contact: { title: "Contact", icon: "Contact" },
  },
  profile: {
    changepass: { title: "Changepass", icon: "changepass" },
    body: { title: "Body", icon: "Body" },
    form: { title: "Form", icon: "Form" },
  },
  contact: {
    line: { title: "Line", icon: "Line" },
  },
  media: {
    gallery: { title: "Gallery", icon: "gallery" },
    audio: { title: "Audio", icon: "audio" },
    video: { title: "Video", icon: "video" },
    image: { title: "Image", icon: "camera" },
    file: { title: "File", icon: "file" },
  },

  notifications: {
    notification: { title: "Notification", icon: "Notification" },
  },
  notification: {
    message: { title: "Message", icon: "Message" },
  },
  message: {
    title: { title: "Title", icon: "title" },
    body: { title: "Body", icon: "Body" },
  },

  script: {
    function: { title: "Function", icon: "Function" },
  },

  conditions: {
    condition: { title: "Conditions", icon: "Conditions" },
  },
  condition: {
    property: { title: "Property", icon: "Property" },
  },

  lookups: {
    lookup: { title: "Lookup", icon: "Lookup" },
  },
  lookup: {
    whereclause: { title: "Whereclause", icon: "Whereclause" },
    body: { title: "Body", icon: "Body" },
  },

  body: {
    attribute: { title: "Attribute", icon: "Attribute" },
    header: { title: "header", icon: "header" },
    footer: { title: "footer", icon: "footer" },
    thumb: { title: "thumb", icon: "thumb" },
  },
  header: {
    tag: { title: "Tag", icon: "tag" },
    media: { title: "media", icon: "media" },
    takegeoposition: { title: "takegeoposition", icon: "takegeoposition" },
    event: { title: "event", icon: "Event" },
  },
  footer: {
    tag: { title: "Tag", icon: "tag" },
  },

  page: {
    app: { title: "Application", icon: "Application" },
    kpi: { title: "KPI", icon: "kpi" },
    module: { title: "Module", icon: "Module" },
    row: { title: "Row", icon: "row" },
  },

  row: {
    app: { title: "Application", icon: "Application" },
    kpi: { title: "KPI", icon: "kpi" },
  },

  app: {
    list: { title: "List", icon: "list" },
    event: { title: "Event", icon: "Event" },
    form: { title: "Form", icon: "Form" },
  },

  module: {
    app: { title: "App", icon: "Application" },
  },

  list: {
    whereclause: { title: "Whereclause", icon: "Whereclause" },
    maximomedia: { title: "maximomedia", icon: "maximomedia" },
    calendar: { title: "Calendar", icon: "Calendar" },
    map: { title: "Map", icon: "Map" },
    toolbar: { title: "Toolbar", icon: "Toolbar" },
    fab: { title: "Fab", icon: "Fab" },
    swipe: { title: "Swipe", icon: "Swipe" },
    hform: { title: "Hform", icon: "Hform" },
    form: { title: "Form", icon: "Form" },
    body: { title: "Body", icon: "Body" },
    detail: { title: "Detail", icon: "Detail-comp" },
    marker: { title: "Marker", icon: "Marker" },
    inspection: { title: "inspection", icon: "inspection" },
    path: { title: "path", icon: "path" },
    header: { title: "header", icon: "headerComp" },
  },
  inspection: {
    total: { title: "total", icon: "total" },
    Required: { title: "Required", icon: "required" },
    optionalQuestions: {
      title: "optionalQuestions",
      icon: "optionalQuestions",
    },
    filters: { title: "filters", icon: "filter" },
    body: { title: "body", icon: "Body" },
    attachment: {
      title: "attachment",
      icon: "attachment",
      event: [
        {
          title: "event",

          itemOrder: 375,
          service: "UPLOADDOCLINK",
          name: "UPLOAD",
          id: "imxComponent_0_1708335286250_0.5321106271447846",
        },
        {
          title: "event",

          itemOrder: 376,
          service: "DELETEDOCLINK",
          name: "DELETE",
          id: "imxComponent_1_1708335286250_0.3784518660136307",
        },
      ],
    },
    toolbar: { title: "toolbar", icon: "Toolbar" },
    question: { title: "question", icon: "Question" },
  },
  question: {
    placeholder: { title: "placeholder", icon: "placeholder" },
  },
  calendar: {
    attribute: { title: "Attribute", icon: "Attribute" },
  },

  map: {
    pullup: { title: "Pullup", icon: "Pullup" },
    marker: { title: "Marker", icon: "Marker" },
  },

  marker: {
    attribute: { title: "Attribute", icon: "Attribute" },
    //for gis
    body: { title: "Body", icon: "Body" },
    pin: { title: "Pin", icon: "Pin" },
    detail: { title: "Detail", icon: "Detail-comp" },
    pullup: { title: "Pullup", icon: "Pullup" },
  },

  pullup: {
    attribute: { title: "Attribute", icon: "Attribute" },
    body: { title: "Body", icon: "Body" },
    toolbar: { title: "toolbar", icon: "Toolbar" },
    swipe: { title: "swipe", icon: "Swipe" },
    detail: { title: "Detail", icon: "Detail-comp" },
  },

  toolbar: {
    event: { title: "Event", icon: "Event" },
  },

  fab: {
    event: { title: "Event", icon: "Event" },
  },

  swipe: {
    event: { title: "Event", icon: "Event" },
  },

  form: {
    field: { title: "TextField", icon: "TextField" },
    header: { title: "header", icon: "headerComp" },
    geomodule: { title: "geomodule", icon: "geomodule" },
    title: { title: "title", icon: "title" },
  },

  hform: {
    field: { title: "TextField", icon: "TextField" },
    header: { title: "header", icon: "headerComp" },
  },

  detail: {
    tab: { title: "Tab", icon: "Tab" },
    section: { title: "Section", icon: "Section" },
    list: { title: "list", icon: "list" },
    event: { title: "event", icon: "Event" },
  },

  kpi: {
    detail: { title: "Detail", icon: "Detail-comp" },
    graph: { title: "Graph", icon: "graph" },
  },

  graph: {
    list: { title: "List", icon: "list" },
  },

  tab: {
    list: { title: "List", icon: "list" },
    toolbar: { title: "Toolbar", icon: "Toolbar" },
    section: { title: "Section", icon: "Section" },
    carroussel: { title: "Caroussel", icon: "Caroussel" },
    timer: { title: "Timer", icon: "Timer" },
    geopositioning: { title: "geopositioning", icon: "Geoposition" },
    hform: { title: "Hform", icon: "Hform" },
    form: { title: "Form", icon: "Form" },
    fab: { title: "Fab", icon: "Fab" },
    signature: { title: "Signature", icon: "signature" },
  },

  timer: {
    attribute: { title: "Attribute", icon: "Attribute" },
  },

  section: {
    attribute: { title: "Attribute", icon: "Attribute" },
  },

  gis: {
    labortracking: { title: "LaborTracking", icon: "LaborTracking" },
    list: { title: "List", icon: "list" },
  },

  labortracking: {
    marker: { title: "Marker", icon: "Marker" },
  },
  path: {
    attribute: { title: "Attribute", icon: "Attribute" },
  },
  pin: {
    attribute: { title: "Attribute", icon: "Attribute" },
  },
};
export const TagsColors = {
  advanced: "#dcfcf1",
  simple: "#ececec",
  lookups: "#f0ffd5",
  app: "#f6f4dc",
  module: "#ffefef",
  gis: "#fcdcfa",
  list: "#feeaf3",
  map: "#fcdcfa",
  swipe: "#f0ffd5",
  whereclause: "#d5effe",
  body: "#ececec",
  calendar: "#d5effe",
  form: "#feeaf3",
  toolbar: "#dcfcf1",
  fab: "#f0ffd5",
  event: "#f6f4dc",
  attribute: "#dcfcf1",
  pullup: "#ececec",
  marker: "#dcfcf1",
  pin: "#feeaf3",
  field: "#f0ffd5",
  hform: "#f0ffd5",
  detail: "#ececec",
  tab: "#dcfcf1",
  section: "#dcfcf1",
  carroussel: "#f0ffd5",
  function: "#fcdcfa",
  geopositioning: "#f0ffd5",
  labortracking: "#dcfcf1",
  property: "#dcdcf1",
  timer: "#f6f4dc",
  lookup: "#fcdcfa",
  condition: "#f0ffd5",
  pending: "#fcdcfa",
  resolved: "#dcfcf1",
  status: "#ececec",
};

export const colors = {
  profile: "#E34F73",
  contact: "#F2A699",
  page: "#B7CA4A",
  gis: "#9248B5",
  notifications: "#F7CC01",
  lookups: "#FF834F",
  script: "#3C57A4",
  conditions: "#C83E12",
  app: "#1A8A5C",
  module: "#33BEBB",
  list: "#5EA992",
  home: "#BB96FF",
  whereclause: "#53A6C4",
  swipe: "#53A6C4",
  fab: "#53A6C4",
  toolbar: "#53A6C4",
  body: "#53A6C4",
  detail: "#53A6C4",
  form: "#53A6C4",
  hform: "#53A6C4",
  labortracking: "#53A6C4",

  advanced: "#dcfcf1",
  simple: "#ececec",
  map: "#fcdcfa",
  calendar: "#d5effe",
  event: "#f6f4dc",
  attribute: "#dcfcf1",
  pullup: "#ececec",
  marker: "#dcfcf1",
  pin: "#feeaf3",
  field: "#f0ffd5",
  tab: "#dcfcf1",
  section: "#dcfcf1",
  carroussel: "#f0ffd5",
  function: "#fcdcfa",
  geopositioning: "#f0ffd5",
  property: "#dcdcf1",
  timer: "#f6f4dc",
  lookup: "#fcdcfa",
  condition: "#f0ffd5",
  pending: "#fcdcfa",
  resolved: "#dcfcf1",
  status: "#ececec",
};
