import "./index.scss";

const QuestionInspectionOld = ({
  setViewContent = () => {},
  viewContent,
  listQuestions,
  questionToDisplay,
  setQuestionToDisplay = () => {},
  setIsOpen = () => {},
  isOpen,
  closeModal,
}) => {
  return (
    <div
      className="question-inspec-old-container"
      onClick={() => setIsOpen(false)}
    >
      <div className="question-inspec-old-header">
        <text style={{ color: "#6A549E", fontSize: 14, fontWeight: 700 }}>
          AMC Audit Cheklist.
        </text>
        <div className="btn-x-container" onClick={() => closeModal()}>
          <i className="bx bx-x" style={{ color: "#fff" }}></i>
        </div>
      </div>
      <div className="question-inspec-old-content">
        <div className="question-old-container">
          <text className="text-question">2.1</text>
          <text className="text-question">Fire and Emergency Preparedness</text>
        </div>
        <div className="input-check-container">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              checked={true}
              type="radio"
              className="input-check"
              id="customRadio"
            />
            <label htmlFor="customRadio" className="custom-label"></label>
            <text style={{ marginLeft: 10 }}>Fail</text>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              checked={false}
              type="radio"
              className="input-check"
              id="customRadio"
            />
            <label htmlFor="customRadio" className="custom-label"></label>
            <text style={{ marginLeft: 10 }}>Pass</text>
          </div>
        </div>
        <div className="question-old-container">
          <text className="text-question">2.2</text>
          <text className="text-question">Fire and Emergency Preparedness</text>
        </div>
        <div className="input-check-container">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              checked={true}
              type="radio"
              className="input-check"
              id="customRadio"
            />
            <label htmlFor="customRadio" className="custom-label"></label>
            <text style={{ marginLeft: 10 }}>Fail</text>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              checked={false}
              type="radio"
              className="input-check"
              id="customRadio"
            />
            <label htmlFor="customRadio" className="custom-label"></label>
            <text style={{ marginLeft: 10 }}>Pass</text>
          </div>
        </div>
      </div>
      <div className="question-inspec-old-feet">
        <div className="btn-old-container">
          <button className="btn-back-old">
            <i className="bx bx-chevron-left" style={{ fontWeight: "700" }}></i>
            {!isOpen ? "Back" : ""}
          </button>
          <button className="btn-next-old">
            {!isOpen ? "Next" : ""}
            <i
              className="bx bx-chevron-right"
              style={{ fontWeight: "700" }}
            ></i>
          </button>
        </div>
        <div className="btn-old-complete-save-container">
          <div className="btn-save">
            <i
              className="bx bx-save"
              style={{ color: "#6A549E", fontSize: 20 }}
            ></i>
          </div>
          <button className="btn-complete">Complete</button>
        </div>
      </div>
    </div>
  );
};
export default QuestionInspectionOld;
