function arrayToObject(data = [], addToast = () => {}) {
  try {
    const obj = {};
    const seenKeys = {};
    for (const item of data) {
      if (!item?.key) {
        // throw new Error("Empty property found");
      }

      if (seenKeys[item?.key]) {
        addToast("Duplicate property found", "warn");
        return null;
      }

      obj[item?.key] = item?.value;
      seenKeys[item?.key] = true;
    }

    return obj;
  } catch (error) {
    addToast(`Error: ${error?.message}`, "warn");
    return null;
  }
}

export default arrayToObject;
