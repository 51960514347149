import "./index.scss"
import React, { useState, useEffect } from "react"
import ImxLoggerAction from "./components/IMXLoggerAction/index"
import useLoggerActionHandler from "./services/useLoggerActionHandler.js"
import { useTheme } from "../../../../../theme/themeContext"

const IMXLoggerLeft = ({ getenvconfValues, envConfData = {} }) => {
  const { themeMode, themeColors } = useTheme()
  const [disableSwitch, setDisableSwitch] = useState(false)
  const handleSetDisableAll = useLoggerActionHandler(
    "NODE_ENDPOINT_SET_DISABLE_ALL_IMXLOGGER",
    getenvconfValues
  )
  const handleUnsetDisableAll = useLoggerActionHandler(
    "NODE_ENDPOINT_UNSET_DISABLE_ALL_IMXLOGGER",
    getenvconfValues
  )
  const handleSetLogOnly = useLoggerActionHandler(
    "NODE_ENDPOINT_SET_LOG_ONLY_IMXLOGGER",
    getenvconfValues
  )
  const handleUnsetLogOnly = useLoggerActionHandler(
    "NODE_ENDPOINT_UNSET_LOG_ONLY_IMXLOGGER",
    getenvconfValues
  )
  const handleEnableLogs = useLoggerActionHandler(
    "NODE_ENDPOINT_ENABLE_LOGS_IMXLOGGER",
    getenvconfValues
  )
  const handleDisableLogs = useLoggerActionHandler(
    "NODE_ENDPOINT_DISABLE_LOGS_IMXLOGGER",
    getenvconfValues
  )
  const handleEnableErrorLogs = useLoggerActionHandler(
    "NODE_ENDPOINT_ENABLE_ERRORS_LOGS_IMXLOGGER",
    getenvconfValues
  )
  const handleDisableErrorLogs = useLoggerActionHandler(
    "NODE_ENDPOINT_DISABLE_ERRORS_LOGS_IMXLOGGER",
    getenvconfValues
  )
  const handleEnableDebugLogs = useLoggerActionHandler(
    "NODE_ENDPOINT_ENABLE_DEBUG_LOGS_IMXLOGGER",
    getenvconfValues
  )
  const handleDisableDebugLogs = useLoggerActionHandler(
    "NODE_ENDPOINT_DISABLE_DEBUG_LOGS_IMXLOGGER",
    getenvconfValues
  )
  const handleSetLocalLogging = useLoggerActionHandler(
    "NODE_ENDPOINT_SET_LOCAL_LOGGING_IMXLOGGER",
    getenvconfValues
  )
  const handleUnsetLocalLogging = useLoggerActionHandler(
    "NODE_ENDPOINT_UNSET_LOCAL_LOGGING_IMXLOGGER",
    getenvconfValues
  )
  const handleSetErrorLocalLogging = useLoggerActionHandler(
    "NODE_ENDPOINT_SET_ERROR_LOCAL_LOGGING_IMXLOGGER",
    getenvconfValues
  )
  const handleUnsetErrorLocalLogging = useLoggerActionHandler(
    "NODE_ENDPOINT_UNSET_ERROR_LOCAL_LOGGING_IMXLOGGER",
    getenvconfValues
  )
  const handleSetDebugLocalLogging = useLoggerActionHandler(
    "NODE_ENDPOINT_SET_DEBUG_LOCAL_LOGGING_IMXLOGGER",
    getenvconfValues
  )
  const handleUnsetDebugLocalLogging = useLoggerActionHandler(
    "NODE_ENDPOINT_UNSET_DEBUG_LOCAL_LOGGING_IMXLOGGER",
    getenvconfValues
  )

  useEffect(() => {
    setDisableSwitch(envConfData?.IMX_LOGGER_DISABLE_ALL)
  }, [envConfData])

  return (
    <div className='IMXLogger-main-left'>
      <h3 className='IMXLogger-main-title'>
        Actions that doesn't require reconnection:
      </h3>
      <div className='IMXLogger-Action-container'>
        <ImxLoggerAction
          label={"Set/Unset Disable ALL"}
          backgroundColor={themeColors[themeMode].monitorSwitchBackground}
          actionFunctions={{
            enable: handleSetDisableAll,
            disable: handleUnsetDisableAll,
          }}
          envConfData={envConfData?.IMX_LOGGER_DISABLE_ALL}
          borderRadius={2}
        />
        <ImxLoggerAction
          label={"Enable/Disable Log Only"}
          actionFunctions={{
            enable: handleSetLogOnly,
            disable: handleUnsetLogOnly,
          }}
          envConfData={envConfData?.IMX_LOGGER_LOG_ONLY}
          borderRadius={2}
          border={true}
          borderColor={themeColors[themeMode].monitorInputBorder}
          disableSwitch={disableSwitch}
        />
        <div className='IMXLogger-Action-flex-1'>
          <ImxLoggerAction
            label={"Enable/Disable ERRORS/DEBUG Logs"}
            paddingBottom={0.625}
            paddingLeft={0}
            paddingRight={0}
            actionFunctions={{
              enable: handleEnableLogs,
              disable: handleDisableLogs,
            }}
            borderBottomColor={themeColors[themeMode].monitorBorder}
            disableSwitch={disableSwitch}
          />
          <ImxLoggerAction
            label={"Enable/Disable DEBUG Logs"}
            paddingLeft={1}
            paddingRight={0}
            actionFunctions={{
              enable: handleEnableDebugLogs,
              disable: handleDisableDebugLogs,
            }}
            envConfData={envConfData?.MXLOGGER_ENABLE_DEBUG}
            disableSwitch={disableSwitch}
          />
          <ImxLoggerAction
            label={"Enable/Disable ERRORS Logs"}
            paddingLeft={1}
            paddingRight={0}
            actionFunctions={{
              enable: handleEnableErrorLogs,
              disable: handleDisableErrorLogs,
            }}
            envConfData={envConfData?.MXLOGGER_ENABLE_ERRORS}
            disableSwitch={disableSwitch}
          />
        </div>
        <ImxLoggerAction
          label={"Enable/Disable Reconnect (40000 ms)"}
          borderRadius={2}
          border={true}
          borderColor={themeColors[themeMode].monitorInputBorder}
        />
        <div className='IMXLogger-Action-flex-2'>
          <ImxLoggerAction
            label={"Enable/Disable Local Logging"}
            paddingBottom={0.625}
            paddingLeft={0}
            paddingRight={0}
            borderBottomColor={themeColors[themeMode].monitorBorder}
            actionFunctions={{
              enable: handleSetLocalLogging,
              disable: handleUnsetLocalLogging,
            }}
            envConfData={envConfData?.IMXLOGGER_ENABLE_LOCALLOGS}
          />
          <ImxLoggerAction
            label={"Enable/Disable DEBUG Logging in Local"}
            paddingLeft={1}
            paddingRight={0}
            actionFunctions={{
              enable: handleSetDebugLocalLogging,
              disable: handleUnsetDebugLocalLogging,
            }}
            envConfData={envConfData?.IMXLOGGER_ENABLE_DEBUG_LOCAL}
          />
          <ImxLoggerAction
            label={"Enable/Disable Error Logging in Local"}
            paddingLeft={1}
            paddingRight={0}
            actionFunctions={{
              enable: handleSetErrorLocalLogging,
              disable: handleUnsetErrorLocalLogging,
            }}
            envConfData={envConfData?.IMXLOGGER_ENABLE_ERRORS_LOCAL}
          />
        </div>
      </div>
    </div>
  )
}

export default IMXLoggerLeft
