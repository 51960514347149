import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "./index.scss";
import imxtools from "imxtools";
import Attribute from "../../../../Attribute";
import DragItemAttribute from "../../../../../../../ComponentsLibrary/DragItemAttribute";
import { useSelector, useStore } from "react-redux";
import { AppContext } from "../../../../../../../../services/AppContext";
import DropTargetBody from "../../../../DropTargetBody";
import { handleComponentProp } from "../../../../../services/handleComponentProperties";
const fakeDataInit = [
  {
    title: "Meeting 1",
    subTitle: "Team discussion",
    description: "Discuss project updates",
    scheduledStart: "--",
    reportedDate: "05/11/2023 01:00",
  },
  {
    title: "Event 2",
    subTitle: "Workshop",
    description: "Interactive workshop",
    scheduledStart: "--",
    reportedDate: "05/11/2023 01:00",
  },
  {
    title: "Task 3",
    subTitle: "Coding task",
    description: "Complete coding",
    scheduledStart: "--",
    reportedDate: "05/11/2023 01:00",
  },
];
function CalendarView(props) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isOpenPullup, setIsOpenPullup] = useState(false);
  const [listDaysInHeader, setListaysInHeader] = useState([]);
  const [fakeData, setFakeData] = useState(fakeDataInit);
  const [dataAttributes, setDataAttributes] = useState([]);
  const { setIsDragingIn } = useContext(AppContext);
  const store = useStore();

  const eventDates = [new Date(2023, 11, 5), new Date(2023, 11, 15)];
  function getNext30Days() {
    const today = new Date();
    const daysArray = [];

    for (let i = 0; i < 30; i++) {
      const date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i
      );
      const dayName = date.toLocaleString("en-US", { weekday: "long" });
      daysArray.push({ dayName, dayNumber: date.getDate() });
    }
    setListaysInHeader(daysArray);

    return daysArray;
  }

  const handleDayClick = (date) => {
    if (date.getDate() !== 5) {
      getNext30DaysFromCalendar(date);
      setIsOpenPullup(true);
      setFakeData([]);
    } else {
      getNext30DaysFromCalendar(date);
      setIsOpenPullup(true);
      setFakeData(fakeDataInit);
    }
  };

  function getNext30DaysFromCalendar(startDate) {
    const start = startDate instanceof Date ? startDate : new Date();
    const daysArray = [];

    for (let i = 0; i < 30; i++) {
      const date = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate() + i
      );
      const dayName = date.toLocaleString("en-US", { weekday: "long" });
      daysArray.push({ dayName, dayNumber: date.getDate() });
    }
    setListaysInHeader(daysArray);

    return daysArray;
  }

  const openFromPull = () => {
    setIsOpenPullup(true);
    getNext30Days();
  };

  const closePullup = () => {
    setIsOpenPullup(false);
  };
  const getMonthCalendar = (monthOffset) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month + monthOffset, 1);

    return (
      <div className="calendar-month">
        <Calendar
          key={monthOffset}
          activeStartDate={firstDayOfMonth}
          onActiveStartDateChange={({ activeStartDate }) =>
            setCurrentDate(activeStartDate)
          }
          showNeighboringMonth={false}
          locale="en-US"
          tileContent={({ date, view }) => {
            if (view === "month") {
              const dateHasEvent = eventDates.some(
                (eventDate) =>
                  date.getDate() === eventDate.getDate() &&
                  date.getMonth() === eventDate.getMonth() &&
                  date.getFullYear() === eventDate.getFullYear()
              );

              if (date.getDate() === 5) {
                return (
                  <div
                    className="custom-tile-content"
                    style={{ fontSize: 28, fontWeight: 700 }}
                    onClick={() => handleDayClick(date)}
                  >
                    <div
                      className={`dot ${dateHasEvent ? "with-event" : ""}`}
                    ></div>
                    .
                  </div>
                );
              } else {
                return (
                  <div
                    className="custom-tile-content"
                    style={{ color: "#fff" }}
                    onClick={() => handleDayClick(date)}
                  >
                    <div
                      hidden={true}
                      className={`dot ${dateHasEvent ? "with-event" : ""}`}
                    ></div>
                    *
                  </div>
                );
              }
            }
            return null;
          }}
        />
      </div>
    );
  };
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  useEffect(() => {
    setDataAttributes(imxtools.toArray(props?.data?.list?.calendar?.attribute));
  }, [props?.data?.list?.calendar?.attribute]);

  return (
    <div className="calendar-container">
      {!isOpenPullup ? (
        <div className="container-no-pullup">
          {getMonthCalendar(0)}
          {getMonthCalendar(1)}
          {getMonthCalendar(2)}
          <div className="pullup-container">
            <i
              className="bx bx-minus"
              style={{ fontSize: 48, borderRadius: 500, color: "#9135D5" }}
              onClick={() => openFromPull()}
            ></i>
          </div>
        </div>
      ) : (
        <div
          className="calendar-days-details-container"
          style={{ borderRadius: `0px 0px 50px 50px` }}
        >
          <div className="days-tab-container">
            <div className="header-days-container">
              {listDaysInHeader?.length > 0 ? (
                listDaysInHeader.map((day, index) => (
                  <div className="day-container-in-header" key={index}>
                    <p
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "row",
                        color: "#BCC1CD",
                      }}
                    >
                      {day?.dayName.substring(0, 3)}
                    </p>
                    {index === 0 ? (
                      <p
                        style={{
                          textAlign: "center",
                          color: "#fff",
                          backgroundColor: "#6A549E",
                          height: 30,
                          width: 30,
                          borderRadius: 500,
                          display: "flex",

                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {day?.dayNumber}
                      </p>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                          color: "#000",
                        }}
                      >
                        {day?.dayNumber}
                      </p>
                    )}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                height: 25,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <i
                className="bx bx-minus"
                style={{ fontSize: 42, color: "#9516F3" }}
                onClick={() => closePullup()}
              ></i>
            </div>
          </div>
          <div className="calendar-days-per-days-details-x">
            {listDaysInHeader?.length > 0 ? (
              listDaysInHeader.map((day, index) => {
                if (day?.dayNumber === 5) {
                  return (
                    <div className="calendar-detail-item" key={index}>
                      <div
                        className="day-num-name-container"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div>{day?.dayName.substring(0, 3)}</div>

                        <div>{day?.dayNumber}</div>
                      </div>
                      <div
                        className="day-detail-container"
                        style={{
                          backgroundColor: "#fff",
                          margin: 5,
                          borderRadius: 9,
                        }}
                        onClick={(e) =>
                          handleComponentProp(
                            e,
                            null,
                            props?.data?.list?.calendar,
                            [...props?.path, "calendar"],
                            store,
                            "calendar"
                          )
                        }
                      >
                        <DropTargetBody
                          accept={["attribute"]}
                          dragParent={[...props?.path, "calendar"]}
                          style={{
                            width: "100%",
                            overflowY: "auto",
                            height: "100%",
                            padding: 10,
                          }}
                        >
                          {dataAttributes?.length > 0 ? (
                            dataAttributes?.map((fData, index) => (
                              <DragItemAttribute
                                index={index}
                                component={"attribute"}
                                style={
                                  !readOnly
                                    ? {
                                        display: "flex",
                                        width: "100%",
                                        padding: 15,
                                        justifyContent: "flex-end",
                                        borderBottomLeftRadius: dataAttributes
                                          ? "9px"
                                          : "0px",
                                        borderBottomRightRadius: dataAttributes
                                          ? "9px"
                                          : "0px",
                                      }
                                    : {}
                                }
                                dragParentPath={[...props?.path, "calendar"]}
                                dragData={fData}
                                dataAttributes={dataAttributes}
                                setDataAttributes={setDataAttributes}
                                setIsDraging={setIsDragingIn}
                              >
                                <Attribute
                                  data={fData}
                                  readOnly={readOnly}
                                  index={index}
                                  path={[...props?.path, "calendar"]}
                                  editMode={readOnly}
                                />
                              </DragItemAttribute>
                            ))
                          ) : (
                            <DropTargetBody
                              accept={["attribute"]}
                              dragParent={[...props?.path, "calendar"]}
                              style={{
                                width: "100%",
                                height: "100%",
                                paddingTop: 20,
                                paddingBottom: 20,
                                paddingLeft: 5,
                                backgroundColor: "#fff",
                                borderRadius: 9,
                              }}
                            >
                              <span
                                style={{
                                  color: "#AAAAAA",
                                  textAlign: "start",
                                  fontSize: 12,
                                }}
                              >
                                Drag Attributes here{" "}
                              </span>
                            </DropTargetBody>
                          )}
                        </DropTargetBody>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="calendar-detail-item">
                      <div
                        className="day-num-name-container"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          style={{
                            color: "#6A549E",
                            fontWeight: "500",
                            fontSize: 16,
                          }}
                        >
                          {day?.dayNumber}
                        </div>
                        <div
                          style={{
                            color: "#6A549E",
                            fontWeight: "500",
                            fontSize: 16,
                          }}
                        >
                          {day?.dayName.substring(0, 3)}
                        </div>
                      </div>
                      <div className="day-detail-container"></div>
                    </div>
                  );
                }
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CalendarView;
