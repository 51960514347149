import updateMaintenance from "./updateMaintenance";

async function handleUpdateMaintenance(setMaintenanceStatus) {
  try {
    const data = await updateMaintenance(true);
    if (data && data.success) {
      setMaintenanceStatus(data.content.ENABLE_MAINTENANCE);
    }
  } catch (error) {
    console.error("Error updating maintenance:", error);
  }
}

export default handleUpdateMaintenance;
