import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import imxtools from "imxtools";
import { filterPageData } from "./filterPageData";

const useHomeLogic = () => {
  const [pages, setPages] = useState([]);
  const [onlyPages, setOnlyPages] = useState([]);
  const [numPages, setNumPages] = useState(0);
  const [gis, setGIS] = useState(null);
  const [path, setPath] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [greeting, setGreeting] = useState(false);
  const [notif, setNotif] = useState(false);
  const [openModuleMatrix, setOpenModuleMatrix] = useState(false);
  const [moduleData, setModuleData] = useState({});
  const [heightPageContent, setHeightPageContent] = useState("100%");
  const [moduleApps, setModuleApps] = useState([]);

  const { skeleton, readOnly, currentPage } = useSelector((state) => ({
    skeleton: state?.entities?.explorer?.skeleton?.maxapps || {},
    readOnly: state?.entities?.explorer?.readOnly,
    currentPage: state?.entities?.deviceview?.currentPage,
  }));

  useEffect(() => {
    const pagesArray = imxtools?.toArray(skeleton?.page ? skeleton?.page : []);
    const gisObject = skeleton?.gis ? skeleton?.gis : {};

    setPages(skeleton?.gis ? [...pagesArray, { gis: gisObject }] : pagesArray);
    setOnlyPages(pagesArray);
    setNumPages(skeleton?.gis ? pagesArray?.length + 1 : pagesArray?.length);
    setGIS(gisObject);
  }, [skeleton?.gis, skeleton?.page]);

  useEffect(() => {
    if (!pages[currentPage]) {
      return;
    }
    const filteredPageContent = filterPageData(pages[currentPage]);
    setPageData(filteredPageContent);
  }, [currentPage, pages]);

  useEffect(() => {
    setGreeting(
      Object.prototype.hasOwnProperty.call(skeleton ?? {}, "header") &&
        skeleton?.header &&
        skeleton?.header?.displaygreeting?.toLowerCase() === "true"
    );

    setNotif(
      Object.prototype.hasOwnProperty.call(skeleton ?? {}, "notifications") &&
        skeleton?.notifications
    );

    if (pages[currentPage]?.gis) {
      setHeightPageContent("92%");
    } else if (numPages <= 1 && readOnly) {
      if (greeting && notif) {
        setHeightPageContent("69%");
      } else if (greeting && !notif) {
        setHeightPageContent("83%");
      } else if (!greeting && !notif) {
        setHeightPageContent("88%");
      } else if (!greeting && notif) {
        setHeightPageContent("74%");
      }
    } else if (
      (numPages === 1 && !readOnly) ||
      (numPages > 1 && readOnly) ||
      (numPages > 1 && !readOnly) ||
      (numPages < 1 && !readOnly)
    ) {
      if (greeting && notif) {
        setHeightPageContent("61% ");
      } else if (greeting && !notif) {
        setHeightPageContent("75%");
      } else if (!greeting && !notif) {
        setHeightPageContent("80%");
      } else if (!greeting && notif) {
        setHeightPageContent("66%");
      }
    }
  }, [currentPage, greeting, notif, numPages, pages, readOnly, skeleton]);

  return {
    pages,
    setPages,
    onlyPages,
    numPages,
    gis,
    path,
    setPath,
    pageData,
    openModuleMatrix,
    setOpenModuleMatrix,
    moduleData,
    setModuleData,
    heightPageContent,
    moduleApps,
    setModuleApps,
  };
};

export default useHomeLogic;
