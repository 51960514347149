import "./index.scss"

function UserStat({ number = 0, description = "users" }) {
  return (
    <div className={`user-stat`}>
      <h1>{number}</h1>
      <p>{description}</p>
    </div>
  )
}

export default UserStat
