function handleAddProperty(data = [], setData) {
  try {
    const newProp = { key: "", value: "" };
    setData([...data, newProp]);
  } catch (error) {
    console.error("An error occurred in handleAddProperty:", error);
  }
}

export default handleAddProperty;
