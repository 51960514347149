import { useStore } from "react-redux";
import { handleComponentProp } from "../../services/handleComponentProperties";
import DropTargetBody from "../DropTargetBody";
import "./index.scss";
import React from "react";
import { generateUniqueKey } from "imxtools";
import Icon from "../../../../../../../components/CustomIcon/Icon";

const QuestionInspection = ({
  setViewContent = () => {},
  viewContent,
  listQuestions,
  questionToDisplay,
  setQuestionToDisplay = () => {},
  setIsOpen = () => {},
  isOpen,
  data = {},
  path = [],
  setOpenForm = () => {},
  uploadedPhotos = [],
  setUploadedPhotos = () => {},
}) => {
  let store = useStore();

  const doUploadPhoto = () => {
    let newUploadedPhotos =
      uploadedPhotos?.length > 0
        ? [
            ...uploadedPhotos,
            {
              photo: generateUniqueKey(path?.length),
              name: `photo + ${generateUniqueKey(path?.length)}`,
            },
          ]
        : [
            {
              photo: generateUniqueKey(path?.length),
              name: `photo + ${generateUniqueKey(path?.length)}`,
            },
          ];

    setUploadedPhotos(newUploadedPhotos);
  };

  return (
    <DropTargetBody
      accept={[
        "total",
        "Required",
        "optionalQuestions",
        "filters",
        "body",
        "attachment",
        "toolbar",
        "question",
      ]}
      dragParent={path}
      style={{
        width: "100%",
        overflowY: "auto",
        height: "100%",
        padding: 3,
      }}
    >
      <div
        className="content-qte"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="question-container">
          {questionToDisplay?.content?.length > 0 ? (
            questionToDisplay?.content?.map((q, index) => (
              <React.Fragment key={index}>
                <div className="title-container">
                  <span className="title-txt">
                    {String(q?.qte).substring(0, 1)}
                  </span>
                  <span className="title-txt-content">
                    Fire and Emergency Preparedness
                  </span>
                </div>
                <div className="question-num-content">
                  <span className="question-txt">{q?.qte}:</span>
                  <span className="required-txt">Required</span>
                </div>
                <div>
                  <p className="description-txt">
                    Are fire extinguishers located in a suitable location,
                    visible, signposted, checked regularly, tagged, sited
                    throughout the place and the right types of extinguishers
                    located close to the fire hazards?
                  </p>
                </div>
                <div>
                  <span className="visual-title">
                    Visual check/Check PPM records:
                  </span>
                  <div className="question-input-container">
                    <span style={{ fontSize: 12 }}>
                      Hide optional questions
                    </span>
                    <input
                      type="checkbox"
                      defaultValue=""
                      className="input-check"
                    />
                  </div>
                  <div className="question-input-container">
                    <span style={{ fontSize: 12 }}>
                      Hide optional questions
                    </span>
                    <input
                      type="checkbox"
                      defaultValue=""
                      className="input-check"
                    />
                  </div>
                  <div className="question-input-container">
                    <span style={{ fontSize: 12 }}>
                      Hide optional questions
                    </span>
                    <input
                      type="checkbox"
                      defaultValue=""
                      defaultChecked={true}
                      className="input-check"
                    />
                  </div>
                </div>
                {data?.attachment && data?.hasOwnProperty("attachment") ? (
                  <div
                    onClick={(e) =>
                      handleComponentProp(
                        e,
                        null,
                        data?.attachment,
                        [...path, "attachment"],
                        store,
                        "attachment"
                      )
                    }
                  >
                    <span className="visual-title">Upload an attachment:</span>
                    <div className="file-upload-wrapper">
                      <div
                        className="file-upload-btn"
                        onDoubleClick={() => doUploadPhoto()}
                      >
                        <i className="bx bx-file-blank"></i>
                      </div>
                      {uploadedPhotos?.length > 0 ? (
                        <div className="uploaded-photos-container">
                          {uploadedPhotos?.length > 0 ? (
                            uploadedPhotos?.map((upPhoto, index) => (
                              <div className="media-image-container">
                                <div className="media-image-wrap">
                                  <div
                                    style={{
                                      height: 2,
                                      width: 2,
                                      backgroundColor: "#6a549e",
                                    }}
                                  />
                                  <div
                                    className="file-content"
                                    onDoubleClick={() => setOpenForm(true)}
                                  >
                                    <Icon
                                      icon="doc-file"
                                      backgroundColor="#fff"
                                      color="#fff"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {data?.question && data?.hasOwnProperty("question") ? (
                  <div
                    onClick={(e) =>
                      handleComponentProp(
                        e,
                        null,
                        data?.question,
                        [...path, "question"],
                        store,
                        "question"
                      )
                    }
                  >
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        color: "#6A549E",
                      }}
                    >
                      Observation:
                    </span>
                    <div
                      className="input-observation-container"
                      onClick={(e) =>
                        handleComponentProp(
                          e,
                          null,
                          data?.question?.placeholder,
                          [...path, "question", "placeholder"],
                          store,
                          "placeholder"
                        )
                      }
                    >
                      <input
                        type="text"
                        defaultValue=""
                        className="input-observation"
                        placeholder="Write answer here..."
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="feet">
          <div className="back-next-btn-container">
            <button
              className="back-btn"
              onClick={() => {
                setViewContent(viewContent - 1);
                setQuestionToDisplay(listQuestions[questionToDisplay?.num - 2]);
              }}
            >
              <i className="bx bx-chevron-left"></i> {!isOpen ? "Back" : ""}
            </button>
            <button
              className="next-btn"
              onClick={() => {
                setViewContent(viewContent + 1);
                setQuestionToDisplay(listQuestions[questionToDisplay?.num]);
              }}
            >
              {!isOpen ? "Next" : ""} <i className="bx bx-chevron-right"></i>
            </button>
          </div>
          <div className="save-complete-btn-container">
            <button className="save-btn">
              <i className="bx bx-save" style={{ color: "#6a549e" }}></i>
            </button>
            <button className="complete-btn">
              Complete <i className="bx bx-check"></i>
            </button>
          </div>
        </div>
      </div>
    </DropTargetBody>
  );
};
export default QuestionInspection;
