export const Luaris = {
  themeName: "Luaris",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#5A5B5D80",
  primary: "#AF9751",
  iconAppItem: "#AF9751",
  AppText: "#414141",
  loginFooter: "#AF9751",
  connectBtn: "#AF9751",
  loader: "#AF9751",
  headerColor: "#AF9751",
  primaryLight: "#AF975150",
  primaryColor: "#AF9751B2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#5A5B5D",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#8D7C49",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#AF9751",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#AF9751",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#D1C8AB",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#AF9751",
  groupIcon: "#ffffff",
  borderRecord: "#AF975150",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D1C8AB",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D1C8AB",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#AF9751",
  listBgCard: "#eee",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#B3B3B3",
  loginOptions: "#FFF",
  shadowBtnConnect: "#5A5B5D",
  bnConnect: "#AF9751",
  loginBtnLabel: "#FFF",
  focusedBorderLoginInput: "#AF9751",
  focusedLabelLoginInput: "#AF9751",
  resetInputLogin: "#AF9751",
  languageIcon: "#AF9751",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#FFF",
  loginFooter: "#77777A",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#AF975100",
  headerDark: "#8D7C4900",
  headerText: "#414141",
  notificationUpColor: "#FCFBF8",
  notificationDownColor: "#F7F5EE",

  listItemNotificationUpColor: "#FAF9F5",
  listItemNotificationDownColor: "#F4F0E7",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#EFEEEB",

  notFocusedSocialMedia: "#AF9751",
  focusedSocialMedia: "#AF9751cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#AF9751",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
