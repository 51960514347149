export const Orange = {
  themeName: "Orange",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#55565A80",
  primary: "#fc7600",
  iconAppItem: "#fc7600",
  AppText: "#fc7600",
  loginFooter: "#fc7600",
  connectBtn: "#fc7600",
  loader: "#fc7600",
  headerColor: "#fc7600",
  primaryLight: "#fc760050",
  primaryColor: "#FC7600B2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#55565A",
  focusedBorderLoginInput: "#55565A",
  focusedLabelLoginInput: "#55565A",
  resetInputLogin: "#55565A",

  languageIcon: "#55565A",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#D56A0A",
  offline: "#ec4545",
  offlineLabel: "#ec4545",
  labelColor: "#fc7600",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#fc7600",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#D1C8AB",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#fc7600",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#fc760050",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#ECC19B",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#ECC19B",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#fc7600",
  listBgCard: "#eee",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#55565A",
  bnConnect: "#fc7600",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#fc760000",
  headerText: "#fc7600",
  headerDark: "#D56A0A00",
  notificationUpColor: "#FFFAF5",
  notificationDownColor: "#FFF1E5",

  listItemNotificationUpColor: "#FFF7F0",
  listItemNotificationDownColor: "#FFECDB",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F2F1",

  notFocusedSocialMedia: "#fc7600",
  focusedSocialMedia: "#fc7600cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#fc7600",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
