import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function CheckboxWithThreeStates({
  label = "",
  checked = false,
  onSelectionChange,
  boxSize = "1rem",
  gap = "0.35rem",
  fontSize = "14px",
  withLabel = true,
  readOnly = true,
  checkmarkColor = "#4C36A5",
  width = "auto",
  height = "100%",
  backgroundColor = "transparent",
  verticalPadding = "0px",
  horizontalPadding = "0px",
  borderColor = "transparent",
  borderWidth = "0px",
  verticalBorder = false,
  labelColor = "inherit",
}) {
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = false;
    }
  }, [checked]);

  const handleChange = () => {
    if (readOnly) {
      return;
    }
    onSelectionChange();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: gap,
        fontSize: fontSize,
        color: readOnly ? "#B9B8BD" : "inherit",
        backgroundColor: backgroundColor,
        width: width,
        height: height,
        padding: `${verticalPadding} ${horizontalPadding}`,
        border: verticalBorder && `${borderWidth} solid ${borderColor}`,
        borderWidth: borderWidth,
        borderLeft: `${borderWidth} solid ${borderColor}`,
        borderRight: `${borderWidth} solid ${borderColor}`,
      }}
    >
      <input
        type="checkbox"
        ref={checkboxRef}
        checked={checked}
        onChange={handleChange}
        disabled={readOnly}
        style={{
          width: boxSize,
          height: boxSize,
          accentColor: checkmarkColor,
          borderColor: borderColor,
          borderWidth: "2px",
          borderStyle: "solid",
        }}
      />
      {withLabel && (
        <span style={{ color: labelColor }}>
          {label}
        </span>
      )}
    </div>
  );
}



export default CheckboxWithThreeStates;
