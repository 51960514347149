import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import "./index.scss";
import { arraysAreEqual } from "../../../../../../../utils/util";

function Geomodule({ data = {}, path = [] }) {
  let store = useStore();
  const center = {
    lat: 30.55,
    lng: 10.33,
  };
  let edit = useSelector((state) => state?.entities?.editor);

  return (
    <div
      className={
        arraysAreEqual([...path, "geomodule"], edit.parentToEdit)
          ? `map-container  ${"selectedComponent"}`
          : `map-container`
      }
      onClick={(event) =>
        handleComponentProp(event, null, data, path, store, "geomodule")
      }
    >
      <GoogleMap
        clickableIcons={false}
        key={"AIzaSyDQdZ3NJ_ScT4WsJG00f1drRUksu062db4"}
        zoom={4}
        center={center}
        mapContainerClassName="map-container"
        options={{
          disableDefaultUI: true,
        }}
      ></GoogleMap>
    </div>
  );
}

export default Geomodule;
