import axios from "axios";

const apiKey = "3247af9a9d828b551b6a4f6bd7445643";

export async function getWeatherByGeolocationAndUnits(units = "metric") {
  try {
    const coords = await getUserGeolocation();
    if (coords) {
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${coords?.latitude}&lon=${coords?.longitude}&units=${units}&appid=${apiKey}`
      );

      if (response?.data) {
        const weatherData = {
          icon: `http://openweathermap.org/img/w/${response?.data?.weather[0]?.icon}.png`,
          weather: response?.data?.main?.temp,
        };
        return weatherData;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching weather data:", error);
    return null;
  }
}

export function getUserGeolocation() {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords);
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not available in this browser."));
    }
  });
}

export const toggleUnits = (e, units, setUnits) => {
  e.stopPropagation();
  setUnits(units === "metric" ? "imperial" : "metric");
};
