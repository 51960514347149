import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import "./index.scss";

function DragUtility({
  children,
  component,
  dragData = {},
  reference = "",
  style = {},
}) {
  const ref = useRef(null);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let XMLChanged = useSelector((state) => state?.entities?.editor?.XMLChanged);

  const [{ isDragging }, drag] = useDrag({
    type: component,
    item: {
      type: component,
      reference,
      dragData,
    },
    options: {
      dropEffect: "move",
    },
    canDrag: !readOnly && !XMLChanged,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div className={isDragging ? "dragging" : ""} style={style} ref={drag(ref)}>
      {children}
    </div>
  );
}

export default DragUtility;
