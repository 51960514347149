export const Bluenorange = {
  themeName: "bluenorange",
  CardViewBgColor: "#EFEDE710",
  secondaryLight: "#55565A80",
  primary: "#010064",
  iconAppItem: "#010064",
  AppText: "#010064",
  loginFooter: "#010064",
  connectBtn: "#010064",
  loader: "#FEA525",
  headerColor: "#010064",
  primaryLight: "#01006450",
  primaryColor: "#010064B2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#ED4600",
  focusedLabelLoginInput: "#ED4600",
  resetInputLogin: "#ED4600",
  focusedBorderLoginInput: "#ED4600",

  languageIcon: "#ED4600",
  settingCollapseColor: "#eee",
  welcomeBg: "#F3F1F5",
  success: "#3ecd7e",
  primaryDark: "#010064",
  offline: "#ec4545",
  labelColor: "#010064",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#ED4600",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#FEA525",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#010064",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#01006450",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#ED4600",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#FEA525",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#010064",
  listBgCard: "#eee",
  bgLoginInput: "#FFF",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  linkColor: "#1D9BF0",
  loginOptions: "#000",
  shadowBtnConnect: "#ED4600",
  bnConnect: "#010064",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#01006400",
  headerDark: "#01006400",
  headerText: "#010064",
  notificationUpColor: "#F5F5FF",
  notificationDownColor: "#E6E5FF",

  listItemNotificationUpColor: "#F0F0FF",
  listItemNotificationDownColor: "#DCDBFF",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F1F4",

  notFocusedSocialMedia: "#010064",
  focusedSocialMedia: "#010064cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#010064",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
