import validateUrl from "./validateUrl";

function handleInput(e, setFieldValue, field) {
  if (field.name === "url") {
    if (!validateUrl(e.currentTarget.value)) {
      setFieldValue("oslcUrl", "");
      setFieldValue("maxRestUrl", "");
    } else {
      // setCondition(true);
      const trimmedUrl =
        e.currentTarget.value.slice(-1) === "/"
          ? e.currentTarget.value.slice(
              0,
              e.currentTarget.value.lastIndexOf("/")
            )
          : e.currentTarget.value;

      // setFieldValue("oslcUrl", `${trimmedUrl}/oslc`);
      setFieldValue("oslcUrl", `${trimmedUrl}/api`);
      setFieldValue(
        "maxRestUrl",
        trimmedUrl.match(new RegExp("/", "g")) || [].length >= 3
          ? `${trimmedUrl.slice(0, trimmedUrl.lastIndexOf("/"))}/maxrest`
          : `${trimmedUrl}/maxrest`
      );
    }
  }
}
export default handleInput;
