function RevisionToCompare(store, item, addToast, xmlValue, showLeft) {
  try {
    if (!showLeft) {
      store.dispatch({
        type: "compareFiles/setLeftMode",
        payload: {
          leftMode: "Revision",
        },
      });
      store.dispatch({
        type: "compareFiles/setSelectedItemLeft",
        payload: {
          itemLeftSelected: item,
        },
      });
      store.dispatch({
        type: "compareFiles/setLeftXmlValue",
        payload: {
          leftXml: xmlValue,
        },
      });
      store.dispatch({
        type: "compareFiles/setLeftSideShow",
        payload: {
          showLeft: true,
        },
      });
    } else {
      store.dispatch({
        type: "compareFiles/setRightMode",
        payload: {
          rightMode: "Revision",
        },
      });
      store.dispatch({
        type: "compareFiles/setSelectedItemRight",
        payload: {
          itemRightSelected: item,
        },
      });
      store.dispatch({
        type: "compareFiles/setRightXmlValue",
        payload: {
          rightXml: xmlValue,
        },
      });
      store.dispatch({
        type: "compareFiles/setRightSideShow",
        payload: {
          showRight: true,
        },
      });
      store.dispatch({
        type: "compareFiles/updateCompareState",
        payload: {
          compare: true,
        },
      });
    }
  } catch (error) {
    addToast("Server is unreachable!", "error");
  }
}

export default RevisionToCompare;
