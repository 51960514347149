import themeColors from '../../../../../theme/themeColors';

const getStatusOrMethodCodeColor = (statusCodeOrMethod, type) => {
  if (!statusCodeOrMethod) return '#000'; // Default color if no status code

  const statusCodeRanges = {
    200: [200, 299],
    300: [300, 399],
    400: [400, 499],
    500: [500, 599],
  };
  const methodTypeColors = {
    GET: '#4C36A5',
    POST: themeColors.light.Orange,
    PUT: '#2196f3',
    DELETE: themeColors.light.Red,
  };

  const statusCodeColor = {
    200: themeColors.light.Green,
    300: '#2196f3',
    400: themeColors.light.Orange,
    500: themeColors.light.Red,
  };

  if (type === 'method') {
    if (!methodTypeColors[statusCodeOrMethod]) return '#000'; // Default color if method is not GET, POST, PUT, DELETE
    return methodTypeColors[statusCodeOrMethod];
  }

  for (const [baseCode, [min, max]] of Object.entries(statusCodeRanges)) {
    if (statusCodeOrMethod >= min && statusCodeOrMethod <= max) {
      return statusCodeColor[baseCode];
    }
  }

  return '#000'; // Default color if status code doesn't fall in any range
};

export default getStatusOrMethodCodeColor;
