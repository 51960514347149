import Icon from "../../../../../../components/CustomIcon/Icon"
import CheckboxWithThreeStates from "../../../../../../components/Checkbox"
import DeleteItems from "../components/DeleteItems"
import { handleSelectAll } from "../utilities"
import "./index.scss"

const SearchBar = ({
  searchText,
  setSearchText,
  selectedCards,
  readOnly,
  functions,
  setSelectedCards,
  filterData,
  path,
  component,
}) => {
  return (
    <div className='actionHeader'>
      <div className='actionHeader-search'>
        <div className='uSearch-icon' placeholder='Search'>
          <Icon icon='search' size={22} color='#989898' />
        </div>
        <input
          placeholder='Search'
          className='SearchPlaceHolder'
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className='actionHeader-right'>
        <CheckboxWithThreeStates
          label='Select All'
          selectedCards={selectedCards}
          totalCount={functions.length}
          readOnly={readOnly}
          data={functions}
          onSelectionChange={
            readOnly
              ? () => {}
              : (newSelection) =>
                  handleSelectAll(newSelection, setSelectedCards)
          }
        />
        <DeleteItems
          readOnly={readOnly}
          selectedCards={selectedCards}
          component={component}
          setSelectedCards={setSelectedCards}
          filterData={filterData}
          path={path}
        />
      </div>
    </div>
  )
}
export default SearchBar
