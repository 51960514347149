export const Caelus = {
  themeName: "Caelus",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#5A5B5D80",
  primary: "#2574BB",
  iconAppItem: "#06A7E0",
  AppText: "#414141",
  connectBtn: "#2574BB",
  loader: "#2574BB",
  headerColor: "#2574BB",
  primaryLight: "#2574BB50",
  primaryColor: "#2574BBB2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#00A7E1",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#1A5091",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#2574BB",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#2574BB",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#FDE7C9",
  appBg: "#f8f8f8",
  appBorder: "#EEEEEE",
  label: "#2574BB",
  groupIcon: "#ffffff",
  borderRecord: "#B9915E50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D3E3F1",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#FDE7C9",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#2574BB",
  listBgCard: "#eee",
  colorTextInput: "#565757",
  LogincolorTextInput: "#565757",
  LogincolorLabelInput: "#B3B3B3",
  loginOptions: "#565757",
  shadowBtnConnect: "#00A7E1",
  bnConnect: "#2574BB",
  loginBtnLabel: "#fff",
  focusedBorderLoginInput: "#2574BB",
  focusedLabelLoginInput: "#2574BB",
  resetInputLogin: "#2574BB",
  languageIcon: "#77777A",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#FFF",
  loginFooter: "#77777A",
  loginText: "#000",
  notfocusedBorderLoginInput: "#EEEEEE",
  headerPrimary: "#AF975100",
  headerDark: "#8D7C4900",
  headerText: "#414141",
  notificationUpColor: "#F6FAFD",
  notificationDownColor: "#EAF3FB",

  listItemNotificationUpColor: "#F2F8FC",
  listItemNotificationDownColor: "#E1EEF9",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#EAF3FB",

  notFocusedSocialMedia: "#2574BB",
  focusedSocialMedia: "#2574BBcc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#2574BB",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
