const revision = {
  initialState: {
    list: [],
    lastVersion: 0,
    itemSelected: {},
    revisionSelected: {},
  },
  reducers: {
    dataAdded: (dataSet, action) => {
      const { list } = action.payload;
      dataSet["list"] = list;
      if (list?.length > 0) {
        dataSet["lastVersion"] = list?.[0]?.version;
      } else {
        dataSet["lastVersion"] = 0;
      }
    },
    updateData: (dataSet, action) => {
      const { objToAdd } = action.payload;
      dataSet["list"].unshift(objToAdd);
      dataSet["lastVersion"] = objToAdd?.version;
    },
    setVersion: (dataSet, action) => {
      const { version } = action.payload;

      dataSet["lastVersion"] = version;
    },
    deleteData: (dataSet, action) => {
      const { filename } = action.payload;
      let ind = dataSet["list"].findIndex((item) => item.filename === filename);
      dataSet["list"].splice(ind, 1);
    },
    setSelectedItem: (dataSet, action) => {
      const { obj } = action.payload;
      dataSet["itemSelected"] = { ...obj };
    },
    setSelectedRevision: (dataSet, action) => {
      const { data } = action.payload;
      dataSet["revisionSelected"] = { ...data };
    },
    newSkeleton: (dataSet) => {
      dataSet["itemSelected"] = {};
      dataSet["revisionSelected"] = {};
    },
    reset: (dataSet) => {
      return (dataSet = {
        list: [],
        lastVersion: 0,
        itemSelected: {},
        revisionSelected: {},
      });
    },
    updateXmlAlias: (dataSet, action) => {
      const { objToAdd } = action.payload;
      const newDataSet = dataSet["list"]?.map((obj) => {
        if (obj?.filename === objToAdd?.filename) {
          return { ...obj, xmlAlias: objToAdd?.xmlAlias };
        }
        return obj;
      });
      dataSet["list"] = newDataSet;
    },
  },
};

export default revision;
