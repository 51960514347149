import { useState } from "react"

function useInput(initialValue) {
  const [value, setValue] = useState(initialValue)
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (e) => {
    // const regex = /^-?\d*\.?\d*$/
    // if (regex.test(e.target.value) || e.target.value === '') {
    setValue(e.target.value)
    // }
  }

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  const reset = () => setValue("")

  return {
    value,
    isFocused,
    reset,
    bind: {
      value,
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
    },
  }
}

export default useInput
