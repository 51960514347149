export function handleComponentProp(
  e,
  index = null,
  data,
  path = [],
  store,
  component = ""
) {
  try {
    e.stopPropagation();
    let newPath = [];
    if (index === null) {
      if (component === path[path.length - 1]) {
        newPath = [...path];
      } else {
        newPath = [...path, component];
      }
    } else if (component === path[path.length - 1]) {
      newPath = [...path, index];
    } else {
      newPath = [...path, component, index];
    }
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  } catch (error) {
    console.error("An error occurred in handleComponentProp:", error);
  }
}
