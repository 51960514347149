import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import { defaultStore } from './store';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from './theme/themeContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CustomDndProvider from './DragAndDrop/DndContext.jsx';
import { LanguageProvider } from './Translate/LanguageContext.js';
import './index.scss';
import './Translate/Languages/i18n.js';

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={defaultStore.store}>
    <PersistGate persistor={defaultStore.persistor}>
      <ToastProvider>
        <ThemeProvider>
          <DndProvider backend={HTML5Backend}>
            <CustomDndProvider>
              <LanguageProvider>
                <App />
              </LanguageProvider>
            </CustomDndProvider>
          </DndProvider>
        </ThemeProvider>
      </ToastProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
