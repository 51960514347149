export const Ason = {
  themeName: "Ason",
  isDark: false,
  CardViewBgColor: "#d3bb9510",
  secondaryLight: "#f7987e80",
  primary: "#d3bb95",
  iconAppItem: "#d3bb95",
  AppText: "#d3bb95",
  loginFooter: "#d3bb95",
  connectBtn: "#d3bb95",
  loader: "#d3bb95",
  headerColor: "#d3bb95",
  primaryLight: "#d3bb9550",
  primaryColor: "#d3bb95B2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#f7987e",
  focusedLabelLoginInput: "#f7987e",
  resetInputLogin: "#f7987e",
  focusedBorderLoginInput: "#f7987e",

  languageIcon: "#f7987e",
  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#E1DDD2",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#f7987e",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#d3bb95",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#f7987e",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#d3bb9550",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#d3bb95",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#f7987e",
  bnConnect: "#d3bb95",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#d3bb9500",
  headerDark: "#E1DDD200",
  headerText: "#d3bb95",
  notificationUpColor: "#FCFAF8",
  notificationDownColor: "#F8F3ED",

  listItemNotificationUpColor: "#FBF8F4",
  listItemNotificationDownColor: "#F5EFE6",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F3F1",
  notFocusedSocialMedia: "#d3bb95",
  focusedSocialMedia: "#d3bb95cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#d3bb95",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",
};
