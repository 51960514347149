function deleteConditionByRef(conditions, setConditions, refToDelete) {
  try {
    if (!conditions.value.includes(refToDelete)) return;

    let refsArray = conditions.value.split(",");
    refsArray = refsArray.filter((ref) => ref !== refToDelete);
    const newValue = refsArray.join(",");

    setConditions({ ...conditions, value: newValue });
  } catch (error) {
    console.error("Error deleting reference from value:", error);
  }
}

export default deleteConditionByRef;
