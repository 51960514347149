const settings = {
  initialState: {
    mode: "light",
    language: "en",
  },
  reducers: {
    toggleTheme: (dataSet, action) => {
      dataSet["mode"] = dataSet["mode"] === "light" ? "dark" : "light";
    },
    changeLanguage: (dataSet, action) => {
      const { language } = action.payload;
      dataSet["language"] = language;
    },
  },
};

export default settings;
