import React, { useState } from "react";
import "./index.scss";
import Pullup from "./Pullup";
import { GoogleMap } from "@react-google-maps/api";
import { useSelector, useStore } from "react-redux";
import { handleComponentProp } from "../../../../../services/handleComponentProperties";
import DropTargetBody from "../../../../../components/DropTargetBody";

function MapView({ data = {}, path = [] }) {
  const [position, setPosition] = useState(false);
  const center = {
    lat: 60.55,
    lng: 10.33,
  };
  let store = useStore();

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  return (
    <div className="MapView">
      <div
        className={!readOnly ? "map-wrapper-edit" : "map-wrapper"}
        onClick={(e) =>
          handleComponentProp(e, null, data?.list, path, store, "list")
        }
      >
        <DropTargetBody
          accept={["map"]}
          dragParent={path}
          style={{
            width: "100%",
            minHeight: "100%",
            overflowY: "auto",
            height: "100%",
          
          }}
        >
          {data?.list?.hasOwnProperty("map") &&
          data?.list?.map !== undefined ? (
            <div
              style={{
                width: "100%",
                minHeight: "100%",
                overflowY: "auto",
                height: "100%",
              }}
              onClick={(e) =>
                handleComponentProp(
                  e,
                  null,
                  data?.list?.map,
                  path,
                  store,
                  "map"
                )
              }
            >
              <DropTargetBody
                accept={["marker" , 'pullup']}
                dragParent={[ ...path , 'map']}
                style={{
                  width: "100%",
                  minHeight: "100%",
                  overflowY: "auto",
                  height: "100%",
                }}
              >
                <GoogleMap
                  clickableIcons={false}
                  zoom={4}
                  center={center}
                  mapContainerClassName="map-container"
                  options={{
                    disableDefaultUI: true,
                  }}
                  key={"AIzaSyDQdZ3NJ_ScT4WsJG00f1drRUksu062db4"}
                ></GoogleMap>
              </DropTargetBody>
            </div>
          ) : (
            <DropTargetBody
              accept={["map"]}
              dragParent={path}
              style={{
                width: "100%",
                minHeight: "100%",
                overflowY: "auto",
                height: "100%",
                padding: 10,
              }}
            ></DropTargetBody>
          )}
        </DropTargetBody>
      </div>
      {data?.list?.map?.hasOwnProperty("pullup") && data?.list?.map?.pullup !== undefined ? (
        <div
          className={!readOnly ? "pullup-container-edit" : "pullup-container"}
          style={position ? { height: "30%" } : { height: "60%" }}
          onClick={(e) =>
            handleComponentProp(
              e,
              null,
              data?.list?.map,
              [...path, "map", "pullup"],
              store,
              "pullup"
            )
          }
        >
          <Pullup
            setPosition={setPosition}
            position={position}
            data={data?.list?.map?.pullup}
            path={[...path , 'map' , 'pullup']}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MapView;
