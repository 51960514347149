import { getIn, setIn } from "lodash-redux-immutability";
import imxtools from "imxtools";
import { batch } from "react-redux";
import { restrictedList } from "../../../../../../utils/util";

const handleAddItem = (
  key,
  store,
  skeletonState,
  parent,
  insideParent = null,
  targetRefEvent = null
) => {
  const state = getIn(skeletonState, [...parent, key]);
  if (restrictedList?.includes(key) && parent[1] !== "gis") {
    if (
      insideParent?.hasOwnProperty(key) &&
      insideParent?.key === undefined &&
      state
    ) {
      return;
    }
  }

  const id = imxtools.generateUniqueKey(parent?.[parent.length - 1] ?? key);
  const index = store.getState()?.entities?.explorer?.maxOrder
    ? store.getState()?.entities?.explorer?.maxOrder
    : 0;
  const newElementContent =
    (key === "form" || key === "hform") && targetRefEvent != null
      ? {
          id: `imxComponent${id}`,
          itemOrder: index,
          reference: targetRefEvent,
        }
      : key === "attachment"
      ? {
          id: `imxComponent${id}`,
          itemOrder: index,
          event: [
            {
              title: "event",
              itemOrder: index + Math.random(5),
              service: "UPLOADDOCLINK",
              name: "UPLOAD",
              id: `imxComponent${id}`,
            },
            {
              title: "event",
              itemOrder: index + Math.random(7),
              service: "DELETEDOCLINK",
              name: "DELETE",
              id: `imxComponent${id}`,
            },
          ],
        }
      : key === "question"
      ? {
          id: `imxComponent${id}`,
          itemOrder: index,
          placeholder: {
            label_ar: "ملاحظات",
          },
        }
      : {
          id: `imxComponent${id}`,
          itemOrder: index,
        };

  const dataToInsert = !state
    ? newElementContent
    : [...imxtools.toArray(state), newElementContent];
  const newData = setIn(skeletonState, [...parent, key], dataToInsert);

  batch(() => {
    try {
      store.dispatch({
        type: "explorer/updateData",
        payload: {
          skeleton: { ...newData },
          readOnly: false,
        },
      });
      store.dispatch({
        type: "explorer/incrementOrder",
        payload: { order: index + 1 },
      });
     
    } catch (error) {
      console.error("handleAddItem error", error);
    }
  });
};

export default handleAddItem;
