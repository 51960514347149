const themeColors = {
  light: {
    primary: "#4C36A5",
    secondary: "#7D6DBE",
    logo: "#4C36A5",
    disabled: "#B9B8BD",
    header: "#FFF",
    ToolBarBg: "#FCFBFF",
    BodyBg: "#FCFBFF",
    BorderStroke: "#ECECEC",
    BorderButtons: "#ECECEC",
    Shadow: "#000",
    separator: "#D7D7D7",

    //Switcher
    switcherBgActive: "#16A34A",
    switcherCircleActive: "#fff",
    switcherBgInactif: "#686868",
    switcherCircleInactif: "#fff",

    //auth
    footerText: "#808080",
    inputBorder: "#4C36A5",
    placeholder: "#aaa",
    langBoxBorder: "#eee",

    //PopUp
    PopUpBg: "#FFF",
    PopUpStoke: "#EEE",
    LinkFilename: "#4C36A5",
    PopUpBlur: "#000",

    //------Support------//
    CardBg: "#FFF",
    Title: "#222",
    ColorButton: "#4C36A5",
    BorderColor: "#4C36A5",
    BorderButtonSupport: "#FFF",
    SupportButtonBg: "#4C36A5",

    //------Login------//
    LoginLogo: "#4C36A5",
    LoginBoxBg: "#FBFBFB",
    LoginBoxBorder: "#fff",
    LoginBoxStroke: "#EEE",
    LoginBG: "#FFF",
    LoginButton: "#4C36A5",
    dropdownBg: "#FFFFFF",
    dropdownColor: "#262626",

    //------Designer------//
    PropertieBg: "#FFF",
    Globe: "#39354A",
    PublishDisabled: "#B9B8BD",
    PublishEnabled: "#39354A",
    PublishIcon: "#4C36A5",
    ConditionUI: "#ffffff",
    Condition: "#dfdfdf",
    tagBg: "#4C36A533",
    tag: "#4C36A5",
    tagComp: "#4C36A5B2",
    viewMode: "#4C36A533",
    viewModeLabel: "#4C36A5",
    editMode: "#4C36A5",
    borderTheme: "#EEEEEE",
    borderBottom: "#ECECEC",
    publishedItem: "#F4F2F9",
    dateColor: "#B9B8BD",
    dateColorDisabled: "#9793A6",
    searchRevisionBackground: "#F8F8F8",
    searchCloseIcon: "#C4C3C9",
    fileIcon: "#BFBFBF",
    SpliteBorder: "#9183a080",

    //interceptor
    interceptor: "#4C36A5",
    interceptorBtn: "#4C36A5",
    responseDataButtonArrow: "rgba(57, 53, 74, 1)",
    interceptorBgCl: "#F3EFFF",
    selectedRequest: "#4C36A5",
    requestsNb: "#4C36A5",
    interceptorIcons: "#4C36A5",
    bgTable: "rgba(238, 238, 238, 1)",

    //----Installer----//
    titleText: "#4C36A5",
    NextButtonBg: "#DCDCDC",
    NextButtonColor: "#908D9A",
    ProgressionBar: "#D9D9D9",
    disconnectBtnBorder: "#4C36A5",
    Green: "#16A34A",
    Red: "#DC2626",
    Orange: "#ca8a04",

    //----IconManager----//
    serachBar: "#f8f8f8",
    info_title: "#39354a",
    CustomButtons: "#4C36A5",
    BorderDashed: "#EEEEEE",
    border: "#EEEEE",

    //Ifons
    success: "#16A34A",
    error: "#DC2626",
    warning: "#CA8A04",
    closeIcon: "#FF505A",
    checkBox: "#3869E7",
    //Text
    text: "#39354A",
    textDisabled: "#B9B8BD",
    textHovered: "#ccc",
    textInactive: "#ECECEC",
    TextSelected: "#4C36A5",
    TitleContainer: "#4C36A5",
    SearchText: "#AAAAAA",
    ColorButtons: "#39354A",
    DraftActiveText: "#ffffff",

    //Components library
    icon: "#9183A0",
    iconBg: "#D9D4ED",
    iconLabel: "#9183A0",
    iconHover: "#4C36A5",
    iconDisable: "#DEDAE3",
    iconBgDisable: "#eeeeee",
    iconLabelDisable: "#909090",
    iconLabelHovered: "#ffffff",
    iconColorDisabled: "#CECDD2",
    iconEvent: "#808080",
    //Main Buttons
    DefaultButton: "#FFFFFF",
    ButtonHover: "#7D6DBE",
    ButtonClicked: "#4C36A5",
    ButtonDisable: "#DCDCDC",
    DefaultButtonLabel: "#39354A",
    ButtonLabel: "#FFF",
    ButtonLabelDisable: "#908D9A",
    DefaultButtonStroke: "#EEEEEE",
    ButtonDisableStroke: "#68686800",
    //ToolBar Buttons
    TbDefaultButton: "#FFFFFF",
    TbButtonHover: "#7D6DBE",
    TbButtonClicked: "#4C36A5",
    TbButtonDisable: "#FFF",
    TbDefaultButtonLabel: "#39354A",
    TbButtonLabel: "#FFF",
    TbButtonLabelDisable: "#B9B8BD",
    TbDefaultButtonStroke: "#EEEEEE",
    iconColor: "#4C36A5",
    //Components Layer
    CpSelection: "#F3EFFF",
    //Revision
    RSelectedBG: "#4C36A5",
    RSelectedLabel: "#FFF",
    REditStroke: "#4C36A5",
    REditLabel: "#39354A",
    //Input
    InputBg: "#FFF",
    InputStroke: "#EEEE",
    InputLabel: "#AAAA",
    InputStrokeSelected: "#4C36A5",
    InputLabelSelected: "#4C36A5",

    //------Monitor------//
    monitorText: "#39354A",
    monitorBorder: "#EEEEEE",
    monitorBg1: "#F5F5F5",
    monitorBg2: "#FFFFFF",
    monitorSwitchBackground: "#EEEEEE",
    monitorInput: "#FAFAFB",
    monitorInputBorder: "",
    moniteurRow: "#F3EFFF",
    MoniteurCollectionColor: "#7C7987",
    //Number Bar
    NbBg: "#EEEEEE",
    NbIconBg: "#908D9A",
    NbNumberBg: "#FFFFFF",
    NbNumberStroke: "#EEEEEE",
    NbNumberinput: "#39354A",
    NbIconinput: "#FFFFFF",
    NbNumberBgSelected: "#4C36A5",
    NbNumberinputSelected: "#FFF",
    //Side Panel

    SpBG: "#F5F5F5",
    SpBox: "#FFF",
    SpBoxStroke: "#68686800",
    UsersNumber: " #4C36A5",
    UsersTitle: "#39354A",
    UsersText: "#39354A",
    PerformanceTitle: "#39354A",
    PerformanceText: "#39354A",
    PerformanceIcon: "#E1E1E1",
    User: "#765CA8",
    PerformanceIcon: "#E1E1E1",
    PerformanceBar: "#F4F4F4",
    CPU: "#9C37DA",
    RAM: "#EF7E2C",
    HDD: "#20DEBC",
    BorderButtonSideBar: "#4C36A5",
    ButtonText: "#4C36A5",
    ButtonBackground: "#FFFFFF",
    MonitorButtonHover: "#7d6dbe",

    //Toolbar
    StatusBarBg: "#FFFFFF",
    StatusBarBorder: "#EEEEEE",
    StatusWarning: "#CA8A04",
    StatusError: "#DC2626",
    ButtonSelected: "#4C36A5",
    ToolbarBgButton: "#FFFFFF",
    ToolbarColorButton: "#39354A",
    ToolbarBorderButton: "#EEEEEE",
    MonitorSearchBgColor: "#F8F8F8",
    MonitorSearchColor: "#AAAAAA",
    MonitorSearchBorder: "#EEEEEE",

    //Table
    TableEven: "#F5F5F5",
    TableOdd: "#FFFFFF",
    TableSelected: "#F3EFFF",
    Processing: "#16A34A",
    StopRefetch: "#DC2626",

    //Pagination

    PaginationContainerBG: "#EEEEEE",
    PaginationBG: "#FFFFFF",
    PaginationBorder: "#E3E3E3",
    PaginationColor: "#212B36",
    RowHover: "#f3efff",
    PaginationHover: "#7d6dbe",
    PaginationTextHover: "#FFFFFF",
    PaginationActive: "#4C36A5",
    PaginationArrowColor: "#4C36A5",
    PaginationArrowHoverColor: "#FFFFFF",
    PaginationArrowBG: "#FFFFFF",
    HoverButtonColor: "#FFFFFF",

    //MonitorButton

    MonitorButtonColor: "#39354A",
    MonitorButtonColor2: "#4C36A5",
    MonitorButtonBackground: "#FFFFFF",
    MonitorButtonBorder: "#EEEEEE",
    MonitorButtonHoverColor: "#FFFFFF",
    MonitorButtonHoverBackground: "linear-gradient(0deg, #634AC6, #634AC6)",
    MonitorButtonHoverShadow: "inset -4px -4px 7px #4C36A5",
    MonitorButtonActiveBackground: "#4C36A5",
    MonitorButtonActiveColor: "#FFFFFF",
    MonitorBackgroundDisabled: "#DCDCDC",
    MonitorColorDisabled: "#908D9A",
    MonitorColorButtonDisabled: "#B9B8BD",
    MonitorBackgroundButtonDisabled: "#EEEEEE",

    //Logger

    loggerIcon: "#FFB240",
    LoggerTitleColor: "#4C36A5",
    loggerSeparator: "#D9D9D9",
    loggerServerNameColor: "#4C36A5",
    loggerServerNameBackground: "#4C36A533",
    loggerNoFileBackground: "#FAFAFB",
    loggerNoFileColor: "#AAAAAA",
    loggerButtonBorder: "#EEEEEE",
    loggerButtonColor: "#4C36A5",
    hoverMenuButtonBackground: "#2A253F80",
    emailButtonBackground: "#4C36A5",
    emailBackground: "#DBD7ED",
    emailFormBackground: "#FFFFFF",
    emailInputBorder: "#EEEEEE",
    emailPlaceholderColor: "#AAAAAA",
    emailButtonBorderColor: "#EEEEEE",

    //------Installer------//
    //Login
    InLoginBoxBg: "#FFF",
    InLoginBoxStroke: "#EEE",
    InLoginType: "#FEFEFE",
    InLoginTypeStroke: "#EEEE",
    InLoginTypeSelected: "#4C36A5",
    InLoginTypeLabel: "#4C36A5",
    InLoginTypeSelectedLabel: "#FFF",
    //Installing
    InstallerBoxBg: "#FCFCFC",
    InstallerBoxStroke: "#EEEE",
    InstallerLoading: "#4C36A5",
    InstallerLoadingLabel: "#39354A",
    InstallerLoaded: "#4C36A5",
    InstallerLoadedLabel: "#4C36A5",
    InstallerDisabled: "#D9D9D9",
    InstallerDisabledLabel: "#CFCFCF",
    Links: "#4C36A5",
    InstallerScript: "#39354A",

    //Button
    CalltoActionButton: "#4C36A5",
    SecondaryButton: "#FFF",
    CalltoActionButtonLabel: "#FFF",
    SecondaryButtonLabel: "#39354A",
    SecondaryButtonStroke: "#EEEEEE",
    ToggleOn: "#16A34A",
    ToggleOff: "#686868",
    white: "#FFF",
    LottieBackground: "#4C4C4E",
    StepperBar: "#D9D9D9",
    //Disconnect
    DisconnectLink: "#4079E8",
  },

  dark: {
    primary: "#4C36A5",
    secondary: "#8D77E5",
    logo: "#FFF",
    disabled: "#B9B8BD",
    header: "#141414",
    ToolBarBg: "#1C1E1F",
    BodyBg: "#1C1E1F",
    BorderStroke: "#686868",
    Shadow: "#6A549E",
    BorderButtons: "#4C4C4E",
    separator: "#4C4C4E",

    //Switcher
    switcherBgActive: "#16A34A",
    switcherCircleActive: "#fff",
    switcherBgInactif: "#686868",
    switcherCircleInactif: "#fff",

    //auth
    footerText: "#4C4C4E",
    inputBorder: "#7C5FF0",
    placeholder: "#4C4C4E",
    langBoxBorder: "#4C4C4E",

    //PopUp
    PopUpBg: "#1C1E1F",
    PopUpStoke: "#686868",
    LinkFilename: "#8D77E5",
    PopUpBlur: "#363636",

    //------Support------//
    CardBg: "#222224",
    Title: "#F3F3F3",
    ColorButton: "#F3F3F3",
    BorderColor: "#7B7B7F",
    BorderButtonSupport: "#4C36A5",
    SupportButtonBg: "#7C5FF0",
    //------Login------//
    LoginLogo: "#8D77E5",
    LoginBoxBg: "#1C1E1F",
    LoginBoxBorder: "#4C4C4E",
    LoginBoxStroke: "#686868",
    LoginBG: "#141414",
    LoginButton: "#7C5FF0",
    dropdownBg: "#141414",
    dropdownColor: "#FFF",

    //------Designer------//
    PropertieBg: "#141414",
    Globe: "#F3F3F3",
    PublishDisabled: "#F3F3F3",
    PublishEnabled: "#F3F3F3",
    PublishIcon: "#F3F3F3",
    ConditionUI: "#363637",
    Condition: "#1F1F20",
    tagBg: "#C7C6FF",
    tag: "#141414",
    tagComp: "#141414B2",
    viewMode: "#C7C6FF",
    viewModeLabel: "#4A3990",
    editMode: "#7C5FF0",
    borderTheme: "#4C4C4E",
    borderBottom: "#4C4C4E",
    publishedItem: "#141414",
    dateColor: "#F3F3F3",
    searchCloseIcon: "#4C4C4E",
    searchRevisionBackground: "#141414",
    fileIcon: "#CCCCCC",
    SpliteBorder: "#686868",

    //----Installer----//
    titleText: "#FCFBFF",
    NextButtonBg: "#141414",
    NextButtonColor: "#7B7B7F",
    ProgressionBar: "#AAAAAA",
    disconnectBtnBorder: "#F3F3F3",
    Green: "#4ADE80",
    Red: "#F87171",
    Orange: "#FFC121",

    //----IconManager----//
    serachBar: "#141414",
    info_title: "#FFF",
    CustomButtons: "#FFF",
    BorderDashed: "#4C4C4E",
    border: "#EEEEE",

    //Ifons
    success: "#4ADE80",
    error: "#F87171",
    warning: "#FACC15",
    closeIcon: "#FF505A",
    checkBox: "#3869E7",
    //Text
    text: "#FFF",
    textDisabled: "#908D9A",
    textInactive: "#B9B8BD",
    TextSelected: "#8D77E5",
    TitleContainer: "#BFBDFF",
    SearchText: "#7B7B7F",
    ColorButtons: "#DCDCDC3F3",

    //Components library
    icon: "#FFFFFF#",
    iconBg: "#323337",
    iconLabel: "#EEEEEE",
    iconHover: "#8D77E5",
    iconDisable: "#606162",
    iconBgDisable: "#323337",
    iconLabelDisable: "#565859",
    iconColorDisabled: "#5B5B5E",
    //Main Buttons
    DefaultButton: "#2B2A2D",
    ButtonHover: "#8D77E5",
    ButtonClicked: "#4C36A5#",
    ButtonDisable: "#2B2A2D",
    DefaultButtonLabel: "#FFF",
    ButtonLabel: "#FFF",
    ButtonLabelDisable: "#DCDCDC",
    DefaultButtonStroke: "#686868",
    ButtonDisableStroke: "#686868",
    //ToolBar Buttons
    TbDefaultButton: "#1C1E1F",
    TbButtonHover: "#8D77E5",
    TbButtonClicked: "#4C36A5",
    TbButtonDisable: "#141414",
    TbDefaultButtonLabel: "#FFF",
    TbButtonLabel: "#FFF",
    TbButtonLabelDisable: "#DCDCDC",
    TbDefaultButtonStroke: "##686868",
    iconColor: "#F3F3F3",
    borders: "#141414",
    //Components Layer
    CpSelection: "#323337",
    //Revision
    RSelectedBG: "#4C36A5",
    RSelectedLabel: "#FFF",
    REditStroke: "#4C36A5",
    REditLabel: "#FFF",
    //Input
    InputBg: "#323337",
    InputStroke: "#686868",
    InputLabel: "#AAAAAA",
    InputStrokeSelected: "#4C36A5",
    InputLabelSelected: "#8D77E5",

    //------Monitor------//
    monitorText: "#F3F3F3",
    monitorBorder: "#4C4C4E",
    monitorBg1: "#141414",
    monitorBg2: "#1C1E1F",
    monitorInput: "#1C1E1F",
    monitorInputBorder: "#4C4C4E",
    monitorSwitchBackground: "#141414",
    moniteurRow: "#7C5FF0",
    //Number Bar
    NbBg: "#323337",
    NbIconBg: "#5F5E61",
    NbNumberBg: "#B9B8BD",
    NbNumberStroke: "#686868",
    NbNumberinput: "#39354A",
    NbIconinput: "#FFF",
    NbNumberBgSelected: "#4C36A5",
    NbNumberinputSelected: "#FFF",

    //Side Panel
    SpBG: "#141414",
    SpBox: "#1C1E1F",
    SpBoxStroke: "#686868",
    UsersText: "#F3F3F3",
    UsersNumber: "#8D77E5",
    UsersTitle: "#F3F3F3",
    PerformanceTitle: "#F3F3F3",
    PerformanceText: "#F3F3F3",
    PerformanceBar: "#4C4C4E",
    PerformanceIcon: "#765CA8",
    CPU: "#9C37DA",
    RAM: "#EF7E2C",
    HDD: "#20DEBC",
    BorderButtonSideBar: "#7C5FF0",
    ButtonBackground: "#1C1E1F",
    ButtonText: "#F3F3F3",
    MonitorButtonHover: "#8D77E5",

    //toolBar
    StatusBarBg: "#1C1E1F",
    StatusBarBorder: "#4C4C4E",
    StatusWarning: "#FFCC47",
    StatusError: "#F87171",
    ButtonSelected: "#7C5FF0",
    ToolbarBgButton: "#141414",
    ToolbarColorButton: "#F3F3F3",
    ToolbarBorderButton: "#4C4C4E",
    MonitorSearchBgColor: "#1C1E1F",
    MonitorSearchColor: "#AAAAAA",
    MonitorSearchBorder: "#4C4C4E",

    //Table
    TableEven: "#141414",
    TableOdd: "#1C1E1F",
    TableSelected: "#323337",
    Processing: "#4ADE80",
    StopRefetch: "#F87171",

    //Pagination
    PaginationBG: "#1F1F1F",
    PaginationContainerBG: "#141414",
    PaginationBorder: "#4C4C4E",
    PaginationColor: "#F3F3F3",
    PaginationHover: "#8D77E5",
    PaginationActive: "#7C5FF0",
    PaginationTextHover: "#F3F3F3",
    RowHover: "#4C36A5",
    PaginationArrowColor: "#7C5FF0",
    PaginationArrowHoverColor: "#F3F3F3",
    PaginationArrowBG: "#2B2A2D",
    HoverButtonColor: "#F3F3F3",

    //MonitorButton

    MonitorButtonColor: "#FFFFFF",
    MonitorButtonColor2: "#4C36A5",
    MonitorButtonBackground: "#2B2A2D",
    MonitorButtonBorder: "#7B7B7F",
    MonitorButtonHoverColor: "#FFFFFF",
    MonitorButtonHoverBackground: "linear-gradient(0deg, #8C8ADA, #8C8ADA)",
    MonitorButtonHoverShadow: "inset -4px -4px 7px #6851C7",
    MonitorButtonActiveBackground: "#7C5FF0",
    MonitorButtonActiveColor: "#FFFFFF",
    MonitorColorDisabled: "#DCDCDC80",
    MonitorBackgroundButtonDisabled: "#4C4C4E80",

    //logger
    loggerIcon: "#FFC632",
    LoggerTitleColor: "#BFBDFF",
    loggerSeparator: "#4C4C4E",
    loggerServerNameColor: "#F3F3F3",
    loggerServerNameBackground: "#7C5FF0",
    loggerButtonBorder: "#7C5FF0",
    loggerNoFileBackground: "#141414",
    loggerNoFileColor: "#4C4C4E",
    loggerButtonColor: "#BFBDFF",
    hoverMenuButtonBackground: "#F3F3F380",
    emailButtonBackground: "#FFFFFF",
    emailBackground: "#7C5FF0",
    emailFormBackground: "#141414",
    emailInputBorder: "#7B7B7FEE",
    emailPlaceholderColor: "#B7B7BF",
    emailButtonBorderColor: "#B7B7BF",

    //------Installer------//
    //Login
    InLoginBoxBg: "#141414",
    InLoginBoxStroke: "#686868",
    InLoginType: "#1C1E1F",
    InLoginTypeStroke: "#686868",
    InLoginTypeSelected: "#4C36A5",
    InLoginTypeLabel: "#FFF",
    InLoginTypeSelectedLabel: "#FFF",
    //Installing
    InstallerBoxBg: "#141414",
    InstallerBoxStroke: "#686868",
    InstallerLoading: "#4C36A5",
    InstallerLoadingLabel: "#FCFBFF",
    InstallerLoaded: "#4C36A5",
    InstallerLoadedLabel: "#8D77E5",
    InstallerDisabled: "#BABABA",
    InstallerDisabledLabel: "#CFCFCF   ",
    Links: "#8D77E5 ",
    InstallerScript: "#F3F3F3",
    //Button
    CalltoActionButton: "#4C36A5 ",
    SecondaryButton: "#2B2A2D",
    CalltoActionButtonLabel: "#FFF",
    SecondaryButtonLabel: "#FFF",
    SecondaryButtonStroke: "#686868",
    ToggleOn: "#16A34A",
    ToggleOff: "#686868",
    white: "#FFF",
    LottieBackground: "#5A5A5A",
    StepperBar: "#4C4C4E",
    //Disconnect
    DisconnectLink: "#BFBDFF",
  },
};

export default themeColors;
