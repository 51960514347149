import React, { useState } from "react";
import "./index.scss";

import Icon from "../../../../../components/CustomIcon/Icon";
import DynamicModal from "../../../../../components/CustomModal";

function InstallerItem({ scripts = [] }) {
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  const openModal = (message, status) => {
    setCurrentMessage(message);
    {
      status === "failure"
        ? setCurrentStatus("ERROR")
        : setCurrentStatus(status.toUpperCase());
    }
    setShowPopUp(true);
  };
  const closePopUp = () => {
    setShowPopUp(false);
  };

  return (
    <div className="installationScripts">
      {scripts
        ?.filter((script) => script?.content)
        .map((script, index) => (
          <div key={index} className="installation-script">
            <div className="installation-script-name">
              {script?.content?.name}
              {(script?.status?.toString()?.toUpperCase() === "FAILURE" ||
                script?.status?.toString()?.toUpperCase() === "WARNING") && (
                <Icon
                  icon="info1"
                  size={20}
                  className="icon-hover"
                  onClick={() => openModal(script.message, script.status)}
                />
              )}
            </div>
            {script?.status && (
              <span className="file-status">
                {script?.status?.toString()?.toUpperCase() === "FAILURE" && (
                  <Icon icon="nomark" size={24} style={{ color: "#CE2728" }} />
                )}
                {script?.status?.toString()?.toUpperCase() === "WARNING" && (
                  <Icon icon="warning" size={24} style={{ color: "#CA8A04" }} />
                )}
                {script?.status?.toString()?.toUpperCase() === "SUCCESS" && (
                  <Icon icon="check" size={24} style={{ color: "#16A34A" }} />
                )}
              </span>
            )}
          </div>
        ))}
      <DynamicModal
        open={showPopUp}
        onClose={closePopUp}
        modalTitle={currentStatus}
        showFooter={false}
        iconTitle={
          currentStatus === "ERROR" ? (
            <Icon icon="close1" size={27} style={{ color: "#DC2626" }} />
          ) : (
            <Icon icon="warning" size={16} style={{ color: "#CA8A04" }} />
          )
        }
      >
        <div className="popupContainer-script">
          <div className="title-popup-script">{currentMessage}</div>
        </div>
      </DynamicModal>
    </div>
  );
}

export default InstallerItem;
