import React from "react";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useStore } from "react-redux";
import "./index.scss";

function Title({ data = {}, path = [], titleForm = "--" }) {
  let store = useStore();
  return (
    <div
      className="titleMap"
      onClick={(event) =>
        handleComponentProp(event, null, data, path, store, "title")
      }
    >
      <div className="title-icon">
        <Icon icon="picture_solid" color="#ccc" size={25} />
      </div>
      <div className="title-value">{data?.name ? data?.name : "--"}</div>
    </div>
  );
}

export default Title;
