export const Higanta = {
  themeName: "Higanta",
  isDark: false,
  CardViewBgColor: "#2D373B10",
  secondaryLight: "#59C8CD80",
  primary: "#21776D",
  iconAppItem: "#21776D",
  AppText: "#21776D",
  loginFooter: "#444",
  connectBtn: "#21776D",
  loader: "#59C8CD",
  headerColor: "#21776D",
  primaryLight: "#21776D50",
  primaryColor: "#21776DB2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#59C8CD",
  focusedBorderLoginInput: "#21776D",
  focusedLabelLoginInput: "#21776D",
  resetInputLogin: "#21776D",

  languageIcon: "#444",
  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#09342f",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#59C8CD",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#000",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#21776D",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#21776D",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#21776D50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#21776D",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#f3f6f5AA",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#59C8CD",
  bnConnect: "#21776D",
  notfocusedBorderLoginInput: "#50585b",
  headerPrimary: "#21776D00",
  headerDark: "#09342f00",
  headerText: "#21776D",
  notificationUpColor: "#F7FDFC",
  notificationDownColor: "#EBF9F8",

  listItemNotificationUpColor: "#EBF9F8",
  listItemNotificationDownColor: "#E3F7F5",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F4F3",

  notFocusedSocialMedia: "#21776D",
  focusedSocialMedia: "#21776Dcc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#21776D",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
