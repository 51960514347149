import React from "react"
import { useSelector, useStore } from "react-redux"
import CustomSide from "./CustomSide"
import ViewModeCompareFiles from "./XmlEditor"
import EditModeCompareFiles from "./XmlEditor/compare"
import fetchListVersion from "../../services/revision"
import "./index.scss"
function CompareFiles(xmlValue, onChange = () => {}, revision) {
  const {
    showRight,
    showLeft,
    leftCode,
    rightCode,
    draftDisplay,
    versionsList,
  } = useSelector((state) => ({
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    rightFileName: state?.entities?.compareFiles?.rightCode?.filename || "",
    showLeft: state?.entities?.compareFiles?.leftCode?.active,
    showRight: state?.entities?.compareFiles?.rightCode?.active,
    leftCode: state?.entities?.compareFiles?.leftCode?.code,
    rightCode: state?.entities?.compareFiles?.rightCode?.code,
    draftDisplay: state?.entities?.explorer?.draftDisplay,
    versionsList: state?.entities?.revision?.list || [],
  }))
  let store = useStore()
  const isOn = JSON.parse(localStorage.getItem("isUp"))
  const iconClicked = JSON.parse(localStorage.getItem("iconClicked"))
  /* useEffect(() => {
    return () => {
      HandleOneSideOpened(
        store,
        showLeft,
        showRight,
        leftFileName,
        rightFileName,
        isOn,
        iconClicked,
        draftDisplay
      );
    };
  }, []); */
  const { dataVersions = [] } = fetchListVersion(versionsList) || {}
  const lastVersion = dataVersions?.[0]
  return (
    <React.Fragment>
      {!showRight || !draftDisplay ? (
        <div className='CompareFile-Container'>
          {!showLeft || !showRight ? (
            <div className='CompareFiles'>
              <div
                className='split3 readOnly3'
                style={{
                  backgroundColor: "white",
                }}
              >
                <CustomSide
                  data={dataVersions}
                  onChange={xmlValue.onChange}
                  status={showLeft}
                />
              </div>

              <div
                className='split4 readOnly4'
                style={{
                  backgroundColor: "white",
                }}
              >
                <CustomSide
                  data={dataVersions}
                  isleft={false}
                  status={showRight}
                  onChange={xmlValue.onChange}
                />
              </div>
            </div>
          ) : (
            <ViewModeCompareFiles
              leftCode={leftCode}
              rightCode={rightCode}
              revision={xmlValue.revision}
            />
          )}
        </div>
      ) : (
        <div className='CompareFile-Container'>
          <EditModeCompareFiles
            leftCode={rightCode}
            xmlValue={xmlValue.xmlValue}
            revision={xmlValue.revision}
          />
        </div>
      )}
    </React.Fragment>
  )
}
export default CompareFiles
