import React, { useState, useEffect, useCallback } from "react";
import IconManagerBar from "./components/IconManagerBar";
import IconCard from "./components/IconCard";
import UploadIcons from "./components/UploadIcons";
import SideBar from "./components/SideBar";
import imxtools from "imxtools";
import getAllIcons from "./services/getAllIcons";
import { useDropzone } from "react-dropzone";
import CustomModal from "../../components/CustomModal";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import useToasts from "../../components/CustomToast/useToasts";
import {
  handleUpload,
  handleDownload,
  handleCopyName,
  confirmDelete,
} from "./services/index";

const IconManager = () => {
  const { addToast } = useToasts();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [iconsSelected, setIconsSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [iconsListing, setIconsListing] = useState([]);
  const [isRenameActive, setIsRenameActive] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [iconsToDelete, setIconsToDelete] = useState([]);
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [ctrlPressed, setCtrlPressed] = useState(false);

  const iconMaxSize =
    (parseFloat(process.env.REACT_APP_ICON_MAX_SIZE) || 0.5) * 1024 * 1024;

  const OnConfirmDelete = useCallback(() => {
    confirmDelete(
      iconsToDelete,
      setIconsListing,
      iconsListing,
      addToast,
      setIconsToDelete,
      setIconsSelected,
      setShowDeleteModal
    );
  }, [
    iconsToDelete,
    setIconsListing,
    iconsListing,
    addToast,
    setIconsToDelete,
    setIconsSelected,
    setShowDeleteModal,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && showDeleteModal) {
        OnConfirmDelete();
      }
    };

    if (showDeleteModal) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showDeleteModal, OnConfirmDelete]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Control") {
        setCtrlPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Control") {
        setCtrlPressed(false);
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        iconsSelected.forEach((icon) => {
          handleDeleteRequest(icon);
        });
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconsSelected]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "a") {
        event.preventDefault();

        handleSelectAll();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  useEffect(() => {
    async function fetchIcons() {
      try {
        const iconsData = await getAllIcons(addToast);
        if (iconsListing?.length !== iconsData?.length) {
          setIconsListing(iconsData);
          setFilteredIcons(iconsData);
        }
      } catch (error) {}
    }
    fetchIcons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconsListing]);

  useEffect(() => {
    const filteredIcons = iconsListing.filter((icon) => {
      const lowerCaseSearch = searchText.toLowerCase();
      return (
        (icon?.iconName &&
          icon?.iconName.toLowerCase().includes(lowerCaseSearch)) ||
        (icon?.property &&
          imxtools
            ?.toArray(icon?.property)
            ?.some(
              (prop) =>
                prop?.iconName &&
                prop?.iconName.toLowerCase().includes(lowerCaseSearch)
            ))
      );
    });
    setFilteredIcons(filteredIcons);
  }, [iconsListing, searchText]);

  function downloadIcons() {
    handleDownload(iconsListing, iconsSelected, addToast);
  }

  function handleRenameClick(icon) {
    setCurrentIcon(icon);
    setIsRenameActive(true);
  }

  function DeleteMessage() {
    const numberOfIcons = iconsToDelete.length;
    if (numberOfIcons === 1) {
      return `Do you want to delete this icon "${iconsToDelete[0]}"?`;
    } else {
      return `Do you want to delete the ${numberOfIcons} selected icons?`;
    }
  }

  function toggleIconSelection(icon) {
    setOpenDropdown(null);
    if (ctrlPressed) {
      setIconsSelected((prevIconsSelected) => {
        const isSelected = prevIconsSelected.some(
          (selectedIcon) => selectedIcon.iconName === icon.iconName
        );

        if (isSelected) {
          return prevIconsSelected.filter(
            (selectedIcon) => selectedIcon.iconName !== icon.iconName
          );
        } else {
          return [...prevIconsSelected, icon];
        }
      });
    } else {
      setIconsSelected([icon]);
    }
  }

  function handleToggle(index) {
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === index ? null : index
    );
    setIconsSelected((prevIconsSelected) => {
      const isSelected = prevIconsSelected.includes(iconsListing[index]);
      if (!isSelected) {
        return [...prevIconsSelected, iconsListing[index]];
      }
      return prevIconsSelected;
    });
  }

  function handleSelectAll() {
    setSelectAll(!selectAll);
    setIconsSelected(selectAll ? [] : [...filteredIcons]);
  }

  function handleDeleteRequest() {
    if (iconsSelected.length === 0) {
      addToast("Select at least one icon to delete.", "warn");
    } else {
      setIconsToDelete(iconsSelected.map((icon) => icon.iconName));
      setShowDeleteModal(true);
    }
  }

  const { getRootProps } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const pngFiles = acceptedFiles.filter(
          (file) => file.type === "image/png" || file.name.endsWith(".png")
        );
        if (pngFiles.length > 0) {
          handleUpload(
            pngFiles,
            iconMaxSize,
            addToast,
            setIconsListing,
            iconsListing
          );
        } else {
          addToast("Only png files are supported.", "warn");
        }
      }
    },
    accept: ".png",
  });

  return (
    <div className="iconManager" {...getRootProps()}>
      <IconManagerBar
        iconsListing={filteredIcons}
        setIconsListing={setIconsListing}
        iconsSelected={iconsSelected}
        DownloadIcon={downloadIcons}
        toggleSelectAll={handleSelectAll}
        iconMaxSize={iconMaxSize}
      />
      {iconsListing?.length === 0 ? (
        <div className="upload-container">
          <UploadIcons
            setIconsListing={setIconsListing}
            iconMaxSize={iconMaxSize}
          />
        </div>
      ) : (
        <div className="main-container">
          <div className="icons-container" onClick={() => setIconsSelected([])}>
            {filteredIcons?.map((icon, index) => (
              <IconCard
                key={index}
                icon={icon}
                onToggle={() => handleToggle(index)}
                onClick={() => toggleIconSelection(icon)}
                isOpen={openDropdown === index}
                isSelected={iconsSelected?.includes(icon)}
                setIconsSelected={setIconsSelected}
                onDeleteIcon={handleDeleteRequest}
                iconsSelected={iconsSelected}
                DownloadIcon={downloadIcons}
                onRenameClick={handleRenameClick}
                isMultiSelect={iconsSelected.length > 1}
                OnCopy={handleCopyName}
              />
            ))}
          </div>
          <div className="icon-sideBar">
            <SideBar
              iconsSelected={iconsSelected}
              searchText={searchText}
              setSearchText={setSearchText}
              iconsListing={iconsListing}
              setIconsListing={setIconsListing}
              setIconsSelected={setIconsSelected}
              onDeleteIcon={handleDeleteRequest}
              isRenameActive={isRenameActive}
              setIsRenameActive={setIsRenameActive}
              currentIcon={currentIcon}
              setCurrentIcon={setCurrentIcon}
              OnCopy={handleCopyName}
              addToast={addToast}
            />
          </div>
        </div>
      )}
      <CustomModal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onClickPrimaryAction={OnConfirmDelete}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowDeleteModal(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Delete"
        children={DeleteMessage()}
        alignment="center"
      />
    </div>
  );
};
export default IconManager;
