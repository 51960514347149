import React, { useEffect, useState } from "react";
import Icon from "../../../../components/CustomIcon/Icon";
import { useStore } from "react-redux";
import { getBorderRadius } from "../../services/utility";
import "./index.scss";

function LayoutsButton({
  compareXML,
  readOnly = true,
  componentsLibrary = false,
  properties = false,
  codeEditor = false,
  floatingBtnStatus,
  setFloatingBtnStatus = () => {},
  selected,
  versionsList,
}) {
  const [hoveredButton, setHoveredButton] = useState(null);
  let store = useStore();

  useEffect(() => {
    if (Object.keys(selected)?.length === 0) {
      const payload = versionsList[0]?.filename
        ? { obj: { [versionsList[0]?.filename]: true } }
        : {};
      store.dispatch({
        type: "revision/setSelectedItem",
        payload,
      });
      store.dispatch({
        type: "revision/setSelectedRevision",
        payload: {
          data: versionsList[0],
        },
      });
    }
  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionsList]);

  const layoutBtn = [
    {
      icon: "lib",
      label: "Components Library",
      status: componentsLibrary && !readOnly,
      reducer: "compLibLayout",
    },
    {
      icon: "prop",
      label: "Properties",
      status: properties,
      reducer: "propLayout",
    },
    {
      icon: "code",
      label: "Code Editor",
      status: codeEditor,
      reducer: "codeLayout",
    },
  ];

  const setLayout = (reducer) => {
    if (reducer === "compLibLayout" && readOnly) {
      return;
    }
    store.dispatch({
      type: `layouts/${reducer}`,
    });
  };

  return (
    <div
      className="floating-content"
      onMouseLeave={() => setFloatingBtnStatus(!floatingBtnStatus)}
    >
      {!compareXML &&
        (floatingBtnStatus ? (
          <React.Fragment>
            {layoutBtn.map((layout, index) => (
              <div
                key={index}
                className="floatingBtn-open"
                onMouseEnter={() => setHoveredButton(index)}
                onMouseLeave={() => setHoveredButton(null)}
                onClick={() => setLayout(layout?.reducer)}
              >
                <div
                  className="floatingBtn-icon"
                  style={{
                    borderRadius: getBorderRadius(
                      index,
                      hoveredButton === index
                    ),
                  }}
                >
                  <Icon
                    icon={layout.icon}
                    size={24}
                    color={layout?.status ? "white" : "#ffffff33"}
                  />
                </div>
                {hoveredButton === index && (
                  <div
                    className="floatingBtn-label"
                    style={{ color: !layout?.status && "#ffffff33" }}
                  >
                    {layout.label}
                  </div>
                )}
              </div>
            ))}
          </React.Fragment>
        ) : (
          <div
            className="floatingBtn close"
            onMouseEnter={() => setFloatingBtnStatus(!floatingBtnStatus)}
          >
            <Icon icon="sections" size={20} color="white" />
          </div>
        ))}
    </div>
  );
}

export default LayoutsButton;
