function calculatePercentages(scripts = []) {
  try {
    const totalScripts = scripts.length;
    const installedScripts = scripts.filter(
      (script) => script.status === true
    ).length;
    const installedPercentage = Math.round(
      (installedScripts / totalScripts) * 100
    );
    const failedPercentage = 100 - installedPercentage;

    return { installedPercentage, failedPercentage };
  } catch (error) {
    console.error("Error in calculatePercentages:", error);
  }
}
export default calculatePercentages;
