import React, { useContext, useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import SearchBar from "../SearchBar";
import imxtools from "imxtools";
import "./index.scss";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { handleComponentProp } from "../../services/handleComponentProperties";
import DropTargetBody from "../DropTargetBody";
import { getIn } from "lodash-redux-immutability";
import useToasts from "../../../../../../../components/CustomToast/useToasts";
import { AppContext } from "../../../../../services/AppContext";
import Modal from "../Modal";

import Form from "../Form";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import EventToolbar from "./EventToolbar";

function Toolbar({
  data = {},
  path = [],
  searchStatus = false,
  readOnly = true,
  currentPath = [],
  listData = {},
}) {
  const numForms = 0;

  const [search, setSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [adSearchIndex, setAdSearchIndex] = useState(null);
  const [events, setEvents] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [targetForm, setTargetForm] = useState("");
  const [targetFormIndex, setTargetFormIndex] = useState();
  const [formHform, setFormHform] = useState(null);
  let store = useStore();
  const { addToast } = useToasts();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  let skeletonState = useSelector(
    (state) => state?.entities?.explorer?.skeleton || {}
  );

  const { setTargetRefEvent, targetRefEvent, setHeaderEvent } =
    useContext(AppContext);

  const numEvent = imxtools?.toArray(data?.event)?.length;

  let formsData = imxtools.toArray(
    getIn(skeletonState, [...path, "form"]) || []
  );
  let hformsData = imxtools.toArray(
    getIn(skeletonState, [...path, "hform"]) || []
  );

  useEffect(() => {
    setEvents(imxtools?.toArray(data?.event));
  }, [data?.event]);

  useEffect(() => {
    let findSearchEvent = -1;
    findSearchEvent = imxtools
      ?.toArray(data?.event ? data?.event : [])
      ?.findIndex((e) => e?.service?.toUpperCase() === "ADVANCEDSEARCH");
    setIsSearch(
      findSearchEvent !== -1 && listData?.search?.toLowerCase() === "true"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.event]);

  const handleInputSearch = (event) => {
    event.stopPropagation();
    const adSearchIndex = imxtools
      ?.toArray(data?.event ? data?.event : [])
      ?.findIndex(
        (event) => event?.service?.toUpperCase() === "ADVANCEDSEARCH"
      );
    setAdSearchIndex(
      numEvent <= 1 || adSearchIndex === -1 ? null : adSearchIndex?.toString()
    );
    handleComponentProp(
      event,
      numEvent <= 1 || adSearchIndex === -1 ? null : adSearchIndex?.toString(),
      imxtools?.toArray(data?.event ? data?.event : [])[adSearchIndex],
      [...path, "toolbar"],
      store,
      "event"
    );
  };

  const handleForm = (e, event) => {
    const targetRef = event?.targetref ? event?.targetref?.toLowerCase() : "";
    let foundIndex = -1;
    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRef) {
        foundIndex = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
    if (event && event.hasOwnProperty("targetref")) {
      setTargetRefEvent(event?.targetref);
      setOpenForm(true);
    } else {
      addToast("you should add targetref to the event !", "warn");
    }
  };

  useEffect(() => {
    let foundIndex = -1;
    let foundIndexHform = -1;
    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndex = index;
        return true;
      }
      return false;
    });
    const foundHform = hformsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndexHform = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
      setFormHform("form");
    } else if (foundHform) {
      setTargetForm(foundHform);
      setTargetFormIndex(foundIndexHform);
      setFormHform("hform");
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
  }, [data, formsData, hformsData, targetRefEvent]);
  return (
    <React.Fragment>
      <DropTargetBody
        className={`toolbar-content ${
          isSelectedComponent(readOnly, [...path, "toolbar"], currentPath)
            ? "selectedComponent"
            : !readOnly && "unSelectedComponent"
        }`}
        style={{
          flexDirection: language?.toLowerCase() === "ar" && "row-reverse",
        }}
        accept={"event"}
        dragParent={path}
        onClick={(e) => {
          handleComponentProp(e, null, data, path, store, "toolbar");
          setHeaderEvent(false);
        }}
      >
        {search ? (
          <SearchBar
            handleInputSearch={handleInputSearch}
            data={data?.event}
            search={search}
            setSearch={setSearch}
            currentPath={currentPath}
            path={[...path, "toolbar"]}
            numEvent={numEvent}
            adSearchIndex={adSearchIndex}
            readOnly={readOnly}
          />
        ) : (
          <React.Fragment>
            {searchStatus ? (
              <div
                className={`searchIcon ${
                  isSelectedComponent(
                    readOnly,
                    numEvent > 1
                      ? [...path, "toolbar", "event", adSearchIndex]
                      : [...path, "toolbar", "event"],
                    currentPath
                  ) && "selectedComponent"
                }`}
                onClick={(event) => handleInputSearch(event)}
                onDoubleClick={() => setSearch(!search)}
              >
                <Icon icon="search" size={25} />
              </div>
            ) : null}
            <div
              className="other-events"
              style={{
                width: isSearch && "calc(100% - 35px)",
                maxWidth: isSearch && "calc(100% - 35px)",
                minWidth: isSearch && "calc(100% - 35px)",

                flexDirection:
                  language?.toLowerCase() === "ar" && "row-reverse",
              }}
            >
              {events
                ?.filter(
                  (event) =>
                    event?.service?.toUpperCase() !== "ADVANCEDSEARCH" ||
                    !event?.service
                )
                ?.map((event, indexEv) => {
                  return (
                    <EventToolbar
                      key={indexEv}
                      index={indexEv}
                      data={event}
                      path={[...path, "toolbar"]}
                      events={events}
                      setEvents={setEvents}
                      currentPath={currentPath}
                      numEvent={numEvent}
                      handleForm={handleForm}
                      readOnly={readOnly}
                    />
                  );
                })}
            </div>
          </React.Fragment>
        )}
      </DropTargetBody>
      <Modal openModal={openForm} tab={true} isForm={true}>
        <Form
          data={targetForm}
          path={
            formHform === "form"
              ? formsData.length === 1
                ? [...path, formHform]
                : [...path, formHform, targetFormIndex]
              : hformsData.length === 1
              ? [...path, formHform]
              : [...path, formHform, targetFormIndex]
          }
          index={
            formsData <= 1
              ? null
              : targetForm > numForms - 1
              ? (targetForm - numForms)?.toString()
              : targetForm?.toString()
          }
          parentPath={path}
          component="form"
          setOpenForm={setOpenForm}
          readOnly={readOnly}
        />
      </Modal>
    </React.Fragment>
  );
}

export default Toolbar;
