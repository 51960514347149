import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

function Uninstallation() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="setupVerificationContainer">
      <div className="VerificationBody">
        <div className="VerificationTitle">Uninstalling MAXapps</div>
        <div className="verif-loader">
          <Box>
            <CircularProgress
              variant="determinate"
              value={100}
              style={{
                color: "#e0e0e0",
                position: "absolute",
                zIndex: 1,
              }}
            />
            <CircularProgress
              variant="determinate"
              value={progress}
              style={{
                color: "#4c36a5",
                position: "absolute",
                zIndex: 2,
              }}
            />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Uninstallation;
