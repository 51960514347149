import MaximoInstall from "./steps/MaximoInstallation";
import MaximoLogin from "./steps/MaximoLogin";
import MaximoSetup from "./steps/MaximoSetup";
import DisconnectComponent from "./steps/components/DisconnectComponent";
import Disconnect from "./steps/Disconnect";

export const renderStep = {
  login: <MaximoLogin />,
  install: <MaximoInstall />,
  MaximoSetup: <MaximoSetup />,
  SyncBackend: <Disconnect />,
  Disconnect: <DisconnectComponent />,
};
