function handleSaveRevision(store, skeleton, addToast) {
  try {
    store.dispatch({
      type: "explorer/updateData",
      payload: {
        skeleton: { ...skeleton },
        readOnly: false,
      },
    });

    store.dispatch({
      type: "editor/updateData",
      payload: {
        data: { ...skeleton },
      },
    });

    addToast("Saved Successfully", "success");
  } catch (error) {
    console.error("error--------------", error);
  }
}

export default handleSaveRevision;
