import React, { useState } from 'react';

import themeColors from '../../../../../theme/themeColors';
import CustomButton from '../../../../../components/CustomButton';
import Icon from '../../../../../components/CustomIcon/Icon';
import CustomModal from '../../../../../components/CustomModal';
import { changeMode } from '../services/getMode';
import { useDispatch } from 'react-redux';

////////

const RESET = 'interceptor/resetRequestResponsePair';

const Modes = {
  disableInterceptor: { disableInterceptor: true },
  rabbitmq: { rabbitmq: true },
  justSocket: { justSocket: true },
};

const RequestsHeader = ({
  mode,
  setMode,
  setRequests,
  startSocket,
  stopSocket,
  setInterceptorIsPaused,
  interceptorIsPaused,
  requestsData,

  setSelectedRequest,
}) => {
  const dispatch = useDispatch();
  const resetPairs = () => {
    dispatch({
      type: RESET,
    });
  };
  const [clearReq, setClearReq] = useState(false);
  const [isChanginMode, setIsChangingMode] = useState(false);
  const [confirmChangeMode, setConfirmChangeMode] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const handleClearAll = () => {
    setSelectedRequest(null);
    resetPairs();
    setRequests([]);
  };

  const [searchInput, setSearchInput] = useState('');

  const handleModeChange = async (modeToChange) => {
    const mode = await changeMode(modeToChange);
    setMode(mode);
  };

  return (
    <div className="request-header-container">
      {/* modal */}
      <CustomModal
        open={clearReq}
        onClose={() => {
          setClearReq(false);
        }}
        onClickPrimaryAction={handleClearAll}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setClearReq(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Clear All Requests"
        children={'Are you sure you want to clear all requests?'}
        alignment="center"
      >
        <p>Are you sure you want to clear all requests?</p>
      </CustomModal>
      <CustomModal
        open={confirmChangeMode}
        onClose={() => setConfirmChangeMode(false)}
        onClickPrimaryAction={() => {
          handleModeChange(selectedMode);
          setConfirmChangeMode(false);
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => setConfirmChangeMode(false)}
        primaryLabel="Change Mode"
        secondaryLabe="Cancel"
        modalTitle="Change Interceptor Mode"
        alignment="center"
      >
        <p>You’re about to switch Mode. Would you like to proceed?</p>
      </CustomModal>
      <div className="request-header">
        <h2
          style={{
            color: themeColors.light.requestsNb,
            fontSize: '24px',
          }}
        >
          {requestsData?.length}{' '}
          <span
            style={{
              fontSize: '20px',
              fontWeight: 400,
            }}
          >
            Requests
          </span>
        </h2>

        <div className="buttons-requests">
          <CustomButton
            iconName={interceptorIsPaused ? 'Resume' : 'Pause'}
            iconColor={themeColors.light.interceptorBtn}
            iconSize={25}
            tooltip={
              interceptorIsPaused ? 'Resume intercepting' : 'Pause intercepting'
            }
            onClick={() => {
              interceptorIsPaused ? startSocket() : stopSocket();
              setInterceptorIsPaused(!interceptorIsPaused);
            }}
          />
          {/* vertical seperator */}
          <div
            style={{
              borderLeft: '2px solid #D7D7D7',
              height: '21px',
              margin: '0px 10px',
              borderRadius: '50px',
            }}
          ></div>
          <CustomButton
            iconName="delete_solid"
            iconColor={themeColors.light.interceptorBtn}
            iconSize={25}
            tooltip="Clear All Requests"
            onClick={() => setClearReq(true)}
          />
          <div
            style={{
              borderLeft: '2px solid #D7D7D7',
              height: '21px',
              margin: '0px 10px',
              borderRadius: '50px',
            }}
          ></div>
          {/* mode */}
          {/* it s select but  have first value as mode?.justSocket */}
          {/* {isLoadingMode ? (
            <Lottie options={LoadingOptions} height={40} width={40} />
          ) : 
           */}
          {/* ( */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              position: 'relative',
            }}
          >
            {isChanginMode && (
              <div
                style={{
                  position: 'absolute',
                  top: '35px',
                  right: '0',
                  backgroundColor: '#fff',
                  border: '1px solid #EEEEEE',
                  borderRadius: '2px',
                  zIndex: 9999,
                  display: 'flex',

                  flexDirection: 'column',
                }}
              >
                <div
                  className="selection-mode"
                  onClick={() => {
                    setSelectedMode(Modes.justSocket);
                    setConfirmChangeMode(true);
                    setIsChangingMode(false);
                  }}
                >
                  <div>Realtime</div>
                  {/* checked icon for if it s selected */}
                  {mode?.httpInterceptor ? (
                    !mode?.rabbitmq && mode?.justSocket ? (
                      <Icon
                        icon="check"
                        size={20}
                        style={{
                          color: '#16A34A',
                        }}
                      />
                    ) : null
                  ) : null}
                </div>
                <div
                  className="selection-mode"
                  onClick={() => {
                    setSelectedMode(Modes.rabbitmq);
                    setConfirmChangeMode(true);
                    setIsChangingMode(false);
                  }}
                >
                  <div>Persist</div>
                  {/* checked icon for if it s selected */}
                  {mode?.httpInterceptor ? (
                    mode?.rabbitmq ? (
                      <Icon
                        icon="check"
                        size={20}
                        style={{
                          color: '#16A34A',
                        }}
                      />
                    ) : null
                  ) : null}
                </div>

                <div
                  className="selection-mode"
                  onClick={() => {
                    setSelectedMode(Modes.disableInterceptor);
                    setConfirmChangeMode(true);
                    setIsChangingMode(false);
                  }}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Disabled
                  </div>
                  {/* checked icon for if it s selected */}
                  {!mode?.httpInterceptor ? (
                    <Icon
                      icon="check"
                      size={20}
                      style={{
                        color: '#16A34A',
                      }}
                    />
                  ) : null}
                </div>
              </div>
            )}
            <div
              style={{
                //disabled border red
                border: !mode?.httpInterceptor
                  ? '1px solid #FF0000'
                  : '1px solid #16A34A',
                color: !mode?.httpInterceptor ? '#FF0000' : '#16A34A',
                backgroundColor: !mode?.httpInterceptor ? '#FFD6D8' : '#DCF1E4',
                borderRadius: '72px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              {mode?.httpInterceptor ? 'On' : 'Off'}
            </div>
            {/* mode */}

            <div
              style={{
                color: '#39354A',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {mode?.httpInterceptor
                ? mode?.rabbitmq
                  ? 'Persist'
                  : 'Realtime'
                : 'Disabled'}
            </div>
            {/* menu-dots-vertical */}
            <CustomButton
              onClick={() => {
                setIsChangingMode(!isChanginMode);
              }}
              iconName="menu-dots-vertical"
              iconColor="#4C36A5"
              iconSize={20}
              tooltip="Change Mode"
            />
            {/* the actuial mode */}
          </div>
          {/* )} */}
        </div>
      </div>
      {/* search */}
      <div className="search-requests">
        <input
          type="text"
          placeholder="Search"
          className="search-input"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        <Icon
          icon="search"
          size={22}
          className="search-icon"
          style={{
            color: searchInput ? themeColors.light.interceptorBtn : '#989898',
          }}
        />
        {/* div of searched/allrequest requests */}
        <div className="searched-requests">
          {/* vertical seperatir */}
          <div
            style={{
              borderLeft: '2px solid #D7D7D7',
              alignSelf: 'center',
              height: '20px',
              margin: '0 10px',
            }}
          ></div>
          <span className="requests-result">0</span>
          <span className="requests-result">/</span>
          <span className="requests-result">{requestsData?.length}</span>
          <span className="requests-span">Requests</span>
        </div>
      </div>
    </div>
  );
};

export default RequestsHeader;
