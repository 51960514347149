import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { useStore } from "react-redux";

import Icon from "../../../../../components/CustomIcon/Icon";
import ProgressBar from "../../../components/progress-bar";
import InstallerItem from "../../../components/items-install/item";

import { __installAll, __getData } from "./services/index";
import { HandleStepChange } from "../../../services/stepChange/stepChange";

const InstallationComponent = () => {
  const store = useStore();
  const [isCompleted, setIsCompleted] = useState(false);
  const [scriptsList, setScriptsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [scriptsNumber, setScriptsNumber] = useState(null);

  const allScriptsLength = 47;

  const handleError = useCallback((message) => {
    setErrorMessage(message);
  }, []);

  const installAll = useCallback(async () => {
    await __installAll(setProgress, setScriptsList, setIsCompleted);
  }, [handleError]);

  const restartInstallation = useCallback(() => {
    setProgress(0);
    setIsCompleted(false);
    setErrorMessage("");
    setScriptsList([]);
    installAll();
  }, [installAll]);

  const prepareData = useCallback(() => {
    __getData(setScriptsNumber, handleError);
  }, [handleError]);

  useEffect(() => {
    const ac = new AbortController();
    if (!scriptsNumber) {
      prepareData();
    } else {
      installAll();
    }
    return () => ac.abort();
  }, [scriptsNumber, installAll, prepareData]);

  return (
    <div className="LoaderConatiner">
      <div className="installationHeader">
        <div className="installationHeader-title">
          {progress < 100 ? (
            <h1>Installation</h1>
          ) : (
            <h1>MAXapps components are installed successfully</h1>
          )}
        </div>
        <ProgressBar pourcentage={progress} />
      </div>
      {scriptsList?.length ? <InstallerItem scripts={scriptsList} /> : null}
      <div className="installationFooter">
        {scriptsList?.length == allScriptsLength ? (
          <Icon
            icon="reset"
            size={28}
            className="login-cached-icon"
            style={{ color: "var(--SupportButtonBg)", cursor: "pointer" }}
            onClick={restartInstallation}
          ></Icon>
        ) : null}

        <button
          className={`installation-next-button ${
            scriptsList?.length < allScriptsLength ? "grey-out" : ""
          }`}
          onClick={() => HandleStepChange(store, "MaximoSetup")}
          disabled={scriptsList?.length < allScriptsLength}
        >
          Next
          <Icon
            icon="chevron-right"
            size={16}
            style={{
              color:
                scriptsList?.length < allScriptsLength ? "#808080" : "#FFFFFF",
              marginLeft: "7px",
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default InstallationComponent;
