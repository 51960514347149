function HandleCloseEditMode(store) {
  store.dispatch({
    type: "compareFiles/setRightSideShow",
    payload: {
      showRight: false,
    },
  });
  store.dispatch({
    type: "compareFiles/updateRightXmlValue",
    payload: {
      rightXml: "",
    },
  });
  store.dispatch({
    type: "compareFiles/setRightMode",
    payload: {
      rightMode: "",
    },
  });
  store.dispatch({
    type: "compareFiles/setSelectedItemRight",
    payload: {
      itemRightSelected: {},
    },
  });
  store.dispatch({
    type: "compareFiles/updateCompareState",
    payload: {
      compare: false,
    },
  });
  store.dispatch({
    type: "compareFiles/setRightFileName",
    payload: {
      rightFile: "",
    },
  });
}
export default HandleCloseEditMode;
