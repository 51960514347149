import "./index.scss"
import Icon from "../../../../../../components/CustomIcon/Icon"
import { useTheme } from "../../../../../../theme/themeContext"

const LogFilesContent = ({
  filename,
  lineCount,
  size,
  clearLogsFileByFileName,
  downloadLogFile,
}) => {
  const { themeMode, themeColors } = useTheme()

  return (
    <div className='logFiles-content-container'>
      <div className='file-info'>
        <div className='file-icon'>
          <Icon
            icon='log'
            size={24}
            style={{ color: themeColors[themeMode].Globe }}
          />
        </div>
        <div className='file-details'>
          <h5 className='file-name'>{filename}</h5>
          <p className='file-line'>{lineCount} Line(s)</p>
          <p className='file-size'>{size} KO</p>
        </div>
      </div>
      <div className='file-actions'>
        <button
          className='clear-icon'
          onClick={() => clearLogsFileByFileName(filename)}
        >
          <Icon
            icon='clear'
            size={24}
            style={{ color: themeColors[themeMode].ButtonSelected }}
          />
        </button>
        <div className='vertical-bar'> </div>

        <a href={`logger/downloadLogFile/${filename}`} download>
          <button
            className='download-icon'
            onClick={() => downloadLogFile(filename)}
          >
            <Icon
              icon='Download'
              size={24}
              style={{ color: themeColors[themeMode].ButtonSelected }}
            />
          </button>
        </a>
      </div>
    </div>
  )
}
export default LogFilesContent
