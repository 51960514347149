import "./index.scss"

const FilterInput = ({
  label,
  type = "text",
  id,
  advancedSearch = () => {},
  value,
}) => {
  const handleChange = (e) => {
    advancedSearch(e.target.value, label, false)
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      advancedSearch(e.target.value, label, true)
    }
  }

  return (
    <div className='input-group-Advanced-search'>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}

export default FilterInput
