import React, { useRef, useEffect, useState } from "react";
import CodeMirror from "codemirror";
import diff_match_patch from "diff-match-patch";
import "codemirror/addon/merge/merge.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/merge/merge.css";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/xml-fold.js"; // If you're folding XML/HTML
import exportFile from "../services/exportFile";
import "codemirror/theme/material-ocean.css";
import "codemirror/theme/abbott.css";
import "codemirror/theme/duotone-light.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/xml-hint.js";
import { useStore, useSelector } from "react-redux";
import xml2js from "minexml2js";
import { useLocation } from "react-router-dom";
import useToasts from "../../../../../components/CustomToast/useToasts";
import resize from "../services/resize";
import "./themes/vscode-dark.css";
import "./themes/vscode-light.css";
import calculateDifferences from "../services/calculateDifferences";
import "./index.css";

import Icon from "../../../../../components/CustomIcon/Icon";
import HandleCloseForViewMode from "../services/handleCloseViewMode";
import Dropdown from "../../../../../components/Dropdown";
import { MoreVert } from "@mui/icons-material";
import handleEditContentVersion from "../../../services/helpers/handleEditContentVersion";

Object.keys(diff_match_patch).forEach((key) => {
  window[key] = diff_match_patch[key];
});

function ViewModeCompareFiles(leftCode, rightCode, revision) {
  const store = useStore();
  const { addToast } = useToasts();

  let location = useLocation();
  const value = leftCode.leftCode;
  const orig1 = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n<maxapps version="V2">\n  <notifications/>\n</maxapps>`;
  const orig2 = leftCode.rightCode;
  const {
    itemRightSelected,
    itemLeftSelected,
    leftFileName,
    rightFileName,
    leftMode,
    rightMode,
    resetChanges,
    leftCod,
    rightCod,
    fontSize,
    theme,
  } = useSelector((state) => ({
    itemRightSelected:
      state?.entities?.compareFiles?.rightCode?.fileSelected
        ?.itemRightSelected || {},
    itemLeftSelected:
      state?.entities?.compareFiles?.leftCode?.fileSelected?.itemLeftSelected ||
      {},
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    rightFileName: state?.entities?.compareFiles?.rightCode?.filename || "",
    leftMode: state?.entities?.compareFiles?.leftCode?.mode || "",
    rightMode: state?.entities?.compareFiles?.rightCode?.mode || "",
    resetChanges: state?.entities?.compareFiles?.resetChanges || false,
    leftCod: state?.entities?.compareFiles?.leftCode?.code || "",
    rightCod: state?.entities?.compareFiles?.rightCode?.code || "",
    theme: state?.entities?.codeeditor?.theme,
    fontSize: state?.entities?.codeeditor?.fontSize,
  }));
  const view = useRef(null);
  const [mvInstance, setMvInstance] = useState(null);
  const [panes, setPanes] = useState(2);
  const [highlight, setHighlight] = useState(true);
  const [connect, setConnect] = useState("align");
  const [collapse, setCollapse] = useState(true);
  const [editorValue, setEditorValue] = useState(leftCode.leftCode);
  const [rightValue, setRightValue] = useState(leftCode.rightCode);

  const [addedLines, setAddedLines] = useState(0);
  const [removedLines, setRemovedLines] = useState(0);
  useEffect(() => {
    setEditorValue(leftCode.leftCode);
  }, [leftCode.leftCode]);

  useEffect(() => {
    if (value == null || !view.current) return;
    var target = view.current;
    var height = view.current.clientHeight;
    target.innerHTML = "";
    const newThemeValue = theme === "vs" ? "vscode-light" : "vscode-dark";
    const mvInstance = CodeMirror.MergeView(target, {
      value: editorValue,
      connect,
      origRight: rightValue,
      lineNumbers: true,
      mode: "xml",
      highlightDifferences: highlight,
      theme: newThemeValue,
      collapseIdentical: collapse,
      revertButtons: true,
      showDifferences: false,
      allowEditingOriginals: true,
      lineWrapping: true,
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
      foldGutter: true,
      foldOptions: {
        widget: "\u2194", // This is the default fold widget. You can customize it as needed.
      },
      indentWithTabs: true,
    });
    const handleResize = () => {
      resize(mvInstance, window.innerHeight); // Adjust this line if resize needs more args
    };

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Call handler to set initial size
    handleResize();
    const editor = mvInstance.editor();
    editor.getWrapperElement().style.fontSize = `${fontSize}px`;
    const setupAutocomplete = (editor) => {
      editor.setOption("extraKeys", {
        "Ctrl-Space": function (cm) {
          cm.showHint({ hint: CodeMirror.hint.xml });
        },
      });
    };
    setupAutocomplete(editor);
    const modified = mvInstance.rightOriginal();
    modified.getWrapperElement().style.fontSize = `${fontSize}px`;
    if (modified) {
      setupAutocomplete(modified); // For the right editor
    }

    editor.on("change", () => {
      const { addedLines, removedLines } = calculateDifferences(
        mvInstance,
        addToast
      );
      setAddedLines(addedLines);
      setRemovedLines(removedLines);
      setEditorValue(editor.getValue());
    });

    modified.on("change", () => {
      const { addedLines, removedLines } = calculateDifferences(
        mvInstance,
        addToast
      );
      setAddedLines(addedLines);
      setRemovedLines(removedLines);
      setRightValue(modified.getValue());
    });

    setMvInstance(mvInstance);
    const initialCounts = calculateDifferences(mvInstance, addToast);
    setAddedLines(initialCounts.addedLines);
    setRemovedLines(initialCounts.removedLines);
    if (theme === "vs") {
      const mergePanesGutter = document.querySelectorAll(
        ".CodeMirror-gutter, .CodeMirror-merge-gutter"
      );
      mergePanesGutter.forEach((pane) => {
        pane.style.backgroundColor = "#FFFFFF";
      });
    }
    // Remember to clean up the event listener when the component unmounts or dependencies change
    return () => {
      editor.off("change");
    };
  }, [value, orig1, orig2, panes, highlight, connect, collapse]);

  useEffect(() => {
    if (mvInstance) {
      mvInstance.setShowDifferences(highlight);
    }
  }, [highlight, mvInstance]);

  useEffect(() => {
    if (resetChanges && mvInstance) {
      mvInstance.editor().setValue(leftCod); // Resetting the main editor
      if (mvInstance.rightOriginal()) {
        mvInstance.rightOriginal().setValue(rightCod); // Resetting the right (original) editor if it exists
      }
      setCollapse(!collapse);
      // Alternatively, if you want to refresh or reload the MergeView to its initial state:
      // dv.refresh(); // This is a placeholder; actual implementation may vary
      store.dispatch({
        type: "compareFiles/updateResetChangesState",
        payload: {
          resetChanges: false,
        },
      });
    }
  }, [resetChanges, mvInstance]);

  useEffect(() => {
    if (mvInstance) {
      let d = document.createElement("div");
      d.style.cssText = "width: 50px; margin: 7px; height: 14px";
      mvInstance.editor().addLineWidget(57, d);
    }
  }, [mvInstance]);
  const handleEditVersion = async () => {
    const searchRegExp = new RegExp("&", "g");
    const xmlData = editorValue.replace(searchRegExp, "&amp;");
    const parser = new xml2js.Parser({
      explicitArray: false,
      emptyTag: { $: { id: "mockId" } },
    });
    const { maxOrder, result } = await parser.parseStringPromise(xmlData);
    await handleEditContentVersion(
      addToast,
      store,
      itemLeftSelected?.filename,
      maxOrder,
      itemLeftSelected
    );
  };

  const DropdownLeftEditorOptions = [
    {
      label: "Switch to Edit mode",
      action: handleEditVersion,
      active: true,
      icon: "edit2",
    },
    {
      label: "Export",
      action: () => exportFile(editorValue, location),
      active: true,
      icon: "Export",
    },
    {
      label: "Device view",
      icon: "Device",
      active: false,
    },
  ];
  const DropdownRightEditorOptions = [
    {
      label: "Export",
      action: () => exportFile(rightValue, location),
      active: true,
      icon: "Export",
    },
    {
      label: "Device view",
      icon: "Device",
      active: false,
    },
  ];

  return (
    <div className="diffContainer">
      <div
        className="compareContainer"
        style={{ width: leftCode.revision ? "calc(100vw - 22rem)" : "100%" }}
      >
        <div className="LeftContainer">
          <div className="editorContainerLeft">
            <div className="editorHeaderLeft">
              <>
                <div className="editorName">
                  <p className="statutLeft">
                    <span className="originalContext">{leftMode}</span>
                    <span className="hoveredContext">
                      <span>Close</span>

                      <Icon
                        icon={"close1"}
                        size={22}
                        color="white"
                        className="iconClose"
                        onClick={() => HandleCloseForViewMode(true, store)}
                      />
                    </span>
                  </p>
                  <p className="headerFile">
                    {itemLeftSelected &&
                    Object.keys(itemLeftSelected).length > 0
                      ? `${itemLeftSelected.version} ${itemLeftSelected.xmlAlias}`
                      : `${leftFileName}`}
                  </p>
                </div>
                <div className="editorProprety">
                  <div className="editorPropList">
                    <Dropdown
                      options={DropdownLeftEditorOptions}
                      withIcon={true}
                    >
                      <MoreVert />
                    </Dropdown>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="CollapseContainer">
          <Icon
            icon={collapse ? "collaps" : "expand"}
            size={24}
            color="#959497"
            onClick={() => setCollapse(!collapse)}
          />
        </div>
        <div className="RightContainer">
          <div className="editorContainernewRight">
            <div className="editorHeaderRight">
              <>
                <div className="editorName">
                  <div className="rightStatut">
                    <span className="originalContext">{rightMode}</span>

                    <span className="hoveredContext">
                      <span>Close</span>
                      <Icon
                        className="iconClose"
                        icon={"close1"}
                        size={22}
                        color="white"
                        onClick={() => HandleCloseForViewMode(false, store)}
                      />
                    </span>
                  </div>
                  <div className="headerFile">
                    {itemRightSelected &&
                    Object.keys(itemRightSelected).length > 0
                      ? `${itemRightSelected.version}  ${itemRightSelected.xmlAlias}`
                      : `${rightFileName}`}
                  </div>
                </div>
                <div className="editorProprety">
                  <div className="editorPropretyIcon">
                    <Icon icon="infos" size={24} color="#36a5a5" />
                    <p className="changesRight">
                      {addedLines + removedLines} Changes
                    </p>
                  </div>
                  <div className="editorPropList">
                    <Dropdown
                      options={DropdownRightEditorOptions}
                      withIcon={true}
                    >
                      <MoreVert />
                    </Dropdown>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <div
          ref={view}
          className="difContainer codeMirrorContainer"
          style={{ width: leftCode.revision ? "calc(100vw - 22rem)" : "100%" }}
        />
      </React.Fragment>
    </div>
  );
}

export default ViewModeCompareFiles;
