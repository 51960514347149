export const Avocado = {
  themeName: "Avocado",
  isDark: false,
  CardViewBgColor: "#0c756010",
  secondaryLight: "#12182380",
  primary: "#0c7560",
  iconAppItem: "#0c7560",
  AppText: "#333",
  loginFooter: "#0c7560",
  loader: "#0c7560",
  headerColor: "#0c7560",
  primaryLight: "#0c756050",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  primaryColor: "#0C7560B2",
  secondary: "#91c44d",
  focusedLabelLoginInput: "#00652d",
  resetInputLogin: "#00652d",
  focusedBorderLoginInput: "#00652d",

  languageIcon: "#0c7560",

  settingCollapseColor: "#eee",
  welcomeBg: "#FBF9F9",
  success: "#3ecd7e",
  primaryDark: "#116151",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#0c7560",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#333",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#0c7560",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#C9D9CF",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#0c7560",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#0c756050",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#C9D9CF",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#C9D9CF",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#0c7560",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#ffffffdd",
  colorTextInput: "#91c44d",
  LogincolorTextInput: "#FFF",
  LogincolorLabelInput: "#00652d",
  connectBtn: "#84bd00",
  loginOptions: "#000",
  shadowBtnConnect: "#91c44d",
  bnConnect: "#0c7560",
  notfocusedBorderLoginInput: "#eee",
  headerPrimary: "#0c756000",
  headerDark: "#11615100",
  headerText: "#0c7560",
  notificationUpColor: "#F6FEFC",
  notificationDownColor: "#E8FDF8",

  listItemNotificationUpColor: "#F1FEFB",
  listItemNotificationDownColor: "#DFFCF6",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F4F3",

  notFocusedSocialMedia: "#0c7560",
  focusedSocialMedia: "#0c7560cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#0c7560",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
