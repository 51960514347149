import { services } from "../../../services/configVariable";
import { axiosProviderNode as serviceRequest } from "../../../../../services/http/httpService";
import transformForXml from "../../../services/helpers/transformForXml";
import xml2js from "minexml2js";
async function getFileXmlContent(filename) {
  const transactionNodeService = services.NODE_ENDPOINT_GET_FILE_CONTENT;

  const res = await serviceRequest.get(transactionNodeService, {
    filename,
  });

  const builder = new xml2js.Builder();
  const xml = builder.buildObject(
    transformForXml(res?.data?.content?.skeleton)
  );
  const searchRegExp = new RegExp("&amp;", "g");
  const finalXML = xml.replace(searchRegExp, "&");

  return finalXML;
}
export default getFileXmlContent;
