import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import ReactJson from "react-json-view";
import { useTheme } from "../../../../../../theme/themeContext";
import Icon from "../../../../../../components/CustomIcon/Icon";
import { tableFailedDataHeader, LoadingOptions } from "../../data";
import useDataCollection from "../../../../customHooks/useDataCollection";
import AdvancedSearch from "../AdvancedSearch/index";
import { cleanFetchCollection } from "../../services/cleanFetchCollection";
import NoData from "../NoData/index";
import { recursiveFn } from "../../services/recursiveFn";
import Lottie from "react-lottie";

function FailedCollectionTable({
  toggleTable = "",
  toggleAdvancedSearch = "",
  setToggleAdvancedSearch = () => {},
  advancedSearchValue = {},
  setAdvancedSearchValue = () => {},
  advancedInputVisibility = {},
  setAdvancedInputVisibility = () => {},
  selectedFailedDataId = "",
  scrollPosition = "",
  updateScrollPosition = () => {},
  activeRow = "",
  setActiveRow = () => {},
  searchValue = "",
  setSearchValue = () => {},
  isSearching = "",
  setIsSearching = () => {},
  filteredData = [],
  setFilteredData = () => {},
  handleFailedDataToFetch = () => {},
}) {
  const { themeMode } = useTheme();
  const { data: finalDataCollection, loading } = useDataCollection(
    "NODE_ENDPOINT_GET_FAILEDDATACOLLECTION",
    cleanFetchCollection
  );
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const tableRef = useRef(null);
  const rowScrollPosition = useRef(0);

  useEffect(() => {
    if (!isSearching && finalDataCollection.length > 0) {
      setFilteredData(finalDataCollection);
    }
  }, [finalDataCollection, isSearching]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = scrollPosition;
    }
  });

  useEffect(() => {
    if (selectedFailedDataId) {
      const rowIndex = filteredData.findIndex(
        (row) => row?.dataCollectionId === selectedFailedDataId
      );
      if (rowIndex !== -1) {
        setActiveRow(rowIndex);
      }
    }
  }, [selectedFailedDataId, filteredData]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = rowScrollPosition.current;
    }
  }, [activeRow]);

  const handleSearch = () => {
    if (searchValue.trim() === "") {
      setFilteredData(finalDataCollection);
      setIsSearching(false);
      setFilteredDataLength(0);
    } else {
      const newFilteredData = finalDataCollection.filter((item) =>
        recursiveFn(item, searchValue)
      );
      setFilteredData(newFilteredData);
      setFilteredDataLength(newFilteredData?.length);
      setIsSearching(true);
    }
    setActiveRow(null);
  };

  const handleRowClick = (index) => {
    const isRowActive = index === activeRow;
    if (!isRowActive) {
      rowScrollPosition.current = tableRef.current.scrollTop;
    }
    setActiveRow(isRowActive ? null : index);
  };

  const handleResetSearch = () => {
    setSearchValue("");
    setFilteredData(finalDataCollection);
    setIsSearching(false);
    setActiveRow(null);
  };

 

  return (
    <div>
      {loading ? (
        <Lottie
          options={LoadingOptions}
          height={100}
          width={120}
          isClickToPauseDisabled={true}
          style={{ cursor: "auto" }}
        />
      ) : (
        <div className="data-collection-container">
          {toggleAdvancedSearch[toggleTable]?.toggleFilter && (
            <AdvancedSearch
              setFilteredData={setFilteredData}
              data={finalDataCollection}
              toggleSearch={toggleTable}
              advancedSearchValue={advancedSearchValue}
              setAdvancedSearchValue={setAdvancedSearchValue}
              advancedInputVisibility={advancedInputVisibility}
              setAdvancedInputVisibility={setAdvancedInputVisibility}
              setActiveRow={setActiveRow}
            />
          )}
          <div className="monitor-Dashboard">
            <div className="search-table">
              <button
                className="advanced-filter"
                onClick={() => {
                  setToggleAdvancedSearch((prev) => ({
                    ...prev,
                    [toggleTable]: {
                      toggleFilter: !prev[toggleTable].toggleFilter,
                    },
                  }));
                }}
              >
                <Icon
                  icon="advanced-search"
                  size={22}
                  style={{
                    color: toggleAdvancedSearch[toggleTable]?.toggleFilter
                      ? "#4C36A5"
                      : "#989898",
                  }}
                />
              </button>
              <p className="search-divider"></p>
              <button className="default-search" onClick={handleSearch}>
                <Icon
                  icon={"search"}
                  size={22}
                  style={{ color: searchValue ? "#4C36A5" : "#989898" }}
                />
              </button>
              <input
                type="text"
                className="dashboard-search-input"
                placeholder={"Search"}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />

              <button className="reset-search" onClick={handleResetSearch}>
                <Icon
                  icon={isSearching && "circle-close-new"}
                  size={22}
                  style={{ color: "#C4C3C9" }}
                />
              </button>
              <p className="search-divider"></p>
              <div
                className="CollectionLength"
                style={{
                  color: isSearching ? "var(--primary)" : "var(--monitorText)",
                  width: isSearching && "auto",
                }}
              >
                <p className="Collection-sum">
                  {" "}
                  {isSearching
                    ? filteredDataLength + " /  " + finalDataCollection?.length
                    : finalDataCollection?.length}
                </p>
                <sub className="Collection"> Collections</sub>
              </div>
            </div>

            {finalDataCollection.length > 0 ? (
              <div
                className="data-table-wrapper"
                ref={tableRef}
              >
                <table className="data-table">
                  <thead className="table-header">
                    <tr>
                      {tableFailedDataHeader?.map((header, index) => (
                        <th
                          className={`header-cell ${
                            header === "#" || header === "Actions"
                              ? "row-index-header"
                              : ""
                          }`}
                          key={index}
                          style={{
                            textAlign: header === "#" && "center",
                            paddingLeft: header === "#" && "2rem",
                          }}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="tbody-wrapper">
                    {filteredData.length > 0 ? (
                      filteredData?.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          <>
                            <tr
                              onClick={() => handleRowClick(rowIndex)}
                              id={`row-${rowIndex}`}
                              style={{
                                background:
                                  activeRow === rowIndex &&
                                  "var(--moniteurRow)",
                              }}
                            >
                              <td className="row-index-cell">
                                <div className="row-icon">
                                  <Icon
                                    icon={
                                      activeRow === rowIndex
                                        ? "arrow-up"
                                        : "arrow-down"
                                    }
                                    size={16}
                                    color={
                                      activeRow !== rowIndex
                                        ? "#DADADA"
                                        : "var(--primary)"
                                    }
                                  />

                                  {rowIndex + 1}
                                </div>
                              </td>
                              {/* <td
                                className="row-index-cell"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleFailedDataToFetch(
                                    row?.dataCollectionId
                                  );
                                }}
                              >
                                <button className="fetch-reference">
                                  <Icon
                                    icon={"returnToSource"}
                                    size={22}
                                    style={{
                                      color: "#989898",
                                    }}
                                  />
                                </button>
                              </td> */}
                              <td>{row?.dataCollectionId || "--"}</td>
                              <td>{row?.error || "--"}</td>
                              <td>{row?.users[0]?.login || "--"}</td>
                              <td>{row?.timeout || "--"}</td>
                              <td>{row?.date || "--"}</td>
                              <td>{row?.isProcessing || "--"}</td>
                              <td>{row?.refreshNumber || "--"}</td>
                              <td>{String(row?.stopRefetch) || "--"}</td>
                            </tr>
                            {activeRow === rowIndex && (
                              <tr>
                                <td colSpan="9" className="full-width-row">
                                  <ReactJson
                                    src={row}
                                    theme={
                                      themeMode === "light"
                                        ? "rjv-default"
                                        : "monokai"
                                    }
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordBreak: "break-all",
                                    }}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        </React.Fragment>
                      ))
                    ) : (
                      <NoData
                        data1={"No results found."}
                        data2={"Please try another search."}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoData data1={"No Data to Display."} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FailedCollectionTable;

// {
//   filteredData.length > 0 ? (
//     <div className={`grid-container ${activeRow ? "detail-shown" : ""}`}>
//       {filteredData
//         .slice((currentPage - 1) * 10, currentPage * 10)
//         .map((row, index) => {
//           const rowIndex = index + (currentPage - 1) * 10
//           if (activeRow !== null && activeRow !== rowIndex) {
//             return null
//           }

//           return (
//             <React.Fragment key={`row-${rowIndex}`}>
//               <div
//                 className={`row ${index % 2 === 0 ? "odd" : "even"} ${
//                   activeRow === rowIndex ? "active-row" : ""
//                 }`}
//                 onClick={() => handleRowClick(rowIndex)}
//               >
//                 {renderTruncatedCell(rowIndex + 1, "", true, rowIndex)}
//                 {renderTruncatedCell(row[0])}
//                 {renderTruncatedCell(row[1].mboname)}
//                 {renderTruncatedCell(row[1].login[0])}
//                 {renderTruncatedCell(row[1].whereClauseId)}
//                 {renderTruncatedCell(String(row[1].ready))}
//                 {renderTruncatedCell(row[1].result)}
//                 {renderTruncatedCell(row[1].dataHash)}
//                 {renderTruncatedCell(row[1].whereclause)}
//                 {/* {renderTruncatedCell(
//                 row.isProcessing?.toString(),
//                 "green-cell"
//               )} */}

//                 {/* {renderTruncatedCell(
//                 row.stopRefetch?.toString(),
//                 "red-cell"
//               )} */}
//               </div>
//               {activeRow === rowIndex && (
//                 <div className='full-width-row'>
//                   <ReactJson
//                     src={row}
//                     theme={
//                       themeMode === "light" ? "rjv-default" : "monokai"
//                     }
//                   />
//                 </div>
//               )}
//             </React.Fragment>
//           )
//         })}
//     </div>
//   ) : (
//     <div className='no-results-container'>
//       <p className='no-results-found-title '>No results found.</p>
//       <p className='no-results-found-title '>
//         Please try another search.
//       </p>
//     </div>
//   )
// }

// useEffect(() => {
//   const newFilteredData = finalDataCollection.filter((item) =>
//     Object.values(item || {}).some((value) =>
//       value?.toString().toLowerCase().includes(searchValue.toLowerCase())
//     )
//   )
//   setFilteredDataCollection(newFilteredData)
//   setTotalPages(Math.ceil(finalDataCollection.length / 10))
// }, [finalDataCollection, searchValue, setTotalPages])

// <div className='table-pagination'>
//   <PaginationButton
// totalPages={totalPages}
//     currentPage={currentPage}
//     handlePageChange={handlePageChange}
//     setActiveRow={setActiveRow}
//   />
// </div>
