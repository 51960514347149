import HomeSettingsInspection from "../HomeSettingsInspection";
import QuestionInspection from "../QuestionInspection";
import "./index.scss";

const RightSideInspection = ({
  isOpen,
  viewContent,
  setViewContent,
  listQuestions,
  questionToDisplay,
  setQuestionToDisplay,
  data,
  path,
  setOpenForm = () => {},
  uploadedPhotos = [],
  setUploadedPhotos = () => {},
  handleConfirm = () => {},
}) => {
  return (
    <div
      className={
        isOpen
          ? "inspection-content-right-open"
          : "inspection-content-right-close"
      }
      style={{ borderBottomRightRadius: 35 }}
    >
      {viewContent === "home" ? (
        <HomeSettingsInspection
          setViewContent={setViewContent}
          listQuestions={listQuestions}
          setQuestionToDisplay={setQuestionToDisplay}
          data={data}
          path={path}
        />
      ) : (
        <QuestionInspection
          isOpen={isOpen}
          setViewContent={setViewContent}
          viewContent={viewContent}
          listQuestions={listQuestions}
          questionToDisplay={questionToDisplay}
          setQuestionToDisplay={setQuestionToDisplay}
          data={data}
          path={path}
          setOpenForm={setOpenForm}
          setUploadedPhotos={setUploadedPhotos}
          uploadedPhotos={uploadedPhotos}
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};
export default RightSideInspection;
