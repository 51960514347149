import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Language from "./index";

const i18nInstanceApp = i18n.createInstance();
i18nInstanceApp.use(initReactI18next).init({
  resources: {
    ar: {
      translation: Language.ar,
    },
    en: {
      translation: Language.en,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18nInstanceApp;
