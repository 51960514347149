import "./index.scss";
import LogFilesContent from "./LogFilesContent/index";
import LoggerInput from "./LoggerInput/index";
import clearLocalLogsByName from "./services/clearLocalLogs";
import getLogFilesInfo from "./services/getLogFilesInfo";
import downloadLogFileByFileName from "./services/downloadLogFileByFileName";
import setenvConfDataWithParams from "./services/setenvConfData";
import React, { useState, useEffect } from "react";
import Icon from "../../../../../components/CustomIcon/Icon";
import { useTheme } from "../../../../../theme/themeContext";
import useToasts from "../../../../../components/CustomToast/useToasts";

const IMXLoggerRight = ({ envConfData = {}, setEnvConfData = () => {} }) => {
  const [reload, setReload] = useState(false);
  const { themeMode, themeColors } = useTheme();
  const { addToast } = useToasts();
  const [logFilesInfo, setLogFilesInfo] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const inputs = [
    { label: "Logger Queue Name", id: "MX_LOGGER_QUEUE_NAME", type: "text" },
    { label: "Logger Host", id: "MXLOGGER_HOST", type: "text" },
    { label: "Logger Port", id: "MXLOGGER_PORT", type: "text" },
    { label: "Logger Username", id: "MXLOGGER_USERNAME", type: "text" },
    { label: "Logger Password", id: "MXLOGGER_PASSWORD", type: "password" },
    {
      label: "Logger Reconnect Timeout",
      id: "MXLOGGER_RECONNECT_TIMEOUT",
      type: "text",
    },
  ];

  const handleSaveClick = () => {
    const inputKeys = [
      "MX_LOGGER_QUEUE_NAME",
      "MXLOGGER_HOST",
      "MXLOGGER_PORT",
      "MXLOGGER_USERNAME",
      "MXLOGGER_PASSWORD",
      "MXLOGGER_RECONNECT_TIMEOUT",
    ];

    const updatedValues = {};

    inputKeys.forEach((key) => {
      const element = document.getElementById(key);
      if (element) {
        updatedValues[key] = element.value;
      }
    });

    const updatedEnvConfData = {
      ...envConfData,
      ...updatedValues,
    };
    setEnvConfData(updatedEnvConfData);
  };

  // useEffect(() => {
  //   const updateBackend = async () => {
  //     try {
  //       await setenvConfDataWithParams({
  //         newEnvconf: envConfData,
  //       });
  //     } catch (error) {}
  //   };

  //   updateBackend();
  // }, [envConfData]);

  const handleReloadClick = () => {
    setReload(true);
    setTimeout(() => setReload(false), 100);
  };

  const getLogsFilesInfo = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await getLogFilesInfo();
      setLogFilesInfo(res?.content || []);
      setLoading(false);
    } catch (error) {
      console.error("error ===>", error);
      setLogFilesInfo([]);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearLogsFileByFileName = React.useCallback(async (fileName) => {
    try {
      const res = await clearLocalLogsByName({ fileName });
      getLogsFilesInfo();
      addToast(res?.content?.result, "success");
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadLogFile = React.useCallback(async (fileName) => {
    try {
      const res = await downloadLogFileByFileName({ fileName });
    } catch (error) {
      console.error("error ===>", error);
    }
  }, []);

  React.useEffect(() => {
    getLogsFilesInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="IMXLogger-main-right">
      <div className="logFiles-container">
        <div className="logFiles-title-flex">
          <h3 className="logFiles-title">Local log Files</h3>
          <button className="log-reload" onClick={getLogsFilesInfo}>
            <Icon
              icon="retry"
              size={16}
              style={{ color: themeColors[themeMode].loggerIcon }}
            />
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="logFiles-content-flex">
            {logFilesInfo.length > 0 ? (
              logFilesInfo.map((data) => (
                <LogFilesContent
                  key={data.filename}
                  filename={data.fileName}
                  lineCount={data.numberOfLines}
                  size={data.fileSize}
                  clearLogsFileByFileName={clearLogsFileByFileName}
                  downloadLogFile={downloadLogFile}
                />
              ))
            ) : (
              <div className="no-logs-loaded">
                <p>No Logs Loaded</p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="horizontal-separator"></div>
      <div className="logger-config-container">
        <div className="logger-config-header-container">
          <div className="logger-config-title-container">
            <h3 className="logger-config-title">
              Edit config and reconnect to IMX LOGGER:
            </h3>
            <p className="logger-config-subtitle">
              Make sure to save changes before reconnecting.
            </p>
          </div>
          <div className="save-reload-icons">
            <button className="save-file" onClick={handleSaveClick}>
              <Icon
                icon="Save"
                size={24}
                style={{ color: themeColors[themeMode].ButtonSelected }}
              />
            </button>
            <button className="reload-file" onClick={handleReloadClick}>
              <Icon
                icon="retry"
                size={16}
                style={{ color: themeColors[themeMode].loggerIcon }}
              />
            </button>
          </div>
        </div>
        <div className="loggers-input-container">
          {inputs.map((input) => (
            <LoggerInput
              key={input.id}
              label={input.label}
              data={envConfData[input.id]}
              id={input.id}
              type={input.type || "text"}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default IMXLoggerRight;
