import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import Modal from "../Modal";
import imxtools from "imxtools";
import { useSelector, useStore } from "react-redux";
import { navigateTo } from "../../services/navigationMiddleware";
import DrawerItem from "./DrawerItem";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";
import { useLanguageDevice } from "../../services/DeviceTranslate/LanguageContext";
import { AppContext } from "../../../../../services/AppContext";

function Drawer() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [originalY, setOriginalY] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const drawerBtnRef = useRef(null);
  const { theme, themes } = useDeviceTheme();
  const { translate } = useLanguageDevice();
  const { eventApp } = useContext(AppContext);
  let store = useStore();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  let skeletonState = useSelector(
    (state) => state?.entities?.explorer?.skeleton || {}
  );

  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit || []
  );

  let currentPage = useSelector(
    (state) => state?.entities?.deviceview?.currentPage
  );

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const startDragging = (e) => {
    setOriginalY(e.clientY);
    setIsDragging(true);
  };

  const MAX_TRANSLATE_Y = 50;
  const MIN_TRANSLATE_Y = -250;

  const onDragging = (e) => {
    if (!isDragging) return;

    const deltaY = e.clientY - originalY;
    const newTranslateY = translateY + deltaY;
    if (newTranslateY >= MIN_TRANSLATE_Y && newTranslateY <= MAX_TRANSLATE_Y) {
      setTranslateY(newTranslateY);
      setOriginalY(e.clientY);
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      onDragging(e);
    };

    const handleMouseUp = () => {
      stopDragging();
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, originalY, translateY]);

  function navigateToHome() {
    store.dispatch({
      type: "deviceview/navigateToHome",
    });
    store.dispatch({
      type: "deviceview/setCurrentPage",
      payload: {
        currentPage: 0,
      },
    });
  }

  const indexGIS = skeletonState?.maxapps?.page
    ? imxtools?.toArray(skeletonState?.maxapps?.page)?.length
    : 0;

  return (
    <React.Fragment>
      {!openDrawer && !eventApp && (
        <div
          className={`drawer-btn1 ${
            language.toLowerCase() === "ar" ? "btn-ar" : "btn-en"
          }`}
          onClick={handleOpenDrawer}
          onMouseDown={startDragging}
          ref={drawerBtnRef}
          style={{ transform: `translateY(${translateY}px)` }}
        >
          <Icon
            icon={
              language?.toLowerCase() === "ar"
                ? "chevron-left"
                : "chevron-right"
            }
            size={18}
            color="white"
          />
        </div>
      )}
      <Modal openModal={openDrawer} bg={null}>
        <div
          className="drawer-view"
          style={{
            flexDirection:
              language.toLowerCase() === "ar" ? "row-reverse" : "row",
          }}
        >
          <div
            className="drawer-container"
            style={{
              borderRadius:
                language.toLowerCase() === "ar" ? "0 0 36px 0" : "0 0 0 36px",
            }}
          >
            <div
              className={`drawer-item ${
                !currentPath.includes("list") &&
                (skeletonState?.maxapps?.gis
                  ? currentPage !== indexGIS
                  : currentPage === 0) &&
                "selected-item"
              }`}
              style={{
                flexDirection: language === "ar" && "row-reverse",
              }}
              onClick={() => {
                navigateToHome();
                setOpenDrawer(false);
              }}
            >
              <div className="drawer-item-icon">
                <Icon icon="home" size={18} color={themes?.[theme]?.primary} />
              </div>
              <div className="drawer-item-label">{translate("Home")}</div>
            </div>
            {imxtools
              ?.toArray(skeletonState?.maxapps?.page)
              ?.map((pageItem, pageIndex) => {
                const pagePath =
                  imxtools?.toArray(skeletonState?.maxapps?.page)?.length > 1
                    ? ["maxapps", "page", pageIndex?.toString()]
                    : ["maxapps", "page"];
                return (
                  <React.Fragment key={pageIndex}>
                    {/* Rows */}
                    {pageItem.row &&
                      imxtools
                        ?.toArray(pageItem?.row)
                        ?.map((rowItem, rowIndex) => {
                          const rowPath =
                            imxtools?.toArray(pageItem?.row)?.length > 1
                              ? [...pagePath, "row", rowIndex?.toString()]
                              : [...pagePath, "row"];
                          return (
                            <React.Fragment key={`row-${rowIndex}`}>
                              {/* KPIs/Apps */}
                              {rowItem?.kpi &&
                              imxtools?.toArray(rowItem?.kpi)?.length > 0
                                ? imxtools
                                    ?.toArray(rowItem?.kpi)
                                    ?.map((kpi, kpiIndex) => {
                                      const kpiPath =
                                        imxtools?.toArray(rowItem?.kpi)
                                          ?.length > 1
                                          ? kpi?.graph
                                            ? [
                                                ...rowPath,
                                                "kpi",
                                                kpiIndex?.toString(),
                                                "graph",
                                              ]
                                            : [
                                                ...rowPath,
                                                "kpi",
                                                kpiIndex?.toString(),
                                                "detail",
                                              ]
                                          : kpi?.graph
                                          ? [...rowPath, "kpi", "graph"]
                                          : [...rowPath, "kpi", "detail"];
                                      return (
                                        <React.Fragment key={kpiIndex}>
                                          <DrawerItem
                                            index={kpiIndex}
                                            data={kpi}
                                            path={kpiPath}
                                            setOpenDrawer={setOpenDrawer}
                                            kpi={true}
                                          />
                                        </React.Fragment>
                                      );
                                    })
                                : rowItem?.app &&
                                  imxtools?.toArray(rowItem?.kpi) === 0 &&
                                  imxtools?.toArray(rowItem?.app)?.length > 0 &&
                                  imxtools
                                    ?.toArray(rowItem?.app)
                                    ?.map((app, appIndex) => {
                                      const appPath =
                                        imxtools?.toArray(rowItem?.app)
                                          ?.length > 1
                                          ? [
                                              ...rowPath,
                                              "app",
                                              appIndex?.toString(),
                                            ]
                                          : [...rowPath, "app"];
                                      return (
                                        <React.Fragment key={appIndex}>
                                          <DrawerItem
                                            index={appIndex}
                                            data={app}
                                            path={appPath}
                                            setOpenDrawer={setOpenDrawer}
                                          />
                                        </React.Fragment>
                                      );
                                    })}
                            </React.Fragment>
                          );
                        })}

                    {/* Kpis */}
                    {pageItem?.kpi &&
                      imxtools?.toArray(pageItem?.kpi)?.map((kpi, kpiIndex) => {
                        const kpiPath =
                          imxtools?.toArray(pageItem?.kpi)?.length > 1
                            ? kpi?.graph
                              ? [
                                  ...pagePath,
                                  "kpi",
                                  kpiIndex?.toString(),
                                  "graph",
                                ]
                              : [
                                  ...pagePath,
                                  "kpi",
                                  kpiIndex?.toString(),
                                  "detail",
                                ]
                            : kpi?.graph
                            ? [...pagePath, "kpi", "graph"]
                            : [...pagePath, "kpi", "detail"];
                        return (
                          <React.Fragment key={kpiIndex}>
                            <DrawerItem
                              index={kpiIndex}
                              data={kpi}
                              path={kpiPath}
                              setOpenDrawer={setOpenDrawer}
                              kpi={true}
                            />
                          </React.Fragment>
                        );
                      })}

                    {/* Modules */}
                    {pageItem?.module &&
                      imxtools
                        ?.toArray(pageItem?.module)
                        ?.map((moduleItem, moduleIndex) => {
                          const modulePath =
                            imxtools?.toArray(pageItem?.module)?.length > 1
                              ? [...pagePath, "module", moduleIndex?.toString()]
                              : [...pagePath, "module"];
                          return (
                            <React.Fragment key={`module-${moduleIndex}`}>
                              <DrawerItem
                                index={moduleIndex}
                                data={moduleItem}
                                path={modulePath}
                                isModule={true}
                                setOpenDrawer={setOpenDrawer}
                              />
                              {moduleItem?.app &&
                                imxtools
                                  ?.toArray(moduleItem?.app)
                                  ?.map((app, appIndex) => {
                                    const appPath =
                                      imxtools?.toArray(pageItem?.app)?.length >
                                      1
                                        ? [
                                            ...modulePath,
                                            "app",
                                            appIndex?.toString(),
                                          ]
                                        : [...modulePath, "app"];
                                    return (
                                      <React.Fragment key={appIndex}>
                                        <DrawerItem
                                          index={appIndex}
                                          data={app}
                                          path={appPath}
                                          setOpenDrawer={setOpenDrawer}
                                          isModuleChild={true}
                                        />
                                      </React.Fragment>
                                    );
                                  })}
                            </React.Fragment>
                          );
                        })}

                    {/* Apps */}
                    {pageItem?.app &&
                      imxtools?.toArray(pageItem?.app)?.map((app, appIndex) => {
                        const appPath =
                          imxtools?.toArray(pageItem?.app)?.length > 1
                            ? [...pagePath, "app", appIndex?.toString()]
                            : [...pagePath, "app"];
                        return (
                          <React.Fragment key={appIndex}>
                            <DrawerItem
                              index={appIndex}
                              data={app}
                              path={appPath}
                              setOpenDrawer={setOpenDrawer}
                            />
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            {/* GIS */}
            {skeletonState?.maxapps?.gis && (
              <DrawerItem
                data={skeletonState?.maxapps?.gis}
                path={["maxapps", "gis"]}
                setOpenDrawer={setOpenDrawer}
                GIS={true}
                indexGIS={indexGIS}
                currentPage={currentPage}
              />
            )}
            <div
              className="drawer-item"
              style={{
                flexDirection: language === "ar" && "row-reverse",
              }}
              onClick={() => {
                navigateTo(store, "Settings", {});
                setOpenDrawer(false);
              }}
            >
              <div className="drawer-item-icon">
                <Icon icon="config_solid" size={18} />
              </div>
              <div className="drawer-item-label">{translate("Settings")}</div>
            </div>
          </div>
          <div className="void" onClick={handleOpenDrawer}>
            <div
              className={`drawer-btn1 ${
                language.toLowerCase() === "ar"
                  ? "drawer-btn2-ar"
                  : "drawer-btn2-en"
              }`}
              onClick={handleOpenDrawer}
              onMouseDown={startDragging}
              ref={drawerBtnRef}
              style={{ transform: `translateY(${translateY}px)` }}
            >
              <Icon
                icon={
                  language?.toLowerCase() === "ar"
                    ? "chevron-right"
                    : "chevron-left"
                }
                size={18}
                color="white"
              />{" "}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default Drawer;
