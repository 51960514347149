import React, { useEffect, useState } from "react";
import imxtools from "imxtools";
import { useModal } from "../../../../services/ModalContext";
import { handleOpenModal } from "../../service/handleModa";
import Property from "../../../../../../components/Property";
import { functionReference } from "../../service";

function ScriptProp({
  readOnly = true,
  data = {},
  skeleton = {},
  scripts = [],
  setScripts,
  component = "",
  XMLChanged = false,
}) {
  const [options, setOptions] = useState([]);
  const { openModal } = useModal();

  const scriptType = {
    field: ["init", "action"],
    attribute: ["action", "init"],
    form: ["on_click_sumbit_hook", "on_sumbit_hook", "on_success_hook"],
    kpi: ["value"],
  };

  useEffect(() => {
    const options = scriptType?.[component]?.reduce((accumulator, key) => {
      accumulator.push({
        label: key
          ?.split("_")
          ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
          ?.join(" "),
        value: key,
      });
      return accumulator;
    }, []);
    setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);

  useEffect(() => {
    const scripts = Object.keys(data ?? {})
      ?.filter((att) => data[att]?.includes("#FCT"))
      ?.reduce((accumulator, key) => {
        accumulator.push({ key, value: data[key] });
        return accumulator;
      }, []);
    setScripts(scripts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const functions = imxtools?.toArray(
    skeleton?.maxapps?.script?.function ?? []
  );

  const path = ["maxapps", "script", "function"];

  return (
    <React.Fragment>
      {scripts &&
        scripts?.map((script, index) => {
          return (
            <div
              key={index}
              className="propertie"
              style={{
                gridTemplateColumns: readOnly ? "80% 20%" : "75% 20% 3%",
                gap: "1%",
              }}
              onDoubleClick={() =>
                handleOpenModal(
                  "script",
                  functionReference(script?.value),
                  functions,
                  openModal,
                  path
                )
              }
            >
              <Property
                Key={script?.key}
                Value={script?.value}
                disabled={readOnly ?? true}
                index={index}
                data={scripts}
                setData={setScripts}
                readOnly={readOnly}
                scripts={true}
                options={options}
              />
            </div>
          );
        })}
    </React.Fragment>
  );
}

export default ScriptProp;
