export const Lyfe = {
  themeName: "Lyfe",
  isDark: false,
  CardViewBgColor: "#0B122310",
  secondaryLight: "#6FFFDC80",
  primary: "#0B1223",
  iconAppItem: "#0B1223",
  AppText: "#0B1223",
  loginFooter: "#0B1223",
  connectBtn: "#0B1223",
  loader: "#0B1223",
  headerColor: "#0B1223",
  primaryLight: "#0B122350",
  primaryColor: "#0B1223B2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#6FFFDC",

  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#061933",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#6FFFDC",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#0B1223",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#0B1223",
  groupIcon: "#ffffff",

  borderRecord: "#0B122350",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#0B1223",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",

  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#233e81",
  bnConnect: "#0B1223",
  notFocusedLabelInput: "#76777A",
  focusedLabelLoginInput: "#233e81",
  resetInputLogin: "#233e81",
  focusedBorderLoginInput: "#233e81",

  loginFooter: "#77777A",
  languageIcon: "#233e81",
  loginText: "#000",
  loginBtnLabel: "#fff",
  notfocusedBorderLoginInput: "#dddddd",
  bgLoginInput: "#FFFFFF1A",
  headerPrimary: "#0B122300",
  headerDark: "#06193300",
  headerText: "#0B1223",
  notificationUpColor: "#F7F9FD",
  notificationDownColor: "#ECEFF9",

  listItemNotificationUpColor: "#F3F6FB",
  listItemNotificationDownColor: "#E4E9F6",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F2F4",

  notFocusedSocialMedia: "#0B1223",
  focusedSocialMedia: "#0B1223cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#0B1223",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
