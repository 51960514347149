import React, { useState } from "react";
import DragItem from "../../views/Designer/components/ComponentsLibrary/DragItem";
import Icon from "../CustomIcon/Icon";
import "./index.scss";
import pointer from "../../views/Designer/components/ComponentsLibrary/Assets/Pointer.svg";
import grab from "../../views/Designer/components/ComponentsLibrary/Assets/Grab.svg";
import grabbing from "../../views/Designer/components/ComponentsLibrary/Assets/Grabbing.svg";
import Tooltip from "@mui/material/Tooltip";

import NotAllowed from "../../views/Designer/components/ComponentsLibrary/Assets/NotAllowed.svg";
import CustomCursorDiv from "../CustomCursor";
const ComponentButton = ({
  icon = "",
  iconColor = "",
  backgroundColor = "",
  color = "#D9D4ED",
  hoverColor = "#9183A0",
  label,
  labelColor = "",
  borderRadius = "2px",
  height = "60px",
  width = "60px",
  paddingVertical = 2,
  borderColor = "transparent",
  borderWidth = "0px",
  onClick,
  componentName = "",
  boxShadow = " 0 3px 10px rgb(0 0 0 / 0.2)",
  handleAddItem = () => {},
  activeLib,
  tooltip = "",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <CustomCursorDiv svgCursor={activeLib ? pointer : NotAllowed}>
      <Tooltip title={tooltip}>
        <div
          className="componentButton"
          onClick={() => (activeLib ? handleAddItem(componentName) : null)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <CustomCursorDiv svgCursor={activeLib ? grab : NotAllowed}>
            <DragItem
              component={componentName}
              fromComponentLib={true}
              className={`libItem ${isHovered ? "hover" : ""}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              tooltip={tooltip}
              style={{
                backgroundColor:
                  activeLib && isHovered ? hoverColor : backgroundColor,
                height: height,
                width: width,
                // paddingRight: paddingVertical,
                // paddingLeft: paddingVertical,
                borderRight: `${borderWidth} solid ${borderColor}`,
                borderLeft: `${borderWidth} solid ${borderColor}`,
                borderRadius: borderRadius,
                color: color,
                boxShadow: activeLib && isHovered ? boxShadow : "",
              }}
              onClick={onClick}
            >
              {icon && (
                <>
                  {isHovered && activeLib ? (
                    <div className={`hovered ${isHovered ? "hover" : ""}`}>
                      <div className="addIconHovered">
                        <Icon icon="addNew" color="#fff" size={15} />
                      </div>
                      <div className="iconHovered">
                        <Icon icon={icon} color="#fff" size={25} />
                      </div>
                    </div>
                  ) : (
                    <Icon
                      icon={icon}
                      color={activeLib ? iconColor : "var(--iconColorDisabled)"}
                      size={25}
                    />
                  )}
                </>
              )}
            </DragItem>
          </CustomCursorDiv>
          <div className="label" style={{ color: labelColor }}>
            {label}
          </div>
        </div>
      </Tooltip>
    </CustomCursorDiv>
  );
};

export default ComponentButton;
