import React from "react";
import SimpleProp from "./SimpleProp";
import LookupProp from "./LookupProp";
import ScriptProp from "./ScriptProp";
import ConditionProp from "./ConditionProp";

function PropertiesList({
  readOnly = true,
  component = "",
  skeleton = {},
  data = {},
  simpleProps = [],
  conditions = {},
  lookup = {},
  scripts = [],
  setSimpleProps,
  setConditions,
  setLookup,
  setScripts,
  XMLChanged = false,
}) {
  return (
    <React.Fragment>
      <SimpleProp
        readOnly={readOnly}
        data={data}
        props={simpleProps}
        setProps={setSimpleProps}
        XMLChanged={XMLChanged}
      />
      <LookupProp
        readOnly={readOnly}
        data={data}
        lookup={lookup}
        setLookup={setLookup}
        skeleton={skeleton}
        XMLChanged={XMLChanged}
      />
      <ScriptProp
        readOnly={readOnly}
        data={data}
        scripts={scripts}
        setScripts={setScripts}
        skeleton={skeleton}
        component={component}
        XMLChanged={XMLChanged}
      />
      <ConditionProp
        readOnly={readOnly}
        data={data}
        conditions={conditions}
        setConditions={setConditions}
        skeleton={skeleton}
        XMLChanged={XMLChanged}
      />
    </React.Fragment>
  );
}

export default PropertiesList;
