import { React } from "react";
import "./index.scss";
import { calculateTotalSizeInMB } from "../services/StorageSize";
import Icon from "../../../../../../components/CustomIcon/Icon";
import InputBase from "@mui/material/InputBase";

function DefaultSideBar({ searchText = "", setSearchText, iconsListing = [] }) {
  return (
    <div className="icons-sidebar">
      <div className="default-sidebar">
        <div className="default-actions">
          <div className="search-bar">
            <div className="uSearch-icon">
              <Icon icon="search" size={22} />
            </div>
            <InputBase
              sx={{
                ml: 1,
                mr: 1,
                flex: 1,
              }}
              className="search-input"
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="icons-info">
            <div className="info">
              <div className="info-title">Icons</div>
              <div className="info-value">{iconsListing.length}</div>
            </div>
            <div className="info">
              <div className="info-title">Storage Usage</div>
              <div className="info-value">
                {calculateTotalSizeInMB(iconsListing)}
              </div>
            </div>
          </div>
          <div className="drop-icons">
            <Icon icon="drag_drop" size={50} style={{ color: "#AAA" }} />
            <div className="upload-instructions">
              <p>Drop your Icon(s)</p>
              <p id="text-S">Supports only .png</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultSideBar;
