import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss";
import NoItem from "../../../../components/NoItem";
import { useTheme } from "../../../../theme/themeContext";
import Revisions from "./Revisions";
import { handleMouseMove, handleMouseUp } from "../../services/utility";
import CustomButton from "../../../../components/CustomButton";
import { getButtonStyles } from "../AppBar/services/getButtonStyles ";

function RevisionsList({
  revision,
  setRevision = () => {},
  versionsList = [],
  lastVersion = {},
  draftDisplay = false,
  dataVersions = {},
  readOnly = true,
  compareXML = false,
  showDraftsList = "",
  setShowDraftsList = () => {},
  isTransformed,
  setIsTransformed = () => {},
  setCompareXML = () => {},
  enableBackground,
  setEnableBackground = () => {},
  Bordered,
  setBorder = () => {},
}) {
  const [revisionBtnTop, setRevisionBtnTop] = useState(15 * 16);
  const [isDragging, setIsDragging] = useState(false);
  const { themeMode, themeColors } = useTheme();
  let revisionSelected = useSelector(
    (state) => state?.entities?.revision?.revisoionSelected || {}
  );
  const commonButtonStyles = getButtonStyles(
    readOnly,
    themeColors,
    themeMode,
    false,
    false
  );
  useEffect(() => {
    const onMouseMove = (e) =>
      handleMouseMove(e, isDragging, setRevisionBtnTop, setIsDragging);

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", () => handleMouseUp(setIsDragging));

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", () =>
        handleMouseUp(setIsDragging)
      );
    };
  }, [isDragging]);
  useEffect(() => {
    if (!readOnly) {
      setShowDraftsList(true);
    }
  }, [readOnly]);
  useEffect(() => {
    if (revisionSelected.typeFile != "draft") {
      setShowDraftsList(true);
    } else {
      setShowDraftsList(false);
    }
  }, []);
  return (
    <div
      className={`floating-revision ${revision && "show-revisionList"}`}
      style={{ top: `${revisionBtnTop}px` }}
    >
      <div
        className={`revisionList ${
          versionsList && versionsList?.length === 0 && !draftDisplay
            ? "revisionList-empty"
            : ""
        }`}
      >
        <div className="revisionTypesBtn">
          <CustomButton
            heightBtn="100%"
            widthBtn="50%"
            // borderBottom={true}
            {...commonButtonStyles}
            label="Drafts"
            color={
              showDraftsList
                ? themeColors[themeMode].iconColor
                : themeColors[themeMode].disabled
            }
            backgroundColor={
              !showDraftsList
                ? themeColors[themeMode].langBoxBorder
                : themeColors[themeMode].CardBg
            }
            borderBottom={showDraftsList ? false : true}
            borderLeft={false}
            borderRight={false}
            onClick={() => setShowDraftsList(true)}
          />
          <CustomButton
            widthBtn="50%"
            {...commonButtonStyles}
            disableClickReadOnly={false}
            label="Publish History"
            color={
              !showDraftsList
                ? themeColors[themeMode].iconColor
                : themeColors[themeMode].disabled
            }
            backgroundColor={
              showDraftsList
                ? themeColors[themeMode].langBoxBorder
                : themeColors[themeMode].CardBg
            }
            borderBottom={!showDraftsList ? false : true}
            borderLeft={true}
            borderRight={false}
            onClick={() => setShowDraftsList(false)}
          />
        </div>
        {versionsList?.length === 0 && !draftDisplay ? (
          <div className="emptyList">
            <NoItem
              icon="code"
              text="No XML uploaded"
              iconColor={themeColors[themeMode].iconLabelDisable}
              textColor={themeColors[themeMode].iconLabelDisable}
              marginVertical={10}
            />
          </div>
        ) : (
          <Revisions
            draftDisplay={showDraftsList ? true : false}
            lastVersion={lastVersion}
            versionsList={versionsList}
            data={dataVersions}
            revision={revision}
            setRevision={setRevision}
            isTransformed={isTransformed}
            setIsTransformed={setIsTransformed}
            setCompareXML={setCompareXML}
            compareXML={compareXML}
            enableBackground={enableBackground}
            setEnableBackground={setEnableBackground}
            setShowDraftsList={setShowDraftsList}
            Bordered={Bordered}
            setBorder={setBorder}
          />
        )}
      </div>
    </div>
  );
}

export default RevisionsList;
