export const CleaningDataCollection = (data) => {
  if (!data || !data.content) return []

  const { content } = data

  const transformedData = Object.keys(content).reduce((acc, key, index) => {
    const item = content[key]

    if (typeof item === "object" && Object.keys(item).length > 1) {
      const transformedItem = {
        ...item,
        dataCollectionId: key,
      }

      acc.push(transformedItem)
    }

    return acc
  }, [])

  return transformedData
}
