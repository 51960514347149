import "./index.scss"
import PerformanceBar from "./components/PerformanceBar/index"
import { getColor } from "./services/colorUtils"
import { getIcon } from "./services/iconUtils"

function PerformanceItem({ label = "", value = null }) {
  const isValueObject = typeof value === "object" && value !== null
  const main = isValueObject ? value?.main : value
  const unit = isValueObject ? value?.unit : ""

  const color = getColor(label)
  const icon = getIcon(label)

  return (
    <div className={`performance-${label.toLowerCase()}`}>
      <h2>{label}</h2>
      <div className='performance-icon-stats'>
        <div className='performance-icon'>{icon}</div>
        <div style={{ color }}>
          <span className='performance-stats-main'>{main}</span>
          {isValueObject && (
            <span className='performance-stats-unit'>{unit}</span>
          )}
        </div>
      </div>
      <PerformanceBar
        performance={parseInt(main, 10) ? parseInt(main, 10) : 0}
        unit={unit}
        color={color}
      />
    </div>
  )
}

export default PerformanceItem
