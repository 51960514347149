import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import { setIn } from "lodash-redux-immutability";

function DragItem({
  sort = false,
  dataSorted,
  moveItem,
  hoveredComponent = "",
  index,
  app,
  children,
  component,
  dragData = {},
  dragPath = [],
  dragParentPath = [],
  style = {},
  fromComponentLib = false,
  className = "",
  onClick = () => {},
  onMouseLeave = () => {},
  onMouseEnter = () => {},
}) {
  let store = useStore();
  const [hoverCom, setHoverCom] = useState("");
  const ref = useRef(null);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer?.skeleton
      : {}
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: component,
    item: {
      type: component,
      index, // Make sure to pass the index for drag and drop logic
      dragData,
      dragPath,
      dragParentPath,
      fromComponentLib,
    },

    canDrag: !readOnly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),

    end: (item, monitor) => {
      if (dragParentPath[dragParentPath.length - 1] === hoverCom) {
        let newData = setIn(
          skeletonState,
          [...dragParentPath, component],
          dataSorted
        );
        if (sort) {
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: newData,
              readOnly: false,
            },
          });
        } else {
          return;
        }
      }
    },
  });

  const [, drop] = useDrop({
    accept: [], // You should specify which component types can be dropped here
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      setHoverCom(hoveredComponent);

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
      const mousePosition = monitor.getClientOffset();
      const hoverClientY = mousePosition.y - hoveredRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      setHoverCom((preview) => hoveredComponent);

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  preview(<div>{children}</div>);

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={`${className} ${isDragging ? "dragging" : ""}`}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
}

export default DragItem;
