export const Ola = {
  themeName: "Ola",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#55565A80",
  primary: "#AD8A19",
  iconAppItem: "#AD8A19",
  AppText: "#AD8A19",
  loginFooter: "#AD8A19",
  connectBtn: "#AD8A19",
  loader: "#AD8A19",
  headerColor: "#AD8A19",
  primaryLight: "#AD8A1950",
  primaryColor: "#AD8A19B2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#55565A",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#8D7C49",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#AD8A19",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#AD8A19",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#D1C8AB",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#AD8A19",
  groupIcon: "#ffffff",
  borderRecord: "#AD8A1950",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D1C8AB",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D1C8AB",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#AD8A19",
  listBgCard: "#eee",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#55565A",
  bnConnect: "#AD8A19",
  loginBtnLabel: "#FFF",
  focusedBorderLoginInput: "#AD8A19",
  focusedLabelLoginInput: "#AD8A19",
  resetInputLogin: "#AD8A19",
  languageIcon: "#AD8A19",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#FFF",
  loginFooter: "#000",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#AD8A1900",
  headerText: "#AD8A19",
  headerDark: "#8D7C4900",
  notificationUpColor: "#FEFCF6",
  notificationDownColor: "#FCF7E9",

  listItemNotificationUpColor: "#FDFAF2",
  listItemNotificationDownColor: "#FAF4E0",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F3F1",

  notFocusedSocialMedia: "#AD8A19",
  focusedSocialMedia: "#AD8A19cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#AD8A19",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
