import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../../services/http/httpService";
import handleClickedItem from "./helpers/handleClickedItem";

async function deleteVersion(store, filename, addToast, typeFile, selectedData,setBorder) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_DELETE_FILE;

    const transactionPayload = {
      filename: filename,
    };

    const res = await serviceRequest.delete(
      transactionNodeService,
      transactionPayload
    );

    if (res?.data?.success) {
      store.dispatch({
        type: "revision/deleteData",
        payload: {
          filename: filename,
        },
      });

      let list = store.getState()?.entities?.revision?.list;
      const draftList = list.filter(item => item.typeFile === "draft");
      const publishedList = list.filter(item => item.typeFile === "Published");


      if (list.length > 0) {
        if (selectedData.typeFile === "draft" && draftList.length > 0) {
          store.dispatch({
            type: "revision/setSelectedRevision",
            payload: {
              data: draftList[0],
            },
          });

          const payload = draftList[0]?.filename
            ? { obj: { [draftList[0]?.filename]: true } }
            : {};
          store.dispatch({
            type: "revision/setSelectedItem",
            payload,
          });
        } else if (selectedData.typeFile === "Published" && publishedList.length > 0) {
          store.dispatch({
            type: "revision/setSelectedRevision",
            payload: {
              data: publishedList[0],
            },
          });
          setBorder(true);
          const payload = publishedList[0]?.filename
            ? { obj: { [publishedList[0]?.filename]: true } }
            : {};
          store.dispatch({
            type: "revision/setSelectedItem",
            payload,
          });
        }
        if (selectedData.typeFile === "Published" && publishedList.length === 0 && draftList.length > 0) {
          store.dispatch({
            type: "revision/setSelectedRevision",
            payload: {
              data: draftList[0],
            },
          });

          const payload = draftList[0]?.filename
            ? { obj: { [draftList[0]?.filename]: true } }
            : {};
          store.dispatch({
            type: "revision/setSelectedItem",
            payload,
          });}
        

        await handleClickedItem(
          addToast,
          store,
          {},
          selectedData.typeFile === "draft" ? draftList[0]?.filename : publishedList[0]?.filename,
          false,
          true
        );
      }

      addToast(res.data.message, "success");
    } else {
      addToast(res.data.message, "error");
    }
  } catch (error) {
    addToast("Server is unreachable!", "error");
  }
}

export default deleteVersion;
