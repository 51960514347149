function mergeArray(originArray = [], mergedArray = [], uniqueAttribute = "") {
  if (
    !Array.isArray(originArray) ||
    !Array.isArray(mergedArray) ||
    typeof uniqueAttribute !== "string"
  ) {
    return [];
  }
  const differenceInArray = mergedArray
    // eslint-disable-next-line array-callback-return
    ?.map((mergedArrayElement) => {
      const filteredArray = originArray?.filter(
        (originArrayElement) =>
          originArrayElement[uniqueAttribute] ===
          mergedArrayElement[uniqueAttribute]
      );

      if (filteredArray?.length === 0) {
        return mergedArrayElement;
      }
    })
    ?.filter((e) => e);

  const finalArray = originArray?.map((originArrayElement) => {
    const filteredArray = mergedArray?.find(
      (mergedArrayElement) =>
        mergedArrayElement[uniqueAttribute] ===
        originArrayElement[uniqueAttribute]
    );
    if (filteredArray) {
      return filteredArray;
    }
    return originArrayElement;
  });

  return [...finalArray, ...differenceInArray];
}

export default mergeArray;
