import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";
import PenguinButton from "../../assets/PenguinButton.svg";
import Icon from "../../components/CustomIcon/Icon";
import CustomInput from "../../components/CustomInput";
import { useTheme } from "../../theme/themeContext";
import lightBackground from "../../assets/support-light.png";
import darkBackground from "../../assets/support-dark.png";
import { useSelector } from "react-redux";
import { useLanguage } from "../../Translate/LanguageContext";

function Support() {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const { themeMode } = useTheme();

  let language = useSelector(
    (state) => state?.entities?.settings?.language || "en"
  );

  return (
    <div
      className="high"
      style={{
        backgroundImage: `url(${
          themeMode?.toLowerCase() === "dark" ? darkBackground : lightBackground
        })`,
      }}
    >
      <div className="leftSide"></div>
      <div className="CentreSide">
        <Formik
          initialValues={{
            name: "",
            email: "",
            companyName: "",
            message: "",
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form className="Card" onSubmit={handleSubmit}>
              <h1 className="title">How can we help you?</h1>
              <CustomInput
                label="Name"
                value={values.name}
                language={language}
                onChange={handleChange("name")}
                required
              />
              <CustomInput
                label="Business Email"
                name="email"
                value={values.email}
                language={language}
                onChange={handleChange("email")}
                required
              />
              <CustomInput
                label="Company Name"
                name="companyName"
                value={values.companyName}
                language={language}
                onChange={handleChange("companyName")}
                required
              />
              <CustomInput
                label="Message"
                rows={11}
                value={values.message}
                language={language}
                onChange={handleChange("message")}
                required
              />
              <button
                className={Formik.isValid ? "submitButton" : "submitButton"}
                disabled={Formik.isValid}
                data-testid="ConnectButton"
                type="submit"
              >
                <span className="ConnectButton-text">
                  {translate("Submit")}
                </span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="RightSide">
        <div className="SmallCard">
          <div className="Upper"></div>
        </div>
        <div className="Button">
          <a
            href="https://maxapps-maximo.com/support/"
            target="_blank"
            className="iconButton"
            rel="noopener noreferrer"
          >
            <img src={PenguinButton} className="Penguin"></img>
          </a>

          <button className="HomeButton1" onClick={() => navigate("/monitor")}>
            Go Back to Home Page
            <Icon className="Fleche" icon="Fleche" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Support;
