import { checkSyncBackend } from "../../../../services";
import { HandleStepChange } from "../../../../services/stepChange/stepChange";

const checkVerif = async (
store={},
  inputAddress,
  setIsChecked = () => {},
  setIsVerifying = () => {},
  setVerifInstall = () => {},
  setErrorMessage = () => {}
) => {
  try {
    setIsChecked(true);
    setIsVerifying(true);

    const response = await checkSyncBackend(inputAddress);

    if (response?.content?.status === "failure" || !response?.success) {
      setIsVerifying(false);
      setVerifInstall(false);
      setErrorMessage(response?.content?.message ?? response.message);
    } else {
      setVerifInstall(true);
      HandleStepChange(store, "Disconnect");
    }
  } catch (error) {
    setIsVerifying(false);
    setVerifInstall(false);
    setErrorMessage(error?.message ?? "An unexpected error occurred");
  }
};

export default checkVerif;
