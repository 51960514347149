import React from 'react';
import ReactJSON from 'react-json-view';
import CustomButton from '../../../../../components/CustomButton';
import themeColors from '../../../../../theme/themeColors';
import getObjectSize from '../services/getObjectSize';
import exportToFile from '../services/exportFile';
const ResponseBody = ({ selectedRequest, display, setDisplay }) => {
  const responseExact = selectedRequest?.response?.responseData;

  const size = getObjectSize(responseExact);
  return (
    <div className="response-options-container">
      <div
        className="response-options-header"
        onClick={() => {
          setDisplay({
            ...display,
            responseBody: !display?.responseBody,
          });
        }}
      >
        <div>
          <div className="response-key">
            <h4>Response body</h4>
          </div>
        </div>
        <CustomButton
          iconName={display?.responseBody ? 'arrow-down' : 'arrow-right'}
          iconColor={themeColors.light.responseDataButtonArrow}
          iconSize={20}
          tooltip={
            display?.responseBody ? 'Hide Response Body' : 'Show Response Body'
          }
        />
      </div>
      <div className="response-request-body ">
        <span className="response-area-keys">
          Size: <span className="size-value">{size} KB</span>
        </span>
        {/* import */}

        <div style={{ display: 'flex', gap: '5px' }}>
          <CustomButton
            iconName="export2"
            iconSize={20}
            iconColor="rgba(57, 53, 74, 1)"
            tooltip="Export Response Body"
            style={{
              marginLeft: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0',
            }}
            onClick={() => {
              exportToFile(
                JSON.stringify(responseExact, null, 2),
                `response-body ${new Date().toLocaleString()}`
              );
            }}
          />
          {/* copy button */}
          <CustomButton
            iconName="copy"
            iconSize={20}
            iconColor="rgba(57, 53, 74, 1)"
            tooltip="Copy Response Body"
            style={{
              marginLeft: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0',
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                typeof responseExact === 'object'
                  ? JSON.stringify(responseExact)
                  : responseExact
              );
            }}
          />
        </div>
      </div>

      <pre
        className="json-container"
        style={{
          display: display?.responseBody ? 'flex' : 'none',
          dispayDirection: 'column',
        }}
      >
        {typeof responseExact === 'object' ? (
          <ReactJSON src={responseExact} />
        ) : (
          responseExact
        )}
      </pre>
    </div>
  );
};

export default ResponseBody;
