import React, { useRef, useEffect, useState } from "react";
import CodeMirror from "codemirror";
import diff_match_patch from "diff-match-patch";
import "codemirror/addon/merge/merge.js";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/xml-fold.js"; // If you're folding XML/HTML
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/css/css.js";
import xml2js from "minexml2js";
import exportFile from "../services/exportFile";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/merge/merge.css";
import "codemirror/theme/material-ocean.css";
import { useSelector, useStore } from "react-redux";
import "./index.css";
import useToasts from "../../../../../components/CustomToast/useToasts";
import { useLocation } from "react-router-dom";
import "./themes/vscode-dark.css";
import "./themes/vscode-light.css";
import { transformXml } from "../../../services/transformXml";
import Icon from "../../../../../components/CustomIcon/Icon";
import transformForStore from "../../../services/helpers/transformForStore";
import handleSaveRevision from "../../../services/helpers/handleSaveRevison";
import resize from "../services/resize";
import calculateDifferences from "../services/calculateDifferences";
import HandleCloseEditMode from "../services/handleCloseEditMode";
import Dropdown from "../../../../../components/Dropdown";
import { MoreVert } from "@mui/icons-material";

Object.keys(diff_match_patch).forEach((key) => {
  window[key] = diff_match_patch[key];
});

function EditModeCompareFiles(leftCode, xmlValue = "", revision) {
  const { addToast } = useToasts();
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const store = useStore();

  let location = useLocation();

  const {
    fileSelected,
    itemLeftSelected,
    leftFileName,
    revisionSelected,
    rightFileName,
    rightMode,
    leftMode,
    resetChanges,
    leftCod,
    rightCod,
    fontSize,
    theme,
    selected,
    versionsList,
  } = useSelector((state) => ({
    fileSelected:
      state?.entities?.compareFiles?.rightCode?.fileSelected
        ?.itemRightSelected || {},
    itemLeftSelected:
      state?.entities?.compareFiles?.leftCode?.fileSelected?.itemLeftSelected ||
      {},
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    revisionSelected: state?.entities?.revision?.revisionSelected || {},
    rightFileName: state?.entities?.compareFiles?.rightCode?.filename || "",
    leftMode: state?.entities?.compareFiles?.leftCode?.mode || "",
    rightMode: state?.entities?.compareFiles?.rightCode?.mode || "",
    resetChanges: state?.entities?.compareFiles?.resetChanges || false,
    rightCod: state?.entities?.compareFiles?.rightCode?.code || "",
    leftCod: state?.entities?.compareFiles?.leftCode?.code || "",
    fontSize: state?.entities?.codeeditor?.fontSize,
    theme: state?.entities?.codeeditor?.theme,
    selected: state?.entities?.revision?.itemSelected || {},
    versionsList: state?.entities?.revision?.list || [],
  }));
  const view = useRef(null);
  const [editorValue, setEditorValue] = useState(
    !leftMode ? leftCode.xmlValue : leftCod
  );

  const [mvInstance, setMvInstance] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(versionsList[0]);
  const [panes, setPanes] = useState(2);
  const [highlight, setHighlight] = useState(true);
  const [connect, setConnect] = useState("align");
  const [collapse, setCollapse] = useState(true);
  const [addedLines, setAddedLines] = useState(0);
  const [removedLines, setRemovedLines] = useState(0);
  const [rightValue, setRightValue] = useState(leftCode.leftCode);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const value = leftMode ? leftCode.xmlValue : leftCod;
  const orig2 = leftCode.leftCode;
  useEffect(() => {
    if (value == null || !view.current) return;
    var target = view.current;
    target.innerHTML = "";
    var height = view.current.clientHeight;
    const newThemeValue = theme === "vs" ? "vscode-light" : "vscode-dark";
    const mvInstance = CodeMirror.MergeView(target, {
      value: editorValue,
      connect,
      origRight: rightValue,
      lineNumbers: true,
      mode: "xml",
      highlightDifferences: highlight,
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
      foldGutter: true,
      foldOptions: {
        widget: "\u2194", // This is the default fold widget. You can customize it as needed.
      },
      lineWrapping: true,
      theme: newThemeValue,
      addModeClass: true,
      collapseIdentical: collapse,
      revertButtons: true,
      allowEditingOriginals: true,
      showDifferences: true,
    });
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      resize(mvInstance, window.innerHeight); // Adjust this line if resize needs more args
    };

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Call handler to set initial size
    handleResize();
    const editor = mvInstance.editor();
    editor.getWrapperElement().style.fontSize = `${fontSize}px`;
    editor.on("change", () => {
      const { addedLines, removedLines } = calculateDifferences(
        mvInstance,
        addToast
      );
      setAddedLines(addedLines);
      setRemovedLines(removedLines);
      setEditorValue(editor.getValue());
    });
    const modified = mvInstance.rightOriginal();
    modified.getWrapperElement().style.fontSize = `${fontSize}px`;
    modified.on("change", () => {
      const { addedLines, removedLines } = calculateDifferences(
        mvInstance,
        addToast
      );
      setAddedLines(addedLines);
      setRemovedLines(removedLines);
      setRightValue(modified.getValue());
    });

    setMvInstance(mvInstance);
    const initialCounts = calculateDifferences(mvInstance, addToast);
    setAddedLines(initialCounts.addedLines);
    setRemovedLines(initialCounts.removedLines);
    const mergePanes = document.querySelectorAll(".CodeMirror-merge-pane");

    const mergePanesRight = document.querySelectorAll(
      ".CodeMirror-merge-pane-rightmost"
    );
    if (theme === "vs") {
      const mergePanesGutter = document.querySelectorAll(
        ".CodeMirror-gutter, .CodeMirror-merge-gutter"
      );
      mergePanesGutter.forEach((pane) => {
        pane.style.backgroundColor = "#FFFFFF";
      });
    }
    mergePanes.forEach((pane) => {
      // Modify this as per your "true" state styling needs
      pane.style.borderRight = "2px solid #4c36a5";
      pane.style.marginTop = "-1px";
      pane.style.borderTop = "1px solid #eeeeee";
      pane.style.borderLeft = "2px solid #4c36a5";
      pane.style.borderBottom = "2px solid #4c36a5";
      pane.style.borderRadius = "0px 0px 6px 6px";
    });
    mergePanesRight.forEach((pane) => {
      pane.style.marginTop = "-2px";
      pane.style.borderTop = "1px solid #eeeeee";
      pane.style.borderRight = "3px solid #36a5a5";
      pane.style.borderLeft = "3px solid #36a5a5";
      pane.style.borderBottom = "3px solid #36a5a5";
      pane.style.borderRadius = "0px 0px 6px 6px";
    });
    // Remember to clean up the event listener when the component unmounts or dependencies change
    return () => {
      editor.off("change");
    };
  }, [value, orig2, panes, highlight, connect, collapse]);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  useEffect(() => {
    if (mvInstance) {
      mvInstance.setShowDifferences(highlight);
    }
  }, [highlight, mvInstance]);
  useEffect(() => {
    if (resetChanges && mvInstance) {
      mvInstance.editor().setValue(leftCode.xmlValue); // Resetting the main editor
      if (mvInstance.rightOriginal()) {
        mvInstance.rightOriginal().setValue(rightCod); // Resetting the right (original) editor if it exists
      }

      setCollapse(!collapse);
      // Alternatively, if you want to refresh or reload the MergeView to its initial state:
      // mvInstance.refresh(); // This is a placeholder; actual implementation may vary
      store.dispatch({
        type: "compareFiles/updateResetChangesState",
        payload: {
          resetChanges: false,
        },
      });
    }
  }, [resetChanges, mvInstance]);
  useEffect(() => {
    // Select all elements with the .CodeMirror-merge-pane class
  }, []);
  useEffect(() => {
    if (mvInstance) {
      let d = document.createElement("div");
      d.style.cssText = "width: 50px; margin: 7px; height: 14px";
      mvInstance.editor().addLineWidget(57, d);
    }
  }, [mvInstance]);

  function toggleDifferences() {
    setHighlight(!highlight);
  }

  const onSave = async () => {
    try {
      const searchRegExp = new RegExp("&", "g");
      const xmlData = editorValue.replace(searchRegExp, "&amp;");

      const { result } = await new xml2js.Parser({
        explicitArray: false,
        emptyTag: { $: { id: "mockId" } },
      }).parseStringPromise(xmlData);
      const skeleton = await transformForStore(result);
      handleSaveRevision(store, skeleton, addToast);
    } catch (error) {
      addToast("Save failed. Please try again.", "warn");
    }
  };
  useEffect(() => {
    // Extracting the first key as the filename to search
    const filenameToSearch = Object.keys(selected)[0]; // Assuming selected has only one key, and it's the filename.

    // Search for a selected filename in the listVersion array
    const match = versionsList.find(
      (version) => version.filename === filenameToSearch
    );
    setSelectedVersion(match);
  }, []);
  const DropdownLeftEditorOptions = [
    {
      label: "Switch to Edit mode",
      active: false,
      icon: "edit2",
    },
    {
      label: "Export",
      action: () => exportFile(editorValue, location),
      active: true,
      icon: "Export",
    },
    {
      label: "Device view",
      icon: "Device",
      active: false,
    },
  ];
  const DropdownRightEditorOptions = [
    {
      label: "Export",
      action: () => exportFile(rightValue, location),
      active: true,
      icon: "Export",
    },
    {
      label: "Device view",
      icon: "Device",
      active: false,
    },
  ];

  return (
    <div className="diffContainer">
      <div
        className="compareContainer"
        style={{ width: leftCode.revision ? "calc(100vw - 22rem)" : "100%" }}
      >
        <div className="LeftContainer">
          <div className="editorContainerDraft">
            <div className="editorHeaderDraft">
              <>
                <div className="editorName">
                  <p className="statutDraft">Draft</p>
                  {selectedVersion ? (
                    <p className="headerFile">
                      {/* V{selectedVersion.version} - {selectedVersion.xmlAlias} */}
                    </p>
                  ) : (
                    <p className="headerFile">Untitled</p>
                  )}
                </div>
                <div className="editorPropretyDraft">
                  <div className="iconEditor" onClick={onSave}>
                    <Icon icon={"Save"} size={24} color="#39354A" />
                  </div>
                  <div className="editorPropList">
                    <Dropdown
                      options={DropdownLeftEditorOptions}
                      withIcon={true}
                    >
                      <MoreVert />
                    </Dropdown>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="CollapseContainer">
          <Icon
            icon={collapse ? "collaps" : "expand"}
            size={24}
            color="#959497"
            onClick={toggleCollapse}
          />
        </div>
        <div className="RightContainer">
          <div className="editorContainernewRight">
            <div className="editorHeaderRight">
              <>
                <div className="editorName">
                  <p className="rightStatut">
                    <span className="originalContext">{rightMode}</span>
                    <span className="hoveredContext">
                      <span>Close</span>
                      <Icon
                        className="iconClose"
                        icon={"close1"}
                        size={22}
                        color="white"
                        onClick={() => HandleCloseEditMode(store)}
                      />
                    </span>
                  </p>
                  <p className="headerFile">
                    {fileSelected && Object.keys(fileSelected).length > 0
                      ? `${fileSelected.version} - ${fileSelected.xmlAlias}`
                      : `${rightFileName}`}
                  </p>
                </div>
                <div className="editorProprety">
                  <div className="editorPropretyIcon">
                    <Icon icon="infos" size={24} color="#36a5a5" />
                    <p className="changesRight">
                      {addedLines + removedLines} Additions
                    </p>
                  </div>
                  <div className="editorPropList">
                    <Dropdown
                      options={DropdownRightEditorOptions}
                      withIcon={true}
                    >
                      <MoreVert />
                    </Dropdown>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <div
          ref={view}
          className="difContainer"
          style={{ width: leftCode.revision ? "calc(100vw - 22rem)" : "100%" }}
        />
      </React.Fragment>
    </div>
  );
}
export default EditModeCompareFiles;
