import "./index.scss";

import React, { useState, useEffect } from "react";

import { useSelector, useStore } from "react-redux";

import SubItem from "./item/sub-item";

function ExplorerItems({ data = {} }) {
  const [displayAdd, setDisplayAdd] = useState(false);
  const [parentDetail, setParentDetail] = useState({ parent: [], item: "" });
  const [currentOpen, setCurrentOpen] = useState({});
  const [edited, setEdited] = useState({});

  let store = useStore();

  const [firstOpen, setFirstOpen] = useState(parentDetail?.parent);

  let defaultOpen = store?.getState()?.entities?.explorer?.openDefault;

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  useEffect(() => {
    setFirstOpen(defaultOpen?.parent);
    setCurrentOpen({
      [defaultOpen?.parent?.toString()]: true,
    });
    setEdited({ [defaultOpen?.parent?.toString()]: true });
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: { ...defaultOpen?.data },
        parent: defaultOpen?.parent,
        component: defaultOpen?.component,
      },
    });
  }, [store, defaultOpen]);

  const displayAddNew = (isOpen, parent) => {
    setDisplayAdd(isOpen);
    parent && setParentDetail(parent);
  };

  const handleCurrentParent = (parent) => {
    setParentDetail(parent);
  };

  return (
    <div className="ExplorerItems" id="ExplorerItems" data-test="ExplorerItems">
      {!displayAdd && (
        <React.Fragment>
          <SubItem
            data={data?.maxapps}
            parent={["maxapps"]}
            edited={edited}
            setEdited={setEdited}
            displayAddNew={displayAddNew}
            currentParent={firstOpen}
            setCurentParent={handleCurrentParent}
            readOnly={readOnly}
            setFirstOpen={setFirstOpen}
            currentOpen={currentOpen}
            setCurrentOpen={setCurrentOpen}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default ExplorerItems;
