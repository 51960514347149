function getButtonProps({
  iconName = "",
  navigateTo,
  draftPresence = false,
  location,
  themeColors,
  themeMode,
  navigate,
  setNavigateTo = () => {},
  setOpenDiscardModal = () => {},
  active = true,
  pageName = "",
}) {
  const isActive = location.pathname === navigateTo;
  const label = isActive ? pageName : "";
  return {
    heightBtn: "100%",
    widthBtn: isActive ? "" : "4rem",
    color: isActive ? "white" : "",
    gap: isActive ? 1 : 0,
    paddingVertical: isActive ? 1 : 0,
    paddingHorizontal: isActive ? 1 : 0,
    iconName,
    iconSize: 23,
    backgroundColor: isActive
      ? themeColors[themeMode].SupportButtonBg
      : themeColors[themeMode].header,
    hoveredBackground: isActive
      ? themeColors[themeMode].SupportButtonBg
      : themeColors[themeMode].header,
    ActiveBackground: isActive
      ? themeColors[themeMode].SupportButtonBg
      : themeColors[themeMode].header,
    iconColor: !active
      ? themeColors[themeMode].disabled
      : isActive
      ? "white"
      : themeColors[themeMode].text,
    label,

    onClick: () => {
      if (!draftPresence) {
        navigate(navigateTo);
      } else {
        setNavigateTo(navigateTo);
        setOpenDiscardModal(true);
      }
    },
  };
}

export default getButtonProps;
