import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";

import Icon from "../../../../../components/CustomIcon/Icon";
import DynamicModal from "../../../../../components/CustomModal";

import Lottie from "react-lottie";

import {
  __getScripts,
  checkInstallerTools,
  exitInstaller,
  clearAll,
} from "./services/index";
import SyncConfigComponent from "../SyncConfigComponent";
import { ClickAwayListener, LinearProgress, Tooltip } from "@mui/material";
import { LoadingOptions } from "../data";
import { UpdateMaximoLoginStatus } from "../../../services/stepChange/maximoLoginStatus";
import SyncAutomationScripts from "./components/sync-auto-scripts/indes";
import SyncObjectStructures from "./components/sync-object-structures";
import { checkVerif } from "../SyncConfigComponent/services";
import { HandleStepChange } from "../../../services/stepChange/stepChange";

function DiscoonectComponent({ _onPress = () => {} }) {
  const _isMounted = useRef(true);
  const store = useStore();
  const navigate = useNavigate();

  const [maximoConfig, setMaximoConfig] = useState(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [toClear, setToClear] = useState(false);
  const [clearDb, setClearDb] = useState(false);
  const [checkVersions, setcheckVersions] = useState(true);
  const [finish, setFinish] = useState(false);
  const [failedToClear, setFailedToClear] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disconnectMessage, setDisconnectMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [response, setResponse] = useState({});
  const [showPopUpOptions, setShowPopUpOptions] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [verifInstall, setVerifInstall] = useState(true);
  const [isVerifying, setIsVerifying] = useState(null);
  const [open, setOpen] = useState(false);
  const [maxappsHostAdress, setMaxappsHostAdress] = useState(null);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(maximoConfig.maximoUrl);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const closeDialog = () => setShowDialog(false);
  const openDialog = () => setShowDialog(true);
  const openModal = () => setShowPopUp(true);
  const closePopUp = () => setShowPopUp(false);

  const handleCheckbox = () => {
    setClearDb(!clearDb);
  };

  useEffect(() => {
    
    store.dispatch({
      type: "installer/updateCompletedInstallation",
      payload: {
        installationStatus:true,
      },
    });
  }, []);
 async function getScripts() {
      const response = await __getScripts();
      setMaximoConfig(response);
      setMaxappsHostAdress(response?.maximoUrl)
    }
  useEffect(() => {
    getScripts();
  }, []);

  useEffect(() => {
    let timer;
    if (finish) {
      timer = setTimeout(() => {
        exitInstaller(store, navigate);
      }, 30);
    }
    return () => clearTimeout(timer);
  }, [finish, store, navigate]);

  return (
    <div className="SyncComponentConatiner">
      <div className="SyncHeader">
        <div className="SyncHeader-title">
          Your MAXapps server is connected to the Maximo instance
        </div>
        {maximoConfig?.maximoUrl ? (
          <div className="ipCopySection">
            <div className="ipSection">
              <a
                href={maximoConfig?.maximoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {maximoConfig?.maximoUrl}
              </a>
            </div>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied !"
              >
                <div className="copyIcon">
                  <Icon
                    icon="paste"
                    size={18}
                    color="#B9B8BD"
                    style={{ cursor: "pointer", marginTop: "10px" }}
                    onClick={handleTooltipOpen}
                  />
                </div>
              </Tooltip>
            </ClickAwayListener>
          </div>
        ) : (
          <Lottie
            options={LoadingOptions}
            height={50}
            width={60}
            isClickToPauseDisabled={true}
            style={{
              cursor: "auto",
            }}
          />
        )}
      </div>

      <SyncAutomationScripts maximoConfig={maximoConfig} />
     { maximoConfig?.ObjectStructures &&
      <SyncObjectStructures maximoConfig={maximoConfig} />}

      <div className="SyncFooter">
      { maximoConfig?.syncBackendStatus===false &&
        <button
          className="Sync-retry-button"
          disabled={!maximoConfig}
          onClick={() => openModal()}
        >
          Retry SyncBackend
          <Icon icon="rename" size={22} style={{ marginLeft: "10px" }} />
        </button>}
        <button
          className="Sync-disconnect-button"
          onClick={() => {
            _onPress();
            openDialog();
          }}
        >
          Disconnect
          <Icon
            icon="circle-close-new"
            size={14}
            style={{ marginLeft: "10px" }}
          />
        </button>
      </div>

      <DynamicModal
        open={showPopUp}
        onClose={closePopUp}
        modalTitle="Retry SyncBackend"
        primaryLabel="Submit"
        showFooter={false}
        onClickPrimaryAction={() =>
          checkVerif(
            maxappsHostAdress,
            setIsChecked,
            setIsVerifying,
            setVerifInstall,
            setErrorMessage
          )
        }
      >
        <div className="popupContainer">
          <SyncConfigComponent
            maxappsHostAdress={maxappsHostAdress}
            title={maximoConfig?.maximoUrl}
            showFooter={false}
          />
        </div>
      </DynamicModal>

      <DynamicModal
        open={showDialog}
        onClose={closeDialog}
        showHeader={toClear ? false : true}
        showFooter={toClear ? false : true}
        secondaryAction={true}
        modalTitle={failedToClear ? "Uninstallation Failed" : "Attention !"}
        primaryLabel={failedToClear ? "Retry" : "Yes"}
        secondaryLabe={failedToClear ? "Skip" : "No"}
        onClickSecondaryAction={(e) => {
          if (failedToClear) {
            clearAll(
              store,
              _isMounted,
              clearDb,
              false,
              checkVersions,
              setShowPopUpOptions,
              setFailedToClear,
              setToClear,
              setDisconnectMessage,
              setFinish,
              setErrorMessage,
              setResponse
            );
            UpdateMaximoLoginStatus(store, false);
            HandleStepChange(store, "login");
          } else {
            closeDialog();
          }
        }}
        onClickPrimaryAction={(e) => {
          if (failedToClear) {
            if (
              response &&
              response?.content?.status?.toString()?.toLowerCase() ===
                "failure" &&
              response?.content?.script
            ) {
              checkInstallerTools(
                store,
                response,
                _isMounted,
                clearDb,
                true,
                checkVersions,
                setFailedToClear,
                setToClear,
                setResponse,
                setErrorMessage,
                setShowPopUpOptions,
                setFinish,
                setDisconnectMessage
              );
              return;
            }
            clearAll(
              store,
              _isMounted,
              clearDb,
              true,
              checkVersions,
              setShowPopUpOptions,
              setFailedToClear,
              setToClear,
              setDisconnectMessage,
              setFinish,
              setErrorMessage,
              setResponse
            );
          } else {
            clearAll(
              store,
              _isMounted,
              clearDb,
              true,
              checkVersions,
              setShowPopUpOptions,
              setFailedToClear,
              setToClear,
              setDisconnectMessage,
              setFinish,
              setErrorMessage,
              setResponse
            );
          }
        }}
      >
        <div className="popupContainer">
          <React.Fragment>
            {!finish && !toClear && !failedToClear && (
              <React.Fragment>
                <div className="disconnectConfirm">
                  Do you want to uninstall the current MAXapps configuration?
                </div>
                <div className="dialog-option">
                  <input
                    type="checkbox"
                    id="topping"
                    name="topping"
                    value={clearDb}
                    onChange={handleCheckbox}
                  />
                  <div className="dialog-content">Cleanup database</div>
                </div>

                <div className="dialog-option" data-testid="checkVersions">
                  <input
                    type="checkbox"
                    id="checkVersions"
                    name="checkVersions"
                    value={checkVersions}
                    checked={checkVersions}
                    onChange={() => {
                      setcheckVersions(!checkVersions);
                    }}
                  />
                  <div className="dialog-content">Check files versions</div>
                </div>
              </React.Fragment>
            )}
            {!finish && toClear && !failedToClear && (
              <div className="uninstall-progress">
                <div className="title-popup">Uninstalling MAXapps</div>
                <LinearProgress
                  sx={{
                    height: "10px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "4px",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "var(--ButtonSelected)",
                      borderRadius: "4px",
                    },
                  }}
                />
              </div>
            )}
            {failedToClear && !toClear && !finish && (
              <div className="retry-uninstall">
                <div>
                  <p className="retry-message">{errorMessage}</p>
                </div>
              </div>
            )}
          </React.Fragment>
        </div>
      </DynamicModal>
    </div>
  );
}

export default DiscoonectComponent;
