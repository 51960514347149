import React from "react";
import "./index.scss";
import Icon from "../../../../../../components/CustomIcon/Icon";

function SidebarBtn({ onDeleteIcon }) {
  return (
    <div className="sidebar-btn">
      <button className="deleteButton" onClick={onDeleteIcon}>
        Delete
        <Icon className="delete" icon="delete3" size={20} />
      </button>
    </div>
  );
}

export default SidebarBtn;
