const panelDisplay = {
  initialState: {
    showPanel: false,
  },
  reducers: {
    togglePannel: (dataSet, action) => {
      const { showPanel } = action.payload;
      return { ...dataSet, showPanel: showPanel };
    },
  },
};

export default panelDisplay;
