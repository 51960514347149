import React from "react";
import { useRef } from "react";
import { useTheme } from "../../../../theme/themeContext";
import CheckboxWithThreeStates from "../../../../components/Checkbox";
import "./index.scss";
import useToasts from "../../../../components/CustomToast/useToasts";
import uploadIconToServer from "../../services/uploadIconToServer";
import mergeArray from "../../services/helpers/mergeArrays";
import CustomButton from "../../../../components/CustomButton";

function IconManagerBar({
  iconsListing,
  setIconsListing,
  iconsSelected,
  DownloadIcon,
  toggleSelectAll,
  iconMaxSize,
}) {
  const { themeMode, themeColors } = useTheme();
  const { addToast } = useToasts();
  let hiddenFileInput = useRef(null);
  let readOnly = false;

  function handleClick() {
    hiddenFileInput.current.click();
  }

  function handleSubmit(e = null) {
    if (e?.target?.files) {
      const files = Array.from(e.target.files);

      files.forEach((file) => {
        const fileType = file.type;

        if (fileType !== "image/png") {
          addToast("Only PNG files are allowed for " + file.name, "warn");
          return;
        }

        if (file.size > iconMaxSize) {
          addToast("Icon size is too large for " + file.name, "warn");
          return;
        }

        if (iconsListing.some((icon) => icon.iconName === file.name)) {
          addToast("Icon already uploaded: " + file.name, "warn");
          return;
        }

        const formdata = new FormData();
        formdata.append("files", file);

        uploadIconToServer(formdata, addToast).then((uploadedFiles) => {
          setIconsListing((oldIconsList) => {
            return mergeArray(oldIconsList, uploadedFiles, "iconName");
          });
        });
      });
    }
  }

  return (
    <div className="iconManagerBar">
      {iconsListing?.length > 0 && (
        <CheckboxWithThreeStates
          color="red"
          label="Select All"
          width="9rem"
          checkmarkColor={themeColors[themeMode].SupportButtonBg}
          borderColor="#EEEEEE"
          labelColor={themeColors[themeMode].text}
          borderWidth="0.5px"
          horizontalPadding="0.75rem"
          backgroundColor={themeColors[themeMode].ToolBarBg}
          selectedCards={iconsSelected}
          totalCount={iconsListing.length}
          readOnly={readOnly}
          data={iconsListing}
          onSelectionChange={
            readOnly
              ? () => {}
              : (newSelection) => toggleSelectAll(newSelection)
          }
        />
      )}
      {iconsListing.length > 0 && (
        <CustomButton
          iconName="Download"
          className="CustomButton"
          iconSize={25}
          iconColor={
            iconsSelected.length === 0
              ? themeColors[themeMode].TbButtonLabelDisable
              : themeColors[themeMode].SupportButtonBg
          }
          backgroundColor={themeColors[themeMode].ToolBarBg}
          label="Download"
          color={
            iconsSelected.length === 0
              ? themeColors[themeMode].TbButtonLabelDisable
              : themeColors[themeMode].TbDefaultButtonLabel
          }
          gap={0.625}
          heightBtn="2.938rem"
          fontSize={16}
          paddingHorizontal={1}
          paddingVertical={0.5}
          onClick={DownloadIcon}
          disableClickReadOnly={false}
          borderWidth="0.5px"
          borderColor="#EEEEE"
        />
      )}
      <CustomButton
        iconName="upload"
        iconSize={25}
        iconColor={themeColors[themeMode].CustomButtons}
        backgroundColor={themeColors[themeMode].ToolBarBg}
        label="Upload"
        color={themeColors[themeMode].TbDefaultButtonLabel}
        borderColor="#EEEEEE"
        gap={0.625}
        borderWidth="0.5px"
        heightBtn="2.938rem"
        fontSize={16}
        paddingHorizontal={1}
        paddingVertical={0.5}
        onClick={handleClick}
        Input={true}
        inputRef={hiddenFileInput}
        handleInput={handleSubmit}
        disableClickReadOnly={false}
        multipleFiles={true}
        fileType=".png"
        accept=".png"
      />
    </div>
  );
}

export default IconManagerBar;
