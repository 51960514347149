import React, { useState } from "react";
import "./index.scss";
import { useTheme } from "../../../../../../../../theme/themeContext";
import CustomButton from "../../../../../../../../components/CustomButton/index";
import useInput from "../../../../../../customHooks/useInput";

function EmailInput() {
  const [emails, setEmails] = useState([]);
  const { themeMode, themeColors } = useTheme();
  const { value: input, bind, reset } = useInput("");

  const emailRegex = /\S+@\S+\.\S+/;

  function handleKeyDown(e) {
    if (e.key === "Enter" && input && emailRegex.test(input)) {
      setEmails([...emails, input]);
      reset();
      e.preventDefault();
    }
  }

  function handleRemoveEmail(removedEmail) {
    setEmails(emails.filter((email) => email !== removedEmail));
  }

  return (
    <div className="email-input-container">
      {emails.map((email) => (
        <div key={email} className="email-pill">
          {email}
          <CustomButton
            className="delete-btn"
            onClick={() => handleRemoveEmail(email)}
            iconSize={16}
            iconColor={themeColors[themeMode].emailButtonBackground}
            iconName={"circle-close-new"}
          ></CustomButton>
        </div>
      ))}
      <input
        type="email"
        value={input}
        onChange={bind.onChange}
        onKeyDown={handleKeyDown}
        placeholder="Your e-mail"
        required
      />
    </div>
  );
}

export default EmailInput;
