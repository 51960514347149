import { dataStacked } from "../../../utils/chartFakeData";

const compareFiles = {
  initialState: {
    compare: false,
    resetChanges: false,
    leftCode: {
      code: "",
      active: false,
      fileSelected: {},
      history: [],
      filename: "",
      mode: "",
    },
    rightCode: {
      code: "",
      active: false,
      fileSelected: {},
      history: [],
      filename: "",
      mode: "",
    },
  },
  reducers: {
    setLeftXmlValue: (state, action) => {
      const { leftXml } = action.payload;
      return {
        ...state,
        leftCode: { ...state.leftCode, code: leftXml },
      };
    },
    updateLeftXmlValue: (state, action) => {
      const { leftXml } = action.payload;
      return {
        ...state,
        leftCode: { ...state.leftCode, code: leftXml },
      };
    },
    setRightXmlValue: (state, action) => {
      const { rightXml } = action.payload;
      return {
        ...state,
        rightCode: { ...state.rightCode, code: rightXml },
      };
    },
    updateRightXmlValue: (state, action) => {
      const { rightXml } = action.payload;
      return {
        ...state,
        rightCode: { ...state.rightCode, code: rightXml },
      };
    },
    setLeftSideShow: (state, action) => {
      const { showLeft } = action.payload;
      return {
        ...state,
        leftCode: { ...state.leftCode, active: showLeft },
      };
    },
    setRightSideShow: (state, action) => {
      const { showRight } = action.payload;
      return {
        ...state,
        rightCode: { ...state.rightCode, active: showRight },
      };
    },
    setSelectedItemLeft: (state, action) => {
      const itemLeftSelected = action.payload;
      return {
        ...state,
        leftCode: { ...state.leftCode, fileSelected: itemLeftSelected },
      };
    },
    setSelectedItemRight: (state, action) => {
      const itemRightSelected = action.payload;
      return {
        ...state,
        rightCode: { ...state.rightCode, fileSelected: itemRightSelected },
      };
    },
    setLeftFileName: (state, action) => {
      const { leftFile } = action.payload;
      return {
        ...state,
        leftCode: { ...state.leftCode, filename: leftFile },
      };
    },
    setRightFileName: (state, action) => {
      const { rightFile } = action.payload;
      return {
        ...state,
        rightCode: { ...state.rightCode, filename: rightFile },
      };
    },
    updateCompareState: (state, action) => {
      const { compare } = action.payload;
      return {
        ...state,
        compare: compare,
      };
    },
    updateResetChangesState: (state, action) => {
      const { resetChanges } = action.payload;
      return {
        ...state,
        resetChanges: resetChanges,
      };
    },
    setLeftMode: (state, action) => {
      const { leftMode } = action.payload;
      return {
        ...state,
        leftCode: { ...state.leftCode, mode: leftMode },
      };
    },
    setRightMode: (state, action) => {
      const { rightMode } = action.payload;
      return {
        ...state,
        rightCode: { ...state.rightCode, mode: rightMode },
      };
    },
  },
};

export default compareFiles;
