import React from "react";
import "./index.scss";
import Icon from "../../../../../../../components/CustomIcon/Icon";
function DeleteMedia({ setOpenForm = () => {}  , handleConfirm = () => {} }) {


  return (
    <div className="delete-media-modal-container">
      <div className="delete-media-modal-header">
        <span className="title-modal-delete-media">Delete Media</span>
      </div>
      <div
        style={{
          width: "98%",
          height: 1,
          backgroundColor: "#e2e2e2",
          display: "flex",
          alignSelf: "center",
          marginBottom: 5,
        }}
      />
      <div className="delete-media-modal-body">
        <div className="media-image-container">
          <div className="media-image-wrap">
            <div style={{ height: 2, width: 2, backgroundColor: "#6a549e" }} />
            <div className="file-content">
              <Icon icon="doc-file" backgroundColor="#fff" color="#fff" />
            </div>
          </div>
        </div>
        <div className="media-delete-msg-avert">
          <span>Are you sure you want to remove this Media?</span>
        </div>
      </div>
      <div className="delete-media-modal-footer">
        <button
          className="cancel-btn-delete-media"
          onClick={() => setOpenForm(false)}
        >
          Cancel
        </button>
        <button
          className="confirm-btn-delete-media"
          onClick={() => handleConfirm()}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default DeleteMedia;
