import React from 'react';
import ExecutionTime from './ExecutionTime';
import RequestBody from './RequestBody';
import Request from './Request';
import Response from './Response';
import ResponseBody from './ResponseBody';
import NoRequestSelected from '../NoRequestSelected';
const ResponseArea = ({ selectedRequest, display, setDisplay }) => {
  return (
    <div className="pane ">
      {selectedRequest ? (
        <div className="selected-request">
          {/* performance */}
          <ExecutionTime selectedRequest={selectedRequest} />
          {/* request */}
          <Request
            selectedRequest={selectedRequest}
            display={display}
            setDisplay={setDisplay}
          />

          {/*request  body */}
          {selectedRequest?.request?.requestData?.data && (
            <RequestBody
              selectedRequest={selectedRequest}
              display={display}
              setDisplay={setDisplay}
            />
          )}
          {/* response */}
          {selectedRequest?.response?.responseMetadata?.status && (
            <Response
              selectedRequest={selectedRequest}
              display={display}
              setDisplay={setDisplay}
            />
          )}
          {/* response body */}
          {selectedRequest?.response?.responseData && (
            <ResponseBody
              selectedRequest={selectedRequest}
              display={display}
              setDisplay={setDisplay}
            />
          )}
        </div>
      ) : (
        <NoRequestSelected />
      )}
    </div>
  );
};

export default ResponseArea;
