import React from "react";
import "./index.scss";

const Switcher = ({ toggleSwitch = () => {}, isActive = false }) => {
  return (
    <div
      className={`switcher ${
        isActive ? "active-switcher" : "inactif-switcher"
      }`}
      onClick={toggleSwitch}
    >
      <div
        className={`switcher-circle ${
          isActive ? "active-switcher-circle" : "inactif-switcher-circle"
        }`}
        style={{
          transform: isActive ? "translateX(13px)" : "translateX(0)",
        }}
      ></div>
    </div>
  );
};

export default Switcher;
