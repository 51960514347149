export const Resine = {
  themeName: "Resine",
  isDark: false,
  CardViewBgColor: "#8A172A10",
  secondaryLight: "#12182380",
  primary: "#8A172A",
  iconAppItem: "#8A172A",
  AppText: "#414141",
  connectBtn: "#8A172A",
  loader: "#8A172A",
  headerColor: "#8A172A",
  primaryLight: "#8A172A50",
  primaryColor: "#8A172AB2",

  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#fff",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#FF6E89",
  focusedBorderLoginInput: "#FF6F8A",
  focusedLabelLoginInput: "#FF6E89",
  resetInputLogin: "#FF6E89",
  focusedBorderLoginInput: "#FF6F8A",

  languageIcon: "#333",
  settingCollapseColor: "#eee",
  welcomeBg: "#FBF9F9",
  success: "#3ecd7e",
  primaryDark: "#6E0E1F",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#8A172A",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#8A172A",
  yellowToast: "#edc300",
  greyToast: "#FF6E89",
  grayToast: "#aaa",
  lightToast: "#D9C9CC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#8A172A",
  groupIcon: "#ffffff",
  loginBtnLabel: "#5B0717",

  borderRecord: "#8A172A50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D9C9CC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D9C9CC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#8A172A",
  listBgCard: "#eee",
  noItemToDisplay: "#FF6E89",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#5B0717",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#fff",
  loginFooter: "#333",
  shadowBtnConnect: "#FF6E89",
  bnConnect: "#fff",
  notfocusedBorderLoginInput: "#8A172A",
  headerPrimary: "#8A172A00",
  headerText: "#414141",
  headerDark: "#6E0E1F00",
  notificationUpColor: "#FEF6F7",
  notificationDownColor: "#FBE9EC",

  listItemNotificationUpColor: "#FDF2F4",
  listItemNotificationDownColor: "#FAE0E5",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F1F1",

  notFocusedSocialMedia: "#8A172A",
  focusedSocialMedia: "#8A172Acc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#8A172A",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
