import { getMaximoConfig } from "../../../../services";

async function __getMaxappsHost(setMaxappsHost = () => {}) {
  try {
    const response = await getMaximoConfig();
    setMaxappsHost(response?.content?.dashboardUser?.maxappsHost);
  } catch (error) {
    console.error("Error in __getMaxappsHost:", error);
  }
}

export default __getMaxappsHost;
