function getBorderRadius(index, isHovered) {
  try {
    if (isHovered) return "Opx";
    switch (index) {
      case 0:
        return "25px 25px 0 0";
      case 1:
        return "0px";
      default:
        return "0 0 25px 25px";
    }
  } catch (error) {
    console.error("Error in getBorderRadius function:", error);
    return null;
  }
}

function handleMouseDown(e, setIsDragging) {
  try {
    setIsDragging(true);
    e.preventDefault();
  } catch (error) {
    console.error("Error in handleMouseDown function:", error);
  }
}

function handleMouseMove(e, isDragging, setRevisionBtnTop, setIsDragging) {
  try {
    if (!isDragging) return;
    let newPosition = e.clientY;
    const topLimit = 7 * 16; // 7rem from the top
    const maxBottomPosition = window.innerHeight - topLimit;
    if (newPosition < topLimit) {
      newPosition = topLimit;
    }
    if (newPosition > maxBottomPosition) {
      newPosition = maxBottomPosition;
    }
    setRevisionBtnTop(newPosition);
    e.preventDefault();
  } catch (error) {
    console.error("Error in handleMouseMove function:", error);
  }
}

function handleMouseUp(setIsDragging) {
  try {
    setIsDragging(false);
  } catch (error) {
    console.error("Error in handleMouseUp function:", error);
  }
}

export { getBorderRadius, handleMouseDown, handleMouseMove, handleMouseUp };
