async function handleCopyName(event, iconName, toast) {
  event.stopPropagation();
  try {
    await navigator.clipboard.writeText(iconName);
  } catch (error) {
    console.error("Error copying icon name:", error);
    toast.error("Failed to copy icon name.", {
      appearance: "error",
      autoDismiss: true,
    });
  }
}

export default handleCopyName;
