export const Ruby = {
  themeName: "Ruby",
  isDark: false,
  CardViewBgColor: "#88162a10",
  secondaryLight: "#12182380",
  primary: "#88162a",
  iconAppItem: "#88162a",
  AppText: "#88162a",
  connectBtn: "#88162a",
  loader: "#88162a",
  headerColor: "#88162a",
  primaryLight: "#88162a50",
  primaryColor: "#88162AB2",

  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#ccc",
  focusedBorderLoginInput: "#ccc",
  focusedLabelLoginInput: "#ccc",
  resetInputLogin: "#88162a",

  languageIcon: "#ccc",
  settingCollapseColor: "#eee",
  welcomeBg: "#FBF9F9",
  success: "#3ecd7e",
  primaryDark: "#6E0E1F",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#88162a",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#88162a",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#D9C9CC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#88162a",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#88162a50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D9C9CC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D9C9CC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#88162a",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  loginFooter: "#88162a",
  shadowBtnConnect: "#ccc",
  bnConnect: "#88162a",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#88162a00",
  headerText: "#88162a",
  headerDark: "#6E0E1F00",
  notificationUpColor: "#FEF6F8",
  notificationDownColor: "#FBE9EC",

  listItemNotificationUpColor: "#FAE0E5",
  listItemNotificationDownColor: "#FAE0E5",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F1F1",

  notFocusedSocialMedia: "#88162a",
  focusedSocialMedia: "#88162acc",
  loginBgFooter: "#fff",

  //Costum4
  loginFirstTabTitle: "#222",
  loginTabTitle: "#222",
  loginTabSelected: "#fff",
  loginTabNotSelected: "#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft: "#fff",
  languageContainerRight: "#88162a",
  submitLeftConnect: "#222",
  submitRightConnect: "#fff",
};
