import React from "react";
import { useSelector, useStore } from "react-redux";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";

function SwipeLanguage({ handleOpenActionsLanguage }) {
  const store = useStore();

  const LanguagesSetup = [
    { name: "English", lang: "en" },
    { name: "French", lang: "fr" },
    { name: "العربية", lang: "ar" },
    { name: "Italian", lang: "it" },
    { name: "Allemand", lang: "de" },
    { name: "Norwegian", lang: "nb" },
  ];

  const { theme, themes } = useDeviceTheme();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const changeLanguage = (lang) => {
    store.dispatch({
      type: "deviceview/setLanguage",
      payload: {
        language: lang,
      },
    });
  };

  return (
    <div className="swipe-containerLg" onClick={(e) => handleComponentProp(e)}>
      <div
        className="close-button"
        onClick={(event) => {
          handleOpenActionsLanguage(event, false);
        }}
      >
        <Icon icon="times2" size={15} className="timesIcon" />
      </div>
      <div className="swipe">
        {LanguagesSetup?.map((lg, index) => {
          const isActiveLanguage = language === lg?.lang;
          return (
            
            <div
              key={index}
              className="language-option"
              onClick={(event) =>  {changeLanguage(lg?.lang);
                handleOpenActionsLanguage(event, false)
              }}
              style={{
                backgroundColor: isActiveLanguage
                  && themes?.[theme]?.primaryLight,
                   borderBottom: isActiveLanguage ? "0px" : `1px solid ${themes?.[theme]?.primaryLight}`,
                 borderTop: isActiveLanguage && "0px" 
                  
              }}
            >
              <div className="language-details">
                <div className="language-name">{lg?.name}</div>
                <div className="language-code">{lg?.lang.toUpperCase()}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SwipeLanguage;
