function handleModalData(ref = "", type = "", utilities = []) {
  if (type?.toLowerCase() === "condition") {
    const targetCondition = utilities.find(
      (condition) => condition?.reference === ref
    );
    return targetCondition;
  } else if (type?.toLowerCase() === "script") {
    const targetFunction = utilities.find((fct) => fct?.reference === ref);
    return targetFunction;
  } else if (type?.toLowerCase() === "lookup") {
    const targetLookup = utilities.find((lookup) => lookup?.reference === ref);
    return targetLookup;
  }
}

export function handleOpenModal(
  type = "",
  reference = "",
  utilities = [],
  openModal,
  path = []
) {
  if (reference === "" || type === "") {
    return;
  }

  const indexUtility = utilities?.findIndex((c) => c?.reference === reference);
  const modalData = handleModalData(reference, type, utilities);
  if (!modalData) {
    return;
  }
  openModal(type, reference, false, modalData, [
    ...path,
    indexUtility?.toString(),
  ]);
}
