import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import createCache from "@emotion/cache";
import "./index.scss";

const createRtlTheme = (language) => {
  return createTheme({
    direction: language === "ar" || language === "he" ? "rtl" : "ltr",
  });
};

const CustomInput = ({
  label,
  rows = null,
  type = "",
  value,
  onChange = () => {},
  required = true,
  language = "en",
  children,
  placeholder = "",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const theme = createRtlTheme(language);

  const cacheRtl = createCache({
    key: "custom-input-rtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  return (
    <div
      className="input-group"
      dir={language?.toLowerCase() === "ar" && theme.direction}
    >
      {language?.toLowerCase() === "ar" ? (
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            {type?.toLowerCase() === "password" ? (
              <FormControl
                className="auth-input"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {/* {`${label} ${required && "* "}`} */}
                  {`${label} `}
                </InputLabel>
                <OutlinedInput
                  className="auth-input"
                  type={showPassword ? "text" : "password"}
                  value={value}
                  onChange={onChange}
                  autoComplete="new-password"
                  placeholder={placeholder}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // label={`${label} ${required && "* "}`}
                  label={`${label} `}
                  fullWidth
                />
              </FormControl>
            ) : (
              <TextField
                size="small"
                className="auth-input"
                // label={`${label} ${required && "*"}`}
                label={`${label} `}
                placeholder={label}
                onChange={onChange}
                value={value}
                multiline={rows > 1 && true}
                rows={rows}
                autoComplete="nope"
                fullWidth
              />
            )}
          </ThemeProvider>
        </CacheProvider>
      ) : (
        <React.Fragment>
          {type?.toLowerCase() === "password" ? (
            <FormControl className="auth-input" variant="outlined" size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                {/* {`${label} ${required && "*"} `} */}
                {`${label} `}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                className="auth-input"
                value={value}
                onChange={onChange}
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                // label={`${label} ${required && "*"} `}
                label={`${label}  `}
                fullWidth
              />
            </FormControl>
          ) : (
            <TextField
              size="small"
              className="auth-input"
              // label={`${label} ${required && "*"}`}
              label={`${label} `}
              placeholder={label}
              onChange={onChange}
              value={value}
              multiline={rows > 1 && true}
              rows={rows}
              autoComplete="nope"
              fullWidth
            />
          )}
        </React.Fragment>
      )}
      {children}
    </div>
  );
};

export default CustomInput;
