import React from "react"
import Icon from "../../../../../../../components/CustomIcon/Icon"
export function getIcon(label) {
  switch (label) {
    case "CPU":
      return <Icon icon='CPU' size={47} style={{ color: "#E1E1E1" }} />
    case "RAM":
      return <Icon icon='RAM' size={47} style={{ color: "#E1E1E1" }} />
    case "HDD":
      return <Icon icon='HDD' size={47} style={{ color: "#E3E3E3" }} />
    default:
      return ""
  }
}
