import { axiosProviderNode } from "../../../../../services/http/httpService";
import { services } from "../../../services/configVariables";

async function verifyUrlStatus(url = "", values = {}) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_VERIFY_OSLC_URL;
    const body = {
      url,
      urlVerification: true,
    };
    const params = {};
    const res = await axiosProviderNode.post(
      transactionNodeService,
      body,
      params
    );

    if (
      res?.data?.status === 401 ||
      res?.data?.status === 200 ||
      res?.data?.status === 400
    ) {
      return { ...res?.data, content: { status: true } };
    }
    return {
      ...res?.data,
      content: { status: false, message: res?.data?.message },
    };
  } catch (error) {
    return error;
  }
}
export default verifyUrlStatus;
