import React from "react";
import "./index.scss";

function ProgressBar({ pourcentage = {}, showNumber = true }) {
  return (
    <div className="installation-progress-container">
      <div className="installation-progress-bar">
        <div
          className="installation-progress"
          style={{ width: `${pourcentage}%` }}
        ></div>
      </div>
      {showNumber ? (
        <span className="percentageStyles">{`${pourcentage}%`}</span>
      ) : null}
    </div>
  );
}

export default ProgressBar;
