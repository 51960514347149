export const Adariona = {
  themeName: "Adariona",
  isDark: false,
  CardViewBgColor: "#f1eef6bb",
  primary: "#405378",
  iconAppItem: "#405378",
  AppText: "#405378",
  loginFooter: "#405378",
  connectBtn: "#405378",
  label: "#405378",
  loader: "#405378",
  headerColor: "#405378",
  primaryDark: "#2C3F64",
  offline: "#ec4545",
  offlineLabel: "#838383",
  primaryLight: "#40537840",
  welcomeBg: "#D4DFE5",
  secondary: "#B167A1",
  focusedLabelLoginInput: "#B167A1",
  resetInputLogin: "#B167A1",
  focusedBorderLoginInput: "#B167A1",
  languageIcon: "#B167A1",
  secondaryLight: "#B167A180",
  textColor: "#000",
  labelColor: "#5874AD",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  settingCollapseColor: "#eee",
  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",
  fapMap: "#aaa",
  danger: "#EE6E6E",
  warning: "#e59d4b",
  success: "#3ecd7e",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#6F53A3",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#e5e2ec",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#1874A440",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D4DFE5",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D4DFE5",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMapBg: "#ffffff",
  fapMapActive: "#1874A4",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#B167A1",
  bnConnect: "#405378",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#40537800",
  headerDark: "#2C3F6400",
  headerText: "#405378",
  notificationUpColor: "#F8F9FB",
  notificationDownColor: "#EEF1F6",

  listItemNotificationUpColor: "#F5F7FA",
  listItemNotificationDownColor: "#E8EBF3",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F2F1F4",
  notFocusedSocialMedia: "#405378",
  focusedSocialMedia: "#405378cc",
  loginBgFooter: "#fff",
  
    //Costum4
    loginFirstTabTitle:"#222",
    loginTabTitle:"#222",
    loginTabSelected:"#fff",
    loginTabNotSelected:"#ffffff4d",

    //Custom Languge Modal
    languageContainerLeft:"#fff",
    languageContainerRight:"#405378",
    submitLeftConnect:"#222",
    submitRightConnect:"#fff",
};
