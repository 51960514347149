const installer = {
  initialState: {
    completedInstallation: false,
    loading: false,
    maximoLoginIn: false,
    maxappsHost: null,
    currentStep: "login",
  },
  reducers: {
    updateCurrentStep: (dataSet, action) => {
      const { newStep } = action.payload;
      dataSet["currentStep"] = newStep;
    },

    updateCompletedInstallation: (dataSet, action) => {
      const { installationStatus } = action.payload;
      dataSet["completedInstallation"] = installationStatus;
    },
    setLoading: (dataSet, action) => {
      try {
        const { isLoading } = action.payload;
        dataSet["loading"] = isLoading;
      } catch (error) {
        console.error(action.paylaod);
      }
    },

    updateMaximoLoginStatus: (dataSet, action) => {
      try {
        const { status = false } = action.payload;
        dataSet["maximoLoginIn"] = status;
      } catch (error) {
        console.error("error updateMaximoLoginStatus==", action.payload);
      }
    },
    updateMaxappsHostValue: (dataSet, action) => {
      try {
        const { maxappsHost } = action.payload;
        dataSet["maxappsHost"] = maxappsHost;
      } catch (error) {
        console.error("maxappsHost==", action.paylaod);
      }
    },
    reset: (dataSet) => {
      return (dataSet = {
        completedInstallation: false,
        loading: false,
        maximoLoginIn: false,
        maxappsHost: null,
        currentStep: "login",
      });
    },
  },
};

export default installer;
