import "./index.scss";
import Icon from "../../../../components/CustomIcon/Icon";
export default function SearchBar({
  searchInput,
  handleSearchChange,
  handleSearchClick,
}) {
  return (
    <div className="Sync-Search-Section">
      <input
        className="Sync-Search-bar"
        placeholder="Search for an automation script"
        value={searchInput}
        onChange={handleSearchChange}
      />
      <button className="Sync-search-icon" onClick={handleSearchClick}>
        <Icon icon="search" size={22} style={{ color: "#FFFFFF" }} />
      </button>
    </div>
  );
}
