import React, { useEffect, useMemo, useRef, useState } from "react";
import Body from "../../../../Body";
import Modal from "../../../../Modal";
import Form from "../../../../Form";
import imxtools from "imxtools";
import "./index.scss";
import Card from "./Card";
import DropTargetBody from "../../../../DropTargetBody";

const BodyList = ({
  data = {},
  listData = {},
  path = [],
  setLlistLength,
  handleOpenActions = () => {},
  readOnly = true,
  relationship = false,
  setOpenRelationship,
  currentPath = [],
  setOpenForm = () => {},
}) => {
  const [bodyCount, setBodyCount] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [titleForm, setTitleForm] = useState("");
  const [targetForm, setTargetForm] = useState("");
  const [selectedBody, setSelectedBody] = useState(false);

  useEffect(() => {
    const referenceForm = data?.list
      ? data?.list?.detail?.event?.targetref
      : data?.detail?.list?.detail?.event?.targetref;
    const forms = imxtools?.toArray(data?.list?.form ? data?.list?.form : []);
    const targetIndexForm = forms?.findIndex(
      (form) => form?.reference === referenceForm
    );
    setTargetForm(targetIndexForm !== -1 ? forms?.[targetIndexForm] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function generateRandomString() {
    const minLength = 5;
    const maxLength = 10;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const stringLength =
      Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    let result = "";
    for (let i = 0; i < stringLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }

    return result;
  }

  const randomBodyCountRef = useRef(null);

  useEffect(() => {
    const min = 8;
    const max = 16;
    randomBodyCountRef.current = relationship
      ? 2
      : Math.floor(Math.random() * (max - min + 1)) + min;
    setLlistLength(
      readOnly || listData?.hasImages?.toLowerCase() === "true"
        ? randomBodyCountRef.current
        : 1
    );
    setBodyCount(
      readOnly || listData?.hasImages?.toLowerCase() === "true"
        ? randomBodyCountRef.current
        : 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationship, setLlistLength]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const randomString = useMemo(() => generateRandomString(), [bodyCount]);

  const renderBodies = () => {
    const bodies = [];
    for (let i = 0; i < bodyCount; i++) {
      bodies.push(
        <Body
          key={i}
          index={i}
          parentData={relationship ? data : listData}
          data={relationship ? data?.body : listData?.body}
          listData={listData}
          previewImage={listData?.previewImage === "true" ?? false}
          path={path}
          handleOpenActions={listData?.swipe && handleOpenActions}
          readOnly={readOnly}
          relationship={relationship}
          setOpenRelationship={setOpenRelationship}
          currentPath={currentPath}
          setSelectedBody={setSelectedBody}
          selectedBody={selectedBody}
          hasImage={listData?.hasImages === "true" ?? false}
          mboname={listData?.mboname}
          setOpenForm={setOpenForm}
        />
      );
    }
    return bodies;
  };

  return (
    <React.Fragment>
      {listData && (
        <DropTargetBody
          accept={[
            "body",
            "detail",
            "map",
            "fab",
            "swipe",
            "whereclause",
            "calendar",
          ]}
          dragParent={path}
          className={`list-wrapper ${
            listData?.body?.display?.toLowerCase() === "cards" &&
            "list-display-cards"
          } ${
            listData?.hasImages?.toLowerCase() === "true" &&
            "list-wrapper-hasImage"
          } ${
            listData?.hasImages?.toLowerCase() !== "true" &&
            listData?.body?.display?.toLowerCase() !== "cards" &&
            !readOnly &&
            "list-wrapper-edit"
          } ${
            listData?.body?.display == "cards" &&
            listData?.mboname == "CLASSSTRUCTURE" &&
            "gridLayout"
          }`}
          style={{
            alignItems: listData?.body && "flex-start",
            justifyContent: listData?.body && "start",
          }}
        >
          {listData?.body ? (
            <React.Fragment>{renderBodies()}</React.Fragment>
          ) : (
            <div className="list-no-body">Add Body</div>
          )}
        </DropTargetBody>
      )}
      <Modal openModal={isOpen} tab={true}>
        <Form
          setOpenForm={setIsOpen}
          data={targetForm}
          titleForm={titleForm}
          readOnly={true}
          path={[...path, "form"]}
        />
      </Modal>
    </React.Fragment>
  );
};

export default BodyList;
