const interceptor = {
  initialState: {
    requestsResponsePairs: [],
  },

  reducers: {
    addRequestResponsePair: (state, action) => {
      state.requestsResponsePairs = action?.payload;
    },

    resetRequestResponsePair: (state) => {
      state.requestsResponsePairs = [];
    },
  },
};

export default interceptor;
