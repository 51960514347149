export const Ocean = {
  themeName: "Ocean",
  isDark: false,
  CardViewBgColor: "#f1eef6bb",
  connectBtn: "#222222",
  label: "#D2232A",
  loader: "#F7D600",
  headerColor: "#222222",
  offline: "#ec4545",
  offlineLabel: "#838383",
  primaryColor: "#515050B2",
  welcomeBg: "#CBDBDF",

  textColor: "#222222",
  labelColor: "#8A8A8A",
  listBg: "#ffffff",

  settingCollapseColor: "#eee",
  separatorColor: "#aaa",

  fapMap: "#aaa",
  danger: "#EE6E6E",
  warning: "#e59d4b",
  welcomeBg: "#D7E3E3",
  success: "#3ecd7e",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#D2232A",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#e5e2ec",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  groupIcon: "#ffffff",

  borderRecord: "#51505040",
  borderFormInput: "#eee",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CBDBDF",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMapBg: "#ffffff",
  fapMapActive: "#222222",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffffff",
  linkColor: "#1D9BF0",
  colorTextInput: "#222222",
  LogincolorTextInput: "#222222",
  headerPrimary: "#22222200",
  headerDark: "#29292900",
  headerText: "#222222",

  //notification colors
  //1
  notificationUpColor: "#FEF6F7",
  notificationDownColor: "#FBE9EA",
  //2
  listItemNotificationDownColor: "#FDF2F2",
  listItemNotificationUpColor: "#FAE0E1",
  //3
  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#FBE9EA",

  //login text input
  //bg colors
  bgLoginInput: "#fff",
  //border colors
  notfocusedBorderLoginInput: "#eeeeee",
  focusedBorderLoginInput: "#D2232A",
  //label colors
  focusedLabelLoginInput: "#D2232A",
  notFocusedLabelInput: "#aaaaaa",
  loginText: "#222222",
  LogincolorLabelInput: "#aaaaaa",
  //icons colors
  resetInputLogin: "#D2232A",

  containerLanguageIcon: "#ffffff00",

  loginFooter: "#222222",
  languageIcon: "#222222",
  loginOptions: "#222222",

  //connect button colors
  bnConnect: "#D2232A",
  loginBtnLabel: "#ffffff",
  shadowBtnConnect: "#D2232A33",

  SelectedDrawerItem: "#FDF2F2",

  //app colors
  iconAppItem: "#F7D600",
  AppText: "#222222",

  //primary colors
  primary: "#D2232A",
  primaryDark: "#7B070B",
  primaryLight: "#D2232A40",

  //secondary colors
  secondary: "#F7D600",
  secondaryLight: "#F7D60080",

  loginBgFooter: "#fff",
  //Costum4
  loginFirstTabTitle: "#222",
  loginTabTitle: "#222",
  loginTabSelected: "#fff",
  loginTabNotSelected: "#ffffff4d",

  //CustomLanguge Modal
  languageContainerLeft: "#fff",
  languageContainerRight: "#D2232A",
  submitLeftConnect: "#222",
  submitRightConnect: "#fff",
};
