import { axiosProviderNode } from "../../../../../services/http/httpService"
import { services } from "./loggerEndPoints"

async function makeLoggerRequest(nodeEndpoint, params = {}) {
  try {
    const transactionNodeService = services[nodeEndpoint]
    const res = await axiosProviderNode.get(transactionNodeService, params)
    return res?.data
  } catch (error) {
    throw error
  }
}

export default makeLoggerRequest
