import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import imxtools from "imxtools";
import DropTargetBody from "../DropTargetBody";
import { AppContext } from "../../../../../services/AppContext";
import { RenderApps, RenderKpis, RenderModules, RenderRows } from "./services";
import { ComponentsLibraries } from "../../../../../services/common";

function PageBody({
  PageContent,
  path = [],
  readOnly = true,
  currentPage,
  pages,
  setOpenModuleMatrix = () => {},
  setModuleData = () => {},
}) {
  const [hoverStates, setHoverStates] = useState([]);
  const [Apps, setApps] = useState([]);
  const [Kpis, setKpis] = useState([]);
  const [hoverIn, setHoverIn] = useState("");
  const [theItem, setTheItem] = useState(false);
  const [moduleContent, setModuleContent] = useState([]);
  const [rowContent, setRowContent] = useState([]);
  const store = useStore();

  let view = useSelector(
    (state) => state?.entities?.deviceview?.settings?.view || "row"
  );

  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  const { setIsDragingIn, setKpiDetail } = useContext(AppContext);

  useEffect(() => {
    setModuleContent(imxtools?.toArray(PageContent?.module ?? []));
  }, [PageContent?.module]);

  useEffect(() => {
    setRowContent(imxtools?.toArray(PageContent?.row ?? []));
  }, [PageContent?.row]);

  useEffect(() => {
    setApps(imxtools?.toArray(PageContent?.app ?? []));
  }, [PageContent]);

  useEffect(() => {
    setKpis(imxtools?.toArray(PageContent?.kpi ?? []));
  }, [PageContent]);

  return (
    <DropTargetBody
      className={`drop-page-zone ${!readOnly && "drop-page-zone-edit"}`}
      dragParentPath={["maxapps", "page"]}
      accept={Object.keys(ComponentsLibraries?.page)}
      dropTargetName={"page"}
      pages={pages}
      currentPage={currentPage}
      homeScreen={true}
      style={{
        minHeight: "100%",
      }}
      onClick={() => setKpiDetail(false)}
    >
      {RenderRows(
        path,
        readOnly,
        currentPage,
        pages,
        PageContent,
        rowContent,
        setRowContent,
        setIsDragingIn
      )}
      {RenderKpis(
        store,
        readOnly,
        pages,
        path,
        currentPage,
        PageContent,
        hoverIn,
        setHoverIn,
        theItem,
        setTheItem,
        Kpis,
        setKpis,
        hoverStates,
        setHoverStates,
        currentPath
      )}
      <div
        className={
          view?.toLowerCase() === "row" ? "drop-page" : "drop-page-matrix"
        }
      >
        {RenderModules(
          path,
          readOnly,
          currentPage,
          pages,
          PageContent,
          moduleContent,
          setModuleContent,
          setIsDragingIn,
          view,
          currentPath,
          setOpenModuleMatrix,
          setModuleData
        )}
        {RenderApps(
          store,
          readOnly,
          pages,
          path,
          currentPage,
          PageContent,
          hoverIn,
          setHoverIn,
          theItem,
          setTheItem,
          Apps,
          setApps,
          hoverStates,
          setHoverStates,
          view,
          currentPath
        )}
      </div>
    </DropTargetBody>
  );
}

export default PageBody;
