import React, { useState } from "react";
import Icon from "../CustomIcon/Icon";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import "./index.scss";

const CustomButton = ({
  iconColor = "black",
  Input = false,
  withIcon = true,
  withLabel = true,
  label = "",
  initialActive = false,
  backgroundColor = "none",
  hoveredBackground = "",
  hoveredColor = "",
  isActive: externalActive,
  ActiveColor = "",
  ActiveBackground = "",
  hoveredBorderColor = "",
  color = "",
  fontSize = 16,
  borderRadius = 0,
  lineHeight = "",
  heightBtn = null,
  widthBtn = null,
  paddingVertical = 0,
  paddingHorizontal = 0,
  borderColor = "transparent",
  borderWidth = "1px",
  onClick = () => {},
  handleInput = () => {},
  inputRef,
  attribute,
  iconName = "",
  iconClose = "",
  iconSize = 15,
  gap = 0,
  marginRight = 0,
  marginLeft = 0,
  disableClickReadOnly = false,
  disabled = false,
  reverseBtn = false,
  fontWeight = "",
  alignSelf = "",
  disabledColor = "#B9B8BD",
  disabledBackgroundColor = "#EEEEEE",
  borderLeft = false,
  borderRight = false,
  borderTop = false,
  borderBottom = false,
  border = false,
  fileType = ".xml",
  multipleFiles = false,
  tooltip = "",
  withoutInteration = true,
  XMLChanged = false,
  justifyContent = "",
  cursor = "pointer",
  customStyle = false,
}) => {
  const readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const [hovering, setHovering] = useState(false);
  const [internalActive, setInternalActive] = useState(initialActive);

  const isActive =
    externalActive !== undefined ? externalActive : internalActive;

  const handleClick = () => {
    if (disableClickReadOnly && readOnly) {
      return;
    }
    setHovering(false);
    onClick(attribute);
    if (externalActive === undefined) {
      setInternalActive((prevActive) => !prevActive);
    }
  };

  const currentColor = disabled
    ? disabledColor
    : hovering
    ? hoveredColor
    : isActive
    ? ActiveColor
    : color;

  const currentBackgroundColor = disabled
    ? disabledBackgroundColor
    : hovering
    ? hoveredBackground
    : isActive
    ? ActiveBackground
    : backgroundColor
    ? backgroundColor
    : "none";

  return (
    <Tooltip title={tooltip}>
      <button
        className="CustomBtn"
        onClick={handleClick}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        disabled={disabled}
        style={{
          borderRadius: `${borderRadius}px`,
          color: withoutInteration ? color : currentColor,
          backgroundColor: withoutInteration
            ? backgroundColor
            : currentBackgroundColor,
          height: heightBtn,
          width: widthBtn,
          padding: `${paddingVertical}rem ${paddingHorizontal}rem`,
          gap: `${gap}rem`,
          borderTop:
            (border || borderTop) && `${borderWidth} solid ${borderColor}`,
          borderLeft:
            (border || borderLeft) && `${borderWidth} solid ${borderColor}`,
          borderRight:
            (border || borderRight) && `${borderWidth} solid ${borderColor}`,
          borderBottom:
            (border || borderBottom) && `${borderWidth} solid ${borderColor}`,
          border: `${borderWidth} solid ${borderColor}`,
          fontSize: `${fontSize}px`,
          flexDirection: reverseBtn && "row-reverse",
          justifyContent: justifyContent,
          marginLeft: `${marginLeft}rem`,
          marginRight: `${marginRight}rem`,
          cursor: cursor,

          fontWeight: fontWeight,
          lineHeight: `${lineHeight}px`,
          alignSelf: alignSelf,
        }}
      >
        {customStyle ? (
          <div className="customStyle">
            <div className="customStyleStart">
              {withIcon && (
                <Icon icon={iconName} color={iconColor} size={iconSize} />
              )}
              {withLabel && <div className="labelBtn">{label}</div>}
            </div>
            {withIcon && iconClose !== "" && (
              <Icon icon={iconClose} color={iconColor} size={iconSize} />
            )}
          </div>
        ) : (
          <>
            {withIcon && (
              <Icon icon={iconName} color={iconColor} size={iconSize} />
            )}
            {withLabel && <div className="labelBtn">{label}</div>}
            {withIcon && iconClose !== "" && (
              <Icon icon={iconClose} color={iconColor} size={iconSize} />
            )}
          </>
        )}

        {Input && (
          <input
            type="file"
            ref={inputRef}
            onChange={handleInput}
            className="file-input"
            multiple={multipleFiles}
            accept={fileType}
          />
        )}
      </button>
    </Tooltip>
  );
};

export default CustomButton;
