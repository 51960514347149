import { useState } from "react";
import "./index.scss";
import { useDeviceTheme } from "../../../../../../../../../../DeviceTheme/DeviceThemeContext";
const CustomInputField = ({
  value,
  handleChange,
  name,
  placeHolder,
  errors,
  touched,
  handleBlur,
  setInitValues,
  initValues,
  resetForm,
  language,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { themes, theme } = useDeviceTheme();

  return (
    <div style={{ width: "100%" }}>
      <div
        className={"input-custom-container "}
        style={{
          border: `1px solid ${
            isFocused
              ? themes?.[theme]?.focusedBorderLoginInput
              : themes?.[theme]?.notfocusedBorderLoginInput
          }`,
        }}
      >
        <div className="input-content">
          {Boolean(value) ? (
            <span style={{ color: "#000", fontSize: 10 }}>{name}</span>
          ) : (
            <></>
          )}
          <input
            type={name === "password" ? "password" : "text"}
            name={name}
            placeholder={placeHolder}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`input-custom-style ${
              language?.toLowerCase() === "ar" ? "input-field-rtl" : ""
            }`}
            onFocus={() => setIsFocused(true)}
          />
        </div>

        <i
          hidden={Boolean(value) ? false : true}
          className="bx bx-x"
          style={{
            backgroundColor: "#6A549E",
            padding: 3,
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            color: "#fff",
            borderRadius: 500,
            marginRight: 4,
          }}
          onClick={resetForm}
        ></i>
      </div>
      <p
        style={{
          color: "red",
          fontSize: 11,
          fontWeight: 400,
          marginLeft: 10,
        }}
      >
        {errors?.[name] && touched?.[name] && errors?.[name]}
      </p>
    </div>
  );
};

export default CustomInputField;
