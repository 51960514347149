export default function StandardFineUrl(url) {
  const trimmedUrl = url.trim();

  if (trimmedUrl?.slice(-1) !== "/") return url;
  else {
    let newUrl = trimmedUrl?.slice(0, trimmedUrl.length - 1);
    return StandardFineUrl(newUrl);
  }
}

export function FineUrl(url) {
  let cleanUrl = url.trim();
  if (!(url.startsWith("http://") || url.startsWith("https://"))) {
    return `http://${cleanUrl}`;
  } else {
    return cleanUrl;
  }
}
