import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import DropTargetBody from "../../../DropTargetBody";
import Icon from "../../../../../../../../../components/CustomIcon/Icon";
import {
  arraysAreEqual,
  arraysHaveSameElementsInOrder,
} from "../../../../../../../../../utils/util";
import { handleComponentProp } from "../../../../services/handleComponentProperties";
import imxtools from "imxtools";
import { useSelector, useStore } from "react-redux";
import ImgStreet from "./../../../../../../../../../assets/img-street.png";
import ImgHybride from "./../../../../../../../../../assets/img-hybride.png";
import DragItemAttribute from "../../../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../../../services/AppContext";
function LineButtonsGis({
  btn = {},
  data = {},
  path = [],
  handleForm = () => {},
  handleMapTypeChange = () => {},
  handleToggleBtn = () => {},
  index = null,
  setCurrentMarkerPath = () => {},
  setPathOfList = () => {},
  displayPullup = false,
  currentMarkerData = {},
  openPullup = false
}) {
  let store = useStore();
  const [dataAttributes, setDataAttributes] = useState([]);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let parentToEdit = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );
  const { setIsDragingIn } = useContext(AppContext);

  useEffect(() => {
    setDataAttributes(imxtools.toArray(data?.list));
  }, [data]);

  let edit = useSelector((state) => state?.entities?.editor);
  return (
    <div className="btns-line-wrapper">
      {btn?.isOpen === true ? (
        <>
          {btn?.name === "add" ? (
            <div className="btns-line-content">
              {imxtools
                .toArray(data?.list)
                ?.filter((item) => item.hasOwnProperty("fab"))
                .map((list, index) => {
                  const numList = imxtools.toArray(data?.list)?.length;
                  return (
                    <>
                      <button
                        className="btn-horizontale-wrapper"
                        onClick={(e) => {
                          handleComponentProp(
                            e,
                            numList === 1 ? null : index?.toString(),
                            list,
                            path,
                            store,
                            "list"
                          );
                          setPathOfList(
                            numList === 1
                              ? [...path, "list"]
                              : [...path, "list", index?.toString()]
                          );
                        }}
                      >
                        <Icon icon={list?.icon} color={"#AAAAAA"} />{" "}
                      </button>
                    </>
                  );
                })
                .reverse()}
            </div>
          ) : btn?.name === "layers" ? (
            <div
              className="btns-line-content"
              style={openPullup ? { height: 155 } : {}}
            >
              {data?.hasOwnProperty("labortracking") ? (
                <>
                  <button
                    className="btn-horizontale-wrapper"
                    onClick={(e) =>
                      handleComponentProp(
                        e,
                        null,
                        data?.labortracking,
                        path,
                        store,
                        "labortracking"
                      )
                    }
                  >
                    <Icon
                      icon={data?.labortracking?.icon}
                      color={
                        data?.labortracking?.color
                          ? data?.labortracking?.color
                          : "#AAAAAA"
                      }
                    />
                  </button>
                </>
              ) : (
                <></>
              )}
              {dataAttributes
                ?.map((list, index) => {
                  const numList = imxtools.toArray(data?.list)?.length;
                  return (
                    <>
                      {readOnly ? (
                        <div>
                          <button
                            className="btn-horizontale-wrapper"
                            onClick={(e) => {
                              handleComponentProp(
                                e,
                                numList === 1 ? null : index?.toString(),
                                list,
                                path,
                                store,
                                "list"
                              );
                              setPathOfList(
                                numList === 1
                                  ? [...path, "list"]
                                  : [...path, "list", index?.toString()]
                              );
                            }}
                          >
                            <Icon icon={list?.icon} color={ list?.icon === "layers" ? "#fff" : "#aaaa"} />{" "}
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="list-extended-container">
                            <DragItemAttribute
                              index={index}
                              component={"list"}
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                paddingLeft: 4,
                              }}
                              dragParentPath={path}
                              dragData={list}
                              dataAttributes={dataAttributes}
                              setDataAttributes={setDataAttributes}
                              setIsDraging={setIsDragingIn}
                            >
                              <div
                                className={
                                  arraysAreEqual(
                                    numList === 1
                                      ? [...path, "list"]
                                      : [...path, "list", index?.toString()],
                                    edit.parentToEdit
                                  ) &&
                                  !readOnly &&
                                  ` ${"selectedComponent"}`
                                }
                              >
                                <button
                                  className="btn-horizontale-wrapper"
                                  onClick={(e) => {
                                    handleComponentProp(
                                      e,
                                      numList === 1 ? null : index?.toString(),
                                      list,
                                      path,
                                      store,
                                      "list"
                                    );
                                    setPathOfList(
                                      numList === 1
                                        ? [...path, "list"]
                                        : [...path, "list", index?.toString()]
                                    );
                                  }}
                                >
                                  <Icon icon={list?.icon} color={"#AAAAAA"} />{" "}
                                </button>
                              </div>
                            </DragItemAttribute>
                            <>
                              {arraysHaveSameElementsInOrder(
                                numList === 1
                                  ? [...path, "list"]
                                  : [...path, "list", index?.toString()],
                                parentToEdit
                              ) ? (
                                <>
                                  {list?.hasOwnProperty("marker") &&
                                  list?.marker !== undefined ? (
                                    <div
                                      onClick={(e) => {
                                        setCurrentMarkerPath(
                                          numList === 1
                                            ? [...path, "list", "marker"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                                "marker",
                                              ]
                                        );
                                        handleComponentProp(
                                          e,

                                          null,
                                          list?.marker,
                                          numList === 1
                                            ? [...path, "list"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                              ],
                                          store,
                                          "marker"
                                        );
                                      }}
                                      className={
                                        arraysAreEqual(
                                          numList === 1
                                            ? [...path, "list", "marker"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                                "marker",
                                              ],
                                          edit.parentToEdit
                                        ) && !readOnly
                                          ? ` ${"selectedComponent"}`
                                          : ` ${"unSelectedComponent"}`
                                      }
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: 2,
                                        minWidth: 40,
                                      }}
                                    >
                                      <button
                                        className="btn-horizontale-list-content"
                                        onClick={(e) => {
                                          handleComponentProp(
                                            e,

                                            null,
                                            list?.marker,
                                            numList === 1
                                              ? [...path, "list"]
                                              : [
                                                  ...path,
                                                  "list",
                                                  index?.toString(),
                                                ],
                                            store,
                                            "marker"
                                          );
                                          setCurrentMarkerPath(
                                            numList === 1
                                              ? [...path, "list", "marker"]
                                              : [
                                                  ...path,
                                                  "list",
                                                  index?.toString(),
                                                  "marker",
                                                ]
                                          );
                                        }}
                                      >
                                        <Icon
                                          icon={"Marker"}
                                          color={"#AAAAAA"}
                                        />{" "}
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {list?.hasOwnProperty("fab") &&
                                  list?.fab !== undefined ? (
                                    <div
                                      onClick={(e) =>
                                        handleComponentProp(
                                          e,

                                          null,
                                          list?.fab,
                                          numList === 1
                                            ? [...path, "list"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                              ],
                                          store,
                                          "fab"
                                        )
                                      }
                                      className={
                                        arraysAreEqual(
                                          numList === 1
                                            ? [...path, "list", "fab"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                                "fab",
                                              ],
                                          edit.parentToEdit
                                        ) && !readOnly
                                          ? ` ${"selectedComponent"}`
                                          : ` ${"unSelectedComponent"}`
                                      }
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: 2,
                                        minWidth: 40,
                                      }}
                                    >
                                      <button
                                        // className={"btn-horizontale-list-content"}
                                        className="btn-horizontale-list-content"
                                        onClick={(e) =>
                                          handleComponentProp(
                                            e,

                                            null,
                                            list?.fab,
                                            numList === 1
                                              ? [...path, "list"]
                                              : [
                                                  ...path,
                                                  "list",
                                                  index?.toString(),
                                                ],
                                            store,
                                            "fab"
                                          )
                                        }
                                        onDoubleClick={(e) =>
                                          list?.fab?.hasOwnProperty("event") &&
                                          list?.fab?.event !== undefined
                                            ? handleForm(
                                                e,
                                                null,
                                                list?.fab?.event,
                                                numList === 1
                                                  ? [...path, "list"]
                                                  : [
                                                      ...path,
                                                      "list",
                                                      index?.toString(),
                                                    ]
                                              )
                                            : null
                                        }
                                      >
                                        {list?.fab?.hasOwnProperty("event") &&
                                        list?.fab?.event !== undefined ? (
                                          <div
                                            onClick={(e) =>
                                              handleComponentProp(
                                                e,

                                                null,
                                                list?.fab?.event,
                                                numList === 1
                                                  ? [...path, "list", "fab"]
                                                  : [
                                                      ...path,
                                                      "list",
                                                      index?.toString(),
                                                      "fab",
                                                    ],
                                                store,
                                                "event"
                                              )
                                            }
                                            className={
                                              arraysAreEqual(
                                                numList === 1
                                                  ? [
                                                      ...path,
                                                      "list",
                                                      "fab",
                                                      "event",
                                                    ]
                                                  : [
                                                      ...path,
                                                      "list",
                                                      index?.toString(),
                                                      "fab",
                                                      "event",
                                                    ],
                                                edit.parentToEdit
                                              ) && !readOnly
                                                ? ` ${"selectedComponent"}`
                                                : ` ${"unSelectedComponent"}`
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              height: "100%",
                                              width: "100%",
                                            }}
                                          >
                                            <Icon
                                              icon={"Fab"}
                                              color={"#AAAAAA"}
                                            />
                                          </div>
                                        ) : (
                                          <DropTargetBody
                                            accept={["event"]}
                                            dragParent={parentToEdit}
                                            style={{
                                              width: 26,
                                              height: 26,
                                              fontSize: 6,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            {readOnly ? "No event" : "Drop event"}
                                          </DropTargetBody>
                                        )}
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {list?.hasOwnProperty("whereclause") &&
                                  list?.whereclause !== undefined ? (
                                    <div
                                      onClick={(e) =>
                                        handleComponentProp(
                                          e,

                                          null,
                                          list?.whereclause,
                                          numList === 1
                                            ? [...path, "list"]
                                            : [ ...path,"list",index?.toString(),],
                                          store,
                                          "whereclause"
                                        )
                                      }
                                      className={
                                        arraysAreEqual(
                                          numList === 1
                                            ? [...path, "list", "whereclause"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                                "whereclause",
                                              ],
                                          edit.parentToEdit
                                        ) && !readOnly
                                          ? ` ${"selectedComponent"}`
                                          : ` ${"unSelectedComponent"}`
                                      }
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: 2,
                                        minWidth: 40,
                                      }}
                                    >
                                      <button
                                        className="btn-horizontale-list-content"
                                        onClick={(e) =>
                                          handleComponentProp(
                                            e,

                                            null,
                                            list?.whereclause,
                                            numList === 1
                                              ? [...path, "list"]
                                              : [
                                                  ...path,
                                                  "list",
                                                  index?.toString(),
                                                ],
                                            store,
                                            "whereclause"
                                          )
                                        }
                                      >
                                        <Icon
                                          icon={"Whereclause"}
                                          color={"#AAAAAA"}
                                        />{" "}
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {list?.hasOwnProperty("toolbar") &&
                                  list?.toolbar !== undefined ? (
                                    <div
                                      onClick={(e) =>
                                        handleComponentProp(
                                          e,

                                          null,
                                          list?.toolbar,
                                          numList === 1
                                            ? [...path, "list"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                              ],
                                          store,
                                          "toolbar"
                                        )
                                      }
                                      className={
                                        arraysAreEqual(
                                          numList === 1
                                            ? [...path, "list", "toolbar"]
                                            : [
                                                ...path,
                                                "list",
                                                index?.toString(),
                                                "toolbar",
                                              ],
                                          edit.parentToEdit
                                        ) && !readOnly
                                          ? ` ${"selectedComponent"}`
                                          : ` ${"unSelectedComponent"}`
                                      }
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: 2,
                                        minWidth: 40,
                                      }}
                                    >
                                      <button
                                        className="btn-horizontale-list-content"
                                        onClick={(e) =>
                                          handleComponentProp(
                                            e,

                                            null,
                                            list?.toolbar,
                                            numList === 1
                                              ? [...path, "list"]
                                              : [
                                                  ...path,
                                                  "list",
                                                  index?.toString(),
                                                ],
                                            store,
                                            "toolbar"
                                          )
                                        }
                                      >
                                        {list?.toolbar?.hasOwnProperty(
                                          "event"
                                        ) &&
                                        list?.toolbar?.event !== undefined ? (
                                          <div
                                            onClick={(e) =>
                                              handleComponentProp(
                                                e,

                                                null,
                                                list?.toolbar?.event,
                                                numList === 1
                                                  ? [...path, "list", "toolbar"]
                                                  : [
                                                      ...path,
                                                      "list",
                                                      index?.toString(),
                                                      "toolbar",
                                                    ],
                                                store,
                                                "event"
                                              )
                                            }
                                            className={
                                              arraysAreEqual(
                                                numList === 1
                                                  ? [
                                                      ...path,
                                                      "list",
                                                      "toolbar",
                                                      "event",
                                                    ]
                                                  : [
                                                      ...path,
                                                      "list",
                                                      index?.toString(),
                                                      "toolbar",
                                                      "event",
                                                    ],
                                                edit.parentToEdit
                                              ) && !readOnly
                                                ? ` ${"selectedComponent"}`
                                                : ` ${"unSelectedComponent"}`
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              height: "100%",
                                              width: "100%",
                                            }}
                                          >
                                            <Icon
                                              icon={"Toolbar"}
                                              color={"#AAAAAA"}
                                            />
                                          </div>
                                        ) : (
                                          <DropTargetBody
                                            accept={["event"]}
                                            dragParent={parentToEdit}
                                            style={{
                                              width: 26,
                                              height: 26,
                                              fontSize: 6,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            drop event
                                          </DropTargetBody>
                                        )}
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          </div>
                        </>
                      )}
                    </>
                  );
                })
                .reverse()}
            </div>
          ) : btn?.name === "map-style" ? (
            <div className="btns-line-content-map-style">
              <img
                src={ImgStreet}
                alt="streetmap"
                onClick={() => {
                  handleMapTypeChange("terrain");
                }}
              />

              <img
                src={ImgHybride}
                alt="ImgHybride"
                onClick={() => {
                  handleMapTypeChange("street");
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <button
        className="btn-style"
        style={ btn?.icon === "layers" && btn?.isOpen ? { backgroundColor : "#6A549E" } : {}}
        onDoubleClick={() => handleToggleBtn(index, btn)}
      >
        <Icon icon={btn?.icon} color={ btn?.icon === "layers" && btn?.isOpen ? "#fff" : "#aaaa"} />{" "}
      </button>
    </div>
  );
}

export default LineButtonsGis;
