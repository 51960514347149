import React, { useState } from "react";
import "./index.scss";
import Icon from "../../../../../../../../../../components/CustomIcon/Icon";
import { useSelector } from "react-redux";
import Body from "../../../../Body";

function Profile() {
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="profile-container">
      {skeleton?.maxapps?.profile ? (
        <div className="profile-container">
          <div className="profile-header">
            {skeleton?.maxapps?.profile?.changepass && (
              <Icon icon="password" size={20} />
            )}
            <div onClick={() => setEditMode(true)}>
              <Icon icon="edit" size={20} />
            </div>
          </div>
          <div className="profile-body">
            <Body
              data={skeleton?.maxapps?.profile?.body}
              editMode={editMode}
              profile={true}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Profile;
