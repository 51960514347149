import React from "react";
import loader from "./../../../../../../../assets/maxapps-assets/loader.png";
import "./index.scss";
function ModalLoadingLogin({ setDisplaySplash }) {
  return (
    <div className="loading-login" onClick={() => setDisplaySplash(false)}>
      <div className="loading-login-content">
        <img src={loader} alt="loader" className="loadingLogo" />

        <div className="loader-container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
}

export default ModalLoadingLogin;
