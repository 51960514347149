export const Mechanical = {
  themeName: "Mechanical",
  isDark: false,
  CardViewBgColor: "#f1eef6bb",
  primary: "#1874A4",
  iconAppItem: "#1874A4",
  AppText: "#1874A4",
  loginFooter: "#1874A4",
  connectBtn: "#1874A4",
  label: "#1874A4",
  loader: "#1874A4",
  headerColor: "#1874A4",
  primaryDark: "#104D6D",
  offline: "#ec4545",
  offlineLabel: "#838383",
  primaryLight: "#1874A440",
  primaryColor: "#1874A4B2",
  welcomeBg: "#D4DFE5",
  secondary: "#EC9C4C",
  focusedBorderLoginInput: "#EC9C4C",
  focusedLabelLoginInput: "#EC9C4C",
  resetInputLogin: "#EC9C4C",

  languageIcon: "#EC9C4C",
  secondaryLight: "#EC9C4C80",
  textColor: "#000",
  labelColor: "#5590AF",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  settingCollapseColor: "#eee",
  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  fapMap: "#aaa",
  danger: "#EE6E6E",
  warning: "#e59d4b",
  success: "#3ecd7e",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#6F53A3",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#e5e2ec",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#1874A440",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D4DFE5",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D4DFE5",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMapBg: "#ffffff",
  fapMapActive: "#1874A4",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#EC9C4C",
  bnConnect: "#1874A4",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#1874A400",
  headerText: "#1874A4",
  headerDark: "#104D6D00",
  notificationUpColor: "#F6FBFE",
  notificationDownColor: "#E9F5FC",

  listItemNotificationUpColor: "#F2F9FD",
  listItemNotificationDownColor: "#E0F1FA",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F3F4",

  notFocusedSocialMedia: "#1874A4",
  focusedSocialMedia: "#1874A4cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#1874A4",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
