import DropTargetBody from "../DropTargetBody";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import imxtools from "imxtools";
import React, { useContext, useEffect, useState } from "react";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../services/AppContext";
const HomeSettingsInspection = ({
  setViewContent,
  listQuestions,
  setQuestionToDisplay,
  data,
  path,
}) => {
  const store = useStore();
  const [dataAttributes, setDataAttributes] = useState([]);
  let edit = useSelector((state) => state?.entities?.editor);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const { setIsDragingIn } = useContext(AppContext);
  useEffect(() => {
    setDataAttributes(
      imxtools?.toArray(data?.body?.attribute ? data?.body?.attribute : [])
    );
  }, [data?.body?.attribute]);

  const handleComponent = (e, data, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };
  return (
    <DropTargetBody
      accept={[
        "total",
        "Required",
        "optionalQuestions",
        "filters",
        "body",
        "attachment",
        "toolbar",
        "question",
      ]}
      dragParent={path}
      style={{
        width: "100%",
        overflowY: "auto",
        height: "100%",
        padding: "0 2px",
        background: "white",
      }}
    >
      <div
        className={`home-settings-high-container ${
          isSelectedComponent(readOnly, path, edit?.parentToEdit) &&
          `selectedComponent`
        } `}
      >
        <div
          className="home-settings-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {data?.hasOwnProperty("filters") && Boolean(data?.filters) ? (
            <>
              {data?.filters?.hidden === "true" ? (
                <></>
              ) : (
                <div
                  className="buttons-container"
                  onClick={(e) =>
                    handleComponentProp(
                      e,
                      null,
                      data?.filters,
                      [...path, "filters"],
                      store,
                      "filters"
                    )
                  }
                >
                  <div style={{ color: "#6A549E", fontWeight: 700 }}>
                    Display :
                  </div>
                  <button className="btn">All</button>
                  <button className="btn">Incompleted</button>
                  <button className="btn">Completed</button>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
          {data?.hasOwnProperty("optionalQuestions") &&
          Boolean(data?.optionalQuestions) ? (
            <>
              {data?.optionalQuestions?.hidden === "true" ? (
                <></>
              ) : (
                <div
                  className="opt-qte-container"
                  onClick={(e) =>
                    handleComponentProp(
                      e,
                      null,
                      data?.optionalQuestions,
                      [...path, "optionalQuestions"],
                      store,
                      "optionalQuestions"
                    )
                  }
                >
                  <div>Hide optional questions</div>
                  <input
                    type="checkbox"
                    style={{
                      borderRadius: 10,
                      borderColor: "#C8C8C8",
                      borderWidth: 2,
                      borderStyle: "solid",
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <></>
          )}
          {data?.hasOwnProperty("total") && Boolean(data?.total) ? (
            <>
              {data?.total?.hidden === "true" ? (
                <></>
              ) : (
                <div
                  className="total-container"
                  onClick={(e) =>
                    handleComponentProp(
                      e,
                      null,
                      data?.total,
                      [...path, "total"],
                      store,
                      "total"
                    )
                  }
                >
                  <div>Total</div>
                  <div>0/0</div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
          {data?.hasOwnProperty("Required") && Boolean(data?.Required) ? (
            <>
              {data?.Required?.hidden === "true" ? (
                <></>
              ) : (
                <div
                  className="requires-container"
                  onClick={(e) =>
                    handleComponentProp(
                      e,
                      null,
                      data?.Required,
                      [...path, "Required"],
                      store,
                      "Required"
                    )
                  }
                >
                  <div>Required</div>
                  <div>0/0</div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
          {data?.hasOwnProperty("body") ? (
            <DropTargetBody
              accept={["attribute"]}
              dragParent={[...path, "body"]}
              style={{
                width: "100%",
                overflowY: "auto",
                height: "auto",
                minHeight: 35,
                padding: 3,
              }}
            >
              <div
                className={`body-content  ${
                  isSelectedComponent(
                    readOnly,
                    [...path, "body"],
                    edit?.parentToEdit
                  )
                    ? `selectedComponent`
                    : `unSelectedComponent`
                } `}
                onClick={(e) =>
                  handleComponentProp(
                    e,
                    null,
                    data?.filters,
                    [...path, "body"],
                    store,
                    "body"
                  )
                }
              >
                {data?.body?.attribute &&
                  dataAttributes?.map((att, index) => {
                    const numAttributes = imxtools?.toArray(
                      data?.body?.attribute ? data?.body?.attribute : []
                    )?.length;
                    return (
                      <React.Fragment key={index}>
                        <DragItemAttribute
                          index={index}
                          component={"attribute"}
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            paddingLeft: 4,
                          }}
                          dragParentPath={[...path, "body"]}
                          dragData={att}
                          dataAttributes={dataAttributes}
                          setDataAttributes={setDataAttributes}
                          setIsDraging={setIsDragingIn}
                        >
                          <div
                            className={
                              att?.label === "name"
                                ? "name-container"
                                : "assets-container"
                            }
                            onClick={(e) =>
                              handleComponent(
                                e,
                                att,
                                numAttributes === 1
                                  ? [...path, "body", "attribute"]
                                  : [
                                      ...path,
                                      "body",
                                      "attribute",
                                      index?.toString(),
                                    ],
                                "attribute"
                              )
                            }
                          >
                            <div style={{ fontSize: 14 }}>{att?.label}:</div>
                            <div style={{ fontSize: 14 }}>MDC-001</div>
                          </div>
                        </DragItemAttribute>
                      </React.Fragment>
                    );
                  })}
              </div>
            </DropTargetBody>
          ) : (
            <></>
          )}
        </div>
        <div className="foot">
          <button
            className="btn-start"
            onClick={() => {
              setViewContent(1);
              setQuestionToDisplay(listQuestions[0]);
            }}
          >
            Start <i className="bx bx-chevron-right"></i>
          </button>
        </div>
      </div>
    </DropTargetBody>
  );
};
export default HomeSettingsInspection;
