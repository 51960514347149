import React, { useEffect, useState } from "react";
import "./index.scss";
import InstallationStepper from "../../components/stepper";
import InstallationComponent from "../components/InstallationComponent";
import Uninstallation from "../components/UninstallAndProceed";
import verifyingInstall from "../components/InstallationComponent/services/verifyingInstall";
import useToasts from "../../../../components/CustomToast/useToasts";

function MaximoInstall() {
  const { addToast } = useToasts();
  const [showLoader, setShowLoader] = useState(false);
  const [showUninstallation, setShowUninstallation] = useState("");
  const [showInstallation, setShowInstallation] = useState("");
  const [isVerifying, setIsVerifying] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [AllInstalled, setAllInstalled] = useState("");
  const [completedInsatllation, setCompletedInsatllation] = useState("");

  useEffect(() => {
    if (showUninstallation === false && showInstallation === true) {
      addToast("Uninstallation is complete", "success");
    }
  }, [showUninstallation, showInstallation]);

  async function verifying() {
    await verifyingInstall(
      setIsVerifying,
      setErrorMessage,
      setAllInstalled,
      setCompletedInsatllation
    );
  }
  const handleNextStep = () => {
    verifying();
  };

  const handleUninstallClick = () => {
    setShowUninstallation(true);
    setShowLoader(true);
  };
  const handleUninstallationComplete = () => {
    setShowUninstallation(false);
    setShowInstallation(true);
  };

  return (
    <div className="InstallationContainer">
      <div
        className={`Installation-step-card ${
          !showLoader ? "center-align" : ""
        }`}
      >
        <InstallationStepper
          handleClick={handleNextStep}
          installButtonClicked={!showLoader}
          onUninstallClick={handleUninstallClick}
          onUninstallationComplete={handleUninstallationComplete}
        />
      </div>

      {(showLoader || completedInsatllation === false) && (
        <div className="Installation-action-card">
          {showUninstallation && <Uninstallation />}
          {(completedInsatllation === false || showInstallation) && (
            <InstallationComponent />
          )}
        </div>
      )}
    </div>
  );
}

export default MaximoInstall;
