import React from "react";
import "./index.scss";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";

function Message({ data = {}, path = [], currentPath = [], readOnly = true }) {
  let store = useStore();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  return (
    <div
      className={`message-container ${
        isSelectedComponent(readOnly, [...path, "message"], currentPath) &&
        "selectedComponent"
      }`}
      onClick={(event) =>
        handleComponentProp(event, null, data, path, store, "message")
      }
    >
      {/* notification - message title */}
      {data?.title ? (
        <div
          className={`notification-title ${
            isSelectedComponent(
              readOnly,
              [...path, "message", "title"],
              currentPath
            ) && "selectedComponent"
          } `}
          style={{
            justifyContent:
              language.toLowerCase() === "ar" ? "flex-end" : "flex-start",
            textAlign: language.toLowerCase() === "ar" ? "right" : "left",
            color: !data?.title?.label ? "#aaaaaa" : "#000",
          }}
          onClick={(event) =>
            handleComponentProp(
              event,
              null,
              data?.title,
              [...path, "message"],
              store,
              "title"
            )
          }
        >
          {data?.title?.label
            ? getLabelByLanguage(language, data?.title)
            : "Add label"}
        </div>
      ) : (
        <div className="notification-title empty-message">Add title</div>
      )}
      {/* notification - message body */}
      {data?.body ? (
        <div
          className={`notification-body ${
            isSelectedComponent(
              readOnly,
              [...path, "message", "body"],
              currentPath
            ) && "selectedComponent"
          } `}
          style={{
            justifyContent:
              language.toLowerCase() === "ar" ? "flex-end" : "flex-start",
            textAlign: language.toLowerCase() === "ar" ? "right" : "left",
            color: !data?.body?.label ? "#aaaaaa" : "#000",
          }}
          onClick={(event) => {
            handleComponentProp(
              event,
              null,
              data?.body,
              [...path, "message"],
              store,
              "body"
            );
          }}
        >
          {data?.body?.label ? data?.body?.label : "Add label"}
        </div>
      ) : (
        <div className="notification-body empty-message">Add body</div>
      )}
    </div>
  );
}

export default Message;
