export const steps = [
  {
    label: "Installation of MAXapps",
    description: ` A MAXapps server is already installed on this Maximo
      instance. `,
    question: "Do you want to uninstall the current MAXapps configuration ?",
  },
  {
    label:
      "Object Structures, Maxmessages, and MAXapps Application Installation",
    description: "",
  },
  {
    label: "Post MAXapps Installation Verification ",
    description: ``,
  },
];
