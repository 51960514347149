import React from "react";
import "./index.scss";
import logoAbout from "../../../../../../../../../../assets/maxapps-assets/aboutLogo.png";

function About() {
  const handleButtonClick = () => {
    window.open("https://maxapps-maximo.com", "_blank");
  };
  return (
    <div className="about-container">
      <div className="about-logo">
        <img src={logoAbout} alt="imageAbout" style={{ width: "80%" }} />
      </div>
      <div className="about-platform">Os platform</div>
      <div className="about-version">maxapps version</div>
      <div className="about-moreInfo">
        <div className="moreInfo-text">
          For more informations, please visit our website
        </div>
        <div className="moreInfo-link" onClick={handleButtonClick}>
          maxapps-maximo.com
        </div>
      </div>
    </div>
  );
}

export default About;
