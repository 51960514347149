import { InputLabels } from "../../data"
import FilterInput from "../filterInput/index"
import React from "react"
import AdvancedFilterDataCollection from "../../services/filterDataCollection"
import "./index.scss"

const AdvancedSearch = ({
  data = [],
  toggleSearch = 0,
  setFilteredData = () => {},
  advancedSearchValue = "",
  setAdvancedSearchValue,
  advancedInputVisibility,
  setAdvancedInputVisibility,
  setActiveRow,
}) => {
  const handleButtonClick = (label) => {
    setAdvancedInputVisibility((prevVisibility) => ({
      ...prevVisibility,
      [label]: !prevVisibility[label],
    }))
  }

  const advancedSearch = (searchValue, criteria, filterNow = false) => {
    setAdvancedSearchValue((prev) => ({
      ...prev,
      [toggleSearch]: {
        ...prev[toggleSearch],
        [criteria]: searchValue,
      },
    }))

    if (filterNow) {
      const newFilteredData = AdvancedFilterDataCollection(data, {
        ...advancedSearchValue[toggleSearch],
        [criteria]: searchValue,
      })
      setFilteredData(newFilteredData)
      setActiveRow(null)
    }
  }

  const handleSubmit = () => {
    if (data && advancedSearchValue && advancedSearchValue[toggleSearch]) {
      const advancedFilter = AdvancedFilterDataCollection(
        data,
        advancedSearchValue[toggleSearch]
      )
      setFilteredData(advancedFilter)
      setActiveRow(null)
    }
  }

  const handleReset = () => {
    setAdvancedSearchValue((prev) => ({
      ...prev,
      [toggleSearch]: {},
    }))
    setFilteredData(data)
    setActiveRow(null)
  }

  return (
    <div className='advanced-search-monitor'>
      <div className='advanced-search-container'>
        <p className='advanced-search-title'>Advanced Search:</p>
        <div className='advanced-search-button-container'>
          {InputLabels.map((label, index) => (
            <button
              key={index}
              className={`advanced-search-button ${
                !advancedInputVisibility[label] && "inactive"
              }`}
              onClick={() => handleButtonClick(label)}
            >
              {label}
            </button>
          ))}
        </div>

        <div className='advanced-search-options'>
          {InputLabels.map(
            (label) =>
              advancedInputVisibility[label] && (
                <FilterInput
                  key={label}
                  label={label}
                  advancedSearch={advancedSearch}
                  value={advancedSearchValue[toggleSearch]?.[label] ?? ""}
                />
              )
          )}
        </div>
      </div>
      <div className='buttons-search-container'>
        <button className='reset-search-button' onClick={handleReset}>
          Clear
        </button>
        <button className='submit-search-button' onClick={handleSubmit}>
          Advanced Search
        </button>
      </div>
    </div>
  )
}

export default AdvancedSearch
