import React from "react";
import "./index.scss";
import languageBg from "./../../../../../../../assets/maxapps-assets/languageBg.jpg";
import logoLanguage from "./../../../../../../../assets/maxapps-assets/logoLanguage.png";

function LanguageSetting({ closeModalTwoLang }) {
  return (
    <div
      className="splash-screen-container"
      style={{ background: `url(${languageBg}) center/cover no-repeat` }}
    >
      <div className="splash-content">
        <div className="logo">
          <img src={logoLanguage} width="100%" height="100%" />
        </div>
        <div className="btns-splash-content">
          <button className="btn-En" onClick={() => closeModalTwoLang()}>
            EN
          </button>
          <button className="btn-Ar" onClick={() => closeModalTwoLang()}>
            عربي
          </button>
        </div>
      </div>
    </div>
  );
}

export default LanguageSetting;
