export const recursiveFn = (item, searchValue) => {
  if (item === null || item === undefined) return false

  if (typeof item === "string" || typeof item === "number") {
    return item.toString().toLowerCase().includes(searchValue.toLowerCase())
  }

  if (Array.isArray(item)) {
    return item.some((subItem) => recursiveFn(subItem, searchValue))
  }

  if (typeof item === "object") {
    return Object.values(item).some((value) => recursiveFn(value, searchValue))
  }

  return false
}
