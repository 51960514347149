import React, { useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Icon,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import "./index.scss";

import DynamicModal from "../../../../components/CustomModal";
import { steps } from "./data";
import uninstallAll from "../../steps/components/InstallationComponent/services/uninsatllAll";
import verifyingInstall from "../../steps/components/InstallationComponent/services/verifyingInstall";
import styled from "@emotion/styled";
import { HandleStepChange } from "../../services/stepChange/stepChange";
import { useStore } from "react-redux";

function InstallerStepper({
  activeStep = 0,
  handleClick = () => {},
  installButtonClicked,
  onUninstallClick,
  onUninstallationComplete,
}) {
  const store = useStore();
  const [toClear, setToClear] = useState(false);
  const [finish, setFinish] = useState(false);
  const [failedToClear, setFailedToClear] = useState(false);
  const [isVerifying, setIsVerifying] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [allInstalled, setAllInstalled] = useState("");
  const [checkVersions, setcheckVersions] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showPopUpOptions, setShowPopUpOptions] = useState(false);
  const [completedInsatllation, setCompletedInsatllation] = useState("");

  useEffect(() => {
    if (finish) {
      onUninstallationComplete();
    }
  }, [finish, onUninstallationComplete]);
  useEffect(() => {
    if (failedToClear) {
      openDialog();
    }
  }, [failedToClear]);

  const CustomStepConnector = styled(StepConnector)(
    ({ connectorIsActive }) => ({
      [`& .${stepConnectorClasses.line}`]: {
        minHeight: "70px",
        borderLeft: `4px solid ${
          connectorIsActive ? "var(--SupportButtonBg)" : "var(--StepperBar)"
        }`,
        marginLeft: "20px",
      },
      [`&.${stepConnectorClasses.active}`]: {
        "& .MuiStepConnector-line": {
          borderLeftColor: "var(--SupportButtonBg)",
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        "& .MuiStepConnector-line": {
          borderLeftColor: "var(--SupportButtonBg)",
        },
      },
    })
  );
  const closeDialog = () => setShowDialog(false);
  const openDialog = () => setShowDialog(true);

  const handleUninstallAndProceed = () => {
    uninstallAll(
      checkVersions,
      setShowPopUpOptions,
      setFailedToClear,
      setToClear,
      setFinish,
      setErrorMessage
    );
    onUninstallClick();
  };

  return (
    <div className="StepperContainer">
      <Box sx={{ maxWidth: 400 }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<CustomStepConnector />}
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                icon={
                  <div
                    className={`test ${index > activeStep ? "inactive" : ""}`}
                  >
                    <Icon className="MuiStepIcon-root">
                      <span className="number">{index + 1}</span>
                    </Icon>
                  </div>
                }
              >
                <div
                  className={`StepLabel ${index < activeStep ? "active" : ""}`}
                  style={{ color: index === activeStep ? "var(--Globe)" : "" }}
                >
                  {step?.label}
                </div>
              </StepLabel>

              <StepContent
                sx={{
                  borderLeft: " 4px solid  var(--StepperBar)",
                  marginLeft: "32px",
                }}
              >
                <div className="ButtonSection">
                  {index === 0 &&
                    installButtonClicked &&
                    (!allInstalled && completedInsatllation === "" ? (
                      <Button
                        className="ButtonStepper"
                        sx={{
                          borderRadius: 0,
                          backgroundColor: "var(--SupportButtonBg)",
                          fontFamily: "Hanken Grotesk",
                          textTransform: "none",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                        variant="contained"
                        onClick={() => {
                          verifyingInstall(
                            setIsVerifying,
                            setErrorMessage,
                            setAllInstalled,
                            setCompletedInsatllation
                          );
                          handleClick();
                        }}
                      >
                        Install
                      </Button>
                    ) : completedInsatllation === true ? (
                      <div className="stepDescription">
                        <div className="textDecriptions">
                          {step?.description}
                        </div>
                        <div className="questionDecriptions">
                          {step?.question}
                          <div className="dialog-option">
                            <input
                              className="dialog-input"
                              type="checkbox"
                              id="checkVersions"
                              name="checkVersions"
                              value={checkVersions}
                              checked={checkVersions}
                              onChange={() => {
                                setcheckVersions(!checkVersions);
                              }}
                            />
                            check files versions
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: 0,
                            backgroundColor: "transparent",
                            border: "1px solid var(--LoginButton)",
                            fontFamily: "Hanken Grotesk",
                            textTransform: "none",
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "var(--LoginButton)",
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            setCompletedInsatllation("");
                            setAllInstalled(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleUninstallAndProceed}
                          sx={{
                            borderRadius: 0,
                            backgroundColor: "var(--LoginButton)",
                            fontFamily: "Hanken Grotesk",
                            textTransform: "none",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Uninstall and Proceed
                        </Button>
                      </div>
                    ) : null)}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <DynamicModal
        open={showDialog}
        onClose={closeDialog}
        secondaryAction={true}
        modalTitle="Uninstallation Failed"
        primaryLabel="Retry"
        secondaryLabe="Skip"
        onClickSecondaryAction={() => {
          closeDialog();
          onUninstallationComplete();
        }}
        onClickPrimaryAction={() => {
          uninstallAll(
            checkVersions,
            setShowPopUpOptions,
            setFailedToClear,
            setToClear,
            setFinish,
            setErrorMessage
          );
          closeDialog();
        }}
      >
        <div className="popupContainer">
          <React.Fragment>
            <div className="retry-uninstall">
              <div>
                <p className="retry-message">{errorMessage}</p>
              </div>
            </div>
          </React.Fragment>
        </div>
      </DynamicModal>
    </div>
  );
}
export default InstallerStepper;
