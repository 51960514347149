const calculateScriptCounts = (scripts = []) => {
  try {
    const warningScriptCount = scripts.filter(
      (script) => script.status === true && script.loggerStatus === false
    ).length;
    const errorScriptCount = scripts.filter(
      (script) => script.status === false
    ).length;
    return { warningScriptCount, errorScriptCount };
  } catch (error) {
    console.error("Error in calculateScriptCounts:", error);
  }
};
export default calculateScriptCounts;
