export const Jouf = {
  themeName: "Jouf",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#55565A80",
  primary: "#7B7202",
  iconAppItem: "#7B7202",
  AppText: "#7B7202",
  connectBtn: "#7B7202",
  loader: "#7B7202",
  headerColor: "#7B7202",
  primaryLight: "#7B720250",
  primaryColor: "#7B7202B2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#55565A",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#8D7C49",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#7B7202",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#7B7202",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#D1C8AB",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#7B7202",
  groupIcon: "#ffffff",
  borderRecord: "#7B720250",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D1C8AB",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D1C8AB",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#7B7202",
  listBgCard: "#eee",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#55565A",
  bnConnect: "#7B7202",
  loginBtnLabel: "#FFF",
  focusedBorderLoginInput: "#7B7202",
  focusedLabelLoginInput: "#7B7202",
  resetInputLogin: "#7B7202",
  languageIcon: "#7B7202",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#ffffffb3",
  loginFooter: "#000",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#7B720200",
  headerDark: "#8D7C4900",
  headerText: "#7B7202",
  notificationUpColor: "#FFFEF5",
  notificationDownColor: "#FFFDE6",

  listItemNotificationUpColor: "#FFFEF0",
  listItemNotificationDownColor: "#FEFCDC",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F3F1",

  notFocusedSocialMedia: "#7B7202",
  focusedSocialMedia: "#7B7202cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#7B7202",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
