import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import Icon from "../../../../../../../components/CustomIcon/Icon";
function PhoneFrame(props) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { versionsList, draftSkeleton, skeleton } = useSelector((state) => ({
    versionsList: state?.entities?.revision?.list || [],
    draftSkeleton: state?.entities?.explorer?.draftSkeleton || {},
    skeleton: state?.entities?.explorer?.skeleton || {},
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  return (
    <div className="phone-container">
      <div className="phone-frame">
        <div className="device-content">
          <div className="frame-header">
            <div className="frame-time">{formatTime(currentTime)}</div>
            <div className="header-splite">
              <div className="frame-camera"></div>
            </div>
            <div className="frame-network">
              <Icon icon="mobile-signal" size={15} />
              <Icon icon="wifi" size={15} />
              <Icon icon="battery" size={20} />
            </div>
          </div>
          {versionsList?.length === 0 &&
          !draftSkeleton?.maxapps &&
          !skeleton?.maxapps ? (
            <div className="phone-frame-body empty-device">No XML found</div>
          ) : (
            <div className="phone-frame-body">{props.children}</div>
          )}
          <div className="home-button"></div>
        </div>
      </div>
    </div>
  );
}

export default PhoneFrame;
