import React, { useState } from "react";
import { useSelector, useStore } from "react-redux";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { getLabelByLanguage } from "../../../../../../../utils/getLabelByLanguage";

function Whereclause({
  data = {},
  path = [],
  index = "",
  handleClick = () => {},
  readOnly = true,
  currentPath = [],
}) {
  const [openWhereclause, setOpenWhereclause] = useState(false);
  let store = useStore();

  const handleOpenModule = (event) => {
    handleClick(index, "module", data, event);
    setOpenWhereclause(!openWhereclause);
  };

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const handleComp = (e, data, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };

  return (
    <div
      className={`whereclause-content ${
        isSelectedComponent(readOnly, path, currentPath) && "selectedComponent"
      }`}
      onClick={(e) => handleComp(e, data, path, "whereclause")}
    >
      <div
        className="whereclause-header"
        style={{
          flexDirection:
            language?.toLowerCase() === "ar" ? "row-reverse" : "row",
        }}
        onClick={(event) => handleOpenModule(event)}
      >
        <div className="whereclause-label">
          {data?.label ? getLabelByLanguage(language, data) : "Add Label"}
        </div>
        <div className="whereclause-icon">
          <Icon
            icon={openWhereclause ? "chevron-up" : "chevron-down"}
            size={15}
          />
        </div>
      </div>
      {openWhereclause ? (
        <div className="whereclause-desc">
          {data?.whereclause ? data?.whereclause : "missing desc"}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Whereclause;
