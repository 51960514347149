import { axiosProviderNode } from "../../../services/http/httpService";
import { services } from "./configVariables";

async function renameIcons(renameRequests = [], addToast) {
  try {
    const renameService = services.NODE_ENDPOINT_RENAME_ICON;

    const body = { renameRequests };
    const res = await axiosProviderNode.post(renameService, body);

    if (res.data.success) {
      addToast(res.data.message, "success");
    } else {
      addToast(res.data.message.toString(), "error");
    }

    return res.data.success;
  } catch (error) {
    addToast(error?.message?.toString(), "error");
    return false;
  }
}

export default renameIcons;
