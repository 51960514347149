import React from "react";
import "./index.scss";
import Lottie from "react-lottie";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";
import { useLanguageDevice } from "../../services/DeviceTranslate/LanguageContext";
import { Checkbox, FormControlLabel } from "@mui/material";
import horizontalScroll from "../../../../../../Assets/lottie-files/horizontalScroll.json";
function ScrollGuideModel({ closeModal, handleChange }) {
  const { theme, themes } = useDeviceTheme();
  const { translate } = useLanguageDevice();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: horizontalScroll,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="ScrollGuide-modal-container">
      <div className="ScrollGuide-modal-header">
        <div className="headerIcon">
          <Lottie options={defaultOptions} height={44} width={44} />{" "}
        </div>
      </div>
      <div className="ScrollGuide-modal-body">
        <div className="text-ScrollGuide-wrapper">
          <span className="text-ScrollGuide-style">
            Use the <span className="TextBold">mouse wheel</span> to scroll both{" "}
            <span className="TextBold">vertically</span> and{" "}
            <span className="TextBold">horizontally.</span>
          </span>
        </div>
      </div>
      <div className="horizontal-line" />
      <div className="ScrollGuide-modal-footer">
        <div className="checkBox-container">
          <input
            type="checkbox"
            className="checkBoxInput"
            onChange={handleChange}
          />
          <label className="checkBoxLabel"> Don't show again</label>
        </div>
        <button
          className="btn-confirm-ScrollGuide"
          onClick={() => closeModal()}
        >
          Got it!
        </button>
      </div>
    </div>
  );
}

export default ScrollGuideModel;
