export const Kori = {
  themeName: "Kori",
  isDark: false,
  CardViewBgColor: "#243D8B10",
  secondaryLight: "#179BD780",
  primary: "#243D8B",
  iconAppItem: "#243D8B",
  AppText: "#243D8B",
  loginFooter: "#000",
  connectBtn: "#243D8B",
  loader: "#243D8B",
  headerColor: "#243D8B",
  primaryLight: "#243D8B50",
  primaryColor: "#243D8BB2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#179BD7",
  focusedBorderLoginInput: "#179BD7",
  focusedLabelLoginInput: "#179BD7",
  resetInputLogin: "#179BD7",

  languageIcon: "#243D8B",

  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#0A1A4B",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#179BD7",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#243D8B",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#243D8B",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#243D8B50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#243D8B",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFFFFF2A",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#fff",
  shadowBtnConnect: "#179BD7",
  bnConnect: "#243D8B",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#243D8B00",
  headerDark: "#0A1A4B00",
  headerText: "#243D8B",
  notificationUpColor: "#F7F8FD",
  notificationDownColor: "#EBEEFA",

  listItemNotificationUpColor: "#F3F5FC",
  listItemNotificationDownColor: "#E3E8F8",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F2F4",

  notFocusedSocialMedia: "#243D8B",
  focusedSocialMedia: "#243D8Bcc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#243D8B",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
