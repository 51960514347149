import React, { useState } from "react"
import CustomButton from "../../../../../../../components/CustomButton"
import CustomModal from "../../../../../../../components/CustomModal"
import { useStore } from "react-redux"

function DeleteItems({
  readOnly = true,
  selectedCards = [],
  component = "",
  setSelectedCards,
  filterData = [],
  path = [],
}) {
  const [openModal, setOpenModal] = useState(false)
  let store = useStore()

  function deleteMultiItems() {
    try {
      setSelectedCards([])
      store.dispatch({
        type: "explorer/deleteItems",
        payload: {
          path: path,
          newData: filterData,
        },
      })
    } catch (error) {
      console.error("error-", error)
    }
  }

  return (
    <React.Fragment>
      <CustomButton
        iconName='del'
        iconColor={
          !readOnly && selectedCards?.length !== 0 ? "black" : "#B9B8BD"
        }
        color={!readOnly && selectedCards?.length !== 0 ? "black" : "#B9B8BD"}
        iconSize={20}
        label='Delete'
        backgroundColor='transparent'
        gap={0.3}
        fontSize={14}
        onClick={() => setOpenModal(true)}
      />
      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        onClickPrimaryAction={() => {
          setOpenModal(false)
          deleteMultiItems()
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setOpenModal(false)
        }}
        primaryLabel='Confirm'
        secondaryLabe='Cancel'
        modalTitle='Discard'
      >
        <div className='ModalContent'>
          <div className='message'>
            {`Are you sure to delete ${
              selectedCards?.length > 1 && selectedCards?.length
            } ${component} ?`}
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  )
}

export default DeleteItems
