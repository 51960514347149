export const King = {
  themeName: "King",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#5A5B5D80",
  primary: "#D5C495",
  iconAppItem: "#D5C495",
  AppText: "#414141",
  loginFooter: "#D5C495",
  connectBtn: "#DED0AB",
  loader: "#D5C495",
  headerColor: "#D5C495",
  primaryLight: "#D5C49550",
  primaryColor: "#D5C495B2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#FEC673",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#534F46",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#D5C495",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#D5C495",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#B8E1C6",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#D5C495",
  groupIcon: "#ffffff",
  borderRecord: "#FEC67350",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#F3EEE0",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#B8E1C6",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#D5C495",
  listBgCard: "#eee",
  colorTextInput: "#565757",
  LogincolorTextInput: "#565757",
  LogincolorLabelInput: "#B3B3B3",
  loginOptions: "#414141",
  shadowBtnConnect: "#FEC673",
  bnConnect: "#FEC673",
  loginBtnLabel: "#000",
  focusedBorderLoginInput: "#D5C495",
  focusedLabelLoginInput: "#D5C495",
  resetInputLogin: "#D5C495",
  languageIcon: "#414141",
  containerLanguageIcon: "#fff",
  bgLoginInput: "#FFF",
  loginFooter: "#77777A",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#AF975100",
  headerDark: "#8D7C4900",
  headerText: "#414141",
  notificationUpColor: "#FCFBF8",
  notificationDownColor: "#F8F5ED",

  listItemNotificationUpColor: "#FBF9F4",
  listItemNotificationDownColor: "#F5F1E5",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F8F5ED",

  notFocusedSocialMedia: "#D5C495",
  focusedSocialMedia: "#D5C495cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#D5C495",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
