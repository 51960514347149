export const Sydec = {
  themeName: "Sydec",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#55565A80",
  primary: "#664039",
  iconAppItem: "#664039",
  AppText: "#664039",
  loginFooter: "#664039",
  connectBtn: "#664039",
  loader: "#664039",
  headerColor: "#664039",
  primaryLight: "#66403950",
  primaryColor: "#664039B2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#F69E00",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#8D7C49",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#664039",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#664039",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#D1C8AB",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#664039",
  groupIcon: "#ffffff",
  borderRecord: "#66403950",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D1C8AB",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D1C8AB",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#664039",
  listBgCard: "#eee",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#55565A",
  bnConnect: "#664039",
  loginBtnLabel: "#FFF",
  focusedBorderLoginInput: "#664039",
  focusedLabelLoginInput: "#664039",
  resetInputLogin: "#664039",
  languageIcon: "#664039",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#FFF",
  loginFooter: "#000",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#66403900",
  headerText: "#664039",
  headerDark: "#8D7C4900",
  notificationUpColor: "#FBF9F8",
  notificationDownColor: "#F6F0EF",

  listItemNotificationUpColor: "#FAF6F5",
  listItemNotificationDownColor: "#F6E7E5",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F1F1",

  notFocusedSocialMedia: "#664039",
  focusedSocialMedia: "#664039cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#664039",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
