import "./index.scss"
import extractNumber from "../../../../../../utility/stringToNumber"

function PerformanceBar({ performance = "", unit = "", color = "" }) {
  const maxRam = extractNumber(unit)
  return (
    <div className='performanceBar-container'>
      <div
        className='performanceBar-fill'
        style={{
          width:
            unit && unit !== "/N/A GB"
              ? `${(performance / maxRam) * 100}%`
              : `${performance}%`,
          backgroundColor: color,
        }}
      ></div>
    </div>
  )
}

export default PerformanceBar
