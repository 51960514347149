import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import SplitPane from "react-split-pane";
import AppBar from "./components/AppBar";
import ComponentsLayers from "./components/ComponentsLayers";
import ComponentsLibrary from "./components/ComponentsLibrary";
import XmlSide from "./components/XmlSide";
import Properties from "./components/Properties";
import useToasts from "../../components/CustomToast/useToasts";
import fetchListVersion from "./services/revision";
import { ModalProvider } from "./services/ModalContext";
import { CopyPasteProvider } from "./services/CopyPasteContext";
import { transformXml } from "./services/transformXml";
import CompareFiles from "./components/CompareFiles";
import { useTheme } from "../../theme/themeContext";
import RevisionsList from "./components/RevisionsList";
import LayoutsButton from "./components/LayoutsButton";
import "./index.scss";
import xml2js from "minexml2js";
import { DeviceThemeProvider } from "../../DeviceTheme/DeviceThemeContext";
import { LanguageProviderDevice } from "./components/ComponentsLayers/Preview/services/DeviceTranslate/LanguageContext";
import { SavePropsProvider } from "./services/savePropsContext";
import Default from "./components/ComponentsLibrary/Assets/Default.svg";
import CustomCursorDiv from "../../components/CustomCursor";
import transformForStore from "./services/helpers/transformForStore";
import transformForXml from "./services/helpers/transformForXml";
import handleUpdate from "./components/AppBar/services/handleSaveDraft";

const Designer = () => {
  const [xmlValue, setXmlValue] = useState("");
  const [floatingBtnStatus, setFloatingBtnStatus] = useState(false);
  const [revision, setRevision] = useState(false);
  const [compareXML, setCompareXML] = useState(false);
  const [showDraftsList, setShowDraftList] = useState("");
  const [isTransformed, setIsTransformed] = useState(false);
  const [saveComplete, setSaveComplete] = useState(false);
  const [enableBackground, setEnableBackground] = useState(false);
  const [initialXmlValue, setInitialXmlValue] = useState("");
  const { themeMode, themeColors } = useTheme();
  const [Bordered, setBorder] = useState(false);


  let store = useStore();
  const { addToast } = useToasts();

  const {
    versionsList,
    skeletonState,
    selected,
    revisionSelected,
    layout,
    draftDisplay,
    XMLChanged,
  } = useSelector((state) => ({
    versionsList: state?.entities?.revision?.list || [],
    skeletonState: state?.entities?.explorer?.skeleton || {},
    selected: state?.entities?.revision?.itemSelected || {},
    revisionSelected: state?.entities?.revision?.revisionSelected || {},
    layout: state?.entities?.layouts || {},
    draftDisplay: state?.entities?.explorer?.draftDisplay,
    XMLChanged: state?.entities?.editor?.XMLChanged,
  }));

  const [handleEditorClick, setHandleEditorClick] = useState(false);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  const existSelectedVersion = useMemo(() => {
    return store?.getState()?.entities?.revision?.itemSelected ?? {};
  }, [store]);
  // New state to track save completion
  const [changed, setChanged] = useState(false);
  const { componentsLibrary, properties, codeEditor } = layout;

  const draftVersionsList = versionsList?.filter(
    (version) => version?.typeFile === "draft"
  );

  const publishedVersionsList = versionsList?.filter(
    (version) => version?.typeFile != "draft"
  );

  const { dataVersions = [] } = showDraftsList
    ? fetchListVersion(draftVersionsList)
    : fetchListVersion(publishedVersionsList);

  const lastVersion = publishedVersionsList?.[0];

  useEffect(() => {
    if (readOnly) {
      if (componentsLibrary) {
        store.dispatch({
          type: "layouts/compLibLayout",
        });
      }
    } else {
      if (!componentsLibrary) {
        store.dispatch({
          type: "layouts/compLibLayout",
        });
      }
      if (!properties) {
        store.dispatch({
          type: "layouts/propLayout",
        });
      }
      if (!codeEditor) {
        store.dispatch({
          type: "layouts/codeLayout",
        });
      }
    }
  }, [readOnly]);

  useEffect(() => {
    const xmlValue = transformXml(skeletonState, addToast);
    setXmlValue(xmlValue);
    if (!initialXmlValue) {
      setInitialXmlValue(xmlValue);
    }
  }, [skeletonState]);
  useEffect(() => {
    setInitialXmlValue(xmlValue);
    store.dispatch({
      type: "editor/updateXMLState",
      payload: { XMLChanged: false },
    });
  }, [revisionSelected]);
  const compareXmlValues = async (newXmlValue) => {
    if (saveComplete) {
      setInitialXmlValue(newXmlValue);
    }
    if (
      newXmlValue !== initialXmlValue &&
      !handleEditorClick &&
      !readOnly &&
      !saveComplete
    ) {
      const searchRegExp = new RegExp("&", "g");
      const xmlData = newXmlValue.replace(searchRegExp, "&amp;");
      const { result } = await new xml2js.Parser({
        explicitArray: false,
        emptyTag: { $: { id: "mockId" } },
      }).parseStringPromise(xmlData);
      const skeleton = await transformForStore(result);
      store.dispatch({
        type: "explorer/updateData",
        payload: {
          skeleton: { ...skeleton },
          readOnly: false,
        },
      });

      store.dispatch({
        type: "editor/updateData",
        payload: {
          data: { ...skeleton },
        },
      });
      handleUpdate(
        revisionSelected?.filename,
        transformForXml(skeleton),
        store
      );
      store.dispatch({
        type: "editor/updateXMLState",
        payload: { XMLChanged: false },
      });
    }
    if (newXmlValue !== initialXmlValue && handleEditorClick && !readOnly) {
      store.dispatch({
        type: "editor/updateXMLState",
        payload: { XMLChanged: true },
      });
    }
    setSaveComplete(false);
  };

  const onChange = (newXmlValue) => {
    setXmlValue(newXmlValue);
    compareXmlValues(newXmlValue);
  };


  // async function fetchFiles() {
  //   await getAllFiles(store, addToast);
  // }

  // useEffect(() => {
  //   fetchFiles();
  // }, []);

  useEffect(() => {
    if (!lastVersion) {
      // store.dispatch({
      //   type: "reset",
      // });
    }
  }, [lastVersion]);

  useEffect(() => {
    const filename =
      lastVersion?.typeFile === "Published" ? lastVersion?.filename : null;
    if (
      filename &&
      !draftDisplay &&
      !Object.keys(existSelectedVersion)?.length
    ) {
      // store.dispatch({
      //   type: "revision/setSelectedItem",
      //   payload: { obj: { [filename]: true } },
      // });
      // store.dispatch({
      //   type: "revision/setSelectedRevision",
      //   payload: {
      //     data: lastVersion,
      //   },
      // });
    }
  }, [draftDisplay, existSelectedVersion]);

  const handleTransform = () => {
    setIsTransformed((prevState) => !prevState);
  };


  return (
    <React.Fragment>
      <CustomCursorDiv svgCursor={Default}>
        <div className="container">
          <SavePropsProvider>
            <AppBar
              revisionSelected={revisionSelected}
              setCompareXML={setCompareXML}
              compareXML={compareXML}
              lastVersion={lastVersion}
              XMLChanged={XMLChanged}
              xmlValue={xmlValue}
              revision={revision}
              setRevision={setRevision}
              handleTransform={handleTransform}
              setIsTransformed={setIsTransformed}
              enableBackground={enableBackground}
              setEnableBackground={setEnableBackground}
              setSaveComplete={setSaveComplete}
              setHandleEditorClick={setHandleEditorClick}
              handleEditorClick={handleEditorClick}
              setChanged={setChanged}
              setBorder={setBorder}
            />

            <CopyPasteProvider>
              <ModalProvider>
                <div
                  className={`mainContainer ${
                    isTransformed ? "transformed" : ""
                  }`}
                >
                  {compareXML ? (
                    <CompareFiles
                      xmlValue={xmlValue}
                      onChange={onChange}
                      revision={isTransformed}
                    />
                  ) : (
                    <LanguageProviderDevice>
                      <DeviceThemeProvider>
                        {componentsLibrary && !readOnly && (
                          <ComponentsLibrary />
                        )}
                        <SplitPane
                          split="vertical"
                          defaultSize={"100%"}
                          pane1Style={{
                            borderLeft: `0.15rem solid ${themeColors[themeMode].SpliteBorder}`,
                            borderRight:
                              !codeEditor &&
                              !properties &&
                              `1.5px solid ${themeColors[themeMode].SpliteBorder}`,
                            minWidth:
                              !properties && !codeEditor
                                ? componentsLibrary
                                  ? revision
                                    ? "calc(100vw - (20rem + 300px))"
                                    : "calc(100vw - 300px)"
                                  : revision
                                  ? "calc(100vw - 20rem)"
                                  : "100vw"
                                : "calc((100vh - 10.5rem) * 0.5)",
                            maxWidth:
                              !properties && !codeEditor && !componentsLibrary
                                ? revision
                                  ? "calc(100vw - 20rem)"
                                  : "100vw"
                                : !properties && codeEditor
                                ? componentsLibrary
                                  ? revision
                                    ? "calc(45vw - 20rem)"
                                    : "45vw"
                                  : "40vw"
                                : properties && !codeEditor
                                ? componentsLibrary
                                  ? revision
                                    ? "calc(50vw - 20rem)"
                                    : "55vw"
                                  : "50vw"
                                : componentsLibrary
                                ? revision
                                  ? "calc(60vw - (20rem + 300px))"
                                  : "calc(45vw - 300px)"
                                : revision
                                ? "calc(33.3vw - 20rem)"
                                : "33.3vw",
                          }}
                          pane2Style={{
                            borderRight: `1px solid ${themeColors[themeMode].SpliteBorder}`,
                            borderLeft: `1px solid ${themeColors[themeMode].SpliteBorder}`,
                            minWidth:
                              (!properties && codeEditor) ||
                              (properties && !codeEditor)
                                ? componentsLibrary
                                  ? "calc(35vw - 300px)"
                                  : "35vw"
                                : componentsLibrary
                                ? "calc(40vw - 300px)"
                                : "40vw",
                            maxWidth: "calc(100vw - ((100vh - 10.5rem) * 0.5))",
                          }}
                        >
                          <ComponentsLayers
                            properties={properties}
                            codeEditor={codeEditor}
                          />
                          {properties && codeEditor ? (
                            <SplitPane
                              split="vertical"
                              defaultSize={"50%"}
                              pane1Style={{
                                // borderLeft: `1px solid ${themeColors[themeMode].SpliteBorder}`,
                                // borderRight: `1px solid red`,

                                minWidth: "25rem",
                                maxWidth: revision
                                  ? "calc(40vw - 15rem)"
                                  : "calc(40vw - 10rem)",
                              }}
                              pane2Style={{
                                minWidth: "10rem",
                                borderLeft: `1px solid ${themeColors[themeMode].SpliteBorder}`,
                              }}
                            >
                              <Properties
                                XMLChanged={XMLChanged}
                                xmlValue={xmlValue}
                              />
                              <XmlSide
                                lastVersion={lastVersion}
                                versionsList={versionsList}
                                xmlValue={xmlValue}
                                onChange={onChange}
                                setHandleEditorClick={setHandleEditorClick}
                                XMLChanged={changed}
                              />
                            </SplitPane>
                          ) : !codeEditor && properties ? (
                            <Properties
                              XMLChanged={XMLChanged}
                              xmlValue={xmlValue}
                            />
                          ) : codeEditor && !properties ? (
                            <XmlSide
                              lastVersion={lastVersion}
                              versionsList={versionsList}
                              xmlValue={xmlValue}
                              onChange={onChange}
                              setHandleEditorClick={setHandleEditorClick}
                              XMLChanged={changed}
                            />
                          ) : (
                            <></>
                          )}
                        </SplitPane>
                      </DeviceThemeProvider>
                    </LanguageProviderDevice>
                  )}
                </div>
              </ModalProvider>
            </CopyPasteProvider>
          </SavePropsProvider>
        </div>
      </CustomCursorDiv>
      <LayoutsButton
        compareXML={compareXML}
        readOnly={readOnly}
        componentsLibrary={componentsLibrary}
        properties={properties}
        codeEditor={codeEditor}
        floatingBtnStatus={floatingBtnStatus}
        setFloatingBtnStatus={setFloatingBtnStatus}
        selected={selected}
        versionsList={versionsList}
      />
      <RevisionsList
        compareXml={compareXML}
        revision={revision}
        setRevision={setRevision}
        setFloatingBtnStatus={setFloatingBtnStatus}
        versionsList={versionsList}
        lastVersion={lastVersion}
        draftDisplay={draftDisplay}
        dataVersions={dataVersions}
        readOnly={readOnly}
        showDraftsList={showDraftsList}
        setShowDraftsList={setShowDraftList}
        setIsTransformed={setIsTransformed}
        isTransformed={isTransformed}
        setCompareXML={setCompareXML}
        enableBackground={enableBackground}
        setEnableBackground={setEnableBackground}
        Bordered={Bordered}
        setBorder={setBorder}
      />
    </React.Fragment>
  );
};

export default Designer;
