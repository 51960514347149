import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./useToasts.scss";

const useToasts = () => {
  const addToast = (message, type = "info", options = {}) => {
    const defaultOptions = {
      className: `toast-container toast-${type}`,
      hideProgressBar: true,
      autoClose: 3500,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
      transition: Slide,
    };

    const toastOptions = { ...defaultOptions, ...options };

    toast[type](message, toastOptions);
  };

  return { addToast };
};

export default useToasts;
