function duplicateItemByIndex(data, setData, index) {
  try {
    if (index >= 0 && index < data.length) {
      const duplicatedItem = { ...data[index] };

      const newData = [
        ...data.slice(0, index + 1),
        duplicatedItem,
        ...data.slice(index + 1),
      ];

      setData(newData);
    } else {
      throw new Error("Index out of bounds");
    }
  } catch (error) {
    console.error(
      `An error occurred in duplicateItemByIndex: ${error.message}`
    );
  }
}

export default duplicateItemByIndex;
