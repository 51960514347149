function resize(mergeView, height) {
  //var height = mergeViewHeight(mergeView);7

  mergeView.editor().setSize(null, height - 194);

  mergeView.rightOriginal().setSize(null, height - 194);

  mergeView.wrap.style.height = `${height - 194}px`;
}

export default resize;
