async function installScriptPart(params = {}) {
  const { scriptName, scriptInstall } = params || {};
  try {
    if (typeof arguments[0] !== "object" || !arguments[0]) {
      throw new Error("argument must be an object");
    }
    if (!scriptName || typeof scriptName !== "string") {
      throw new Error("scriptName is undefined");
    }
    if (!scriptInstall instanceof Function) {
      throw new Error("scriptInstall is not a function");
    }
    const response = await scriptInstall();

    return {
      ...response,
      scriptInstall,
      scriptName,
    };
  } catch (error) {
    console.error(
      `error installScriptPart scriptInstall ${scriptInstall?.name}`,
      error
    );
    const res = {
      success: false,
      content: {
        status: "failure",
        result: {},
        message: error?.message
          ? error.message
          : "error on install docklinks script",
      },
      message: "",
      scriptInstall,
      scriptName,
    };

    return res;
  }
}

export default installScriptPart;
