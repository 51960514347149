import React from "react";
import { useSelector, useStore } from "react-redux";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";

function SwipeTheme({ handleOpenActions }) {
  const store = useStore();
  const MaxappsThemes = ["Maxapps", "MaxappsDark"];
  const deviceTheme = process.env.REACT_APP_DEVICE_THEME;
  const Themes = deviceTheme ? [deviceTheme, ...MaxappsThemes] : MaxappsThemes;

  const { theme, themes } = useDeviceTheme();

  const currentTheme = useSelector(
    (state) => state?.entities?.deviceview?.settings?.theme
  );

  const updateTheme = (newTheme) => {
    store.dispatch({
      type: "deviceview/setTheme",
      payload: { theme: newTheme },
    });
  };


  return (
    <div
      className="swipe-container"
      onClick={(e) =>
        handleComponentProp(e)
      }
    >
      <div
        className="close-button"
        onClick={(event) => {
          handleOpenActions(event, false);
        }}
      >
        <Icon icon="times2" size={15} className="timesIcon" />
      </div>
      <div className="swipe">
        {Themes?.map((th, index) => {  const isActiveTheme = th === currentTheme;

return (
          <div
            key={index}
            className="theme-option"
            onClick={(event) => {
                updateTheme(th);
                handleOpenActions(event, false);
              }}
                          style={{
                backgroundColor: isActiveTheme
                  && themes?.[theme]?.primaryLight,

                  borderBottom: isActiveTheme ? "0px" : `1px solid ${themes?.[theme]?.primaryLight}`,
                  borderTop: isActiveTheme && "0px" 

              }}
          >
            <div className="ThemeContainer">
            <div className="theme-name">{th}</div>
            <div className="theme-colors">
              <div
                className="color-box1"
                style={{ backgroundColor: themes[th]?.primary }}
              />
              <div
                className="color-box2"
                style={{ backgroundColor: themes[th]?.primaryDark }}
              />
              <div
                className="color-box3"
                style={{ backgroundColor: themes[th]?.secondary }}
              />
              <div
                className="color-box4"
                style={{ backgroundColor: themes[th]?.secondaryLight }}
              /></div>
          </div>
          </div>
          ); })}
      </div>
    </div>
  );
}

export default SwipeTheme;
