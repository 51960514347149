import React from "react";
import "./index.scss";
import SidebarBtn from "../SidebarBtn";
import Icon from "../../../../../../components/CustomIcon/Icon";

function ManySideBar({
  iconsListing = [],
  iconsSelected = [],
  setIconsListing = () => {},
  setIconsSelected = () => {},
  onDeleteIcon,
}) {
  const deselectIcon = (iconToDeselect) => {
    setIconsSelected(iconsSelected.filter((icon) => icon !== iconToDeselect));
  };
  return (
    <div className="icons-sidebar">
      <div className="many-details">
        <div className="selected-icons">
          {iconsSelected?.length !== iconsListing?.length
            ? `${iconsSelected?.length} Icons Selected`
            : `All Icons Selected (${iconsSelected?.length})`}
        </div>

        <div className="icons-display">
          {iconsSelected?.map((selectedIcon, index) => (
            <div key={index} className="icon-display">
              <div className="display-dtl">
                <div className="small-icon">
                  <img src={selectedIcon?.uri} alt={selectedIcon?.iconName} />
                </div>
                <div className="icon-name">{selectedIcon?.iconName}</div>
              </div>
              <div className="delete-cross">
                <Icon
                  size={20}
                  icon="Close"
                  onClick={() => deselectIcon(selectedIcon)}
                />
              </div>
            </div>
          ))}
        </div>
        <SidebarBtn
          iconsSelected={iconsSelected}
          iconsListing={iconsListing}
          setIconsListing={setIconsListing}
          setIconsSelected={setIconsSelected}
          onDeleteIcon={onDeleteIcon}
        />
      </div>
    </div>
  );
}

export default ManySideBar;
