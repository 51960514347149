import React, { useEffect, useState } from "react";
import "./index.scss";
import { useStore, useSelector } from "react-redux";

import CustomDropzone from "./CustomDropzone";
import CustomEditor from "./CustomEditor";
import fetchListVersion from "../../../services/revision";
function CustomSide({ isleft = true, status = false, onChange = () => {} }) {
  localStorage.setItem("isUp", false);
  localStorage.setItem("isClicked", false);
  const { versionsList, draftDisplay } = useSelector((state) => ({
    versionsList: state?.entities?.revision?.list || [],
    draftDisplay: state?.entities?.explorer?.draftDisplay,
  }));
  const { dataVersions = [] } = fetchListVersion(versionsList) || {};
  const lastVersion = dataVersions?.[0];
  return (
    <React.Fragment>
      {status ? (
        <CustomEditor isleft={isleft} />
      ) : draftDisplay && isleft ? (
        <CustomEditor isleft={isleft} />
      ) : !status && !draftDisplay ? (
        <CustomDropzone isleft={isleft} />
      ) : (
        <CustomDropzone isleft={isleft} />
      )}
    </React.Fragment>
  );
}
export default CustomSide;
