import React, { useEffect, useState } from "react"
import imxtools from "imxtools"
import { useSelector } from "react-redux"
import { useModal } from "../../../../services/ModalContext"
import Icon from "../../../../../../components/CustomIcon/Icon"
import NoItem from "../../../../../../components/NoItem"
import { handleCardClick, handleOpenModal } from "../utilities"
import "./index.scss"
import DragUtility from "../services/DragUtility"
import SearchBar from "../SearchBar"

function Lookups() {
  const [selectedCards, setSelectedCards] = useState([])
  const [searchText, setSearchText] = useState("")
  const [hover, setHover] = useState(false)
  const [filterData, setFilterData] = useState([])
  const { openModal } = useModal()
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton)
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly)

  const path = ["maxapps", "lookups", "lookup"]
  const lookups = imxtools?.toArray(skeleton?.maxapps?.lookups?.lookup ?? [])

  useEffect(() => {
    const filterData = lookups?.filter(
      (c, index) => !selectedCards?.includes(index)
    )
    setFilterData(
      filterData?.length === 0
        ? []
        : filterData?.length === 1
        ? filterData[0]
        : filterData
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCards])

  return (
    <div className='lookups-container'>
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        selectedCards={selectedCards}
        functions={lookups}
        readOnly={readOnly}
        setSelectedCards={setSelectedCards}
        filterData={filterData}
        path={path}
        component={"condition"}
      />
      <div className={`lBody ${!lookups?.length && "noItem"}`}>
        {!readOnly && (
          <div
            className='card New'
            onClick={() => {
              !readOnly && openModal("lookup", "", true, lookups, path)
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Icon
              icon='add-circle'
              size={30}
              color={hover ? "#4C36A5" : "#AAAAAA"}
            />
          </div>
        )}
        {!lookups?.length && readOnly ? (
          <NoItem icon='Lookup' text='No lookup to display' />
        ) : (
          lookups
            ?.filter((lookup) => {
              return lookup.reference
            })
            ?.map((lookup, index) => {
              return (
                <React.Fragment key={index}>
                  <DragUtility component='lookup' reference={lookup?.reference}>
                    <div
                      key={index}
                      className={`card ${
                        selectedCards.includes(index) ? "selected" : ""
                      }`}
                      onClick={() => {
                        !readOnly &&
                          handleCardClick(
                            index,
                            selectedCards,
                            setSelectedCards
                          )
                      }}
                      onDoubleClick={() => {
                        handleOpenModal(
                          lookup?.reference,
                          index,
                          "lookup",
                          lookups,
                          path,
                          openModal,
                          false
                        )
                      }}
                    >
                      <p className='lookup-card-name'>{lookup?.reference}</p>
                      <div className='lookup-icons'>
                        <Icon
                          icon='tag'
                          size={20}
                          color={lookup?.tag ? "#4C36A5" : "#EEEEEE"}
                        />
                        <Icon
                          icon='Attribute'
                          size={20}
                          color={lookup?.body ? "#4C36A5" : "#EEEEEE"}
                        />
                        <Icon
                          icon='Whereclause'
                          size={20}
                          color={lookup?.whereclause ? "#4C36A5" : "#EEEEEE"}
                        />
                      </div>
                    </div>
                  </DragUtility>
                </React.Fragment>
              )
            })
        )}
      </div>
    </div>
  )
}

export default Lookups
