import React from "react";
import "./index.scss";
import { useLanguage } from "../../../Translate/LanguageContext";

function Footer() {
  const { translate } = useLanguage();

  return (
    <div className="footer-container">
      <a
        href={"https://maxapps-maximo.com/privacy-policy/"}
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        {translate("privacyPolicy")}
      </a>
      <span> - </span>
      <a
        href={"https://maxapps-maximo.com/about-us/"}
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        {translate("aboutUs")}
      </a>
    </div>
  );
}

export default Footer;
