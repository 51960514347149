import { Modal } from "@mui/material";
import CustomButton from "../CustomButton";
import "./index.scss";
import { useTheme } from "../../theme/themeContext";

const CustomModal = ({
  open = () => {},
  onClose = () => {},
  onClickSecondaryAction = () => {},
  onClickPrimaryAction = () => {},
  secondaryLabe = "",
  primaryLabel = "",
  children = <></>,
  secondaryAction = false,
  primaryAction = true,
  additionalInformation = "",
  modalTitle = "",
  iconTitle = <></>,
  alignment = "flex-start",
  utility = false,
  showFooter = true,
  showHeader = true,
}) => {
  const { themeMode, themeColors } = useTheme();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClickPrimaryAction();
    }
  };

  return (
    <Modal open={open} onClose={onClose} onKeyDown={handleKeyDown}>
      <div className="modalView">
        {!utility ? (
          <div className="modalContainer" onClick={(e) => e.stopPropagation()}>
            {showHeader ? (
              <div className="modalHeader">
                <div className="void"></div>
                <div className="modalTitle">
                  <div className="modalIcon">{iconTitle}</div>
                  {modalTitle}
                </div>
                <div className="closeButton">
                  <CustomButton
                    withLabel={false}
                    iconColor="white"
                    heightBtn="22px"
                    widthBtn="22px"
                    iconName="Close"
                    iconSize={22}
                    withoutInteration={true}
                    backgroundColor={"#FF505A"}
                    onClick={onClose}
                  />
                </div>
              </div>
            ) : null}
            <div className="modalContent" style={{ justifyContent: alignment }}>
              {children}
            </div>
            {showFooter ? (
              <div className="modalActions">
                <div className="actions">
                  {secondaryAction ? (
                    <div className="secondaryAction">
                      <CustomButton
                        label={secondaryLabe}
                        withoutInteration={true}
                        backgroundColor="white"
                        widthBtn="100%"
                        heightBtn="2.75rem"
                        color="#39354A"
                        borderColor="#EEEEEE"
                        borderWidth="1px"
                        fontSize={16}
                        borderRadius={2}
                        onClick={onClickSecondaryAction}
                        withIcon={false}
                        disableClickReadOnly={false}
                        border={true}
                      />
                    </div>
                  ) : null}
                  {primaryAction ? (
                    <div className="primaryAction">
                      <CustomButton
                        label={primaryLabel}
                        backgroundColor={themeColors[themeMode].SupportButtonBg}
                        hoveredBackground={
                          themeColors[themeMode].SupportButtonBg
                        }
                        ActiveBackground={
                          themeColors[themeMode].SupportButtonBg
                        }
                        widthBtn="100%"
                        heightBtn="2.75rem"
                        color="white"
                        borderColor="#4C36A5"
                        borderWidth="1px"
                        fontSize={16}
                        borderRadius={2}
                        onClick={onClickPrimaryAction}
                        withIcon={false}
                        disableClickReadOnly={false}
                      />
                    </div>
                  ) : null}
                </div>
                {additionalInformation?.length > 0 ? (
                  <div className="additionalInformation">
                    {additionalInformation}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </Modal>
  );
};

export default CustomModal;
