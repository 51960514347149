export const Mira = {
  themeName: "Mira",
  CardViewBgColor: "#EFEDE740",
  secondaryLight: "#5A5B5D80",
  primary: "#00265A",
  iconAppItem: "#4E8BC6",
  AppText: "#414141",
  connectBtn: "#00265A",
  loader: "#00265A",
  headerColor: "#00265A",
  primaryLight: "#00265A50",
  primaryColor: "#00265AB2",
  textColor: "#000",
  listBg: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#4E8BC6",

  settingCollapseColor: "#eee",
  welcomeBg: "#F5F4F1",
  success: "#3ecd7e",
  primaryDark: "#2D3848",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#00265A",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",

  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#00265A",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#FDE7C9",
  appBg: "#f8f8f8",
  appBorder: "#EEEEEE",
  label: "#00265A",
  groupIcon: "#ffffff",
  borderRecord: "#B9915E50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CCD4DE",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#FDE7C9",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#00265A",
  listBgCard: "#eee",
  colorTextInput: "#565757",
  LogincolorTextInput: "#565757",
  LogincolorLabelInput: "#B3B3B3",
  loginOptions: "#565757",
  shadowBtnConnect: "#4E8BC6",
  bnConnect: "#00265A",
  loginBtnLabel: "#fff",
  focusedBorderLoginInput: "#4E8BC6",
  focusedLabelLoginInput: "#4E8BC6",
  resetInputLogin: "#4E8BC6",
  languageIcon: "#565757",
  containerLanguageIcon: "#ffffff",
  bgLoginInput: "#FFF",
  loginFooter: "#565757",
  loginText: "#000",
  notfocusedBorderLoginInput: "#EEEEEE",
  headerPrimary: "#AF975100",
  headerDark: "#8D7C4900",
  headerText: "#414141",
  notificationUpColor: "#F5F9FF",
  notificationDownColor: "#E5F0FF",

  listItemNotificationUpColor: "#F0F6FF",
  listItemNotificationDownColor: "#DBEAFF",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#E5F0FF",

  notFocusedSocialMedia: "#00265A",
  focusedSocialMedia: "#00265Acc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#00265A",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
