import { axiosProviderNode } from "../../../../../services/http/httpService";
import { services } from "../../configVariables";

async function installImglibOS() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_ADD_IMGLIB_OS;

    const body = {};

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    throw error;
  }
}

export default installImglibOS;
