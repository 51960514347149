import React, { createContext, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { colors } from "./Themecolors/themes";

const DeviceThemeContext = createContext();

export const DeviceThemeProvider = ({ children }) => {
  const theme = useSelector(
    (state) => state?.entities?.deviceview?.settings?.theme || "Maxapps"
  );

  useEffect(() => {
    const root = document.documentElement;
    const currentThemeColors = colors[theme ?? "Maxapps"];

    if (currentThemeColors) {
      Object.keys(currentThemeColors).forEach((key) => {
        root.style.setProperty(`---${key}`, currentThemeColors[key]);
      });
    }
  }, [theme]);

  const currentTheme = colors?.[theme];

  return (
    <DeviceThemeContext.Provider
      value={{
        theme,
        colors: currentTheme,
        themes: colors,
      }}
    >
      {children}
    </DeviceThemeContext.Provider>
  );
};

export const useDeviceTheme = () => {
  return useContext(DeviceThemeContext);
};
