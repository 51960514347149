export function getButtonStyles(
  readOnly = true,
  themeColors = {},
  themeMode = "light",
  isActive = false,
  compareBtn = false
) {
  const basicStyles = {
    iconSize: 24,
    backgroundColor: themeColors[themeMode].LoginBG,
    gap: 0.625,
    heightBtn: "2.5rem",
    fontSize: 16,
    borderColor: themeColors[themeMode].BorderButtons,
    borderWidth: "1px",
    paddingHorizontal: 1,
    paddingVertical: 0.5,
    iconColor: readOnly
      ? themeColors[themeMode].iconColor
      : themeColors[themeMode].TbButtonLabelDisable,
    color: readOnly
      ? themeColors[themeMode].iconColor
      : themeColors[themeMode].TbButtonLabelDisable,
    disableClickReadOnly: false,
  };

  const compareBtnStyles = {
    backgroundColor: isActive
      ? themeColors[themeMode].iconColor
      : themeColors[themeMode].LoginBG,

    hoveredBackground: isActive
      ? themeColors[themeMode].iconColor
      : themeColors[themeMode].LoginBG,
    ActiveBackground: isActive
      ? themeColors[themeMode].iconColor
      : themeColors[themeMode].LoginBG,
    iconColor: isActive
      ? themeColors[themeMode].LoginBG
      : themeColors[themeMode].iconColor,
    color: isActive
      ? themeColors[themeMode].LoginBG
      : themeColors[themeMode].iconColor,
    ActiveColor: isActive
      ? themeColors[themeMode].LoginBG
      : themeColors[themeMode].iconColor,
    hoveredColor: isActive
      ? themeColors[themeMode].LoginBG
      : themeColors[themeMode].iconColor,
  };

  return compareBtn
    ? {
        ...basicStyles,
        ...compareBtnStyles,
      }
    : basicStyles;
}
