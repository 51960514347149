import { handleComponentProp } from "../../../services/handleComponentProperties";
import { navigateTo } from "../../../services/navigationMiddleware";

function handleNavigateTo(event, index, app, list, data, store, path) {
  event.stopPropagation();
  let newPath = [];
  if (list !== null) {
    newPath =
      index === null
        ? [...path, app, list]
        : [...path, app, index?.toString(), list];
    handleComponentProp(
      event,
      null,
      data?.list,
      index === null ? [...path, app] : [...path, app, index?.toString()],
      store,
      list
    );
  } else {
    newPath =
      index === null ? [...path, app] : [...path, app, index?.toString()];
    handleComponentProp(event, index, data, path, store, app);
  }
  navigateTo(store, "List", { data, path: newPath });
}

export default handleNavigateTo;
