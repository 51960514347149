import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import Message from "./Message";

function Contact() {
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  return (
    <>
      {skeleton?.maxapps?.contact ? (
        <div className="contact-container">
          <Message data={skeleton?.maxapps?.contact} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Contact;
