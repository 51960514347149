export function isSelectedComponent(
  readOnly = true,
  path = null,
  currentPath = [],
  component = "",
  index = null
) {
  try {
    if (!currentPath || currentPath.length < 1 || readOnly) {
      return false;
    }
    const lastIndex = currentPath.length - 1;
    const secondLastIndex = currentPath.length - 2;

    let result = false;

    if (path !== null) {
      if (currentPath?.length !== path?.length) {
        return false;
      }
      for (let i = 0; i < currentPath?.length; i++) {
        if (currentPath[i] !== path[i]) {
          return false;
        }
      }
      return true;
    } else {
      if (index === null) {
        result = currentPath[lastIndex] === component;
      } else {
        result =
          currentPath[lastIndex] === index.toString() &&
          currentPath[secondLastIndex] === component;
      }
    }
    return result;
  } catch (error) {
    console.error("Error in isSelectedComponent:", error);
    return false;
  }
}
