export const Omnis = {
  themeName: "Omnis",
  isDark: false,
  CardViewBgColor: "#4D7F7110",
  secondaryLight: "#B99C1680",
  primary: "#4D7F71",
  iconAppItem: "#4D7F71",
  AppText: "#4D7F71",
  connectBtn: "#4D7F71",
  loader: "#4D7F71",
  headerColor: "#4D7F71",
  primaryLight: "#4D7F7150",
  primaryColor: "#4D7F71B2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#B99C16",

  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#1F755D",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#B99C16",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#4D7F71",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#4D7F71",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#4D7F7150",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#4D7F7120",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#4D7F71",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#fff",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#4D7F71",
  bnConnect: "#4D7F71",
  notFocusedLabelInput: "#76777A",
  resetInputLogin: "#4D7F71",
  focusedBorderLoginInput: "#4D7F71",
  focusedLabelLoginInput: "#4D7F71",
  loginFooter: "#77777A",
  languageIcon: "#000",
  loginText: "#000",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#4D7F7100",
  headerText: "#4D7F71",
  headerDark: "#1F755D00",
  notificationUpColor: "#F9FBFA",
  notificationDownColor: "#EFF5F4",

  listItemNotificationUpColor: "#F5F9F8",
  listItemNotificationDownColor: "#E5F5F1",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F4F3",

  notFocusedSocialMedia: "#4D7F71",
  focusedSocialMedia: "#4D7F71cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#4D7F71",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
