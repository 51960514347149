export const DarkSea = {
  themeName: "DarkSea",
  isDark: false,
  CardViewBgColor: "#00265A10",
  secondaryLight: "#5592C980",
  primary: "#00265A",
  iconAppItem: "#00265A",
  AppText: "#00265A",
  loginFooter: "#00265A",
  connectBtn: "#00265A",
  loader: "#00265A",
  headerColor: "#00265A",
  primaryLight: "#00265A50",
  primaryColor: "#00265AB2",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  secondary: "#5592C9",

  settingCollapseColor: "#eee",
  welcomeBg: "#F1FAFC",
  success: "#3ecd7e",
  primaryDark: "#061933",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#5592C9",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#FFF",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#00265A",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#CDE6FC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#00265A",
  groupIcon: "#ffffff",

  borderRecord: "#00265A50",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#CDE6FC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#CDE6FC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#00265A",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFFFFF1A",

  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#5592C9",
  bnConnect: "#ffffff",
  notFocusedLabelInput: "#FFFFFF",
  focusedLabelLoginInput: "#5592C9",
  resetInputLogin: "#5592C9",
  focusedBorderLoginInput: "#5592C9",

  languageIcon: "#00265A",
  loginText: "#fff",
  loginBtnLabel: "#00265A",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#00265A00",
  headerDark: "#06193300",
  headerText: "#00265A",
  notificationUpColor: "#F5F9FF",
  notificationDownColor: "#E5F0FF",

  listItemNotificationUpColor: "#F0F6FF",
  listItemNotificationDownColor: "#DBEAFF",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F2F4",

  notFocusedSocialMedia: "#00265A",
  focusedSocialMedia: "#00265Acc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#00265A",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
