function getFailedListScripts(responses = []) {
  try {
    if (!Array.isArray(responses)) {
      return [];
    }
    const filtredList = responses?.filter((script) => {
      if (
        script?.success === false ||
        (script?.success === true &&
          script?.content?.status.toUpperCase() === "FAILURE")
      ) {
        return script;
      }
    });

    return filtredList;
  } catch (error) {
    console.error("Error in getFailedListScripts:", error);
  }
}

export default getFailedListScripts;
