import React from "react";
import Icon from "../CustomIcon/Icon";
import "./index.scss";

function NoItem({
  icon = "",
  iconSize = 100,
  iconColor = "#B9B8BD",
  textColor = "#B9B8BD",
  text = "No Item to display",
  fontSize = "16px",
  paddingVertical = "1rem",
  paddingHorizontal = "1rem",
}) {
  return (
    <div className="NoItem">
      <div>
        <Icon icon={icon} size={iconSize} color={iconColor} />
      </div>
      <div
        className="NoProperties-text"
        style={{
          color: textColor,
          fontSize: fontSize,
          padding: `${paddingHorizontal} ${paddingVertical}`,
        }}
      >
        {text}
      </div>
    </div>
  );
}

export default NoItem;
