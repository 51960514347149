export const Historic = {
  themeName: "Historic",
  isDark: false,
  CardViewBgColor: "#49AC7710",
  secondaryLight: "#12182380",
  primary: "#49AC77",
  iconAppItem: "#49AC77",
  AppText: "#414141",
  loginFooter: "#7C5743",
  loader: "#49AC77",
  headerColor: "#49AC77",
  primaryLight: "#49AC7750",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",

  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",

  danger: "#EE6E6E",
  warning: "#e59d4b",
  primaryColor: "#49AC77B2",
  secondary: "#7C5743",
  focusedLabelLoginInput: "#7C5743",
  resetInputLogin: "#7C5743",
  focusedBorderLoginInput: "#49AC77",

  languageIcon: "#7C5743",

  settingCollapseColor: "#eee",
  welcomeBg: "#FBF9F9",
  success: "#3ecd7e",
  primaryDark: "#116151",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#49AC77",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#2f2f2f",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#49AC77",
  yellowToast: "#edc300",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#C9D9CF",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#49AC77",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",

  borderRecord: "#49AC7750",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#C9D9CF",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#C9D9CF",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#49AC77",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#fff",
  colorTextInput: "#7C5743",
  LogincolorTextInput: "#2f2f2f",
  LogincolorLabelInput: "#9d9d9d",
  connectBtn: "#84bd00",
  loginOptions: "#000",
  shadowBtnConnect: "#7C5743",
  bnConnect: "#49AC77",
  notfocusedBorderLoginInput: "#eee",
  headerPrimary: "#49AC7700",
  headerDark: "#11615100",
  headerText: "#414141",
  notificationUpColor: "#F8FCFA",
  notificationDownColor: "#EDF7F2",

  listItemNotificationUpColor: "#F4FAF7",
  listItemNotificationDownColor: "#E6F4ED",

  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F1F4F2",

  notFocusedSocialMedia: "#49AC77",
  focusedSocialMedia: "#49AC77cc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#49AC77",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
