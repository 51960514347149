function getCurrentDate() {
  const options = {
    weekday: "short", // Short day name (e.g., "Tue")
    month: "short", // Short month name (e.g., "Oct")
    day: "numeric", // Day of the month (e.g., "10")
    year: "numeric", // Full year (e.g., "2023")
  };

  const currentDate = new Date().toLocaleDateString(undefined, options);

  return currentDate;
}

export default getCurrentDate;
