import React, { useEffect, useState } from "react";
import "./index.scss";
import { Box, CircularProgress } from "@mui/material";

import SyncConfigComponent from "../SyncConfigComponent";
import __getMaxappsHost from "./services/__getMaxappsHost";

function VerifComponent() {
  const [progress, setProgress] = useState(0);
  const [maxappsHost, setMaxappsHost] = useState(null);

  useEffect(() => {
    __getMaxappsHost(setMaxappsHost);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? clearInterval(timer) : prevProgress + 30
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="setupVerificationContainer">
      {progress < 100 ? (
        <div className="VerificationBody">
          <div className="VerificationTitle">
            Post MAXapps Installation Verification
          </div>
          <div className="verif-loader">
            <Box>
              <CircularProgress
                variant="determinate"
                value={100}
                style={{
                  color: "#e0e0e0",
                  position: "absolute",
                  zIndex: 1,
                }}
              />
              <CircularProgress
                variant="determinate"
                value={progress}
                style={{
                  color: "var(--TitleContainer)",
                  position: "absolute",
                  zIndex: 2,
                }}
              />
            </Box>
          </div>
        </div>
      ) : (
        <SyncConfigComponent
          maxappsHostAdress={maxappsHost}
          title="Post MAXapps Installation Verification"
        />
      )}
    </div>
  );
}

export default VerifComponent;
