import { axiosProviderNode } from "../../../../services/http/httpService";
import { services } from "../configVariables";

async function verifyAutoscripts() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_MAXIMO_VERIFY;

    const body = {};

    const res = await axiosProviderNode.get(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    console.error("maximoLogin error", error);
    throw error;
  }
}

export { verifyAutoscripts };
