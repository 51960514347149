import React from "react";
import "./index.scss";
import ApiKeyLogin from "./apiKeyLogin";

function MaximoLogin() {

  return (
    <div className="installer-MaximoLogin">
      {/* <div className="login-bloc-tabs">
        {tabs?.map((tab, index) => (
          <React.Fragment key={index}>
            <TabButton
              title={tab?.title}
              handleClick={() => setToggleState(index)}
              active={index === toggleState}
            />
          </React.Fragment>
        ))}
      </div> */}
      <div className="installer-login-form-body">
        <p className="installer-login-form-title">Connect MAXapps to Maximo</p>
        <div className="installer-content-tabs">
          {/* {tabs?.[toggleState]?.component} */}
          <ApiKeyLogin />
        </div>
      </div>
    </div>
  );
}

export default MaximoLogin;
