function isJSON(str) {
  if (typeof str !== "string" || !str) {
    return false;
  }
  try {
    JSON.parse(str || "");
  } catch (e) {
    return false;
  }
  return true;
}

export default isJSON;
