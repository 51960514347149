import { axiosProviderNode } from "../../../services/http/httpService";
import { services } from "./configVariables";

async function uploadIconToServer(formData = {}, addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_UPLOAD_ICON;
    const contentType = "multipart/form-data";
    const res = await axiosProviderNode.post(
      transactionNodeService,
      formData,
      {},
      contentType
    );

    if (res?.data?.success) {
      addToast(res?.data?.message.toString(), "success");
    }
    return res?.data?.content;
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error?.message || "Error in Icon upload";
    addToast(errorMessage, "error");
    throw error;
  }
}

export default uploadIconToServer;
