import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import QuestionInspectionOld from "../QuestionInspectionOld";
import DropTargetBody from "../DropTargetBody";
import { handleComponentProp } from "../../services/handleComponentProperties";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../services/AppContext";
import imxtools from "imxtools";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { useSelector, useStore } from "react-redux";

const listQuest = [
  {
    num: 1,
    title: "Initiative Steel haptic",
    completed: true,
    content: [{ qte: "1.01" }, { qte: "1.02" }],
  },
  {
    num: 2,
    title: "Copy",
    completed: false,
    content: [{ qte: "2.01" }, { qte: "2.02" }],
  },
  {
    num: 3,
    title: "British",
    completed: false,
    content: [{ qte: "3.01" }, { qte: "3.02" }],
  },
  {
    num: 4,
    title: "Jewelery",
    completed: false,
    content: [{ qte: "4.01" }, { qte: "4.02" }],
  },
  {
    num: 5,
    title: "Supply-chains synthesizing Legacy",
    completed: false,
    content: [{ qte: "5.01" }, { qte: "5.02" }],
  },
  {
    num: 6,
    title: "Overriding",
    completed: true,
    content: [{ qte: "6.01" }, { qte: "6.02" }],
  },
  {
    num: 7,
    title: "Virginia",
    completed: false,
    content: [{ qte: "6.01" }, { qte: "6.02" }],
  },
  {
    num: 8,
    title: "Corporate Sausages Locks",
    completed: false,
    content: [{ qte: "7.01" }, { qte: "7.02" }],
  },
  {
    num: 9,
    title: "Shoals red Auto",
    completed: false,
    content: [{ qte: "8.01" }, { qte: "8.02" }],
  },
  {
    num: 10,
    title: "Withdrawal web-readiness",
    completed: false,
    content: [{ qte: "9.01" }, { qte: "9.02" }],
  },
  { num: 11, title: "Toys", completed: false, content: { qte: "1.01" } },
  {
    num: 12,
    title: "interfaces Flat",
    completed: false,
    content: { qte: "1.01" },
  },
];

const InspectionModalOld = ({
  closeModal = () => {},
  data = {},
  path = [],
}) => {
  const store = useStore();

  const [viewContent, setViewContent] = useState("home");
  const [questionToDisplay, setQuestionToDisplay] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [dataAttributes, setDataAttributes] = useState([]);
  let edit = useSelector((state) => state?.entities?.editor);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const { setIsDragingIn } = useContext(AppContext);
  useEffect(() => {
    setDataAttributes(
      imxtools?.toArray(data?.body?.attribute ? data?.body?.attribute : [])
    );
  }, [data?.body?.attribute]);

  const handleComponent = (e, data, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };
  const questionClick = (question) => {
    setIsOpen(true);
    setViewContent(question?.num);
    setQuestionToDisplay(question);
  };

  const startClick = () => {
    setViewContent("home");
    setQuestionToDisplay(undefined);
  };

  return (
    <div className="inspection-modal-old-container">
      <div
        className={isOpen ? "inspection-old-left-open" : "inspection-old-left"}
        style={{ borderRadius: `0px 0px 35px 35px` }}
      >
        <div
          className="one-tab-left-container-start"
          onClick={() => startClick()}
        >
          <i className="bx bxs-right-arrow" style={{ color: "#fff" }}></i>
          <div>Start</div>
        </div>
        <div
          className="inpection-tab-container-list"
          style={{ borderRadius: `0px 0px 0px 35px` }}
        >
          {listQuest?.length > 0 ? (
            listQuest?.map((question, index) => (
              <div
                key={index}
                className={
                  !isOpen
                    ? "question-tab-inspection-open"
                    : "question-tab-inspection"
                }
                style={{
                  backgroundColor:
                    question?.num === viewContent || question?.completed
                      ? "#FFFAEF"
                      : question?.num === viewContent && !question?.completed
                      ? "#FFFAEF"
                      : "#fff",
                }}
                onClick={() => questionClick(question)}
              >
                <div
                  className={"bull-num-q"}
                  style={{
                    backgroundColor:
                      question?.num === viewContent && question?.completed
                        ? "#30BC7E"
                        : question?.num === viewContent && !question?.completed
                        ? "#E3D3EA"
                        : question?.num !== viewContent && !question?.completed
                        ? "#F8B800"
                        : question?.num !== viewContent && !question?.completed
                        ? "#F8B800"
                        : "#30BC7E",
                    color: question?.num === viewContent ? "#6A549E" : "#000",
                  }}
                >
                  <>{question?.num}</>
                </div>
                {isOpen ? (
                  <text
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      marginLeft: 8,
                      textAlign: "start",
                      width: "70%",
                    }}
                  >
                    {question?.title}
                  </text>
                ) : (
                  <></>
                )}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {viewContent === "home" ? (
        <div
          className={
            isOpen ? "inspection-old-right-open" : "inspection-old-right"
          }
          onClick={() => setIsOpen(false)}
        >
          <DropTargetBody
            accept={[
              "total",
              "Required",
              "optionalQuestions",
              "filters",
              "body",
              "attachment",
              "toolbar",
              "question",
            ]}
            dragParent={path}
            style={{
              width: "100%",
              overflowY: "auto",
              height: "100%",
              padding: 3,
            }}
          >
            <div style={{ height: "90%", overflowY: "auto" }}>
              <div className="inspection-right-header">
                <div
                  className="text-header-container"
                  onClick={(e) =>
                    handleComponentProp(
                      e,
                      null,
                      data,
                      path,
                      store,
                      "inspection"
                    )
                  }
                >
                  <text className="text-header">AMC Audit Cheklist.</text>
                </div>
                <div className="close-container" onClick={() => closeModal()}>
                  <i className="bx bx-x" style={{ color: "#fff" }}></i>
                </div>
              </div>
              <div className="home-settings-container">
                {data?.hasOwnProperty("filters") && Boolean(data?.filters) ? (
                  <>
                    {data?.filters?.hidden === "true" ? (
                      <></>
                    ) : (
                      <div
                        className="buttons-container"
                        onClick={(e) =>
                          handleComponentProp(
                            e,
                            null,
                            data?.filters,
                            [...path, "filters"],
                            store,
                            "filters"
                          )
                        }
                      >
                        <div style={{ color: "#6A549E", fontWeight: 700 }}>
                          Display :
                        </div>
                        <button className="btn">All</button>
                        <button className="btn">Incompleted</button>
                        <button className="btn">Completed</button>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {data?.hasOwnProperty("optionalQuestions") &&
                Boolean(data?.optionalQuestions) ? (
                  <>
                    {data?.optionalQuestions?.hidden === "true" ? (
                      <></>
                    ) : (
                      <div
                        className="opt-qte-container"
                        onClick={(e) =>
                          handleComponentProp(
                            e,
                            null,
                            data?.optionalQuestions,
                            [...path, "optionalQuestions"],
                            store,
                            "optionalQuestions"
                          )
                        }
                      >
                        <div>Hide optional questions</div>
                        <input
                          type="checkbox"
                          style={{
                            borderRadius: 10,
                            borderColor: "#C8C8C8",
                            borderWidth: 2,
                            borderStyle: "solid",
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {data?.hasOwnProperty("total") && Boolean(data?.total) ? (
                  <>
                    {data?.total?.hidden === "true" ? (
                      <></>
                    ) : (
                      <div
                        className="total-container"
                        onClick={(e) =>
                          handleComponentProp(
                            e,
                            null,
                            data?.total,
                            [...path, "total"],
                            store,
                            "total"
                          )
                        }
                      >
                        <div>Total</div>
                        <div>0/0</div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {data?.hasOwnProperty("Required") && Boolean(data?.Required) ? (
                  <>
                    {data?.Required?.hidden === "true" ? (
                      <></>
                    ) : (
                      <div
                        className="requires-container"
                        onClick={(e) =>
                          handleComponentProp(
                            e,
                            null,
                            data?.Required,
                            [...path, "Required"],
                            store,
                            "Required"
                          )
                        }
                      >
                        <div>Required</div>
                        <div>0/0</div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {data?.hasOwnProperty("body") ? (
                  <DropTargetBody
                    accept={["attribute"]}
                    dragParent={[...path, "body"]}
                    style={{
                      width: "100%",
                      overflowY: "auto",
                      height: "auto",
                      minHeight: 35,
                      padding: 3,
                    }}
                  >
                    <div
                      className={`body-content  ${
                        isSelectedComponent(
                          readOnly,
                          [...path, "body"],
                          edit?.parentToEdit
                        )
                          ? `selectedComponent`
                          : `unSelectedComponent`
                      } `}
                      onClick={(e) =>
                        handleComponentProp(
                          e,
                          null,
                          data?.filters,
                          [...path, "body"],
                          store,
                          "body"
                        )
                      }
                    >
                      {data?.body?.attribute &&
                        dataAttributes?.map((att, index) => {
                          const numAttributes = imxtools?.toArray(
                            data?.body?.attribute ? data?.body?.attribute : []
                          )?.length;
                          return (
                            <React.Fragment key={index}>
                              <DragItemAttribute
                                index={index}
                                component={"attribute"}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                  paddingLeft: 4,
                                }}
                                dragParentPath={[...path, "body"]}
                                dragData={att}
                                dataAttributes={dataAttributes}
                                setDataAttributes={setDataAttributes}
                                setIsDraging={setIsDragingIn}
                              >
                                <div
                                  className={
                                    att?.label === "name"
                                      ? "name-container"
                                      : "assets-container"
                                  }
                                  onClick={(e) =>
                                    handleComponent(
                                      e,
                                      att,
                                      numAttributes === 1
                                        ? [...path, "body", "attribute"]
                                        : [
                                            ...path,
                                            "body",
                                            "attribute",
                                            index?.toString(),
                                          ],
                                      "attribute"
                                    )
                                  }
                                >
                                  <div style={{ fontSize: 14 }}>
                                    {att?.label}:
                                  </div>
                                  <div style={{ fontSize: 14 }}>MDC-001</div>
                                </div>
                              </DragItemAttribute>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </DropTargetBody>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div
              style={{
                height: "10%",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: 10,
              }}
            >
              <button
                style={{
                  height: "60%",
                  width: "30%",
                  backgroundColor: "#fff",
                  minWidth: 80,
                  minHeight: 30,
                  borderRadius: 9,
                  boxShadow: `0px 4px 10px 4px rgba(0, 0, 0, 0.25)`,
                  borderWidth: 0,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Start
                <i
                  className="bx bx-chevron-right"
                  style={{ fontSize: 16, fontWeight: 500 }}
                ></i>
              </button>
            </div>
          </DropTargetBody>
        </div>
      ) : (
        <div
          className={
            isOpen ? "inspection-old-right-open" : "inspection-old-right"
          }
        >
          <QuestionInspectionOld
            setViewContent={setViewContent}
            viewContent={viewContent}
            listQuestions={listQuest}
            questionToDisplay={questionToDisplay}
            setQuestionToDisplay={setQuestionToDisplay}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            closeModal={closeModal}
          />
        </div>
      )}
    </div>
  );
};
export default InspectionModalOld;
