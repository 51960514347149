import React, { useState } from "react";
import { useStore, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import useToasts from "../../../../../../components/CustomToast/useToasts";
import ClearIcon from "@mui/icons-material/Clear";
import "./index.scss";
import handleClickedItem from "../../../../services/helpers/handleClickedItem";
import CustomModal from "../../../../../../components/CustomModal";
import HandleEditMode from "../../../CompareFiles/services/handleEditMode";

function DraftRevision({ lastVersion, selectedRevision }) {
  const store = useStore();
  const { addToast } = useToasts();
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const { showRight, leftFileName, showLeft } = useSelector((state) => ({
    showRight: state?.entities?.compareFiles?.rightCode?.active,
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    showLeft: state?.entities?.compareFiles?.leftCode?.active,
  }));
  const isOn = JSON.parse(localStorage.getItem("isUp"));
  const iconClicked = JSON.parse(localStorage.getItem("iconClicked"));
  const { version, xmlAlias } =
    store.getState()?.entities?.revision?.revisionSelected;



  const handleDiscard = async () => {
    await handleClickedItem(
      addToast,
      store,
      {},
      lastVersion?.filename,
      false,
      true
    );
    HandleEditMode(store, showLeft, showRight, leftFileName, isOn, iconClicked);
  };


  return (
    <div className="revisionItem-edit" >
      <div className="revision-content">
        <div className="revision-name">{selectedRevision}</div>
        <div className="release-datetime">Already Draft</div>
      </div>
      <div className="actions-btn">
        <IconButton
          className="moreOptions-btn"
          size="small"
          onClick={(e) => {
            setShowDiscardPopup(true);
          }}
        >
          <ClearIcon />
        </IconButton>
      </div>
      <CustomModal
        open={showDiscardPopup}
        onClose={() => {
          setShowDiscardPopup(false);
        }}
        onClickPrimaryAction={handleDiscard}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowDiscardPopup(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Discard"
      >
        <div className="ModalContent">
          <div className="message">Are you sure to discard this draft?</div>
          <div className="draftVersion">{`Draft of: ${version} - ${xmlAlias}`}</div>
        </div>
      </CustomModal>
    </div>
  );
}

export default DraftRevision;
