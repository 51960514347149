import "./index.scss";

import React from "react";

import ExplorerItems from "./explorer-items";

function Explorer({ data = {} }) {
  return <ExplorerItems data={data} />;
}

export default Explorer;
