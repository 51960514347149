import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import { usersHeaderData, LoadingOptions } from "../../data";
import NoData from "../NoData/index";
import Lottie from "react-lottie";
import ReactJson from "react-json-view";
import { useTheme } from "../../../../../../theme/themeContext";
import Icon from "../../../../../../components/CustomIcon/Icon";
import fetchAllUser from "../../services/fetchAllUsers";
function UsersTable() {
  const [usersData, setUsersData] = useState([]); 
   const [loading, setLoading] = useState(true);
  const { themeMode } = useTheme();
  const [activeRow, setActiveRow] = useState(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await fetchAllUser();
        setUsersData(users?.content?.userFromDb || []);
        setLoading(false);  
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false); 
      }
    };

    fetchData();
  }, []);


  const handleRowClick = (index) => {
    setActiveRow(activeRow === index ? null : index);
  };

  return (
    <>
      {loading ? (
        <Lottie
          options={LoadingOptions}
          height={100}
          width={120}
          isClickToPauseDisabled={true}
          style={{ cursor: "auto" }}
        />
      ) : usersData?.length > 0 ? (
        <div className="data-collection-container">
          <div className="monitor-Dashboard">
            <div className="data-table-wrapper" ref={tableRef}>
              <table className="data-table">
                <thead className="table-header">
                  <tr>
                    {usersHeaderData?.map((header, index) => (
                      <th
                        className={`header-cell ${
                          header === "#" ? "row-index-header" : ""
                        }`}
                        key={index}
                        style={{
                          textAlign: header === "#" && "center",
                          paddingLeft: header === "#" && "2rem",
                        }}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="tbody-wrapper">
                  {usersData.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      <tr
                        onClick={() => handleRowClick(rowIndex)}
                        style={{
                          background:
                            activeRow === rowIndex && "var(--moniteurRow)",
                        }}
                      >
                        <td className="row-index-cell">
                          <div className="row-icon">
                            <Icon
                              icon={
                                activeRow === rowIndex
                                  ? "arrow-up"
                                  : "arrow-down"
                              }
                              size={16}
                              color={activeRow !== rowIndex
                                              ? "#DADADA"
                                              : "var(--primary)"}
                            />
                            {rowIndex + 1 || "--"}{" "}
                          </div>
                        </td>
                        <td>{row?.userid || "--"}</td>
                        <td>{row?.login || "--"}</td>
                        <td>{row?.language || "--"}</td>
                        <td>{row?.experienceId || "--"}</td>
                        <td>{String(row?.bundleId) || "--"}</td>
                        <td>{row?.platform || "--"}</td>
                      </tr>
                      {activeRow === rowIndex && (
                        <tr>
                          <td
                            colSpan={usersHeaderData.length}
                            className="full-width-row"
                          >
                            <ReactJson
                              src={row}
                              theme={
                                themeMode === "light"
                                  ? "rjv-default"
                                  : "monokai"
                              }
                              collapsed={false}
                              enableClipboard={true}
                              displayObjectSize={true}
                              displayDataTypes={true}
                              name={false}
                              style={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                              }}
                            />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <NoData data1={"No Data to Display."} />
      )}
    </>
  );
}

export default UsersTable;
