import { axiosProviderNode } from "../../../../../services/http/httpService";
import { services } from "../../configVariables";

async function scriptInstallation(script, withoutCheck = false) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_SCRIPT_INSTALL;

    const params = {};
    const body = { script, withoutCheck };

    const res = await axiosProviderNode.post(
      transactionNodeService,
      body,
      params
    );

    return res?.data;
  } catch (error) {
    console.error("scriptInstallation error", error);
    return error;
  }
}

export default scriptInstallation;
