import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { dataStacked } from "../../../../../../../../../utils/chartFakeData";
import { optionsStacked } from "../../../../../../../../../utils/chartOptions";
import "./index.scss";
import { getLabelByLanguage } from "../../../../../../../../../utils/getLabelByLanguage";
import { useSelector } from "react-redux";

function StackedBar({
  label = "Add label",
  onClick = () => {},
  onDoubleClick = () => {},
  data = {},
}) {
  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  return (
    <div
      className="stackedbar-graph"
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className="stackedbar-graph-label">
        {getLabelByLanguage(language, data)}
      </div>
      <div className="stackedbar-graph-chart">
        <Bar
          data={dataStacked}
          options={optionsStacked}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
}

export default StackedBar;
