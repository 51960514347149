import React, { useEffect } from "react";
import imxtools from "imxtools";
import { handleOpenModal } from "../../service/handleModa";
import { useModal } from "../../../../services/ModalContext";
import Property from "../../../../../../components/Property";

function LookupProp({
  readOnly = true,
  data = {},
  skeleton = {},
  lookup,
  setLookup,
  XMLChanged = false,
}) {
  const { openModal } = useModal();

  useEffect(() => {
    const lookup = Object.keys(data ?? {})
      ?.filter((att) => att?.toString() === "lookup")
      ?.reduce((accumulator, key) => {
        accumulator = { key, value: data[key] };
        return accumulator;
      }, {});
    setLookup(lookup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const Lookups = imxtools?.toArray(skeleton?.maxapps?.lookups?.lookup ?? []);

  const path = ["maxapps", "lookups", "lookup"];

  return (
    <React.Fragment>
      {lookup?.value && (
        <div
          className="propertie"
          style={{
            gridTemplateColumns: readOnly ? "100%" : "96% 3%",
            gap: "1%",
          }}
          onDoubleClick={() =>
            handleOpenModal("lookup", lookup?.value, Lookups, openModal, path)
          }
        >
          <Property
            Key={lookup?.key}
            Value={lookup?.value}
            disabled={readOnly ?? true}
            index={Object.keys(data)?.indexOf("lookup")}
            data={lookup}
            setData={setLookup}
            readOnly={readOnly}
            lookup={true}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default LookupProp;
