const layouts = {
  initialState: {
    componentsLibrary: false,
    properties: false,
    codeEditor: false,
  },
  reducers: {
    compLibLayout: (state) => {
      return {
        ...state,
        componentsLibrary: !state.componentsLibrary,
      };
    },
    propLayout: (state) => {
      return {
        ...state,
        properties: !state.properties,
      };
    },
    codeLayout: (state) => {
      return {
        ...state,
        codeEditor: !state.codeEditor,
      };
    },
    reset: () => {
      return {
        ...layouts.initialState,
      };
    },
  },
};

export default layouts;
