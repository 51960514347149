import React from 'react';
import ReactJSON from 'react-json-view';
import CustomButton from '../../../../../components/CustomButton';
import themeColors from '../../../../../theme/themeColors';
import getObjectSize from '../services/getObjectSize';
import exportToFile from '../services/exportFile';
const RequestBody = ({ selectedRequest, display, setDisplay }) => {
  const requestBody = selectedRequest?.request?.requestData?.data;
  const size = getObjectSize(requestBody);
  return (
    <div className="response-options-container">
      <div
        className="response-options-header"
        onClick={() => {
          setDisplay({
            ...display,
            requestBody: !display?.requestBody,
          });
        }}
      >
        <div>
          <div className="response-key">
            <h4>Request Body</h4>
          </div>
        </div>
        <CustomButton
          iconName={display?.requestBody ? 'arrow-down' : 'arrow-right'}
          iconColor={themeColors.light.responseDataButtonArrow}
          iconSize={20}
          tooltip={
            display?.RequestBody ? 'Hide Request Body' : 'Show Request Body'
          }
        />
      </div>
      <div className="response-request-body ">
        <span className="response-area-keys">
          Size: <span className="size-value">{size} KB</span>
        </span>
        {/* import */}

        <div style={{ display: 'flex', gap: '5px' }}>
          <CustomButton
            iconName="export2"
            iconSize={20}
            iconColor="rgba(57, 53, 74, 1)"
            tooltip="Export Request Body"
            style={{
              marginLeft: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0',
            }}
            onClick={() => {
              exportToFile(
                JSON.stringify(requestBody, null, 2),
                `request-body ${new Date().toLocaleString()}`
              );
            }}
          />
          {/* copy button */}
          <CustomButton
            iconName="copy"
            iconSize={20}
            iconColor="rgba(57, 53, 74, 1)"
            tooltip="Copy Request Body"
            style={{
              marginLeft: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0',
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                typeof requestBody === 'object'
                  ? JSON.stringify(requestBody)
                  : requestBody
              );
            }}
          />
        </div>
      </div>

      <pre
        className="json-container"
        style={{
          display: display?.requestBody ? 'flex' : 'none',
          dispayDirection: 'column',
        }}
      >
        {/* object size */}

        {typeof requestBody === 'object' ? (
          <ReactJSON src={requestBody} />
        ) : (
          requestBody
        )}
      </pre>
    </div>
  );
};

export default RequestBody;
