import { createContext, useContext, useState, useEffect } from "react";
import { compareArraysOfObjects } from "../../../utils/util";
import { useSelector } from "react-redux";

const savePropsContext = createContext();

export function useSaveProps() {
  return useContext(savePropsContext);
}

export function SavePropsProvider({ children }) {
  const [oldData, setOldData] = useState({});
  const [simpleProps, setSimpleProps] = useState([]);
  const [conditions, setConditions] = useState({});
  const [scripts, setScripts] = useState([]);
  const [lookup, setLookup] = useState({});

  let dataToSet = useSelector(
    (state) => state?.entities?.editor?.dataToSet || {}
  );

  useEffect(() => {
    //SimpleProps
    const simpleProps = Object.keys(dataToSet ?? {})
      ?.filter(
        (att) =>
          att?.toString() !== "id" &&
          att?.toLowerCase() !== "itemorder" &&
          typeof dataToSet?.[att] !== "object" &&
          att?.toString() !== "condition" &&
          att?.toString() !== "lookup" &&
          !dataToSet[att]?.includes("#FCT")
      )
      ?.reduce((accumulator, key) => {
        accumulator.push({ key, value: dataToSet[key] });
        return accumulator;
      }, []);

    // Lookups
    const lookup = Object.keys(dataToSet ?? {})
      ?.filter((att) => att?.toString() === "lookup")
      ?.reduce((accumulator, key) => {
        accumulator = { key, value: dataToSet[key] };
        return accumulator;
      }, {});

    //Conditions
    const condition = Object.keys(dataToSet ?? {})
      ?.filter((att) => att?.toString() === "condition")
      ?.reduce((accumulator, key) => {
        accumulator = { key, value: dataToSet[key] };
        return accumulator;
      }, {});

    //Scripts
    const scripts = Object.keys(dataToSet ?? {})
      ?.filter((att) => dataToSet?.att?.includes("#FCT"))
      ?.reduce((accumulator, key) => {
        accumulator.push({ key, value: dataToSet[key] });
        return accumulator;
      }, []);

    setOldData([...simpleProps, ...scripts, lookup, condition]);
  }, [dataToSet]);

  const res_equal = compareArraysOfObjects(oldData, [
    ...simpleProps,
    ...scripts,
    lookup,
    conditions,
  ]);

  const contextValues = {
    simpleProps,
    conditions,
    lookup,
    scripts,
    res_equal,
    setSimpleProps,
    setConditions,
    setLookup,
    setScripts,
  };

  return (
    <savePropsContext.Provider value={contextValues}>
      {children}
    </savePropsContext.Provider>
  );
}
