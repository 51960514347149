import { axiosProviderNode } from "../../../../../services/http/httpService";
import { services } from "../../configVariables";

async function scriptsUninstallAll(checkVersions = true) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_UNINSTALL_ALL;

    const body = { checkVersions };

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    console.error("scriptsUninstallAll error", error);
    return error;
  }
}

export default scriptsUninstallAll;
