import React, { useState } from "react";
import Icon from "../../../../../components/CustomIcon/Icon";
import { utilitiesTabs } from "./utilities";
import "./index.scss";
import CustomModal from "../../../../../components/CustomModal";
import { useModal } from "../../../services/ModalContext";
import ModalWrapper from "./Modal/ModalWrapper";
import CustomButton from "../../../../../components/CustomButton";
import ConditionsUI from "./ConditionsUI/index";
import Lookups from "./Lookups/index";
import Scripts from "./Scripts/index";

function XmlUtilities({
  utilityState = "",
  handleUtilityState = () => {},
  setUtilityState,
  readOnly = true,
  XMLChanged = false,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const { isOpen, closeModal, resetModal } = useModal();
  const [showSwitchPopup, setShowSwitchPopup] = useState(false);

  const handleCloseModal = () => {
    closeModal();
    resetModal();
  };

  return (
    <>
      <div className={`utility-container ${utilityState}-utility `}>
        <div className="utility-bar">
          <div
            className="restBar"
            onClick={() =>
              handleUtilityState(
                utilityState === ""
                  ? "expend"
                  : utilityState === "expend"
                  ? "maximize"
                  : "expend",
                utilityState,
                setUtilityState
              )
            }
          >
            Additional Library
          </div>
          <div
            className="expend-icon"
            onClick={() =>
              handleUtilityState("expend", utilityState, setUtilityState)
            }
          >
            <Icon
              icon={utilityState === "" ? "arrow-up" : "reduire"}
              size={16}
            />
          </div>
          <div
            className="maximize-icon"
            onClick={() =>
              handleUtilityState("maximize", utilityState, setUtilityState)
            }
          >
            <Icon
              icon={utilityState === "" ? "minimize" : "maximize"}
              size={18}
            />
          </div>
        </div>
        {utilityState !== "" && (
          <div className={`utility-body ${utilityState}-body`}>
            <div className="utility-tabs">
              <CustomButton
                label={"Conditions"}
                color={"#B9B8BD"}
                backgroundColor={"#F7F7F7"}
                fontWeight={500}
                lineHeight={20.85}
                paddingVertical={0.72}
                paddingHorizontal={1.188}
                borderRight={true}
                borderBottom={activeTab !== "Conditions"}
                borderWidth={"1px"}
                borderColor={"#ECECEC"}
                disableClickReadOnly={false}
                withoutInteration={false}
                initialActive={false}
                onClick={() => setActiveTab("Conditions")}
                ActiveColor={"#4C36A5"}
                ActiveBackground={"#FFFFFF"}
                isActive={activeTab === "Conditions"}
              />
              <CustomButton
                label={"Lookups"}
                color={"#B9B8BD"}
                backgroundColor={"#F7F7F7"}
                fontWeight={500}
                lineHeight={20.85}
                paddingVertical={0.72}
                paddingHorizontal={1.188}
                borderRight={true}
                borderBottom={activeTab !== "Lookups"}
                borderWidth={"1px"}
                borderColor={"#ECECEC"}
                disableClickReadOnly={false}
                withoutInteration={false}
                initialActive={false}
                onClick={() => setActiveTab("Lookups")}
                isActive={activeTab === "Lookups"}
                ActiveColor={"#4C36A5"}
                ActiveBackground={"#FFFFFF"}
              />
              <CustomButton
                label={"Scripts"}
                color={"#B9B8BD"}
                backgroundColor={"#F7F7F7"}
                fontWeight={500}
                lineHeight={20.85}
                paddingVertical={0.72}
                paddingHorizontal={1.188}
                borderRight={true}
                borderBottom={activeTab !== "Scripts"}
                borderWidth={"1px"}
                borderColor={"#ECECEC"}
                disableClickReadOnly={false}
                withoutInteration={false}
                initialActive={false}
                onClick={() => setActiveTab("Scripts")}
                isActive={activeTab === "Scripts"}
                ActiveColor={"#4C36A5"}
                ActiveBackground={"#FFFFFF"}
              />
              <div className="utility-tabs-empty"></div>
            </div>

            <div className={`utab-container ${utilityState}-tab`}>
              {/* {activeTab && (
                <div
                  className='border-overlay'
                  style={{
                    position: "absolute",
                    top: 0,
                    left: `${
                      activeTab === "Conditions"
                        ? 0
                        : activeTab === "Lookups"
                        ? 116.5
                        : activeTab === "Scripts"
                        ? 215.6
                        : ""
                    }px`,
                    width: `${
                      activeTab === "Conditions"
                        ? 116.2
                        : activeTab === "Lookups"
                        ? 98.7
                        : activeTab === "Scripts"
                        ? 88.5
                        : ""
                    }px`,
                    height: "1px",
                    backgroundColor: "white",
                  }}
                ></div>
              )} */}
              {activeTab === "Conditions" ? (
                <ConditionsUI />
              ) : activeTab === "Lookups" ? (
                <Lookups />
              ) : (
                <Scripts />
              )}
            </div>
          </div>
        )}
      </div>
      <CustomModal utility={true} open={isOpen} onClose={handleCloseModal}>
        <ModalWrapper />
      </CustomModal>
    </>
  );
}
export default XmlUtilities;
