import React from "react";
import "./index.scss";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import imxtools from "imxtools";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useStore } from "react-redux";

function FilterForm({ setOpenFilterForm = () => {}, data, path }) {
  let store = useStore();
  return (
    <div className="filter-form-container">
      <div className="filter-form-content">
        {imxtools.toArray(data?.list)?.map((list, index) => {
          let numList = imxtools.toArray(data?.list)?.length;
          return (
            <div
              key={index}
              className="list-item-content"
              onClick={(e) =>
                handleComponentProp(
                  e,
                  index,
                  list,
                  numList === 1
                    ? [...path, "list"]
                    : [...path, "list", index?.toString()],
                  store,
                  "list"
                )
              }
            >
              <div className="label-icon-container">
                <Icon icon={list?.icon} size={16} />
                <span style={{ marginLeft: 10 }}>{list?.label}</span>
              </div>
              <div className="drop-down-btn">
                <i className="bx bx-chevron-down" style={{ fontSize: 26 }}></i>
              </div>
            </div>
          );
        })}
      </div>
      <div className="filter-form-footer">
        <button className="btn-cancel" onClick={() => setOpenFilterForm(false)}>
          Cancel
        </button>
        <button className="btn-submit" onClick={() => setOpenFilterForm(false)}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default FilterForm;
