import getMaximoConfig from "../../../services/getMaximoConfig";
async function getDebugInfo() {
  try {
    const response = await getMaximoConfig();

    return response;
  } catch (error) {
    console.error("Error=====>", error);
  }
}
export default getDebugInfo;
