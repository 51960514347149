export const Imoka = {
  themeName: "Imoka",
  isDark: false,
  CardViewBgColor: "#88162a10",
  secondaryLight: "#12182380",
  primary: "#169A4D",
  iconAppItem: "#169A4D",
  AppText: "#000",
  loginFooter: "#000",
  connectBtn: "#E21F26",
  loader: "#E21F26",
  headerColor: "#E21F26",
  primaryLight: "#169A4D50",
  primaryColor: "#169A4Db3",
  textColor: "#000",
  listBg: "#ffffff",
  containerLanguageIcon: "#ffffff",
  separatorColor: "#aaa",
  notFocusedLabelInput: "#aaa",
  danger: "#EE6E6E",
  warning: "#D79A28",
  secondary: "#169A4D",
  focusedBorderLoginInput: "#169A4D",
  focusedLabelLoginInput: "#169A4D",
  resetInputLogin: "#169A4D",
  languageIcon: "#169A4D",
  settingCollapseColor: "#eee",
  welcomeBg: "#FBF9F9",
  success: "#3ecd7e",
  primaryDark: "#b5171d",
  offline: "#ec4545",
  offlineLabel: "#838383",
  labelColor: "#E21F26",
  playerControl: "#FFFFFF30",
  whiteToast: "#FFF",
  loginText: "#000",
  blazeOrangeToast: "#FE6301",
  mantisToast: "#69C779",
  altoToast: "#D8D8D8",
  dustyGrayToast: "#979797",
  infoToast: "#E21F26",
  yellowToast: "#D79A28",
  greyToast: "#ccc",
  grayToast: "#aaa",
  lightToast: "#D9C9CC",
  appBg: "#f8f8f8",
  appBorder: "#e7e7e7",
  label: "#E21F26",
  groupIcon: "#ffffff",
  loginBtnLabel: "#ffffff",
  borderRecord: "#E21F2650",
  borderFormInput: "#eee",
  SelectedDrawerItem: "#D9C9CC",
  swipableBg: "#ffffff",
  landingScreenModeBg: "#F3F3F3",
  calenderBorder: "#D9C9CC",
  cancelButton: "#eee",
  listPlaceHolder: "#eee",
  fapMap: "#aaa",
  fapMapBg: "#ffffff",
  fapMapActive: "#E21F26",
  listBgCard: "#eee",
  noItemToDisplay: "#ccc",
  listItem: "#ffff",
  linkColor: "#1D9BF0",
  bgLoginInput: "#FFF",
  colorTextInput: "#000",
  LogincolorTextInput: "#000",
  LogincolorLabelInput: "#aaa",
  loginOptions: "#000",
  shadowBtnConnect: "#169A4D",
  bnConnect: "#E21F26",
  notfocusedBorderLoginInput: "#ffffff",
  headerPrimary: "#E21F2600",
  headerDark: "#b5171d00",
  headerText: "#000",
  notificationUpColor: "#FEF6F6",
  notificationDownColor: "#FCE8E9",
  listItemNotificationUpColor: "#FDF1F2",
  listItemNotificationDownColor: "#FBDFE0",
  listItemOfflineUpColor: "#FFFFFF",
  listItemOfflineDownColor: "#F4F1F1",

  notFocusedSocialMedia: "#169A4D",
  focusedSocialMedia: "#169A4Dcc",
  loginBgFooter: "#fff",
  
  //Costum4
  loginFirstTabTitle:"#222",
  loginTabTitle:"#222",
  loginTabSelected:"#fff",
  loginTabNotSelected:"#ffffff4d",

  //Custom Languge Modal
  languageContainerLeft:"#fff",
  languageContainerRight:"#169A4D",
  submitLeftConnect:"#222",
  submitRightConnect:"#fff",

};
