import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../components/CustomIcon/Icon";

// ------------ TAB BUTTON ------------------
export function TabButton({
  title = "",
  handleClick = () => {},
  active = false,
}) {
  return (
    <button
      data-testid="basic-login-btn"
      className={`tabs ${active && "active-tabs"}`}
      onClick={handleClick}
    >
      {title}
    </button>
  );
}

// ------------ CONNECT BUTTON ------------------
export function ConnectButton({ label = "", handleEvent = () => {} }) {
  return (
    <div className="ForwardButton" onClick={handleEvent}>
      {label}
    </div>
  );
}

export const ToggleButton = ({
  isExpanded = true,
  toggleFunction = () => {},
  text = "",
  icon = "",
}) => (
  <button className="Toggle-button" onClick={toggleFunction}>
    {text}
    <Icon icon={icon} size={12} style={{ marginLeft: "7px" }} />
  </button>
);
