import React, { useContext, useEffect, useState } from "react";
import Whereclause from "../Whereclause";
import Maximomedia from "../Maximomedia";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import DropTargetBody from "../DropTargetBody";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../services/AppContext";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import { useLanguageDevice } from "../../services/DeviceTranslate/LanguageContext";

function Sidebar({
  data = [],
  fullData = {},
  handleOpenSidebar = () => {},
  handleListClick = () => {},
  handleRenderView = () => {},
  currentView = "",
  path = [],
  readOnly = true,
  currentPath = [],
  classification = false,
  maximomedia = [],
}) {
  const handleClick = (event, view) => {
    handleOpenSidebar(event, false);
    handleRenderView(event, view);
  };

  const { translate } = useLanguageDevice();

  let store = useStore();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const [dataAttributes, setDataAttributes] = useState([]);

  const { setIsDragingIn } = useContext(AppContext);

  const listViews = [
    {
      tag: "body",
      view: "ListView",
      icon: "list",
      title: translate("ListView"),
    },
    {
      tag: "calendar",
      view: "CalendarView",
      icon: "calendar",
      title: translate("CalendarView"),
    },
    {
      tag: "map",
      view: "MapView",
      icon: "new-map",
      title: translate("MapView"),
    },
  ];

  useEffect(() => {
    setDataAttributes(data);
  }, [data]);
  return (
    <div
      className="sidebar-container"
      style={{
        flexDirection: language?.toLowerCase() === "ar" ? "row-reverse" : "row",
      }}
    >
      <div
        className="void"
        onClick={(event) => handleOpenSidebar(event, false)}
      ></div>
      <div className="sidebar">
        <DropTargetBody
          className={`whereclauses ${classification && "sideBar-maximomedia"}`}
          accept={["whereclause", "maximomedia"]}
          dragParent={data?.list ? path : [...path, "list"]}
        >
          <div
            className="whereclauses"
            onClick={(e) =>
              handleComponentProp(e, null, fullData?.list, path, store, "list")
            }
          >
            {dataAttributes.map((whereclause, index) => {
              const whereclausePath =
                data.length === 1
                  ? [...path, "whereclause"]
                  : [...path, "whereclause", index.toString()];
              return (
                <React.Fragment key={index}>
                  <DragItemAttribute
                    index={index}
                    component={"whereclause"}
                    className="whereclause-container"
                    dragParentPath={path}
                    dragData={whereclause}
                    dataAttributes={dataAttributes}
                    setDataAttributes={setDataAttributes}
                    setIsDraging={setIsDragingIn}
                  >
                    <Whereclause
                      key={index}
                      data={whereclause}
                      index={index}
                      path={whereclausePath}
                      readOnly={readOnly}
                      currentPath={currentPath}
                    />
                  </DragItemAttribute>
                </React.Fragment>
              );
            })}
            {maximomedia?.length > 0 &&
              maximomedia?.map((maximomedia, index) => {
                const maximomediaPath =
                  maximomedia.length === 1
                    ? [...path, "maximomedia"]
                    : [...path, "maximomedia", index.toString()];
                return (
                  <React.Fragment key={index}>
                    <DragItemAttribute
                      index={index}
                      component={"whereclause"}
                      className="whereclause-container"
                      dragParentPath={path}
                      dragData={maximomedia}
                      dataAttributes={dataAttributes}
                      setDataAttributes={setDataAttributes}
                      setIsDraging={setIsDragingIn}
                    >
                      <Maximomedia
                        key={index}
                        data={maximomedia}
                        index={index}
                        path={maximomediaPath}
                        readOnly={readOnly}
                        currentPath={currentPath}
                      />
                    </DragItemAttribute>
                  </React.Fragment>
                );
              })}
          </div>
        </DropTargetBody>
        {!classification && (
          <div className="views">
            {listViews?.map(({ tag, view, icon, title }, index) => {
              return (
                <div
                  className={
                    fullData?.list?.hasOwnProperty(tag)
                      ? currentView === view
                        ? `${view} active`
                        : `${view} inactive`
                      : `${view} notfound`
                  }
                  style={{
                    justifyContent:
                      language?.toLowerCase() === "ar" ? "space-between" : "",
                  }}
                  key={index}
                  onClick={
                    fullData?.list?.hasOwnProperty(tag)
                      ? (event) => handleClick(event, view)
                      : null
                  }
                >
                  <div className="iconView">
                    <Icon icon={icon} size={18} />
                  </div>
                  <div className="viewTitle">{title}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
