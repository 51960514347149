import React, { useEffect, useState } from "react";
import { useModal } from "../../../../../services/ModalContext";
import { useSelector, useStore } from "react-redux";
import { Close as CloseIcon } from "@mui/icons-material";
import CustomButton from "../../../../../../../components/CustomButton";
import UtilityProperty from "../Components/UtilityProperties";
import { onSave, transformArray } from "../../services/UtilityServices";
import useToasts from "../../../../../../../components/CustomToast/useToasts";
import { useTheme } from "../../../../../../../theme/themeContext";

import "./index.scss";

function ScriptsModal() {
  const { themeMode, themeColors } = useTheme();

  const [functions, setFunctions] = useState([
    { key: "reference", value: "", inputType: "text" },
    { key: "script", value: "", inputType: "expression" },
  ]);
  const {
    closeModal,
    resetModal,
    // type,
    reference,
    createNew,
    data: dataFunction,
    path,
  } = useModal();
  let store = useStore();
  const { addToast } = useToasts();
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  const handleCloseModal = () => {
    closeModal();
    resetModal();
  };

  const PropertyEditor = (data, setData) => {
    setData([...data, { key: "", value: "", inputType: "text" }]);
  };

  const FunctionData = Object.entries(dataFunction).reduce(
    (acc, [key, value]) => {
      if (key?.toLowerCase() !== "id" && key?.toLowerCase() !== "itemorder") {
        acc.push({
          key,
          value,
          inputType: key === "script" ? "expression" : "text",
        });
      }
      return acc;
    },
    []
  );

  useEffect(() => {
    if (createNew) {
      return;
    }
    setFunctions(FunctionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    const isAnyKeyEmpty = functions.some((prop) => prop.key.trim() === "");

    if (!isAnyKeyEmpty) {
      onSave(
        "script",
        createNew,
        path,
        transformArray(functions),
        skeleton,
        store
      );
      handleCloseModal();
    } else {
      <div className="custom-toast">
        {addToast("One or more keys are empty. Please fill them in.", "error")}
      </div>;
    }
  };

  return (
    <div className="FctModal-container">
      <div className={`FctModal-content ${readOnly && "FctModal-readOnly"}`}>
        <div className="FctModal-header">
          <div className="modalTitle">
            {reference !== "" && !createNew
              ? `Function: ${reference}`
              : "New Script"}
          </div>
          <div className="headerActions">
            {!readOnly ? (
              <CustomButton
                iconName="add-circle"
                iconSize={20}
                // iconColor={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                label="Add Property"
                // color={
                //   readOnly
                //     ? themeColors[themeMode].textDisabled
                //     : themeColors[themeMode].text
                // }
                gap={0.5}
                backgroundColor={"white"}
                heightBtn="2.5rem"
                fontSize={16}
                reverseBtn={true}
                paddingVertical={0.5}
                onClick={() => PropertyEditor(functions, setFunctions)}
              />
            ) : (
              <div className="closeButton">
                <CloseIcon
                  onClick={handleCloseModal}
                  sx={{
                    background: "#FF505A",
                    color: "white",
                    width: "22px",
                    height: "22px",
                    borderRadius: "2px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="Function-content">
          {functions &&
            [...functions]
              .sort((a, b) => {
                const order = { reference: 1, script: 2 };
                const orderA = order[a.key] || 3;
                const orderB = order[b.key] || 3;
                return orderA - orderB;
              })
              .map((prop, index) => (
                <div
                  className="Function-line"
                  style={{
                    // display:
                    gridTemplateColumns:
                      prop?.key === "reference" ||
                      prop?.key === "script" ||
                      readOnly
                        ? "20% 78%"
                        : "20% 73% 3%",
                  }}
                  key={index}
                >
                  <UtilityProperty
                    Key={prop?.key}
                    Value={prop?.value}
                    index={index}
                    disabled={readOnly ?? true}
                    data={functions}
                    setData={setFunctions}
                    readOnly={readOnly}
                    script={true}
                    inputType={prop?.inputType}
                    actions={
                      prop?.key !== "reference" &&
                      prop?.key !== "script" &&
                      true
                    }
                    disableKey={
                      (prop?.key === "reference" || prop?.key === "script") &&
                      true
                    }
                    mode={"javascript"}
                    minHeightExp={"10rem"}
                  />
                </div>
              ))}
        </div>
      </div>
      {!readOnly && (
        <div className="modalFooter">
          <div className="secondaryAction">
            <CustomButton
              label={"Cancel"}
              backgroundColor="white"
              widthBtn="100%"
              heightBtn="2.75rem"
              color="#39354A"
              borderColor="#EEEEEE"
              borderWidth="1px"
              fontSize={16}
              onClick={handleCloseModal}
              disableClickReadOnly={false}
              withIcon={false}
              verticalBorder={true}
            />
          </div>
          <div className="primaryAction">
            <CustomButton
              label={createNew ? "Create" : "Save"}
              backgroundColor={themeColors[themeMode].SupportButtonBg}
              borderColor="#4C36A5"
              borderWidth="1px"
              widthBtn="100%"
              heightBtn="2.75rem"
              color="white"
              fontSize={16}
              onClick={handleSave}
              withIcon={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ScriptsModal;
