import React from 'react';
import Icon from '../../../../components/CustomIcon/Icon';
const NoRequestSelected = () => {
  return (
    <div
      className="no-request-selected"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Icon icon="selectexchange" size={100} style={{ color: '#B9B8BD' }} />
      <h4
        style={{
          color: '#B9B8BD',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: 400,
        }}
      >
        Select a Request to see the full details.
      </h4>
    </div>
  );
};

export default NoRequestSelected;
