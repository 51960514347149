import React, { createContext, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18nInstanceApp from "./Languages/i18n";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n: i18 } = useTranslation();
  const language = useSelector(
    (state) => state?.entities?.settings?.language || "en"
  );

  useEffect(() => {
    i18.changeLanguage(language);
  }, [language, i18]);

  const LanguageContextValue = {
    translate: i18nInstanceApp.t.bind(i18nInstanceApp),
  };

  return (
    <LanguageContext.Provider value={LanguageContextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
