import React from 'react';
import ReactJson from 'react-json-view';
import themeColors from '../../../../../theme/themeColors';
import CustomButton from '../../../../../components/CustomButton';
import getStatusOrMethodCodeColor from '../services/getStatusOrMethodCodeColor';
import KeyCopy from './KeyCopy';
const Response = ({
  selectedRequest,

  display,
  setDisplay,
}) => {
  const resonseHeaders = selectedRequest?.response?.responseMetadata?.headers;

  const responseStatus = selectedRequest?.response?.responseMetadata?.status;

  const responseStatusText =
    selectedRequest?.response?.responseMetadata?.statusText;

  return (
    <div className="response-options-container">
      <div
        className="response-options-header"
        onClick={() => {
          setDisplay({
            ...display,
            response: !display?.response,
          });
        }}
      >
        <div>
          <div className="response-key">
            <h4>Response</h4>

            <span
              className="request-method"
              style={{
                color: getStatusOrMethodCodeColor(responseStatus),
                backgroundColor: `${getStatusOrMethodCodeColor(
                  responseStatus
                )}33`,
                border: `1px solid ${getStatusOrMethodCodeColor(
                  responseStatus
                )}`,
                fontSize: '14px',
              }}
            >
              {responseStatus}
            </span>
          </div>
        </div>
        <CustomButton
          iconName={display?.response ? 'arrow-down' : 'arrow-right'}
          iconColor={themeColors.light.responseDataButtonArrow}
          iconSize={20}
          tooltip={display?.response ? 'Hide Response' : 'Show Response'}
        />
      </div>
      {/* METHOD */}

      <div
        style={{
          display: display?.response ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <p
          style={{
            textTransform: 'uppercase',
          }}
        >
          <span className="response-area-keys">STATUS:</span>{' '}
          <span
            style={{
              color: getStatusOrMethodCodeColor(responseStatus),
            }}
          >
            {responseStatus} {responseStatusText}
          </span>
        </p>

        {/* Headers */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <KeyCopy
            text="HEADERS"
            value={resonseHeaders}
            tooltip="Copy response headers"
          />
          <pre className="json-container">
            <ReactJson src={resonseHeaders} />
          </pre>
        </div>
      </div>
    </div>
  );
};

export default Response;
