import "./AlertDialog.scss";

import React from "react";

function AlertDialog(props) {
  const { dialogHeader = null, type = null, show = false } = props;

  return (
    <>
      {show && (
        <div id="AlertDialog" className="AlertDialog" data-testid="AlertDialog">
          <div
            className={
              dialogHeader && type === "alert"
                ? "confirm-dialog confirm-dialog-grid"
                : "confirm-dialog"
            }
          >
            <div
              className={
                dialogHeader
                  ? type === "alert"
                    ? "confirm-dialog-header"
                    : "mini-confirm-dialog-header"
                  : ""
              }
            >
              <p className="confirm-message">{dialogHeader}</p>
            </div>
            <div className="confirm-dialog-footer">{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default AlertDialog;
