const getEnvVar = (key, defaultValue = "") => {
  return process.env[key] || defaultValue;
};

export const enableSocialMedia = getEnvVar("ENABLE_SOCIAL_MEDIA", "false");
export const checkLanguageModal = getEnvVar("CHECK_LANGUAGE_MODAL", "true");
export const displayOptions = getEnvVar(
  "DISPLAY_OPTION",
  "some-default-value",
  "false"
);
export const displayIpDomain = getEnvVar(
  "DISPLAY_IP_DOMAINE",
  "default-domain"
);
