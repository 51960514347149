import imxtools from "imxtools";
import { filterPageData } from "../../screens/HomeScreen/services/filterPageData";
import DragItemAttribute from "../../../../../ComponentsLibrary/DragItemAttribute";
import Row from "../../Row";

function RenderRows(
  path = [],
  readOnly = true,
  currentPage = 0,
  pages = [],
  PageContent = {},
  rowContent,
  setRowContent,
  setIsDragingIn
) {
  const rows = imxtools?.toArray(filterPageData(PageContent)?.row ?? []);
  return rows.length > 0
    ? rows.map(function (row, index) {
        return (
          <DragItemAttribute
            index={index}
            className="drag-row-zone"
            component={"row"}
            dragParentPath={path}
            dragData={row}
            dataAttributes={rowContent}
            setDataAttributes={setRowContent}
            setIsDraging={setIsDragingIn}
          >
            <Row
              data={row}
              path={path}
              key={index}
              numRow={rows?.length}
              indexRow={rows?.length === 1 ? null : index.toString()}
              readOnly={readOnly}
              currentPage={currentPage}
              pages={pages}
            />
          </DragItemAttribute>
        );
      })
    : null;
}

export default RenderRows;
