import React from "react";
import { useDrop } from "react-dnd";
import "./index.scss";

const DropUtility = ({
  children,
  component = "",
  conditions = {},
  scripts = [],
  setConditions,
  setLookup,
  setScripts,
}) => {
  const dropDownItems = {
    field: ["init", "action"],
    attribute: ["init", "action"],
    form: ["on_click_sumbit_hook", "on_sumbit_hook", "on_success_hook"],
    kpi: ["value"],
  };
  const [, drop] = useDrop({
    accept: ["condition", "lookup", "function"],
    drop: (item) => {
      let Reference = "";
      if (item?.type === "condition") {
        Reference = conditions?.value
          ? `${conditions?.value},${item?.reference}`
          : item?.reference;
        if (conditions?.value?.includes(item?.reference)) {
          return;
        }
        setConditions({
          key: "condition",
          value: Reference,
        });
      }
      if (item?.type === "function") {
        if (!Object.keys(dropDownItems)?.includes(component)) {
          return;
        }
        Reference = `#FCT(${item?.reference}) `;
        setScripts([...scripts, { key: "", value: Reference }]);
      }
      if (item?.type === "lookup") {
        Reference = `${item?.reference}`;
        setLookup({ key: "lookup", value: Reference });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div style={{ width: "100%", height: "100%" }} ref={drop}>
      {children}
    </div>
  );
};

export default DropUtility;
