import diff_match_patch from "diff-match-patch";

function calculateDifferences(dv, addToast) {
  try {
    if (!dv) return; // Ensure the MergeView instance is available

    const originalText = dv.editor().getValue();
    const modifiedText = dv.rightOriginal().getValue(); // Assuming the changes are on the right side

    const dmp = new diff_match_patch();
    const diffs = dmp.diff_main(originalText, modifiedText);
    dmp.diff_cleanupSemantic(diffs);

    let addedLines = 0;
    let removedLines = 0;
    diffs.forEach((part) => {
      // Split the text into lines for more detailed counting
      const lines = part[1].split("\n");

      // Count non-empty lines only, as empty lines are not "text" changes
      const nonEmptyLines = lines.filter((line) => line.trim() !== "");

      if (part[0] === 1) {
        // Insertion
        addedLines += nonEmptyLines.length;
      } else if (part[0] === -1) {
        // Deletion
        removedLines += nonEmptyLines.length;
      }
    });

    return { addedLines, removedLines };
  } catch (error) {
    addToast(
      "Failed to calculate differences between the two XML files. Please try again.",
      "warn"
    );
    throw error;
  }
}

export default calculateDifferences;
