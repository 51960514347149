import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../../services/http/httpService";

async function updateVersion(filename, store, skeleton, maxOrder) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_UPDATE_FILE_CONTENT;
    const transactionPayload = {
      filename,
      content: skeleton,
    };

    const res = await serviceRequest.post(
      transactionNodeService,
      transactionPayload
    );
    return res?.data?.content;
  } catch (error) {
    return null;
  }
}

export default updateVersion;
