import React from "react";
import "./index.scss";
import { ArcGauge, CircularGauge } from "@progress/kendo-react-gauges";

const value = 30;

const centerRenderer = () => {
  return (
    <h3
      className="gauge-h3"
      style={{
        fontSize: 12,
      }}
    >
      {30}%
    </h3>
  );
};

function Gauge({
  size = "small",
  label = "Add label",
  data = {},
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  return (
    <div
      className={`gauge ${size?.toLowerCase() !== "small" && "gauge-large"}`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div
        style={{
          display: "flex",
          width: "80%",
          height: "80%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data?.type?.toLowerCase() === "gauge" ? (
          <CircularGauge
            className="gaugeGraph"
            value={value}
            centerRender={centerRenderer}
          />
        ) : (
          <ArcGauge
            className="gaugeGraph"
            value={value}
            centerRender={centerRenderer}
          />
        )}
      </div>
      <div>
        <div>{label}</div>
      </div>
    </div>
  );
}

export default Gauge;
