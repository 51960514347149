function HandleImportFile(side, showLeft, showRight, draftDisplay, xmlData, selectedFile,store) {
  if (side) {
    store.dispatch({
      type: "compareFiles/setLeftMode",
      payload: {
        leftMode: "Imported",
      },
    });
    store.dispatch({
      type: "compareFiles/setLeftXmlValue",
      payload: {
        leftXml: xmlData,
      },
    });
    store.dispatch({
      type: "compareFiles/setLeftSideShow",
      payload: {
        showLeft: true,
      },
    });
    store.dispatch({
      type: "compareFiles/setLeftFileName",
      payload: {
        leftFile: selectedFile.name.replace(/\.xml$/, ""),
      },
    });
    if (showRight === true) {
      store.dispatch({
        type: "compareFiles/updateCompareState",
        payload: {
          compare: true,
        },
      });
    }
  } else {
    store.dispatch({
      type: "compareFiles/setRightMode",
      payload: {
        rightMode: "Imported",
      },
    });
    store.dispatch({
      type: "compareFiles/setRightXmlValue",
      payload: {
        rightXml: xmlData,
      },
    });
    store.dispatch({
      type: "compareFiles/setRightSideShow",
      payload: {
        showRight: true,
      },
    });
    store.dispatch({
      type: "compareFiles/setRightFileName",
      payload: {
        rightFile: selectedFile.name.replace(/\.xml$/, ""),
      },
    });
    if (showLeft || draftDisplay) {
      store.dispatch({
        type: "compareFiles/updateCompareState",
        payload: {
          compare: true,
        },
      });
    }
  }
}
export default HandleImportFile;
