import React, { useEffect } from "react";
import "./index.scss";
import logoImage from "./../../../../../../../../assets/maxapps-assets/splash.png";
import { navigateTo } from "../../../services/navigationMiddleware";
import { useSelector, useStore } from "react-redux";

function SplashScreen() {
  const loginStatus = useSelector(
    (state) => state?.entities?.deviceview?.loginStatus
  );

  const store = useStore();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigateTo(store, loginStatus ? "maxapps" : "LoginScreen", {});
    }, 2000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="splashScreen">
      <img src={logoImage} alt="logoSplash" className="logoSplash" />
    </div>
  );
}

export default SplashScreen;
