import { handleComponentProp } from "./handleComponentProperties";

export const navigateBack = (store) => {
  try {
    store.dispatch({
      type: "deviceview/navigateBack",
    });
  } catch (error) {
    console.error("An error occurred while navigating back:", error);
  }
};

export const navigateTo = (store, component = "Home", props = {}) => {
  try {
    store.dispatch({
      type: "deviceview/navigateTo",
      payload: {
        component: component,
        props: props,
      },
    });

    
  } catch (error) {
    console.error("An error occurred while navigating to:", error);
  }
};
export const navigateToWithDrawer = (
  store,
  component = "Home",
  props = {},
  event = () => {},
  data = {},
  path = []
) => {
  try {
    if (data?.list) {
      handleComponentProp(event, null, data?.list, path, store, "list");
    }
    const pageIndex = getPageIndex(path);
    store.dispatch({
      type: "deviceview/setCurrentPage",
      payload: {
        currentPage: pageIndex,
      },
    });
    store.dispatch({
      type: "deviceview/navigateToWithDrawer",
      payload: {
        component: component,
        props: props,
      },
    });
  } catch (error) {
    console.error("An error occurred while navigating with drawer to:", error);
  }
};

function getPageIndex(path) {
  const pageIndex = path.indexOf("page");

  if (pageIndex !== -1 && pageIndex + 1 < path.length) {
    const pageNumber = parseInt(path[pageIndex + 1], 10);

    if (!isNaN(pageNumber)) {
      return pageNumber;
    }
  }
  return 0;
}
