import imxtools from "imxtools";

function transformForStore(obj) {
  if (!obj || typeof obj !== "object") {
    return {};
  }
  if (obj?.length === 0) {
    return {};
  }
  for (var item in obj) {
    if (item === "name" || item === "attribute_name") {
      obj[item] = obj?.[item]?.toUpperCase();
    }
    if (typeof obj[item] === "object") {
      let key = imxtools.generateUniqueKey(item);

      if (obj[item].hasOwnProperty("$")) {
        obj[item] = {
          ...obj[item],
          ...obj[item]["$"],
          id: `imxComponent${key}`,
        };

        delete obj[item]["$"];
      }
      transformForStore(obj[item]);
    }
  }

  return obj;
}

export default transformForStore;
