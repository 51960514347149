import React from "react";
import * as GraphKPIs from "./GraphComponents";
import UnknownGraphKPI from "./UnknownGraphKPI";

function GraphKpi({
  data = {},
  hadleGraphClick: hadleKPIClick = () => {},
  handleNavigateTo = () => {},
  path = [],
}) {
  const KPIGraphComponentAtt = {
    size: data?.graph?.size,
    label: data?.graph?.label,
    data: data?.graph,
    onClick: (event) => {
      hadleKPIClick("graph", event);
    },
    onDoubleClick: (event) => {
      handleNavigateTo(event, "graph", data?.graph);
    },
  };

  const RenderKPIGraphComponent = (prop) => {
    const GraphKpiType = data?.graph?.type?.toLowerCase();
    if (Object.prototype.hasOwnProperty.call(GraphKPIs, GraphKpiType)) {
      const TargetComponent = GraphKPIs[GraphKpiType];
      return <TargetComponent {...prop} />;
    } else {
      return (
        <UnknownGraphKPI
          UnknownType={GraphKpiType}
          size={data?.graph?.size}
          data={data}
          path={path}
        />
      );
    }
  };

  return RenderKPIGraphComponent(KPIGraphComponentAtt);
}

export default GraphKpi;
