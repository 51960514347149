import React, { useContext, useEffect, useState } from "react";
import imxtools from "imxtools";
import Field from "../Field";
import HeaderForm from "../HeaderForm";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import Geomodule from "../Geomodule";
import Title from "../Titre";
import "./index.scss";
import DropTargetBody from "../DropTargetBody";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../services/AppContext";
import { arraysAreEqual } from "../../../../../../../utils/util";
import { useLanguageDevice } from "../../services/DeviceTranslate/LanguageContext";
import { handleNavigateTo } from "../PageBody/services";
import { List } from "@mui/material";

function Form({
  data = {},
  path = [],
  parentPath = [],
  component = "",
  hform = false,
  setOpenForm = () => {},
  readOnly = false,
  titleForm,
  inGis = false,
  setAppAccept = () => {},
  isApp = false,
  onSubmit = () => {},
  header = false,
}) {
  let store = useStore();
  const [dataFileds, setDataFields] = useState([]);
  const [hoveredZone, setHoveredZone] = useState(false);
  const { translate } = useLanguageDevice();
  const [formClicked, setFormClicked] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const {
    setIsDragingIn,
    listFab,
    setListFab,
    setEventApp,
    SetFilterHeaderForm,
  } = useContext(AppContext);

  const handleComp = (e, data, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
    setFormClicked(true);
  };
  let edit = useSelector((state) => state?.entities?.editor);

  useEffect(() => {
    setDataFields(imxtools?.toArray(data?.field));
  }, [data?.field]);
  useEffect(() => {
    if (formClicked && shouldSubmit) {
      const submitForm = async () => {
        setOpenForm(false);
        if (isApp) {
          setAppAccept(true);
        }
        try {
          await onSubmit();
        } catch (error) {
          console.error("Error in submit:", error);
          // Handle the error appropriately
        }
        setFormClicked(false);
        setShouldSubmit(false);
      };
      submitForm();
    }
  }, [formClicked, shouldSubmit]);

  return (
    <div
      className="form-container"
      style={
        !inGis ? { position: "absolute", bottom: 0, left: header && "4px" } : {}
      }
    >
      <div
        className="close-form"
        onClick={() => {
          setOpenForm(false);
          setListFab(false);
          setEventApp(false);
          SetFilterHeaderForm(false);
        }}
      ></div>
      {Boolean(data) ? (
        <div
          className="form-content"
          onClick={(e) => {
            handleComp(e, data, path, component);
            setListFab(false);
          }}
        >
          <div
            className={
              !readOnly
                ? arraysAreEqual(path, edit.parentToEdit)
                  ? `form-title  ${"selectedComponent"}`
                  : `form-title  ${"unSelectedComponent"}`
                : "form-title"
            }
          >
            {hform
              ? "This is a hidden form (hform)"
              : data?.label
              ? data?.label
              : "Add Label"}
          </div>

          <div className="form-body">
            {data?.geomodule && (
              <div className="geomodule-container">
                <Geomodule data={data?.geomodule} path={path} />
              </div>
            )}
            {data?.title && (
              <div className="title-map">
                <Title data={data?.title} path={path} titleForm={titleForm} />
              </div>
            )}
            {data?.header ? (
              <div
                className={
                  !readOnly
                    ? arraysAreEqual([...path, "header"], edit.parentToEdit)
                      ? `form-header  ${"selectedComponent"}`
                      : `form-header  ${"unSelectedComponent"}`
                    : "form-header"
                }
                onClick={(event) => {
                  handleComponentProp(
                    event,
                    null,
                    data?.header,
                    path,
                    store,
                    "header"
                  );
                  SetFilterHeaderForm(true);
                }}
              >
                <DropTargetBody
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderRadius: 6,
                    marginTop: 5,
                    marginLeft: 5,
                    marginRight: 5,
                    padding: 5,
                    borderWidth: 1,
                    borderColor: "#000",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                  dragParent={[...path, "header"]}
                  accept={["media", "takegeoposition"]}
                >
                  <HeaderForm data={data?.header} path={[...path, "header"]} />
                </DropTargetBody>
              </div>
            ) : (
              <></>
            )}
            {data?.field ? (
              <div className="form-fileds">
                <DropTargetBody
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    borderRadius: 6,
                    marginTop: 5,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                  dragParent={path}
                  accept={["field", "header", "geomodule", "title"]}
                >
                  {imxtools?.toArray(data?.field)?.map((field, index) => {
                    const numFields = imxtools?.toArray(data?.field)?.length;
                    return (
                      <DragItemAttribute
                        index={index}
                        component={"field"}
                        style={{
                          display: "flex",
                          width: "100%",
                          padding: 15,
                          justifyContent: "flex-end",
                          borderBottomLeftRadius: dataFileds ? "9px" : "0px",
                          borderBottomRightRadius: dataFileds ? "9px" : "0px",
                        }}
                        dragParentPath={path}
                        dragData={field}
                        dataAttributes={dataFileds}
                        setDataAttributes={setDataFields}
                        setIsDraging={setIsDragingIn}
                      >
                        <React.Fragment key={index}>
                          <Field
                            key={index}
                            data={field}
                            path={path}
                            numFields={numFields}
                            index={index}
                            readOnly={readOnly}
                          />
                        </React.Fragment>
                      </DragItemAttribute>
                    );
                  })}
                </DropTargetBody>
              </div>
            ) : (
              <div
                style={
                  !readOnly
                    ? {
                        padding: 8,
                        backgroundColor: hoveredZone ? "#fff" : "#fff",
                        height: "100%",
                        width: "100%",
                        borderRadius: 9,
                      }
                    : {}
                }
                onMouseEnter={() => setHoveredZone(true)}
                onMouseLeave={() => setHoveredZone(false)}
              >
                <DropTargetBody
                  style={
                    !readOnly
                      ? {
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          borderRadius: 6,
                          marginTop: 5,
                        }
                      : {}
                  }
                  dragParent={path}
                  accept={"field"}
                ></DropTargetBody>
              </div>
            )}
          </div>
          {hform ? (
            <></>
          ) : (
            <div className="form-buttons">
              <div
                className="cancelBtn"
                onClick={() => {
                  setOpenForm(false);
                  setListFab(false);
                  setEventApp(false);
                  SetFilterHeaderForm(false);
                }}
              >
                {translate("Cancel")}
              </div>
              <div
                className="SubmitBtn"
                onClick={(e) => {
                  SetFilterHeaderForm(false);
                  e.stopPropagation(); // Prevent triggering handleComp
                  setShouldSubmit(true);
                  if (!formClicked) {
                    // If form wasn't clicked, trigger submit immediately
                    setOpenForm(false);
                    if (isApp) {
                      setAppAccept(true);
                      setEventApp(false);
                    }
                    onSubmit();
                  }
                }}
              >
                {translate("Submit")}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="empty-form-content"
          onClick={(e) => {
            handleComp(e, data, parentPath, "list");
            setListFab(true);
          }}
        >
          <DropTargetBody
            accept={["form", "hform"]}
            dragParent={path}
            style={{
              width: "100%",
              overflowY: "auto",
              height: "100%",
              padding: 10,
              backgroundColor: "#fff",
              borderRadius: `9px 9px 36px 36px`,
            }}
          ></DropTargetBody>
        </div>
      )}
    </div>
  );
}

export default Form;
