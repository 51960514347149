import imxtools from "imxtools";

export function handleConditionBackgroundColor(ref = "", conditions) {
  const condition = imxtools
    ?.toArray(conditions || [])
    .find((c) => c?.reference === ref);
  if (!condition) {
    return null;
  }
  const prop = imxtools
    ?.toArray(condition?.property || [])
    .find((prop) => prop?.value?.includes("backgroundColor:"));
  if (!prop) {
    return null;
  }
  const [, backgroundColor] = prop?.value?.split("backgroundColor:");
  return backgroundColor?.trim() || null;
}
