import uninstallAllFromMaximo from "../../../../services/uninstallAllFromMaximo/uninstallAllFromMaximo";

function uninstallAll(
  checkVersions = true,
  setShowPopUpOptions = () => {},
  setFailedToClear = () => {},
  setToClear = () => {},
  setFinish = () => {},
  setErrorMessage = () => {}
) {
  setShowPopUpOptions(true);
  setFailedToClear(false);
  setToClear(true);
  uninstallAllFromMaximo(checkVersions)
    .then((response) => {
      if (
        response?.success &&
        response?.content?.status?.toUpperCase() === "SUCCESS"
      ) {
        setFinish(true);
        setToClear(false);
      } else {
        const errorMessage =
          response?.content?.message === ""
            ? response?.message
            : response?.content?.message;
        setToClear(false);
        setErrorMessage(errorMessage);
        setFailedToClear(true);
      }
    })
    .catch((error) => {
      setToClear(false);
      setErrorMessage(error.message);
      setFailedToClear(true);
    });
}
export default uninstallAll;
