function deepSearch(obj, key, value) {
  if (obj === null || typeof obj !== "object") {
    return false
  }

  if (obj.hasOwnProperty(key)) {
    if (Array.isArray(obj[key])) {
      return obj[key].some((item) =>
        String(item).toLowerCase().includes(value.toLowerCase())
      )
    }
    return String(obj[key]).toLowerCase().includes(value.toLowerCase())
  }

  return Object.keys(obj).some((k) => deepSearch(obj[k], key, value))
}

export default function AdvancedFilterDataCollection(data = [], criteria = {}) {
  const filteredData = data?.filter((item) => {
    return Object.keys(criteria).every((key) => {
      const value = criteria[key]
      if (!value) {
        return true
      }
      return deepSearch(item, key, value)
    })
  })

  return filteredData
}
