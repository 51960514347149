import React, { useEffect, useState } from "react";
import "./index.scss";
import getDebugInfo from "./services/getDebugInfo";
import ReactJson from "react-json-view";
import Lottie from "react-lottie";
import { LoadingOptions } from "../Dashboard/data";
import Icon from "../../../../components/CustomIcon/Icon";
import NoData from "../Dashboard/components/NoData";
function Debugger() {
  const [maximoConfig, setMaximoConfig] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredConfig, setFilteredConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    getDebugInfo().then((response) => {
      if (!isCancelled) {
        setMaximoConfig(response);
        setFilteredConfig(response);
        setLoading(false);
      }
    });

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        setLoading(false);
      }
    }, 15000);

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, []);

  const filterObject = (obj, searchValue) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (!filterObject(obj[key], searchValue)) {
          delete obj[key];
        }
      } else if (
        !String(obj[key]).toLowerCase().includes(searchValue.toLowerCase())
      ) {
        delete obj[key];
      }
    }
    return Object.keys(obj).length > 0;
  };

  useEffect(() => {
    if (maximoConfig) {
      const filtered = JSON.parse(JSON.stringify(maximoConfig));
      if (searchValue) {
        filterObject(filtered, searchValue);
        setFilteredConfig(filtered);
      } else {
        setFilteredConfig(maximoConfig);
      }
    }
  }, [searchValue, maximoConfig]);

  return (
    <div className="debugger-container">
      {loading ? (
        <Lottie
          options={LoadingOptions}
          height={100}
          width={120}
          isClickToPauseDisabled={true}
          style={{ cursor: "auto" }}
        />
      ) : filteredConfig ? (
        <>
          <div className="search-table">
            <Icon icon={"search"} size={22} style={{ color: "#989898" }} />
            <input
              type="text"
              className="dashboard-search-input"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                }
              }}
            />
            {searchValue && (
              <button
                className="reset-search"
                onClick={() => setSearchValue("")}
              >
                <Icon
                  icon={"circle-close-new"}
                  size={22}
                  style={{ color: "#C4C3C9" }}
                />
              </button>
            )}
          </div>
          <ReactJson src={filteredConfig} />
        </>
      ) : (
        <NoData data1={"No Data to Display."} />
      )}
    </div>
  );
}

export default Debugger;
