import React from "react";
import { useStore } from "react-redux";
import GraphKpi from "./GraphKpi";
import SampleKpi from "./SampleKpi";
import "./index.scss";
import { navigateTo } from "../../services/navigationMiddleware";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import UnknownKPI from "./UnknownKPI";

function Kpi({
  data = {},
  path = [],
  readOnly = true,
  currentPath = [],
  inPage = false,
}) {
  let store = useStore();
  function handleNavigateTo(event, kpiType, data) {
    event.stopPropagation();
    const newPath = [...path, kpiType];
    handleComponentProp(event, null, data, newPath, store, "detail");
    navigateTo(store, "List", { data, path: newPath });
  }

  const hadleKPIClick = (type, event) => {
    event.stopPropagation();
    const kpidata = type === "graph" ? data?.graph : data?.detail;
    handleComponentProp(event, null, kpidata, path, store, type);
  };

  return !data?.graph && !data?.detail ? (
    <UnknownKPI data={data} path={path} />
  ) : data?.graph ? (
    <div
      className={`${inPage ? "graphKpi-page" : "graphKpi"} ${
        isSelectedComponent(readOnly, [...path, "graph"], currentPath) &&
        "selectedComponent"
      }`}
    >
      <div
        className={`${inPage ? "graph-container-page" : "graph-container"} ${
          data?.graph?.size?.toLowerCase() !== "small" && inPage
            ? "graph-large-page"
            : "graph-large"
        }`}
      >
        <GraphKpi
          data={data}
          hadleGraphClick={hadleKPIClick}
          handleNavigateTo={handleNavigateTo}
          path={path}
          inPage={inPage}
        />
      </div>
    </div>
  ) : (
    <div
      className={`sampleKpi ${
        isSelectedComponent(readOnly, [...path, "detail"], currentPath) &&
        "selectedComponent"
      }`}
    >
      <SampleKpi
        data={data}
        size={data?.size?.toLowerCase()}
        hadleGraphClick={hadleKPIClick}
        handleNavigateTo={handleNavigateTo}
        path={path}
        currentPath={currentPath}
        readOnly={readOnly}
        inPage={inPage}
      />
    </div>
  );
}

export default Kpi;
